export function calculateMonthDifference(startDateStr, endDateStr) {
  // Parse the date strings into Date objects
  let startDate = new Date(startDateStr)
  let endDate = new Date(endDateStr)

  // Calculate year and month differences
  let yearsDifference = endDate.getFullYear() - startDate.getFullYear()
  let monthsDifference = endDate.getMonth() - startDate.getMonth()

  // Total difference in months
  let totalMonthsDifference = yearsDifference * 12 + monthsDifference

  // Adjust: Add 1 if the end day is the last day of the month
  if (
    endDate.getDate() ===
    new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate()
  ) {
    totalMonthsDifference += 1
  }

  return totalMonthsDifference
}

export function calculateTotalLeasePayment(monthlyPayment, leaseTermInMonths) {
  return monthlyPayment * leaseTermInMonths
}

export function calculatePV(annualRate, leaseMonths, monthlyPayment) {
  // Convert the annual rate to a monthly rate
  const monthlyRate = annualRate / 12 / 100

  // Formula for Present Value of an annuity when payments are made at the beginning of the period (type = 1)
  const pvFactor = (1 - Math.pow(1 + monthlyRate, -leaseMonths)) / monthlyRate

  // Adjust for payment at the beginning of the period
  const presentValue = monthlyPayment * pvFactor * (1 + monthlyRate)

  if (isNaN(presentValue)) return 0
  else return presentValue
}
