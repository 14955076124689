import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ConsolidateListing } from 'containers/ARAPModule/Components/Listing/ConsolidateListing'
import {
  AcctPermission,
  EInvoiceTrxType,
  useGetEinvoiceConsolidationLazyQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

export const ARConsolidateEInvoiceListing = (props: any) => {
  const [isReset, setIsReset] = useState(false)
  const documentType = [
    EInvoiceTrxType.Invoice,
    EInvoiceTrxType.CreditNote,
    EInvoiceTrxType.DebitNote,
    EInvoiceTrxType.RefundNote,
    // { value: 'All', einvoice_type: 'ALL' },
  ]

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  const {
    register: registerFilter,
    watch: watchFilter,
    control: controlFilter,
    setValue: setValueFilter,
    getValues: getValueFilter,
  } = useForm()

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    fetchConsolidateEinvoice,
    {
      loading: EinvoiceConsolidationLoading,
      data: { getEinvoiceConsolidation } = { getEinvoiceConsolidation: [] },
    },
  ] = useGetEinvoiceConsolidationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getEinvoiceConsolidation }) => {
      if (!!isReset) {
        setIsReset(false)
        registerFilter('DocType')
        setValueFilter('DocType', [])
      }
      if (getEinvoiceConsolidation?.length > 0) {
        setOriginalListing([...filteredList, ...getEinvoiceConsolidation])
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  return (
    <>
      {EinvoiceConsolidationLoading && <Loading />}
      <ConsolidateListing
        module={'Accounts Receivable'}
        title={'e-Invoice'}
        accountType={'account-receivable'}
        page={'consolidate-einvoice'}
        documentType={documentType}
        fetchConsolidateData={fetchConsolidateEinvoice}
        loading={EinvoiceConsolidationLoading}
        setOriginalListing={setOriginalListing}
        filteredList={filteredList}
        createPerm={AcctPermission.AccReceivableConsolidateEinvoiceCreate}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
