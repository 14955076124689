import { Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

export const AssetsRegisterDepreciationComponent = (props: any) => {
  const {
    listMethod,
    watch,
    setValue,
    mode,
    errors,
    control,
    register,
    accPeriodYears,
    getAccountPeriod,
    AssetsRegistrationID,
    editData,
    setSelectedMethod,
    setMethodID,
    setStartYear,
    setStartPeriod,
    setDepreciationAmt,
    depreciationAmt,
    setUsefulLife,
    usefulLife,
  } = props

  const getStartYearOptions = () => {
    const startYears = Array.from(accPeriodYears)
    const stringStartYears = startYears.map(year => String(year))
    stringStartYears.sort((a: any, b: any) => b - a)
    return stringStartYears
  }

  const getFinancialAccountPeriod = getAccountPeriod?.filter(
    x => x?.FYear === watch('StartYear') && x?.MonthEndClose === false
  )

  const handleStartYearChange = (value, newValue: any) => {
    setValue('StartYear', newValue)
  }

  const handleDepreciationAmtChange = event => {
    setDepreciationAmt(event.value)
  }

  const handleUsefulLifeChange = event => {
    setUsefulLife(event.value)
  }

  return (
    <>
      <Controller
        render={({ value, onChange }) => {
          const defVal = listMethod?.filter(
            coa => coa?.MethodID === editData?.MethodID
          )[0]
          return (
            <Autocomplete
              options={
                listMethod?.sort((a, b) => {
                  return a?.method?.localeCompare(b?.method)
                }) || []
              }
              getOptionLabel={option => {
                return `${option?.method}`
              }}
              fullWidth
              onChange={(value, newValue: any) => {
                if (!newValue) {
                  onChange(null)
                  setSelectedMethod(null)
                  setMethodID(null)
                } else {
                  onChange(newValue?.MethodID)
                  setSelectedMethod(newValue)
                  setMethodID(newValue?.MethodID)
                }
              }}
              defaultValue={defVal}
              disabled={mode === 'approve-reject'}
              renderOption={(props, option) => {
                return (
                  <div>
                    <div>
                      <span className="xsTitle">{props?.method}</span>
                    </div>
                  </div>
                )
              }}
              renderInput={(params: any) => {
                return (
                  <div>
                    <TextField
                      {...params}
                      helperText={errors?.MethodID?.message}
                      error={errors?.MethodID ? true : false}
                      label="Method"
                      required
                      style={{ width: '100%' }}
                      margin="normal"
                      variant="standard"
                    />
                  </div>
                )
              }}
            />
          )
        }}
        label="Method"
        name="MethodID"
        autoComplete="off"
        control={control}
        multiline={true}
        required
        fullWidth
        margin="normal"
        ref={register}
        helperText={errors?.MethodID?.message}
        error={errors?.MethodID ? true : false}
        defaultValue={editData?.MethodID}
      />
      <Grid
        item
        xs={6}
        style={{
          marginBottom: '5px',
          paddingRight: '10px',
        }}
      >
        <Controller
          render={({ value, onChange }) => {
            // const defVal = getRecurringJournal?.filter(
            //   x =>
            //     x?.RecurringJournalID === editData?.RecurringJournalID
            // )[0]
            return (
              <Autocomplete
                options={getStartYearOptions()}
                getOptionLabel={option => option}
                fullWidth
                onChange={(value, newValue: any) => {
                  handleStartYearChange(value, newValue)
                  setValue('StartYear', Number(newValue))
                  setStartYear(Number(newValue))
                }}
                renderOption={(props, option) => {
                  return <span>{props}</span>
                }}
                // defaultValue={
                //   defVal?.StartYear?.toString() ??
                //   editData?.StartYear?.toString()
                // }
                renderInput={(params: any) => {
                  return (
                    <div>
                      <TextField
                        {...params}
                        label="Start Year"
                        required
                        style={{ width: '100%' }}
                        margin="dense"
                        type="number"
                      />
                    </div>
                  )
                }}
              />
            )
          }}
          required
          label="Start Year"
          name="StartYear"
          autoComplete="off"
          control={control}
          multiline={true}
          fullWidth
          margin="dense"
          ref={register}
          helperText={errors?.StartYear?.message}
          error={errors?.StartYear ? true : false}
        />
      </Grid>

      <Grid
        item
        xs={6}
        style={{
          marginBottom: '5px',
          paddingRight: '10px',
        }}
      >
        <Controller
          render={({ value, onChange }) => {
            return (
              <Autocomplete
                options={getFinancialAccountPeriod || []}
                getOptionLabel={option => String(option?.FPeriod)}
                fullWidth
                value={
                  getFinancialAccountPeriod.find(
                    option => option?.FPeriod === value
                  ) || null
                }
                onChange={(value, newValue: any) => {
                  onChange(newValue?.FPeriod)
                  setStartPeriod(newValue?.FPeriod)
                }}
                renderOption={(props, option) => {
                  return (
                    <div>
                      <div>
                        <span className="xsTitle">{props?.FPeriod}</span>
                      </div>
                      <div className="desc">
                        {`${formatDate(props?.StartDate)} - ${formatDate(
                          props?.EndDate
                        )}`}
                      </div>
                    </div>
                  )
                }}
                renderInput={(params: any) => {
                  return (
                    <div>
                      <TextField
                        {...params}
                        helperText={errors?.StartPeriod?.message}
                        error={errors?.StartPeriod ? true : false}
                        label="Start Period"
                        style={{ width: '100%' }}
                        margin="dense"
                        type="number"
                        required
                      />
                    </div>
                  )
                }}
              />
            )
          }}
          label="Start Period"
          name="StartPeriod"
          autoComplete="off"
          control={control}
          multiline={true}
          fullWidth
          margin="dense"
          ref={register}
          helperText={errors?.StartPeriod?.message}
          error={errors?.StartPeriod ? true : false}
          required
          className="right"
          disabled={mode === 'approve-reject' || !watch('StartYear')}
          // defaultValue={editData?.StartPeriod ?? ''}
        />
      </Grid>

      <Controller
        as={<NumberFormat />}
        thousandSeparator
        customInput={TextField}
        id="standard-basic"
        name="DepreciationAmt"
        label="Acc. Depreciation Amount"
        value={depreciationAmt}
        autoComplete="off"
        control={control}
        onValueChange={e => {
          handleDepreciationAmtChange(e)
        }}
        decimalScale={2}
        fixedDecimalScale
        margin="normal"
        required
        helperText={errors?.DepreciationAmt?.message}
        error={errors?.DepreciationAmt ? true : false}
        ref={register}
      />

      <Controller
        as={<NumberFormat />}
        thousandSeparator
        customInput={TextField}
        id="standard-basic"
        name="UsefulLife"
        label="Useful Life (Period)"
        value={usefulLife}
        autoComplete="off"
        control={control}
        onValueChange={e => {
          handleUsefulLifeChange(e)
        }}
        decimalScale={2}
        fixedDecimalScale
        margin="normal"
        required
        helperText={errors?.UsefulLife?.message}
        error={errors?.UsefulLife ? true : false}
        ref={register}
      />
    </>
  )
}
