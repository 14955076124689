import React, { lazy } from 'react'

const AssetsAdditionalFieldSettingListing = lazy(() =>
  import('./AssetsAdditionalFieldSettingListing').then(module => ({
    default: module.AssetsAdditionalFieldSettingListing,
  }))
)

const assetsAdditionalFieldSettingRoutes = [
  {
    props: {
      exact: true,
      path: '/fixed-assets/general-setting/assets-additional-field-setting',
    },
    component: <AssetsAdditionalFieldSettingListing />,
  },
]

export default assetsAdditionalFieldSettingRoutes
