import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import { Checkbox, TablePagination } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DetailDrawer from 'containers/APModule/components/DetailDrawer'
import format from 'date-fns/format'
import { dateFormat } from 'helpers/StringNumberFunction/FormatDate'
import React, { useState } from 'react'

export const InboundTable = ({ data, selectedID, setSelectedID }) => {
  // ==== Table Pagination =====
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClick = x => {
    console.log('Clicked row:', x)
    // Add any additional actions here, such as navigation or opening a modal
    setDrawerOpen(true)
  }

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [inboundData, setInboundData] = useState(null)

  const closeDrawer = () => {
    setDrawerOpen(false)
  }

  /* --------------------------------------------*/
  /*                  CHECKBOX                   */
  /* --------------------------------------------*/
  const handleSelectAll = e => {
    let temp = selectedID
    if (e.target.checked) {
      data?.map(v => {
        temp.add(v?.ID)
      })
    } else {
      temp.clear()
    }
    setSelectedID(new Set(temp))
  }

  const handleCheckBoxChange = id => {
    let temp = selectedID
    if (!temp.has(id)) {
      temp.add(id)
    } else {
      temp.delete(id)
    }

    setSelectedID(new Set(temp))
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: '700' }} size="small">
          <TableHead>
            <TableRow>
              <StickyTableCell style={{ textAlign: 'center' }}>
                <StyledTableCell
                  style={{ textAlign: 'center', padding: '0px 12px 0px 16px' }}
                >
                  <Checkbox
                    color="primary"
                    checked={!!(Array.from(selectedID).length === data?.length)}
                    onChange={handleSelectAll}
                  />
                </StyledTableCell>
              </StickyTableCell>
              <StyledTableCell
                style={{ backgroundColor: '#ff9800', width: '10%' }}
              >
                Doc Date
              </StyledTableCell>
              <StyledTableCell
                style={{ backgroundColor: '#ff9800', width: '15%' }}
              >
                Doc No
              </StyledTableCell>
              <StyledTableCell
                style={{ backgroundColor: '#ff9800', width: '15%' }}
              >
                Doc Type
              </StyledTableCell>
              <StyledTableCell
                style={{ backgroundColor: '#ff9800', width: '20%' }}
              >
                Creditor Name
              </StyledTableCell>
              <StyledTableCell
                style={{ backgroundColor: '#ff9800', width: '15%' }}
              >
                TIN
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#ff9800',
                  width: '10%',
                }}
              >
                Currency Code
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#ff9800',
                  textAlign: 'right',
                  width: '15%',
                }}
              >
                Total
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((v, i) => {
                return (
                  <StyledTableRow key={i}>
                    <StickyTableCell style={{ textAlign: 'center' }}>
                      <StyledTableCell
                        style={{
                          textAlign: 'center',
                          backgroundColor: 'white',
                          padding: '0px 12px 0px 16px',
                        }}
                      >
                        <Checkbox
                          color="primary"
                          onChange={() => {
                            handleCheckBoxChange(v?.ID)
                          }}
                          checked={selectedID.has(v?.ID)}
                        />
                      </StyledTableCell>
                    </StickyTableCell>
                    <StyledTableCell size="small">
                      {!!v?.APDocDate
                        ? format(new Date(v?.APDocDate), 'dd/MM/yyyy')
                        : dateFormat(v?.DocDate)}
                    </StyledTableCell>
                    <StyledTableCell size="small">
                      <span
                        onClick={() => {
                          setInboundData(v)
                          handleClick(v)
                        }}
                        className="click-text"
                      >
                        {v?.DocNo || v?.APDocNo}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell size="small">{v?.DocType}</StyledTableCell>
                    <StyledTableCell size="small">
                      {v?.CreditorAccount?.CompanyName}
                    </StyledTableCell>
                    <StyledTableCell size="small">
                      {v?.CreditorAccount?.einvoice_TIN}
                    </StyledTableCell>
                    <StyledTableCell size="small">
                      {v?.TrxCurrencyCode}
                    </StyledTableCell>
                    <StyledTableCell
                      size="small"
                      style={{ textAlign: 'right' }}
                    >
                      {amtStr(v?.DocAmt + v?.TaxAmt)}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
          </TableBody>
          {/* Render the drawer component */}
          <DetailDrawer
            anchor="right" // Set the drawer anchor as desired
            isOpen={drawerOpen}
            onClose={closeDrawer}
            data={inboundData}
          />
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}

const StyledTableCell = withStyles(theme => ({
  head: {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: 12,
    whiteSpace: 'nowrap',
    border: 'none',
  },
  body: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
}))(TableCell)

const StickyTableCell = withStyles(theme => ({
  head: {
    minWidth: '50px',
    left: 0,
    position: 'sticky',
    zIndex: 3,
    backgroundColor: 'white',
    color: theme.palette.common.white,
    borderBottom: 'none',
    padding: '0',
  },
  body: {
    minWidth: '50px',
    left: 0,
    position: 'sticky',
    zIndex: 3,
    borderBottom: 'none',
    backgroundColor: '#FFFFFF',
    padding: '0',
  },
}))(TableCell)

const StyledTableRow = withStyles({
  root: {
    '&:nth-of-type(odd)': { backgroundColor: 'transparent' },
    '&:nth-of-type(even)': { backgroundColor: '#FFF1DE' },
  },
})(TableRow)
