import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'
import { SwapVert, Tune } from '@material-ui/icons'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import {
  ApprovalStatus,
  useGetRecurringJournalbyStatusListingLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { RecurringEntryList } from './RecurringList/RecurringEntryList'
import { RecurringPostingList } from './RecurringList/RecurringPostingList'
import { SortDialog } from '@ifca-root/react-component/src/components/Sort/SortDialog'

export const RecurringJournalMain = (props: any) => {
  useEffect(() => {
    document.title = 'GL-Recurring Journal'
  }, [])

  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))
  const [openExitConf, setOpenExitConf] = useState(null)
  const [TotalLength, setTotalLength] = useState(0)
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)
  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    loadRecurringJournal,
    {
      loading: RecurringJournalbyStatusLoading,
      error: RecurringJournalbyStatusError,
      data: { getRecurringJournalbyStatusListing } = {
        getRecurringJournalbyStatusListing: {},
      },
    },
  ] = useGetRecurringJournalbyStatusListingLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getRecurringJournalbyStatusListing }) => {
      if (getRecurringJournalbyStatusListing?.Data?.length > 0) {
        setOriginalListing([
          ...filteredList,
          ...getRecurringJournalbyStatusListing?.Data,
        ])
        setTotalLength(getRecurringJournalbyStatusListing?.TotalLength)
      }
    },
  })

  useEffect(() => {
    setOriginalListing([])
    loadRecurringJournal({
      variables: {
        CompanyID: CompanyID,
        StatusArr:
          listStatus === 'COMPLETED'
            ? [listStatus, ApprovalStatus.Cancelled]
            : [listStatus],
        take: 30,
        skip: 0,
        searchValue:
          getSearch?.name !== '' || getSearch?.name === undefined
            ? undefined
            : getSearch?.name,
        sortField: selectedField === null ? undefined : selectedField,
        sortOrder: sortOrder === null ? undefined : sortOrder,
      },
    })
  }, [listStatus])

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  /* -------------------------------------------- */
  /*                   EXIT CONF                  */
  /* -------------------------------------------- */

  const hasChanges = () => {
    if (postIDs?.size === 0) return false
    else return true
  }

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Transaction Date',
      key: 'TransactionDate',
    },
  })

  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadRecurringJournal({
          variables: {
            CompanyID: CompanyID,
            StatusArr:
              listStatus === 'COMPLETED'
                ? [listStatus, ApprovalStatus.Cancelled]
                : [listStatus],
            take: 30,
            skip: 0,
            searchValue: change,
            sortField: selectedField === null ? undefined : selectedField,
            sortOrder: sortOrder === null ? undefined : sortOrder,
          },
        })
      }, 1000)
    )
  }

  //*******************************************************//
  //-------------------SORTING DATA------------------------//
  //*******************************************************//

  const sortRJDataDialog = [
    { ID: '1', value: 'RecurringDate', Name: 'Date' },
    { ID: '2', value: 'NoOfRecurrance', Name: 'No of Recurrence' },
    { ID: '3', value: 'Description', Name: 'Description' },
    { ID: '4', value: 'DocAmt', Name: 'Amount' },
  ]

  const [openSortDialog, setOpenSortDialog] = useState(false)
  const [selectedField, setSelectedField] = useState('')
  const [sortOrder, setSortOrder] = useState(null)

  const handleClickSort = () => {
    setOpenSortDialog(true)
  }

  const handleListStatusChange = newStatus => {
    setListStatus(newStatus)
    setSelectedField(null)
    setSortOrder(null)
  }

  const handleSortConfirm = sortOption => {
    const { selectedField, sortOrder } = sortOption
    setOriginalListing([])
    loadRecurringJournal({
      variables: {
        CompanyID: CompanyID,
        StatusArr:
          listStatus === 'COMPLETED'
            ? [listStatus, ApprovalStatus.Cancelled]
            : [listStatus],
        take: 30,
        skip: 0,
        searchValue:
          getSearch?.name === '' || getSearch?.name === undefined
            ? undefined
            : getSearch?.name,
        sortField: selectedField,
        sortOrder: sortOrder,
      },
    })
  }

  return (
    <>
      {UserLoading && <Loading />}
      {RecurringJournalbyStatusLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true && listStatus === 'SUBMIT'
            ? setOpenExitConf(true)
            : history.push(`/general-ledger/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }} //<- back button action
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'Home Journal' },
          { name: 'Recurring Journal', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            changeDelay(e?.target?.value)
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
            loadRecurringJournal({
              variables: {
                CompanyID: CompanyID,
                StatusArr:
                  listStatus === 'COMPLETED'
                    ? [listStatus, ApprovalStatus.Cancelled]
                    : [listStatus],
                take: 30,
                skip: 0,
                searchValue:
                  getSearch?.name !== '' || getSearch?.name === undefined
                    ? undefined
                    : getSearch?.name,
                sortField: selectedField,
                sortOrder: sortOrder,
              },
            })
          }}
          isDefaultValue={!!getSearch?.name}
          option={{
            icon: <Tune />,
            onClick: openFilterDialog,
          }}
          sort={{
            icon: <SwapVert />,
            onClick: handleClickSort,
          }}
        />

        <SortDialog
          openSortDialog={openSortDialog}
          setOpenSortDialog={setOpenSortDialog}
          title="Sort Options"
          fieldList={sortRJDataDialog}
          onSortConfirm={handleSortConfirm}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />

        {filterDialog}

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={handleListStatusChange}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>
      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') && (
        <RecurringEntryList
          filteredList={filteredList}
          listLoading={RecurringJournalbyStatusLoading}
          handleFilterDate={handleFilterDate}
          listStatus={listStatus}
          setOriginalListing={setOriginalListing}
          userList={getUsersByAccountAndSoftware}
          fetchMore={loadRecurringJournal}
          selectedField={selectedField}
          sortOrder={sortOrder}
        />
      )}
      {listStatus === 'SUBMIT' && (
        <RecurringPostingList
          filteredList={filteredList}
          listLoading={RecurringJournalbyStatusLoading}
          handleFilterDate={handleFilterDate}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing}
          fetchMore={loadRecurringJournal}
          selectedField={selectedField}
          sortOrder={sortOrder}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/general-ledger/${CompanyID}`)
        }}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
