import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import {
  AddFavoriteDialog,
  DeleteFavoriteDialog,
} from 'components/Favorite/FavoriteDialog'
import FavoriteMenu from 'components/Favorite/FavoriteMenu'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  DocumentListingDocument,
  GetFavoriteJournalProcessingDocument,
  GetJournalProcessingbyStatusDocument,
  useCreateFavoriteJournalProcessingMutation,
  useCreateJournalProcessingMutation,
  useDeleteFavoriteJournalProcessingMutation,
  useDocumentListingLazyQuery,
  useGetDocNumTitleQuery,
  useGetFavoriteJournalProcessingQuery,
  useGetJournalTypeQuery,
  useGetOpenAccPeriodDateRangeLazyQuery,
  useGetSelectedAccountPeriodLazyQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateJournalProcessingMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDashDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'
import '../Journal.scss'
import { JournalItems } from './JournalComponent/JournalItemComponent'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

interface JournalProcessingProps {
  CompanyID: string
  DocDate: string
  JournalTypeID: string
  DocAmt: number
  RefNo: string
  Description: string
  JournalProcessingID: string
  debitAmt: number
  RejectionRemark: string
}
interface FavJournalProcessingProps {
  Name: string
}

export const JournalForm = (props: any) => {
  const { formMode, type } = props
  const { CompanyID, JournalProcessingID }: any = useParams()
  const [journalItemDataState, setJournalItemData] = useState([])

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  let history = useHistory()
  let location = useLocation()
  const editData: any = location?.state

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  const docAmtReducer = (total, curVal) => total + curVal?.DocAmt

  const journalProcessingItems = editData
    ? editData?.JournalProcessingItem?.map(item => {
        return {
          JournalProcessingID: item?.JournalProcessingID,
          MasterCOAID: item?.MasterCOAID,
          createdTs: item?.createdTs,
          modTs: item?.modTs,
          MasterCOACode: item?.MasterCOA?.Code,
          MasterCOAName: item?.MasterCOA?.Name,
          DocAmt: item?.DocAmt,
          CostCentreID: item?.CostCentreID,
          CostCentreName: item?.CostCentre?.Name,
          CostCentreCode: item?.CostCentre?.Code,
          Remark: item?.Remark,
        }
      })
    : journalItemDataState

  const journalProcessingDetail = editData

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */
  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [openFavoriteDialog, setOpenFavoriteDialog] = useState(false)
  const [openFavoriteDeleteDialog, setOpenFavoriteDeleteDialog] = useState(
    false
  )

  const getTotalAmt = () => {
    let finalAmt = 0
    for (let i = 0; i < journalItemDataState?.length; i++) {
      const el: any = journalItemDataState[i]
      let documentAmt: number = parseFloat(el?.DocAmt)
      let finalStrAmt: string = finalAmt?.toFixed(2)

      finalAmt = +finalStrAmt + documentAmt
    }
    return finalAmt
  }

  const positiveAmt = journalItemDataState
    ?.filter(v => v?.DocAmt > 0)
    ?.reduce(docAmtReducer, 0)

  const negativeAmt = journalItemDataState
    ?.filter(v => v?.DocAmt < 0)
    ?.reduce(docAmtReducer, 0)

  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()

  const {
    anchorEl: anchorElFav,
    setAnchorEl: setAnchorElFav,
    menu: menuFav,
    handleClick: handleClickFav,
    handleClose: handleCloseFav,
  } = useMenuOption()

  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [val, setVal] = useState(false)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [openExitConf, setOpenExitConf] = useState(null)
  const [initDocs, setInitDocs] = useState([])
  const [openJournalItem, setOpenJournalItem] = useState<boolean>(false)
  const [itemMode, setItemMode] = useState('')
  const [isSubmit, setIsSubmit] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  useEffect(() => {
    if (editData?.Attachment) {
      setInitDocs(editData?.Attachment)
    }
  }, [editData])

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */
  const JournalProcessingFormSchema = yup.object().shape({
    DocDate: yup.string().required('Doc Date is Required'),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    JournalTypeID: CommonYupValidation.requireField(SystemMsgs.journalType()),
  })

  const FavJournalProcessingFormSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    getValues,
  } = useForm<JournalProcessingProps>({
    defaultValues: {
      Description: editData ? editData?.Description : '',
      JournalTypeID: editData ? editData?.JournalTypeID : '',
      RefNo: editData ? editData?.RefNo : '',
      DocDate: editData ? editData?.DocDate : new Date(),
    },
    mode: 'onSubmit',
    resolver: yupResolver(JournalProcessingFormSchema),
  })

  const {
    handleSubmit: handleFavSubmit,
    register: favRegister,
    control: favControl,
    errors: favErrors,
    watch: favWatch,
  } = useForm<FavJournalProcessingProps>({
    mode: 'onSubmit',
    resolver: yupResolver(FavJournalProcessingFormSchema),
  })

  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const {
    loading: JournalTypeLoading,
    data: { getJournalType } = { getJournalType: [] },
  } = useGetJournalTypeQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, IsSystem: false },
  })

  const {
    loading: docNumHeaderLoading,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: 'GL_JournalProcessing',
    },
  })

  const [
    createJournalProcessing,
    {
      loading: createJournalProcessingLoading,
      called: createJournalProcessingCalled,
      error: createJournalProcessingError,
    },
  ] = useCreateJournalProcessingMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/general-ledger/${CompanyID}/journal-processing/${data?.createJournalProcessing?.JournalProcessingID}/preview`,
          })
        } else {
          history.push({
            pathname: `/general-ledger/${CompanyID}/journal-processing/`,
          })
        }
      }, 500)
      localStorage.removeItem('journalProcessing')
      localStorage.removeItem('journalProcessingItem')
      localStorage.removeItem('attachment')
    },
  })

  const [
    updateJournalProcessing,
    {
      loading: updateJournalProcessingLoading,
      called: updateJournalProcessingCalled,
      error: updateJournalProcessingError,
    },
  ] = useUpdateJournalProcessingMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/general-ledger/${CompanyID}/journal-processing/${JournalProcessingID}/preview`,
          })
        } else {
          history.push({
            pathname: `/general-ledger/${CompanyID}/journal-processing/`,
          })
        }
      }, 100)
      localStorage.removeItem('journalProcessing')
      localStorage.removeItem('journalProcessingItem')
      localStorage.removeItem('attachment')
    },
  })

  const [
    fetchDocuments,
    { loading: documentLoading, data: DocData },
  ] = useDocumentListingLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  useEffect(() => {
    if (
      formMode === 'edit' ||
      formMode === 'approve-reject' ||
      formMode === 'resubmit'
    )
      if (!!!editData?.files) {
        fetchDocuments({
          variables: {
            refID: JournalProcessingID,
          },
        })
      }
  }, [formMode])

  useEffect(() => {
    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [editData])

  const [
    fetchSelectedAccountPeriod,
    {
      loading: selectedAccountPeriodLoading,
      data: { getSelectedAccountPeriod } = {
        getSelectedAccountPeriod: {} as any,
      },
    },
  ] = useGetSelectedAccountPeriodLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  const favRefetchQuery = [
    {
      query: GetFavoriteJournalProcessingDocument,
      variables: {
        CompanyID,
        UserID: user?.ID,
      },
    },
  ]

  const {
    loading: FavoriteJournalProcessingLoading,
    data: { getFavoriteJournalProcessing } = {
      getFavoriteJournalProcessing: [],
    },
  } = useGetFavoriteJournalProcessingQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID, UserID: user?.ID },
  })

  const [
    createFavoriteJournalProcessing,
    {
      loading: createFavoriteJournalProcessingLoading,
      error: createFavoriteJournalProcessingError,
    },
  ] = useCreateFavoriteJournalProcessingMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setOpenFavoriteDialog(false)
    },
  })

  const [
    deleteFavoriteJournalProcessing,
    {
      loading: deleteFavoriteJournalProcessingLoading,
      error: deleteFavoriteJournalProcessingError,
    },
  ] = useDeleteFavoriteJournalProcessingMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOpenFavoriteDeleteDialog(false)
    },
    variables: {
      FavoriteJournalProcessingID: menuFav?.ID,
    },
  })

  const favNames = getFavoriteJournalProcessing?.map(fav => fav?.Name)

  //Handle Submit section

  useEffect(() => {
    if (formMode === 'edit' && editData) {
      const JournalInvoiceItems = editData?.JournalProcessingItem?.map(
        (item, index) => {
          return {
            Sequence: item?.Sequence ?? index + 1,
            JournalProcessingID: item?.JournalProcessingID,
            MasterCOAID: item?.MasterCOAID,
            createdTs: item?.createdTs,
            modTs: item?.modTs,
            MasterCOACode: item?.MasterCOA?.Code,
            MasterCOAName: item?.MasterCOA?.Name,
            DocAmt: item?.DocAmt,
            CostCentreID: item?.CostCentreID,
            CostCentreName: item?.CostCentre?.Name,
            CostCentreCode: item?.CostCentre?.Code,
            Remark: item?.Remark,
          }
        }
      )
      journalItemDataState.push(...JournalInvoiceItems)
    }
  }, [formMode, editData])

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })
  const [
    fetchOpenAccPeriodDateRange,
    {
      loading: latestOpenAccPeriodLoading,
      error: latestOpenAccPeriodError,
      data: { getOpenAccPeriodDateRange } = {
        getOpenAccPeriodDateRange: null,
      },
    },
  ] = useGetOpenAccPeriodDateRangeLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
    onCompleted: ({ getOpenAccPeriodDateRange }) => {
      let checkPeriodValid
      if (!!getOpenAccPeriodDateRange) {
        checkPeriodValid =
          getOpenAccPeriodDateRange?.StartDate === null ||
          new Date(watch('DocDate')) <
            new Date(getOpenAccPeriodDateRange?.StartDate) ||
          new Date(watch('DocDate')) >
            new Date(getOpenAccPeriodDateRange?.EndDate)

        return checkPeriodValid
      }
      setIsValid(checkPeriodValid)
    },
  })

  useEffect(() => {
    fetchOpenAccPeriodDateRange({
      variables: {
        CompanyID: CompanyID,
      },
    })
    if (journalProcessingDetail) {
      fetchSelectedAccountPeriod({
        variables: {
          CompanyID: CompanyID,
          selectedDate: journalProcessingDetail?.DocDate ?? new Date(),
        },
      })
    } else if (formMode === 'add') {
      fetchSelectedAccountPeriod({
        variables: {
          CompanyID: CompanyID,
          selectedDate: new Date(),
        },
      })
    } else if (formMode === 'edit') {
      fetchSelectedAccountPeriod({
        variables: {
          CompanyID: CompanyID,
          selectedDate: editData?.DocDate,
        },
      })
    }
  }, [CompanyID, formMode])

  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const openPeriod1 =
    transferDate >= latestOpenPeriodCheckingDate?.StartDate &&
    transferDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? false : true

  /* -------------------------------------------- */
  /*                   ON SUBMIT                  */
  /* -------------------------------------------- */

  const onSubmit = (data, status) => {
    if (val == false) {
      setVal(true)
      if (formMode === 'add') {
        if (
          getDocumentNumberHeader?.filter(
            x => x?.RefTable === 'GL_JournalProcessing'
          )?.length === 0
        ) {
          setOpenSnackBar(true)
          setSnackBarMsg(SystemMsgs.errorNumberingStructure())
        } else {
          createJournalProcessing({
            variables: {
              input: {
                CompanyID: CompanyID,
                DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
                FYear: Number(getSelectedAccountPeriod?.FYear),
                FPeriod: Number(getSelectedAccountPeriod?.FPeriod),
                JournalTypeID: data.JournalTypeID,
                RefNo: data.RefNo,
                Description: data.Description,
                Attachment: files,
                ApprovalStatus: statusInput(status),
              },
              itemInput: journalItemDataState
                ?.sort((a, b) => {
                  return a?.Sequence - b?.Sequence
                })
                ?.map((x, index) => {
                  return {
                    MasterCOAID: x?.MasterCOAID,
                    CostCentreID: x?.CostCentreID,
                    Remark: x?.Remark,
                    DocAmt: parseFloat(amtNumStr(x?.DocAmt)),
                    Sequence: index + 1,
                  }
                }),
            },
            refetchQueries: [
              {
                query: GetJournalProcessingbyStatusDocument,
                variables: {
                  CompanyID: CompanyID,
                  StatusArr: ['ACTIVE'],
                },
              },
              {
                query: DocumentListingDocument,
                variables: {
                  refTable: 'S_Attachment',
                  refID: editData?.JournalProcessingID,
                },
              },
            ],
          })
        }
      } else if (formMode !== 'add') {
        updateJournalProcessing({
          variables: {
            input: {
              JournalProcessingID: JournalProcessingID,
              CompanyID: CompanyID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              FYear: Number(getSelectedAccountPeriod?.FYear),
              FPeriod: Number(getSelectedAccountPeriod?.FPeriod),
              JournalTypeID: data?.JournalTypeID,
              RefNo: data?.RefNo,
              Description: data?.Description,
              ApprovalStatus: statusInput(status),
              Attachment: files,
            },
            itemInput: journalItemDataState
              ?.sort((a, b) => {
                return a?.Sequence - b?.Sequence
              })
              ?.map((y, index) => {
                return {
                  MasterCOAID: y.MasterCOAID,
                  CostCentreID: y.CostCentreID,
                  Remark: y.Remark,
                  DocAmt: parseFloat(amtNumStr(y.DocAmt)),
                  Sequence: index + 1,
                }
              }),
          },
          refetchQueries: [
            {
              query: GetJournalProcessingbyStatusDocument,
              variables: { CompanyID: CompanyID, StatusArr: ['ACTIVE'] },
            },
            {
              query: DocumentListingDocument,
              variables: {
                refTable: 'S_Attachment',
                refID: editData?.JournalProcessingID,
              },
            },
          ],
        })
      }
    }
  }

  const onSubmitFavorite = data => {
    const tempFav = {
      Name: data?.Name,
      UserID: user?.ID,
      JournalTypeID: !!getValues('JournalTypeID')
        ? getValues('JournalTypeID')
        : null,
      Description: !!getValues('Description') ? getValues('Description') : null,
      RefNo: !!getValues('RefNo') ? getValues('RefNo') : null,
      CompanyID: CompanyID ?? null,
    }

    const tempFavItem = journalItemDataState?.map(x => {
      return {
        MasterCOAID: x?.MasterCOAID,
        CostCentreID: x?.CostCentreID,
        Sequence: x?.Sequence,
        DocAmt: parseFloat(amtNumStr(x?.DocAmt)),
        Remark: x?.Remark,
      }
    })

    createFavoriteJournalProcessing({
      variables: {
        FavoriteJournalProcessingInput: tempFav,
        FavoriteJournalProcessingItemInput: tempFavItem,
      },
      refetchQueries: favRefetchQuery,
    })
  }

  const handleSelectFavorite = data => {
    const tempFavItemArr = data?.FavoriteJournalProcessingItem?.map(x => {
      return {
        MasterCOAID: x?.MasterCOAID,
        MasterCOAName: x?.MasterCOA?.Name,
        MasterCOACode: x?.MasterCOA?.Code,
        CostCentreID: x?.CostCentreID,
        CostCentreName: x?.CostCentre?.Name,
        CostCentreCode: x?.CostCentre?.Code,
        DocAmt: parseFloat(amtNumStr(x?.DocAmt)),
        Remark: x?.Remark,
        Sequence: x?.Sequence,
      }
    })
    journalItemDataState.push(...tempFavItemArr)
    setValue('JournalTypeID', data?.JournalTypeID)
    setValue('Description', data?.Description)
    setValue('RefNo', data?.RefNo)
    if (!!data?.DocDate) setValue('DocDate', data?.DocDate)
  }

  let statusInput = mode => {
    let temp
    switch (mode) {
      case 'submit':
        temp = ApprovalStatus.Submit
        break
      case 'draft':
        temp = ApprovalStatus.Active
        break
      case 'approve':
        temp = ApprovalStatus.Approved
        break
      case 'reject':
        temp = ApprovalStatus.Rejected
        break
    }
    return temp
  }

  const handleAddFavorite = () => {
    setOpenFavoriteDialog(true)
  }

  const createUpdateCalled = editData
    ? updateJournalProcessingCalled
    : createJournalProcessingCalled

  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: { type: 'submit' },
    disabled:
      checkingYearClose1 ||
      (isValid &&
        handlePermDisabled({
          companyID: CompanyID,
          permEnum: AcctPermission.GeneralLedgerJournalProcessingDraft,
        })),
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: { type: 'submit' },
    disabled:
      !!errors?.Description ||
      !!errors?.DocDate ||
      !!errors?.JournalTypeID ||
      checkingYearClose1
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.GeneralLedgerJournalProcessingUpdate,
          }),
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: e => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
      setIsSubmit(true)
    },
    color: 'primary',
    props: { type: 'submit' },
    disabled:
      (!user?.superUser
        ? handlePermDisabled({
            companyID: CompanyID,
            permEnum:
              AcctPermission.GeneralLedgerJournalProcessingCreate ||
              AcctPermission.GeneralLedgerJournalProcessingUpdate,
          })
        : false) === false &&
      isValid &&
      getTotalAmt() === 0 &&
      journalItemDataState?.length > 1 &&
      checkingYearClose1 === false
        ? false
        : true,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  const getFiles = file => {
    let myArray = []
    let temp = {}
    for (let i = 0; i < file.length; i++) {
      temp = {
        lastMod: file[i].lastModified,
        lastModDate: file[i].lastModifiedDate,
        name: file[i].name,
        size: file[i].size,
        type: file[i].type,
      }
      myArray.push(temp)
    }
    return myArray
  }

  const handleDeleteItem = index => {
    if (menu?.index > -1) {
      journalItemDataState.splice(menu?.index, 1)
    }
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */

  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      formMode: formMode,
      itemArr: journalItemDataState,
      itemSuffixID: 'JournalProcessingItemID',
      itemTableName: 'JournalProcessingItem',
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
    })

  return (
    <>
      {documentLoading && <Loading />}
      {JournalTypeLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {latestOpenAccPeriodLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}
      {selectedAccountPeriodLoading && <Loading />}
      {createJournalProcessingLoading && <Loading />}
      {updateJournalProcessingLoading && <Loading />}
      {FavoriteJournalProcessingLoading && <Loading />}
      {deleteFavoriteJournalProcessingLoading && <Loading />}
      {createFavoriteJournalProcessingLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            history.push(`/general-ledger/${CompanyID}/journal-processing`)
            localStorage.removeItem('journalProcessing')
            localStorage.removeItem('journalProcessingItem')
            localStorage.removeItem('attachment')
          }
        }}
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'General Ledger' },
          formMode === 'approve-reject'
            ? { name: 'Journal Entry', current: true }
            : { name: 'Journal Entry', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : 'Edit',
            current: true,
          },
        ]}
      />

      <ContentWrapper float footer>
        <CardContents
          section={{
            header: {
              title: 'Journal Entry',

              icon:
                formMode === 'add' || formMode === 'edit' ? (
                  <FavoriteMenu
                    options={getFavoriteJournalProcessing}
                    funcLabel={'Add Favorite'}
                    addFavFunc={handleAddFavorite}
                    selectFavFunc={handleSelectFavorite}
                    optionIDName={'FavoriteJournalProcessingID'}
                    setOpenFavoriteDeleteDialog={setOpenFavoriteDeleteDialog}
                    anchorEl={anchorElFav}
                    setAnchorEl={setAnchorElFav}
                    handleClose={handleCloseFav}
                    handleClickDelete={handleClickFav}
                  />
                ) : null,
            },
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="DocDate"
              required
              label="Journal Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onAccept={(date: Date | null) => {
                fetchSelectedAccountPeriod({
                  variables: {
                    CompanyID: CompanyID,
                    selectedDate: new Date(date),
                  },
                })
              }}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="left"
              value={new Date()}
              defaultValue={editData ? editData?.DocDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose1
                  ? 'Financial Period already closed'
                  : errors?.DocDate?.message
              }
              error={errors?.DocDate || checkingYearClose1 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Journal Type"
                required
                select
                defaultValue={editData ? editData?.JournalTypeID : ''}
                value={value}
                onChange={e => {
                  onChange(e)
                  setValue('JournalTypeID', e?.target?.value)
                }}
                helperText={errors?.JournalTypeID?.message}
                error={errors?.JournalTypeID ? true : false}
                className="right"
                style={{ marginTop: 'auto' }}
              >
                {getJournalType
                  ?.sort((a, b) => {
                    return a.JournalType.localeCompare(b.JournalType)
                  })
                  ?.map((el, index) => (
                    <MenuItem key={index} value={el?.JournalTypeID}>
                      {el.JournalType}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            onChange={e => {
              setValue('JournalTypeID', e?.target?.value)
            }}
            control={control}
            ref={register()}
            name="JournalTypeID"
            autoComplete="off"
            className="right"
            multiline={true}
            margin="dense"
            defaultValue={editData ? editData?.JournalTypeID : ''}
          />

          <Controller
            as={TextField}
            // style={{ marginTop: '-2px', marginBottom: '30px' }}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            // helperText={errors?.RefNo?.message}
            // error={errors?.RefNo ? true : false}
            ref={register}
            defaultValue={
              formMode === 'edit' || formMode === 'approve-reject'
                ? journalProcessingDetail?.RefNo
                : ''
            }
            disabled={formMode === 'approve-reject'}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            ref={register}
            defaultValue={
              formMode === 'edit' || formMode === 'approve-reject'
                ? journalProcessingDetail?.Description
                : ''
            }
            disabled={formMode === 'approve-reject'}
          />

          <div
            style={{ width: '100%', marginTop: '24px', marginBottom: '12px' }}
          >
            <FileUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </div>

          {editData?.mode === 'resubmit' && (
            <Controller
              as={TextField}
              id="standard-basic"
              name="RejectionRemark"
              label="Rejection Remark"
              autoComplete="off"
              control={control}
              fullWidth
              margin="dense"
              ref={register}
              defaultValue={journalProcessingDetail?.RejectionRemark}
              disabled={editData?.mode === 'resubmit'}
            />
          )}
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Journal Detail',
              onClickAction: () => {
                setItemMode('add')

                localStorage.setItem(
                  'attachment',
                  JSON.stringify(getFiles(files))
                )
                setOpenJournalItem(true)
                //SAVE FOR FUTURE (IN CASE REVERT CHANGES)
                // if (formMode === 'add') {
                //   history.push({
                //     pathname: `/general-ledger/${CompanyID}/journal-processing/${formMode}/journal-processing-item/add`,
                //     state: { ...editData, files: files },
                //   })
                // } else {
                //   history.push({
                //     pathname: `/general-ledger/${CompanyID}/journal-processing/${JournalProcessingID}/${formMode}/journal-processing-item/add`,
                //     state: { ...editData, files: files },
                //   })
                // }
              },

              icon:
                formMode === 'add' || formMode === 'edit' ? (
                  <AddIcon
                    htmlColor="white"
                    fontSize="default"
                    style={{
                      width: '1.3rem',
                      height: '1.3rem',
                      margin: '0',
                      background: theme.palette.primary.main,
                      boxShadow: `1px 1px 4px 0px ${theme.palette.primary.main}`,
                      borderRadius: '3px',
                      color: 'rgba(224,234,254,100)',
                      marginTop: '10px',
                    }}
                  />
                ) : null,
            },
          }}
        >
          <div className="rm-padding table-wrap ">
            {journalItemDataState === undefined ||
            journalItemDataState?.length === 0 ? (
              <EmptyList
                title="No Record Found"
                subtitle="Add New Record now."
              />
            ) : (
              journalItemDataState
                ?.sort(function(a, b) {
                  return a?.Sequence < b?.Sequence ? -1 : 1
                })
                ?.map((v, index) => {
                  return (
                    <>
                      {/* ORIGINAL */}
                      <Grid spacing={1} container className="table-content">
                        <Grid item xs={1} style={{ placeSelf: 'start' }}>
                          <span className="xxTitle">{`${index + 1}.`}</span>
                        </Grid>

                        <Grid
                          item
                          xs={7}
                          style={{
                            placeSelf: 'start',
                            marginTop: '8px',
                          }}
                        >
                          <div className="xxTitle text-noflow">
                            {v?.MasterCOACode}&nbsp;{v?.MasterCOAName}
                          </div>
                          <div className="desc text-noflow">
                            {v?.CostCentreName}
                          </div>
                          <div className="desc text-noflow">{v?.Remark}</div>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={{
                            placeSelf: 'start',
                            marginTop: '8px',
                            marginRight: 'auto',
                            textAlign: 'right',
                            flexWrap: 'wrap',
                            display: 'flex',
                            justifyContent: 'end',
                          }}
                        >
                          <div className="xxTitle">{amtStr(v?.DocAmt)}</div>
                        </Grid>

                        <Grid item xs={1} style={{ marginTop: '0px' }}>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            aria-controls="menu-list"
                            aria-haspopup="true"
                            onClick={e =>
                              handleClick(
                                e,
                                v?.JournalProcessingItemID,
                                index,
                                v
                              )
                            }
                          >
                            <MoreVert />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Divider
                        variant="fullWidth"
                        style={{ background: '#E4E4E4' }}
                      />
                    </>
                  )
                })
            )}
          </div>
        </CardContents>
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setItemMode('edit')
            //SAVE FOR FUTURE (IN CASE REVERT CHANGES)
            // if (formMode === 'add')
            //   history.push({
            //     pathname: `/general-ledger/${CompanyID}/journal-processing/${formMode}/journal-processing-item/${menu?.obj?.JournalProcessingItemID}/edit`,
            //     state: { ...editData, files: files },
            //   })
            // else {
            //   history.push({
            //     pathname: `/general-ledger/${CompanyID}/journal-processing/${JournalProcessingID}/${formMode}/journal-processing-item/${menu?.obj?.JournalProcessingItemID}/edit`,
            //     state: {
            //       ...editData,
            //       files: files,
            //     },
            //   })
            // }

            setOpenJournalItem(true)
          }}
        >
          <span className="mdDesc">Edit</span>
        </MenuItem>
        <MenuItem onClick={() => handleDeleteItem(menu?.index)}>
          <span className="mdDesc">Delete</span>
        </MenuItem>
      </Menu>

      {!!openJournalItem && (
        <JournalItems
          openJournalItem={openJournalItem}
          setOpenJournalItem={setOpenJournalItem}
          journalItemData={journalItemDataState}
          CompanyID={CompanyID}
          JournalProcessingID={JournalProcessingID}
          formMode={formMode}
          detailMode={itemMode}
          menu={menu}
          JournalProcessingItemID={
            itemMode === 'edit' ? menu?.obj?.JournalProcessingItemID : ''
          }
        />
      )}

      <AddFavoriteDialog
        openFavoriteDialog={openFavoriteDialog}
        setOpenFavoriteDialog={setOpenFavoriteDialog}
        favRegister={favRegister}
        favControl={favControl}
        favNames={favNames}
        favErrors={favErrors}
        favWatch={favWatch}
        dialogTitle={'Journal Detail'}
        handleFavSubmit={handleFavSubmit}
        onSubmitFavorite={onSubmitFavorite}
      />

      <DeleteFavoriteDialog
        openFavoriteDeleteDialog={openFavoriteDeleteDialog}
        setOpenFavoriteDeleteDialog={setOpenFavoriteDeleteDialog}
        dialogTitle={'Favorite Journal Entry'}
        menuFav={menuFav}
        deleteMutation={deleteFavoriteJournalProcessing}
        favRefetchQuery={favRefetchQuery}
        favID={menuFav?.ID}
      />

      {footerOptions?.length > 0 && (
        <AccountFooter options={[...footerOptions]} />
      )}

      <TotalAmountFooter
        debitCreditInfo={true}
        debitAmt={amtStr(positiveAmt) ?? '0.00'}
        creditAmt={amtStr(Math.abs(negativeAmt)) ?? '0.00'}
      />

      <CommonDialog
        fullWidth={true}
        open={errorDia}
        onClose={() => setErrorDia(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        {'Document Numbering is empty'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>{'Please setup Document Numbering to Submit'}</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setErrorDia(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/general-ledger/${CompanyID}/journal-processing`)
          localStorage.removeItem('journalProcessing')
          localStorage.removeItem('journalProcessingItem')
          localStorage.removeItem('attachment')
        }}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
