import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import Payment from 'assets/icons/CB/Payment.svg'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetApApprovalPolicyAssignmentInfolineQuery,
  useGetGeneralSettingExecutiveSummaryQuery,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router'
export const ApprovalPolicyAPSubmenu = (props: any) => {
  const { module: pageMode } = props

  const { CompanyID }: any = useParams()

  let history = useHistory()
  const { globalState, dispatch }: any = useContext(AppContext as any)
  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    })
  }
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const {
    loading: GSSummaryLoading,
    error,
    data: GeneralSettingSummaryData,
  } = useGetGeneralSettingExecutiveSummaryQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: approvalPolicyInfolineLoading,
    data: { getAPApprovalPolicyAssignmentInfoline } = {
      getAPApprovalPolicyInfoline: [],
    },
  } = useGetApApprovalPolicyAssignmentInfolineQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const fetchDate = category => {
    const item = getAPApprovalPolicyAssignmentInfoline?.find(
      v => v?.Category === category
    )
    return item?.LastUpdatedOn ? formatDate(item.LastUpdatedOn) : null
  }

  const approvalPolicyAP = [
    {
      path: `/account-payable/${CompanyID}/company-setting/approval-policy/payment`,
      icon: Payment,
      disabled: false,
      deployed: true,
      title: `Payment`,
      label1: fetchDate('AP_Payment')
        ? `Last updated on`
        : `No Approval Policy Setup`,
      data1: fetchDate('AP_Payment') || '',
    },
    {
      path: `/account-payable/${CompanyID}/company-setting/approval-policy/advance`,
      icon: Payment,
      disabled: false,
      deployed: true,
      title: `Advance`,
      label1: fetchDate('AP_Advance')
        ? `Last updated on`
        : `No Approval Policy Setup`,
      data1: fetchDate('AP_Advance') || '',
    },
  ]

  const doCheck = (input: string) => {
    return pageMode === input
  }

  let mainMenuList = []
  let headerTitle
  let RETURN_ROUTES

  if (doCheck('account-payable')) {
    mainMenuList = approvalPolicyAP
    headerTitle = 'Account Payable'
    RETURN_ROUTES = ``
  }

  return (
    <>
      {GSSummaryLoading && <Loading />}
      {approvalPolicyInfolineLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/${pageMode}/${CompanyID}/company-setting`)
        }
        smTitle={headerTitle}
        title={user?.companyName}
        routeSegments={[
          { name: `Main Menu` },
          { name: 'Standard Approval Flow', current: true },
        ]}
      />
      <ContentWrapper>
        <List className="core-list">
          {mainMenuList.map((el, index) => (
            <ListItem key={index} onClick={() => history.push(el.path)}>
              <ListItemText
                style={{
                  textAlign: 'right',
                  marginLeft: '5px',
                }}
                primary={<span className="xsTitle">{el.title}</span>}
                secondary={
                  el.label1 ? (
                    <>
                      <span className="desc">
                        <span className="text ">{el.label1}</span>
                        <span className="fw-medium"> {el.data1}.</span>

                        {el.data1 && el.data2 ? <TextSeparator /> : ''}

                        <span
                          className="highlight-text"
                          style={{ marginLeft: '1px' }}
                        >
                          {el.data2}
                        </span>
                        {el.data2 && el.data3 ? <TextSeparator /> : ''}
                        <span
                          className="highlight-text"
                          style={{ marginLeft: '1px' }}
                        >
                          {el.data3}
                        </span>
                      </span>
                    </>
                  ) : null
                }
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                  <KeyboardArrowRight
                    onClick={() =>
                      history.push({
                        pathname: el.path,
                      })
                    }
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ContentWrapper>
    </>
  )
}
