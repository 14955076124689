import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import StandardTabs from '@ifca-root/react-component/src/components/Tabs/StandardTabs'
import {
  useGetStockCategoryItemAssignmentLazyQuery,
  useGetWarehouseQuery,
} from 'generated/graphql'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { AvailableStockItemForm } from './AvailableItemForm'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import { useMediaQuery } from '@material-ui/core'
import theme from '@ifca-root/react-component/src/assets/theme'

export const WarehouseItemAccessListing = (props: any) => {
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [value, setValue] = useState(0)
  const { WarehouseID }: any = useParams()
  const [availableCount, setAvailableCount] = useState(0)
  const [assignedCount, setAssignedCount] = useState(0)
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const handleChange = (event, newValue) => {
    setValue(newValue)
    fetchStockCategory({
      variables: {
        WarehouseID: WarehouseID,
        mode: newValue === 1 ? 'assigned' : 'available',
      },
    })
  }

  //API SECTION

  const {
    loading: WarehouseLoading,
    error: WarehouseError,
    data: { getWarehouse } = { getWarehouse: {} as any },
  } = useGetWarehouseQuery({
    fetchPolicy: 'network-only',
    variables: { WarehouseID: WarehouseID },
    onCompleted: () => {
      fetchStockCategory({
        variables: {
          WarehouseID: WarehouseID,
          mode: 'available',
        },
      })
    },
  })

  const [
    fetchStockCategory,
    {
      loading: getStockCategoryItemAssignmentLoading,
      data: { getStockCategoryItemAssignment } = {
        getStockCategoryItemAssignment: [],
      },
    },
  ] = useGetStockCategoryItemAssignmentLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getStockCategoryItemAssignment }) => {
      if (!!getStockCategoryItemAssignment) {
        setAvailableCount(getStockCategoryItemAssignment?.AvailableItemCount)
        setAssignedCount(getStockCategoryItemAssignment?.AssignedItemCount)
        if (getStockCategoryItemAssignment?.Data?.length > 0) {
          setOriginalListing(getStockCategoryItemAssignment?.Data)
        } else {
          setOriginalListing([])
        }
      }
    },
  })

  return (
    <>
      {WarehouseLoading && <Loading />}
      {getStockCategoryItemAssignmentLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(`/inventory-control/general-setting/warehouse/`)
        }
        smTitle={'Inventory Control'}
        title={user?.companyName}
        routeSegments={[
          { name: 'General Settings' },
          { name: 'User' },
          { name: 'Warehouse', current: true },
        ]}
        rightRouteSegments={[{ name: 'Assign Item', current: true }]}
      />

      <DynamicSubHeader title={getWarehouse[0]?.Name} />

      <StandardTabs
        value={value}
        onChange={handleChange}
        responsive
        fullWidth
        // dynamicInfo
        style={{
          boxShadow: 'none',
          ...(isDesktop
            ? {
                marginTop: '99px',
              }
            : {
                marginTop: '39px',
              }),
        }}
        tabs={[
          {
            label: `Available(${availableCount})`,
            content: (
              <AvailableStockItemForm
                pageMode={'available'}
                filteredList={filteredList}
                handleSearch={handleSearch}
                fetchStockCategory={fetchStockCategory}
              />
            ),
          },
          {
            label: `Assigned(${assignedCount})`,
            content: (
              <AvailableStockItemForm
                pageMode={'assigned'}
                filteredList={filteredList}
                handleSearch={handleSearch}
                fetchStockCategory={fetchStockCategory}
              />
            ),
          },
        ]}
      />
    </>
  )
}
