import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import React from 'react'

export const DeleteDialog = (props: any) => {
  const {
    ID,
    openDeleteDialog,
    setOpenDeleteDialog,
    title,
    menu,
    handleDelete,
    passID,
  } = props

  return (
    <CommonDialog
      fullWidth
      open={openDeleteDialog}
      onClose={() => setOpenDeleteDialog(false)}
      sections={{
        header: {
          title: title,
          rightText: `Delete`,
          infoLine: menu?.RefNo ?? menu?.Remark ?? menu?.obj?.Description,
        },
        body: () => (
          <div className="content-wrap full">
            <span
              style={{
                fontSize: '12px',
                lineHeight: '14px',
                fontWeight: 500,
              }}
            >
              {`Are you sure you want to Delete this ${title ?? menu?.RefNo}?`}
            </span>
          </div>
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: () => setOpenDeleteDialog(false),
                variant: 'contained',
                color: 'primary',
              },
            },
            {
              displayText: 'Confirm',
              props: {
                onClick: () => {
                  if (passID) {
                    handleDelete(ID ?? menu?.RefundID)
                  } else {
                    handleDelete({ variables: ID })
                  }
                },
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}
