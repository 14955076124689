import { AttachFile } from '@material-ui/icons'
import { AttachmentDialog } from 'components/Dialog/AttachmentDialog'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useState } from 'react'
import './LeaseContractDetails.scss'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { AttachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
  apSubmenu?: string
  isSelfBilled?: boolean
}

export const LeaseContractDetailContent = (props: DetailProps) => {
  const { listEl, documentListing, listStatus, userList, mode } = props
  const [openDoc, setOpenDoc] = useState<boolean>(false)
  const { menu } = useMenuOption()

  return (
    <>
      {mode === 'detail' && (
        <>
          <div className="content-wrap ">
            <div className="content-wrap ">
              <div className="desc" style={{ fontWeight: 300 }}>
                Annual Discount Rate %
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {listEl?.AnnualDiscountRate}
              </div>
            </div>

            <div className="desc" style={{ fontWeight: 300 }}>
              Discounted Total Cash Flow
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {amtStr(listEl?.DiscountedTotalCashFlows)}
            </div>
          </div>

          <div className="content-wrap right">
            {documentListing && documentListing?.length > 0 && (
              <>
                <span
                  style={{ float: 'right', color: '#000' }}
                  onClick={() => {
                    setOpenDoc(true)
                  }}
                >
                  <AttachFile
                    className="mdDesc"
                    style={{
                      fontSize: 12,
                      color: 'orange',
                    }}
                  />

                  <span
                    className="mdDesc"
                    style={{
                      float: 'right',
                      color: 'orange',
                      textDecoration: 'underline',
                      paddingRight: '5px',
                      paddingTop: '4px',
                    }}
                  >
                    {documentListing?.length}
                  </span>
                </span>
              </>
            )}
          </div>
          <div className="content-wrap left">
            <div className="desc" style={{ fontWeight: 300 }}>
              Account ROU
            </div>

            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {listEl?.IsAccountROU ? 'Yes' : 'No'}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ fontWeight: 300 }}>
              Start Journal Date
            </div>

            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {formatDate(listEl?.StartJournalDate)}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Description
            </div>
            <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
              {listEl?.Description ?? listEl?.Remark}
            </div>
          </div>

          {listEl?.Remark && (
            <div className="content-wrap full">
              <div className="desc" style={{ fontWeight: 300 }}>
                Remark
              </div>
              <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
                {listEl?.Remark}
              </div>
            </div>
          )}

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                userList?.filter(user => user.ID == listEl?.submittedBy)[0]
                  ?.name
              }
            </div>
          </div>
          {listEl?.approvedBy && (
            <>
              <div className="content-wrap left">
                <div className="desc" style={{ color: 'grey' }}>
                  {'Approved Date'}
                </div>
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {formatDate(listEl?.approvedTs)}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ color: 'grey' }}>
                  {'Approved By'}
                </div>
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {
                    userList?.filter(user => user.ID == listEl?.approvedBy)[0]
                      ?.name
                  }
                </div>
              </div>
            </>
          )}
        </>
      )}

      <AttachmentDialog
        title={'Lease Contract'}
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        AttachmentDetailView={AttachmentDetailView}
        DocumentListing={documentListing}
      />
    </>
  )
}
