import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import {
  Grid,
  IconButton,
  List,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import theme from '@ifca-root/react-component/src/assets/theme'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import React from 'react'
import { RoleListingComponent } from '../RoleListingComponent/RoleListingComponent'

export const ApprovalFormComponent = (props: any) => {
  const {
    fields,
    control,
    errors,
    watch,
    register,
    setValue,
    isDesktop,
    getApprovalList,
    getRole,
    setRemovePolicy,
    checkPendingDocument,
    getPendingAP,
    remove,
  } = props

  const styles = makeStyles(theme => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },

    item: {
      padding: '0',
      paddingRight: '10px',
      borderBottom: '2px solid rgb(224,224,224)',
    },
    lastItem: {
      padding: '0',
      paddingRight: '10px',
    },
    deleteIcon: {
      display: 'flex',
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
      },
    },
    list: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '80%',
        margin: 'Auto',
      },
    },
  }))

  const classesVaria = styles()

  return (
    <>
      {fields?.length === 0 ? (
        <EmptyList
          title="No Approval Policy"
          subtitle="Please create a new approval policy"
        />
      ) : (
        <>
          <List className="core-list">
            {fields
              ?.sort((a, b) => a?.lowerLimit - b?.lowerLimit)
              ?.map((v, i) => (
                <>
                  <CardContents
                    contentStyle={{
                      display: 'block',
                    }}
                    key={v?.id}
                  >
                    <div>
                      <span className="mdDesc">Approval Limit</span>
                      <Grid container style={{ padding: '5px' }}>
                        <Grid
                          item
                          xs={5}
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <Controller
                            render={({ onChange, value }) => (
                              <NumberFormat
                                customInput={TextField}
                                allowNegative={false}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                decimalScale={2}
                                fixedDecimalScale
                                className="price-input-new"
                                label="Lower Limit"
                                thousandSeparator
                                required
                                key={v?.id}
                                disabled
                                defaultValue={v?.lowerLimit ?? 0}
                                onValueChange={({ floatValue, value }) => {
                                  onChange(floatValue)
                                }}
                                helperText={
                                  errors?.approvalPolicy?.lowerLimit
                                    ? errors?.approvalPolicy?.lowerLimit
                                        ?.message
                                    : null
                                }
                                error={!!errors?.lowerLimit ? true : false}
                              />
                            )}
                            name={`approvalPolicy.${i}.lowerLimit`}
                            control={control}
                            defaultValue={v?.lowerLimit ?? 0}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          className="desc"
                          style={{
                            textAlign: 'center',
                            justifyContent: 'center',
                            marginBottom: '10px',
                            marginTop: '10px',
                            fontSize: 'medium',
                          }}
                        >
                          -
                        </Grid>

                        <Grid item xs={5} style={{ textAlign: 'center' }}>
                          <div style={{ display: 'none' }}>
                            <Controller
                              as={<TextField hidden></TextField>}
                              name={`approvalPolicy.${i}.approvalPolicyID`}
                              control={control}
                              defaultValue={v?.approvalPolicyID}
                            />
                          </div>

                          <Controller
                            render={({ onChange, value }) => (
                              <NumberFormat
                                customInput={TextField}
                                allowNegative={false}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                decimalScale={2}
                                fixedDecimalScale
                                className="price-input-new"
                                label="Upper Limit"
                                thousandSeparator
                                required
                                key={v?.id}
                                onValueChange={({ floatValue, value }) => {
                                  onChange(floatValue)
                                }}
                                defaultValue={v?.upperLimit ?? 0}
                                helperText={
                                  errors?.approvalPolicy?.[i]?.upperLimit
                                    ? errors?.approvalPolicy?.[i]?.upperLimit
                                        ?.message
                                    : watch(`approvalPolicy.${i}.upperLimit`) <=
                                      watch(`approvalPolicy.${i}.lowerLimit`)
                                    ? 'Upper Limit must be greater than Lower Limit'
                                    : null
                                }
                                error={
                                  !!errors?.approvalPolicy?.[i]?.upperLimit
                                    ? true
                                    : watch(`approvalPolicy.${i}.upperLimit`) <=
                                      watch(`approvalPolicy.${i}.lowerLimit`)
                                    ? true
                                    : false
                                }
                              />
                            )}
                            name={`approvalPolicy.${i}.upperLimit`}
                            control={control}
                            defaultValue={v?.upperLimit ?? 0}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          className={classesVaria.deleteIcon}
                          style={{
                            justifyContent: 'center',
                          }}
                        >
                          <IconButton
                            className="delete-icon"
                            onClick={() => {
                              checkPendingDocument(getPendingAP)
                              if (getPendingAP?.document?.length === 0) {
                                const lastIndex = fields.length - 1
                                const lastApprovalPolicyID =
                                  fields[lastIndex]?.approvalPolicyID
                                setRemovePolicy(array => [
                                  ...array,
                                  lastApprovalPolicyID,
                                ])
                                remove(lastIndex)
                                let updatedFields = fields.slice(0, lastIndex)
                                setValue('approvalPolicy', [...updatedFields])
                              }
                            }}
                            style={{ scale: 0.7 }}
                          >
                            <Delete
                              htmlColor="white"
                              fontSize="default"
                              style={{
                                width: '1.4rem',
                                height: '1.4rem',
                                margin: '0',
                                background: theme.palette.error.main,
                                boxShadow: `0px 2px 5px 0px ${theme.palette.error.main}`,
                                borderRadius: '3px',
                                color: 'white',
                              }}
                            />
                          </IconButton>
                        </Grid>

                        <RoleListingComponent
                          listIndex={i}
                          control={control}
                          register={register}
                          errors={errors}
                          watch={watch}
                          setValue={setValue}
                          theme={theme}
                          isDesktop={isDesktop}
                          classesVaria={classesVaria}
                          getApprovalList={getApprovalList}
                          getRole={getRole}
                          key={v?.id}
                          checkPendingDocument={checkPendingDocument}
                          getPendingAP={getPendingAP}
                        />
                      </Grid>
                    </div>
                  </CardContents>
                </>
              ))}
          </List>
        </>
      )}
    </>
  )
}
