import theme from '@ifca-root/react-component/src/assets/theme'
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion'
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { InboundCompDetail } from '../APInboundInvoice/APInboundExpansion/InboundCompDetail'
import { InboundDocDetail } from '../APInboundInvoice/APInboundExpansion/InboundDocDetail'
import { InboundTrxDetail } from '../APInboundInvoice/APInboundExpansion/InboundTrxDetail'
import { TrxRefNoTable } from '../APInboundInvoice/APInboundExpansion/Tables/TransactionTables'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

const useStyles = makeStyles({
  header: {
    backgroundColor: '#f7941e', // Customize the background color
    color: 'white', // Text color
    fontSize: '12px', // Font size
    fontWeight: 'bold',
    padding: '16px', // Padding around the text
    textAlign: 'center', // Center the text
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    fontSize: '12px', // Font size
    fontWeight: 'bold',
  },
})

type Anchor = 'top' | 'left' | 'bottom' | 'right'

interface DetailDrawerProps {
  anchor: Anchor
  isOpen: boolean
  data: any
  onClose: () => void
}

export default function DetailDrawer({
  anchor,
  isOpen,
  onClose,
  data,
}: DetailDrawerProps) {
  const classes = useStyles()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const list = data => (
    <>
      {/* Header Section */}
      <div
        className={classes.header}
        style={{ padding: '0 10px', alignItems: 'center' }}
      >
        {isDesktop ? (
          <Button
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon />
          </Button>
        ) : (
          <div style={{ height: '36px' }}></div>
        )}
        <span>{data?.DocNo}</span>
        {/*  Important empty div for space-between CSS; can also use any other empty element */}
        <div></div>
      </div>

      {/* Content Section */}
      <div className={classes.content}>
        <List className="core-list">
          {[
            {
              name: 'Document Detail',
              content: <InboundDocDetail data={data?.DocumentDetail} />,
            },
            {
              name: 'Supplier Detail',
              content: (
                <InboundCompDetail
                  type={'Supplier'}
                  data={data?.CreditorAccount}
                />
              ),
            },
            {
              name: 'Buyer Detail',
              content: (
                <InboundCompDetail type={'Buyer'} data={data?.BuyerDetail} />
              ),
            },
            {
              name: 'Recipient Detail',
              content: (
                <InboundCompDetail
                  type={'Recipient'}
                  data={data?.RecipientDetail}
                />
              ),
            },
            {
              name: 'Transaction Tax',
              content: (
                <InboundTrxDetail type={'Tax'} data={data?.TransactionTax} />
              ),
            },
            {
              name: 'Transaction Ref No',
              content: (
                <TrxRefNoTable TransactionRefNo={data?.TransactionRefNo} />
              ),
            },
            {
              name: 'Transaction Item',
              content: (
                <InboundTrxDetail type={'Item'} data={data?.TransactionItem} />
              ),
            },
          ].map((section, index) => (
            <CardExpansion
              customColor={true}
              backPanelColor="#faf2e8"
              summary={
                <ListItem
                  style={{ backgroundColor: '#faf2e8', color: '#ff9800' }}
                >
                  <ListItemText
                    primary={<span className="xsTitle">{section.name}</span>}
                  />
                </ListItem>
              }
            >
              {section.content}
            </CardExpansion>
          ))}
        </List>
      </div>
    </>
  )

  return (
    <Drawer
      anchor={isDesktop ? 'right' : 'bottom'}
      open={isOpen}
      onClose={onClose}
      PaperProps={{ style: { width: isDesktop && '85%' } }}
    >
      {list(data)}
    </Drawer>
  )
}
