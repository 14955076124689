import DateFnsUtils from '@date-io/date-fns'
import theme from '@ifca-root/react-component/src/assets/theme'
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import AdvanceFilterCardExpansion from 'components/CardExpansion/AdvanceFilterCardExpansion'
import { ConsolidateDialog } from 'components/Dialog/ConsolidateDialog'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  EInvoiceTrxType,
  useCreateEinvoiceConsolidateDocTypeAllMutation,
  useCreateEinvoiceConsolidateMutation,
  useGetCompanyNameQuery,
  useGetDebtorAccountLazyQuery,
  useGetDocToConsolidateLazyQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import {
  dateFormat,
  formatDashDate,
} from 'helpers/StringNumberFunction/FormatDate'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import { ConsolidateTable } from '../../ARAPModule/ConsolidateTable/ConsolidateTable'

export const ARConsolidateEInvoiceForm = (props: any) => {
  /*
  ===========================================
  =================VARIABLES=================
  ===========================================
  */

  let history = useHistory()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const today = new Date()

  /*
  ===========================================
  =================USE STATE=================
  ===========================================
  */

  const { CompanyID }: any = useParams()
  const [itemData, setItemData] = useState([])
  const [errMsg, setErrMsg] = useState<string>('')

  const [docType, setDocType] = useState('Invoice')

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [labelState, setLabelState] = useState<string>()
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [expanded, setExpanded] = useState(false)

  /*
  ====================================
  ==============QUERY=================
  ====================================
  */

  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: ({ getCompany }) => {
      if (!!getCompany) {
        setLabelState(getCompany[0]?.Name)
        fetchDocs({
          variables: {
            CompanyID,
            DocType: docType === 'All' ? null : docType,
            DocNo: '',
            DebtorName: '',
          },
        })
        fetchDebtor({
          variables: {
            CompanyID: CompanyID,
          },
        })
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    fetchDebtor,
    {
      loading: DebtorAccountLoading,
      data: { getDebtorAccount } = { getDebtorAccount: [] },
    },
  ] = useGetDebtorAccountLazyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    fetchDocs,
    {
      loading: ConsolidateLoading,
      data: { getDocToConsolidate } = { getDocToConsolidate: [] },
    },
  ] = useGetDocToConsolidateLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getDocToConsolidate }) => {
      let arry = []
      if (getDocToConsolidate?.length > 0) {
        getDocToConsolidate?.map(el => {
          arry.push({
            ...el,
          })
        })
        setItemData(arry)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  /*
  ==========================================
  =================MUTATION=================
  ==========================================
  */
  const [
    createEinvoiceConsolidate,
    { loading: createEinvoiceConsolidateLoading },
  ] = useCreateEinvoiceConsolidateMutation({
    onError: error => {
      setOpenDialog(false)
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenDialog(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())

      setTimeout(() => {
        history.push({
          pathname: `/account-receivable/${CompanyID}/consolidate-einvoice`,
        })
      }, 500)
    },
  })

  const [
    createEinvoiceConsolidateDocTypeAll,
    { loading: createEinvoiceConsolidateDocTypeAllLoading },
  ] = useCreateEinvoiceConsolidateDocTypeAllMutation({
    onError: error => {
      setOpenDialog(false)
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenDialog(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-receivable/${CompanyID}/consolidate-einvoice`,
        })
      }, 500)
    },
  })

  /*
  ==========================================
  =================USE FORM=================
  ==========================================
  */

  const {
    register: registerFilter,
    control: controlFilter,
    setValue: setValueFilter,
    getValues: getValueFilter,
    watch: watchFilter,
  } = useForm()

  /*
  ==========================================
  ==============FUNCTION & MISC=============
  ==========================================
  */
  const onSubmitFilter = () => {
    const selectedDocType =
      getValueFilter('DocType') === 'All' ? 'All' : getValueFilter('DocType')

    setDocType(selectedDocType)
    setStartDate(
      getValueFilter('StartDate') !== null &&
        getValueFilter('StartDate') !== undefined
        ? dateFormat(new Date(getValueFilter('StartDate')).toISOString())
        : ''
    )
    setEndDate(
      getValueFilter('EndDate') !== null &&
        getValueFilter('EndDate') !== undefined
        ? dateFormat(new Date(getValueFilter('EndDate')).toISOString())
        : ''
    )

    fetchDocs({
      variables: {
        CompanyID: CompanyID,
        DocType: selectedDocType,
        DocNo: getValueFilter('DocNo') ?? '',
        DebtorName: getValueFilter('DebtorName') ?? '',
        StartDate:
          getValueFilter('StartDate') !== null &&
          getValueFilter('StartDate') !== undefined
            ? formatDashDate(
                new Date(getValueFilter('StartDate')).toISOString()
              )
            : null,
        EndDate:
          getValueFilter('EndDate') !== null &&
          getValueFilter('EndDate') !== undefined
            ? formatDashDate(new Date(getValueFilter('EndDate')).toISOString())
            : null,
      },
    })
  }

  const resetFilter = () => {
    setDocType('Invoice')
    setValueFilter('DocType', 'Invoice')
    setValueFilter(
      'StartDate',
      new Date(today.getFullYear(), today.getMonth() - 1, 1)
    )
    setValueFilter(
      'EndDate',
      new Date(today.getFullYear(), today.getMonth(), 0)
    )
    setValueFilter('DebtorName', '')
    setValueFilter('DocNo', '')

    setStartDate('')
    setEndDate('')

    fetchDocs({
      variables: {
        CompanyID,
        DocType: 'Invoice',
        DocNo: '',
        DebtorName: '',
      },
    })

    fetchDebtor({
      variables: {
        CompanyID: CompanyID,
      },
    })
  }

  const renderFilterHeader = () => {
    return (
      <span className={`xsTitle flex-space`}>
        {`Doc Type: `}
        <span className={`mdDesc`} style={{ color: '#000000' }}>
          {docType}
        </span>
        {startDate !== '' && endDate !== '' ? `, ` : null}
        {startDate !== '' && `From: `}
        <span className={`mdDesc`} style={{ color: '#000000' }}>
          {startDate}
        </span>
        {endDate !== '' && ` To: `}
        <span className={`mdDesc`} style={{ color: '#000000' }}>
          {endDate}
        </span>
      </span>
    )
  }

  /*
  ==========================================
  ===================FOOTER=================
  ==========================================
  */
  const footerOptions = [
    {
      disabled: getDocToConsolidate?.length === 0 ? true : false,
      name: 'Send to LHDN',
      onClick: () => {
        setOpenDialog(true)
      },
      color: 'primary',
      props: { type: 'submit' },
      style: { float: 'right', margin: isDesktop ? null : '0 auto' },
    },
  ]

  const documentType = [
    {
      name: 'Invoice',
      value: 'Invoice',
      einvoice_type: EInvoiceTrxType.Invoice,
    },
    {
      name: 'Credit Note',
      value: 'Credit Note',
      einvoice_type: EInvoiceTrxType.CreditNote,
    },
    {
      name: 'Debit Note',
      value: 'Debit Note',
      einvoice_type: EInvoiceTrxType.DebitNote,
    },
    {
      name: 'Refund Note',
      value: 'Refund Note',
      einvoice_type: EInvoiceTrxType.RefundNote,
    },
  ]

  const totalDocAmt = getDocToConsolidate?.reduce(
    (total, current) => total + Number(current?.DocAmt),
    0
  )

  const totalTaxAmt = getDocToConsolidate?.reduce(
    (total, current) => total + Number(current?.TaxAmt),
    0
  )

  /*
  =====================================
  ==============ONSUBMIT=============
  =====================================
  */
  const trimData = (data, itemData) => {
    // Separate arrays for different document types
    const invoices = []
    const creditNotes = []
    const debitNotes = []
    const refundNotes = []
    const filteredData = []

    // Categorize documents into different types
    itemData?.map(doc => {
      switch (doc.DocType) {
        case 'Invoice':
          invoices.push(doc)
          break
        case 'Credit Note':
          creditNotes.push(doc)
          break
        case 'Debit Note':
          debitNotes.push(doc)
          break
        case 'Refund Note':
          refundNotes.push(doc)
          break
      }
    })

    if (invoices.length > 0) {
      const totalInvDocAmt = invoices?.reduce(
        (total, current) => total + Number(current?.DocAmt),
        0
      )
      const totalInvTaxAmt = invoices?.reduce(
        (total, current) => total + Number(current?.TaxAmt),
        0
      )

      filteredData.push({
        CompanyID: CompanyID,
        DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
        Description: data?.Description,
        Amount: Number(
          parseFloat(totalInvDocAmt) - parseFloat(totalInvTaxAmt ?? 0.0)
        ),
        TaxAmt: Number(parseFloat(totalInvTaxAmt ?? 0.0)),
        DocAmt: Number(parseFloat(totalInvDocAmt)),
        einvoice_trx_type: EInvoiceTrxType.Invoice,
        consolidateItems: invoices?.map(x => {
          return {
            RefRecordID: x?.RefRecordID,
            RefTable: x?.RefTable,
            Amount: Number(x?.DocAmt) - Number(x?.TaxAmt),
            TaxAmt: Number(x?.TaxAmt),
            DocAmt: Number(x?.DocAmt),
            DocType: x?.DocType,
          }
        }),
      })
    }

    if (creditNotes.length > 0) {
      const totalCNDocAmt = creditNotes?.reduce(
        (total, current) => total + Number(current?.DocAmt),
        0
      )
      const totalCNTaxAmt = creditNotes?.reduce(
        (total, current) => total + Number(current?.TaxAmt),
        0
      )

      filteredData.push({
        CompanyID: CompanyID,
        DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
        Description: data?.Description,
        Amount: Number(
          parseFloat(totalCNDocAmt) - parseFloat(totalCNTaxAmt ?? 0.0)
        ),
        TaxAmt: Number(parseFloat(totalCNTaxAmt ?? 0.0)),
        DocAmt: Number(parseFloat(totalCNDocAmt)),
        einvoice_trx_type: EInvoiceTrxType.CreditNote,
        consolidateItems: creditNotes?.map(x => {
          return {
            RefRecordID: x?.RefRecordID,
            RefTable: x?.RefTable,
            Amount: Number(x?.DocAmt) - Number(x?.TaxAmt),
            TaxAmt: Number(x?.TaxAmt),
            DocAmt: Number(x?.DocAmt),
            DocType: x?.DocType,
          }
        }),
      })
    }

    if (debitNotes.length > 0) {
      const totalDNDocAmt = debitNotes?.reduce(
        (total, current) => total + Number(current?.DocAmt),
        0
      )
      const totalDNTaxAmt = debitNotes?.reduce(
        (total, current) => total + Number(current?.TaxAmt),
        0
      )

      filteredData.push({
        CompanyID: CompanyID,
        DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
        Description: data?.Description,
        Amount: Number(
          parseFloat(totalDNDocAmt) - parseFloat(totalDNTaxAmt ?? 0.0)
        ),
        TaxAmt: Number(parseFloat(totalDNTaxAmt ?? 0.0)),
        DocAmt: Number(parseFloat(totalDNDocAmt)),
        einvoice_trx_type: EInvoiceTrxType.DebitNote,
        consolidateItems: debitNotes?.map(x => {
          return {
            RefRecordID: x?.RefRecordID,
            RefTable: x?.RefTable,
            Amount: Number(x?.DocAmt) - Number(x?.TaxAmt),
            TaxAmt: Number(x?.TaxAmt),
            DocAmt: Number(x?.DocAmt),
            DocType: x?.DocType,
          }
        }),
      })
    }

    if (refundNotes.length > 0) {
      const totalDNDocAmt = refundNotes?.reduce(
        (total, current) => total + Number(current?.DocAmt),
        0
      )
      const totalDNTaxAmt = refundNotes?.reduce(
        (total, current) => total + Number(current?.TaxAmt),
        0
      )

      filteredData.push({
        CompanyID: CompanyID,
        DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
        Description: data?.Description,
        Amount: Number(
          parseFloat(totalDNDocAmt) - parseFloat(totalDNTaxAmt ?? 0.0)
        ),
        TaxAmt: Number(parseFloat(totalDNTaxAmt ?? 0.0)),
        DocAmt: Number(parseFloat(totalDNDocAmt)),
        einvoice_trx_type: EInvoiceTrxType.DebitNote,
        consolidateItems: refundNotes?.map(x => {
          return {
            RefRecordID: x?.RefRecordID,
            RefTable: x?.RefTable,
            Amount: Number(x?.DocAmt) - Number(x?.TaxAmt),
            TaxAmt: Number(x?.TaxAmt),
            DocAmt: Number(x?.DocAmt),
            DocType: x?.DocType,
          }
        }),
      })
    }

    return filteredData
  }

  const onSubmit = data => {
    if (getValueFilter('DocType') !== 'All') {
      createEinvoiceConsolidate({
        variables: {
          input: {
            CompanyID: CompanyID,
            DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
            Description: data?.Description,
            Amount: totalDocAmt - totalTaxAmt,
            TaxAmt: totalTaxAmt,
            DocAmt: totalDocAmt,
            einvoice_trx_type: documentType?.find(
              x => x?.value === getValueFilter('DocType')
            )?.einvoice_type,
          },
          itemInput: itemData?.map((x, i) => {
            return {
              RefRecordID: x?.RefRecordID,
              DocType: x?.DocType,
              Amount: Number(
                parseFloat(x?.DocAmt) - parseFloat(x?.TaxAmt ?? 0.0)
              ),
              TaxAmt: Number(parseFloat(x?.TaxAmt ?? 0.0)),
              DocAmt: Number(parseFloat(x?.DocAmt)),
            }
          }),
        },
      })
    } else {
      createEinvoiceConsolidateDocTypeAll({
        variables: {
          input: trimData(data, itemData),
        },
      })
    }
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {ConsolidateLoading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {createEinvoiceConsolidateLoading && <Loading />}
      {createEinvoiceConsolidateDocTypeAllLoading && <Loading />}

      <MainHeader
        mainBtn={'close'}
        onClick={() =>
          history.push({
            pathname: `/account-receivable/${CompanyID}/consolidate-einvoice`,
          })
        }
        smTitle={smTitle.ACCOUNT_RECEIVABLE}
        title={labelState}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Accounts Receivable' },
          { name: `Consolidate e-Invoice`, current: true },
        ]}
      />

      <ContentWrapper footer>
        <List className="core-list">
          <AdvanceFilterCardExpansion
            customColor={true}
            customColorCode="#faf2e8"
            expansion={expanded}
            summary={
              <ListItem
                style={{
                  backgroundColor: '#faf2e8',
                  color: '#ff9800',
                }}
              >
                <ListItemText primary={<>{renderFilterHeader()}</>} />
              </ListItem>
            }
          >
            <div
              style={{
                fontSize: '12px',
                marginBottom: '15px',
                display: 'block',
              }}
            >
              <Controller
                render={({ onChange, value }) => (
                  <TextField
                    label="Doc Type"
                    select
                    value={value}
                    fullWidth
                    onChange={e => {
                      onChange(e)
                    }}
                    style={{ marginTop: '8px', marginBottom: '12px' }}
                  >
                    {documentType?.map((el, index) => (
                      <MenuItem key={index} value={el?.value}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                onChange={e => {
                  setValueFilter('DocType', e?.target?.value)
                }}
                defaultValue={
                  documentType?.find(x => x?.value === docType)?.value
                }
                control={controlFilter}
                ref={registerFilter()}
                name="DocType"
                autoComplete="off"
                multiline={true}
                margin="dense"
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  as={KeyboardDatePicker}
                  name="StartDate"
                  label="Doc Start Date"
                  control={controlFilter}
                  onChange={(date: Date | null) => {}}
                  format="dd/MM/yyyy"
                  value={watchFilter('StartDate')}
                  margin="normal"
                  allowKeyboardControl
                  ref={registerFilter}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  className="left"
                  defaultValue={
                    new Date(today.getFullYear(), today.getMonth() - 1, 1)
                  }
                  showTodayButton
                  fullWidth
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  as={KeyboardDatePicker}
                  name="EndDate"
                  label="Doc End Date"
                  control={controlFilter}
                  onChange={(date: Date | null) => {
                    setValueFilter('EndDate', date)
                  }}
                  format="dd/MM/yyyy"
                  value={watchFilter('EndDate')}
                  margin="normal"
                  allowKeyboardControl
                  ref={registerFilter}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  className="right"
                  defaultValue={
                    new Date(today.getFullYear(), today.getMonth(), 0)
                  }
                  showTodayButton
                  fullWidth
                />
              </MuiPickersUtilsProvider>

              {!DebtorAccountLoading && (
                <Controller
                  render={({ value, onChange }) => {
                    return (
                      <Autocomplete
                        options={
                          getDebtorAccount
                            ?.sort((a, b) => {
                              return a?.DebtorName?.localeCompare(b?.DebtorName)
                            })
                            ?.filter(x => {
                              return x?.einvoice_send_individually === false
                            }) || []
                        }
                        getOptionLabel={option => `${option?.DebtorName}`}
                        fullWidth
                        onChange={(value, newValue: any) => {
                          setValueFilter('DebtorName', newValue?.DebtorName)
                        }}
                        renderOption={(props, option) => {
                          return <span>{props?.DebtorName}</span>
                        }}
                        renderInput={(params: any) => {
                          return (
                            <TextField
                              {...params}
                              label="Debtor Name"
                              style={{
                                width: '100%',
                                marginTop: '8px',
                                marginBottom: '12px',
                              }}
                              margin="dense"
                            />
                          )
                        }}
                      />
                    )
                  }}
                  label="Debtor Name"
                  name="DebtorName"
                  autoComplete="off"
                  control={controlFilter}
                  multiline={true}
                  fullWidth
                  margin="dense"
                  ref={registerFilter}
                />
              )}

              <Controller
                as={TextField}
                style={{ marginTop: '8px', marginBottom: '12px' }}
                id="standard-basic"
                name="DocNo"
                label="Document No."
                autoComplete="off"
                control={controlFilter}
                fullWidth
                margin="dense"
                ref={registerFilter}
              />

              <Button
                type="reset"
                color="secondary"
                style={{
                  minWidth: '50px',
                  float: 'left',
                  marginBottom: '15px',
                }}
                variant="contained"
                onClick={() => {
                  resetFilter()
                }}
              >
                Reset
              </Button>

              <Button
                type="submit"
                color="primary"
                style={{
                  minWidth: '50px',
                  float: 'right',
                  marginBottom: '15px',
                }}
                variant="contained"
                onClick={() => {
                  onSubmitFilter()
                }}
              >
                Filter
              </Button>
            </div>
          </AdvanceFilterCardExpansion>
        </List>

        {/* Table Section */}
        <ConsolidateTable
          creditorOrDebtor={'Debtor'}
          data={getDocToConsolidate}
        />
      </ContentWrapper>

      <ConsolidateDialog
        title={'e-Invoice'}
        onSubmit={onSubmit}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        length={getDocToConsolidate?.length}
        totalDocAmt={totalDocAmt}
      />

      <TotalAmountFooter
        countRecord={
          getDocToConsolidate?.length > 0 ? getDocToConsolidate?.length : 0
        }
        taxInfoDetailWithRecord={true}
        docAmt={totalDocAmt}
        totalTaxAmt={totalTaxAmt}
      />

      <AccountFooter
        optionStyle={{ float: isDesktop ? 'right' : null }}
        options={footerOptions}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
