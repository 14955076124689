import {
  Checkbox,
  Grid,
  ListItemText,
  Switch,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import { Controller } from 'react-hook-form'
import React, { Fragment, useState } from 'react'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { changeDateFormat } from '@ifca-root/react-component/src/helpers/Functions/dateFunction'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
interface EinvoiceProps {
  register
  control
  errors
  selfBilled
  defaultMsicCodeID
  isSelfBilled
  handleToggle
  setIsRefundNote?
  msicCodeLoading
  getMSICCode
  msicCodeID
  setMsicCodeID
  selfBilledChecking: boolean
  haveSelfBilled: boolean
  isRefundNote?: boolean
  data?: any
  dataLoading?: any
  isDocRefID?: boolean
  docRefKeyID?: any
  editData?: any
  isSelfBilledForm?: boolean
  isCN?: boolean
  selectedDocs?
  setSelectedDocs?
  clearErrors?
  setValue?
}

export const IsEinvoiceSelfBilled = (props: EinvoiceProps) => {
  const {
    register,
    control,
    errors,
    selfBilled,
    defaultMsicCodeID,
    isSelfBilled,
    handleToggle,
    setIsRefundNote,
    isRefundNote,
    msicCodeLoading,
    getMSICCode,
    msicCodeID,
    setMsicCodeID,
    selfBilledChecking,
    haveSelfBilled,
    data,
    dataLoading,
    isDocRefID,
    docRefKeyID,
    editData,
    isSelfBilledForm = false,
    isCN = false,
    selectedDocs,
    setSelectedDocs,
    clearErrors,
    setValue,
  } = props

  const handleCheckBoxChange = (event, option) => {
    if (!option) return // Prevent any undefined option errors

    const updatedSelectedDocs = new Set(selectedDocs)

    if (event.target.checked) {
      // Check if the current selection can be made based on einvoice_directsend
      const currentDirectSend = option.einvoice_directsend

      // If selectedDocs is empty, we can add any option
      if (updatedSelectedDocs.size === 0) {
        updatedSelectedDocs.add(option[docRefKeyID]) // Add the selected item
      } else {
        // Check if all selected items have the same einvoice_directsend value
        const allSameDirectSend = Array.from(updatedSelectedDocs).every(doc => {
          const currentDoc = data.find(item => item[docRefKeyID] === doc)
          return currentDoc.einvoice_directsend === currentDirectSend
        })

        // If they match, add the new option; otherwise, alert the user
        if (allSameDirectSend) {
          updatedSelectedDocs.add(option[docRefKeyID]) // Add the selected item
        } else {
          alert('All selected items must be in the same category.')
        }
      }
    } else {
      // If unchecked, simply remove the item from the selection
      updatedSelectedDocs.delete(option[docRefKeyID]) // Remove the unchecked item
    }

    setSelectedDocs(updatedSelectedDocs) // Update the state with the new selection
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  //this is for toggle refund note
  const handleRefundNote = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('isRefundNote', e.target.checked)
    setIsRefundNote(e.target.checked)
  }

  return (
    <Grid spacing={1} container className="table-content">
      {selfBilledChecking && (
        <Grid item xs={6} style={{ padding: '18px' }}>
          <span className="flex-space">{'Self-Billed e-Invoice'}</span>
          <Switch
            name="isSelfBilled"
            color="primary"
            inputRef={register()}
            checked={isSelfBilled}
            defaultChecked={
              editData ? editData?.einvoice_self_billed : selfBilled
            }
            onChange={e => {
              handleToggle(e)
            }}
          />
        </Grid>
      )}

      {isCN && (
        <>
          <Grid item xs={4} style={{ alignContent: 'center' }}>
            <span>Refund Note</span>
          </Grid>
          <Grid item xs={2} style={{ alignContent: 'center' }}>
            <Switch
              name="isRefundNote"
              color="primary"
              checked={isRefundNote}
              defaultChecked={
                editData ? editData?.einvoice_is_refund_note : isRefundNote
              }
              onChange={e => {
                handleRefundNote(e)
              }}
            />
          </Grid>
        </>
      )}

      {haveSelfBilled && (
        <>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="einvoice_date"
              label="Self Billed Date"
              control={control}
              onChange={(date: Date | null) => {
                changeDateFormat(date, 'YYYY-MM-DD')
              }}
              format="dd/MM/yyyy"
              value={new Date()}
              allowKeyboardControl
              ref={register}
              defaultValue={new Date()}
              showTodayButton
              disabled={true}
              className=" left"
              style={{ padding: '10px' }}
            />
          </MuiPickersUtilsProvider>

          <Grid item xs={isSelfBilledForm || isDocRefID ? 6 : 12}>
            {!msicCodeLoading && (
              <Controller
                render={({ value, onChange }) => {
                  let defVal
                  if (editData) {
                    defVal = getMSICCode?.filter(
                      msic =>
                        msic?.id === msicCodeID ||
                        msic?.id === editData?.einvoice_msic_code_id
                    )[0]
                  } else {
                    defVal = getMSICCode?.filter(
                      msic => msic?.id === defaultMsicCodeID
                    )[0]
                  }

                  return (
                    <Autocomplete
                      options={
                        getMSICCode?.sort((a, b) => {
                          return a?.msicCode.localeCompare(b?.msicCode)
                        }) || []
                      }
                      getOptionLabel={option =>
                        `${option?.msicCode} (${option?.description})`
                      }
                      fullWidth
                      onChange={(value, newValue: any) => {
                        onChange(newValue?.id)
                        setMsicCodeID(newValue?.id)
                      }}
                      // disabled={!isSelfBilled}
                      PopperComponent={AccCodeDropdownFullWidth}
                      renderOption={(props, option) => {
                        return (
                          <div className="content-wrap full">
                            <div>
                              <span className="xsTitle">{props?.msicCode}</span>
                            </div>
                            <div className="desc">{props?.description}</div>
                          </div>
                        )
                      }}
                      defaultValue={defVal}
                      renderInput={(params: any) => {
                        return (
                          <div>
                            <TextField
                              {...params}
                              name="MSICCodeID"
                              helperText={
                                (msicCodeID === null ||
                                  msicCodeID === undefined) &&
                                errors?.MSICCodeID?.message
                              }
                              error={
                                (msicCodeID === null ||
                                  msicCodeID === undefined) &&
                                errors?.MSICCodeID
                                  ? true
                                  : false
                              }
                              label="MSIC Code"
                              style={{ width: '100%' }}
                              InputLabelProps={defVal ? { shrink: true } : null}
                              margin="dense"
                              variant="standard"
                              required
                            />
                          </div>
                        )
                      }}
                    />
                  )
                }}
                label="MSIC Code"
                name="MSICCodeID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="dense"
                ref={register}
                value={msicCodeID}
                defaultValue={
                  editData ? editData?.einvoice_msic_code_id : defaultMsicCodeID
                }
              />
            )}
          </Grid>
          {isDocRefID && (
            <Grid item xs={isSelfBilledForm || isCN ? 12 : 6}>
              {!dataLoading && (
                <Controller
                  render={({ value, onChange }) => {
                    let defVal
                    if (editData) {
                      defVal = data?.filter(obj =>
                        selectedDocs?.has(obj[docRefKeyID])
                      )
                    }
                    return (
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={
                          data?.sort((a, b) => {
                            return a?.DocNo?.localeCompare(b?.DocNo)
                          }) || []
                        }
                        getOptionLabel={option => `${option?.DocNo}`}
                        fullWidth
                        value={Array.from(selectedDocs)?.map(doc =>
                          data?.find(item => item[docRefKeyID] === doc)
                        )} // Convert Set to array of matching objects
                        onChange={(event, value, reason) => {
                          const selected = new Set(selectedDocs)
                          if (reason === 'select-option') {
                            // Allow adding only if the condition is met
                            const firstSelectedItem = value[0]
                            if (firstSelectedItem) {
                              const firstSelectedDirectSend =
                                firstSelectedItem.einvoice_directsend
                              const validSelection = value.every(
                                item =>
                                  item.einvoice_directsend ===
                                  firstSelectedDirectSend
                              )

                              if (validSelection) {
                                value.forEach(item =>
                                  selected.add(item[docRefKeyID])
                                )
                              } else {
                                // Optionally, show an error or alert to the user
                                if (firstSelectedItem.einvoice_directsend) {
                                  return alert(
                                    'Document selected is Individually. Please choose a document from the same group (Individually).'
                                  )
                                } else {
                                  return alert(
                                    'Document selected is Consolidated. Please choose a document from the same group (Consolidated).'
                                  )
                                }
                              }
                            }
                          } else if (reason === 'remove-option') {
                            // Handle removal of options, including the last one
                            if (value && value.length > 0) {
                              const removedItem = value[value.length - 1]
                              selected.delete(removedItem[docRefKeyID])
                            } else {
                              // Clear all selected options when none are left
                              selected.clear()
                            }
                          } else if (reason === 'clear') {
                            // Clear all selections when the 'clear' button is pressed
                            selected.clear()
                          }
                          setSelectedDocs(selected)
                          register('DocRefID')
                          setValue('DocRefID', Array.from(selected))
                          clearErrors('DocRefID')
                        }}
                        defaultValue={defVal}
                        renderOption={(option, { selected }) => {
                          // Determine if the option can be selected based on einvoice_directsend
                          const canBeChecked =
                            selectedDocs.size === 0 ||
                            Array.from(selectedDocs).every(doc => {
                              const currentDoc = data.find(
                                item => item[docRefKeyID] === doc
                              )
                              return (
                                currentDoc.einvoice_directsend ===
                                option.einvoice_directsend
                              )
                            })

                          return (
                            <Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                value={option[docRefKeyID]}
                                style={{
                                  marginRight: 8,
                                  opacity: canBeChecked ? 1 : 0.5,
                                }}
                                checked={selectedDocs.has(option[docRefKeyID])}
                                color="primary"
                                onChange={event => {
                                  if (canBeChecked) {
                                    handleCheckBoxChange(event, option)
                                  } else {
                                    event.preventDefault() // Prevent checking
                                  }
                                }}
                                // Optional: Style it as disabled
                                disabled={!canBeChecked}
                                // style={{
                                //   cursor: canBeChecked ? 'pointer' : 'not-allowed',
                                //   opacity: canBeChecked ? 1 : 0.5,
                                // }}
                              />
                              <ListItemText
                                primary={
                                  <Grid container>
                                    <Grid
                                      item
                                      component="span"
                                      className="xsTitle"
                                    >
                                      {option?.DocNo}
                                    </Grid>
                                  </Grid>
                                }
                              />
                            </Fragment>
                          )
                        }}
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                helperText={errors?.DocRefID?.message}
                                error={errors?.DocRefID ? true : false}
                                label="Document Reference"
                                style={{ width: '100%' }}
                                InputLabelProps={
                                  defVal ? { shrink: true } : null
                                }
                                margin="dense"
                                variant="outlined"
                                required
                                onKeyDown={(event: any) => {
                                  if (event.key === 'Backspace') {
                                    event.stopPropagation()
                                    setSelectedDocs(new Set())
                                  }
                                }}
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  label="Document Reference"
                  name="DocRefID"
                  autoComplete="off"
                  control={control}
                  multiline={true}
                  fullWidth
                  margin="dense"
                  ref={register}
                  helperText={errors?.DocRefID?.message}
                  error={errors?.DocRefID ? true : false}
                  defaultValue={editData?.einvoice_doc_ref_invoice_id}
                />
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}
