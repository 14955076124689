import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import EventIcon from '@material-ui/icons/Event'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import Fuse from 'fuse.js'
import {
  AcctPermission,
  ApprovalStatus,
  useGetBudgetbyStatusQuery,
  useGetBudgetQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const GLBudgetRevisionListing = (props: any) => {
  useEffect(() => {})
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))

  const [originalBudgetStatus, setOriginalBudgetStatus] = useState<string>(
    getSearch?.option
  )

  let history = useHistory()
  const { CompanyID }: any = useParams()
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openWorkFlow, setWorkFlow] = useState(false)
  const { menu: menu2 }: any = useMenuOption()
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  const {
    loading: BudgetLoading,
    error: BudgetError,
    data: { getBudget: getBudget } = { getBudget: [] },
  } = useGetBudgetQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, orderByAsc: 'Fyear' },
    onCompleted: ({ getBudget }) => {
      const pendingV1 = getBudget
        ?.filter(
          x =>
            x?.Version === 'Version 1' &&
            x?.ApprovalStatus === ApprovalStatus.Active
        )
        ?.map(el => el?.BudgetID)
      const filteredBudget = getBudget?.filter(
        m => !pendingV1.includes(m?.BudgetID)
      )
      let arr = []
      arr.push(
        ...filteredBudget?.filter(
          x => x.ApprovalStatus === ApprovalStatus.Active
        )
      )
      arr.push(
        ...filteredBudget?.filter(
          x =>
            x.ApprovalStatus === ApprovalStatus.Submit ||
            x.ApprovalStatus === ApprovalStatus.Approved
        )
      )
      arr.push(
        ...filteredBudget?.filter(
          x => x.ApprovalStatus === ApprovalStatus.Completed
        )
      )
      arr.push(
        ...filteredBudget?.filter(
          x => x.ApprovalStatus === ApprovalStatus.Rejected
        )
      )
      arr.push(
        ...filteredBudget?.filter(
          x => x.ApprovalStatus === ApprovalStatus.Cancelled
        )
      )

      setOriginalListing(arr)
    },
  })

  const {
    loading: BudgetbyStatusLoading,
    error: BudgetbyStatusError,
    data: { getBudgetbyStatus } = { getBudgetbyStatus: [] },
  } = useGetBudgetbyStatusQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  useEffect(() => {
    if (getBudgetbyStatus && getBudgetbyStatus?.length > 0) {
      setOriginalBudgetStatus(getSearch?.option ?? 'ACTIVE')
    }
  }, [getBudgetbyStatus])

  useEffect(() => {
    if (getSearch?.name && !!originalList) {
      const keys = ['Version', 'Fyear']
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(`'"${getSearch?.name}"`)
      const val = result?.map(x => x.item)
      if (
        val.length !== 0 &&
        getSearch?.name !== `'` &&
        getSearch?.name !== `` &&
        getSearch?.name !== `'""` &&
        getSearch?.name !== undefined
      ) {
        handleSearch(getSearch?.name + '', keys)
      }
    }
  }, [getSearch?.name, originalList])

  //////////////////////////////////////////////////////////////////
  ////// ** ORIGINALBUDGET DATA FILTERED BY STATUS ** //////
  //////////////////////////////////////////////////////////////////

  const approvalStatusEnum = [
    { name: 'All', ID: 'ALL' },
    { name: 'Pending', ID: 'ACTIVE' },
    { name: 'Verified', ID: 'COMPLETED' },
  ]

  let newFilteredList = filteredList?.filter(
    v =>
      v?.ApprovalStatus === originalBudgetStatus ||
      originalBudgetStatus === 'ALL'
  )

  let color
  const handleYearStatusColor = type => {
    switch (type) {
      case 'ACTIVE':
        color = '#FF0000'
        break
      case 'COMPLETED':
        color = ''
        break
    }
    return color
  }

  const divider = ' | '

  return (
    <>
      {BudgetLoading && <Loading />}
      {BudgetbyStatusLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-ledger/${CompanyID}/`)
          localStorage.removeItem('searchFilter')
        }} //<- back button action
        smTitle="General Ledger"
        title={user?.companyName}
        currency={`MYR`}
        routeSegments={[
          { name: 'Home Journal' },
          {
            name: 'Budget Revision',
            current: true,
          },
        ]}
      />
      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['Fyear', 'Version'])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: originalBudgetStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
          }}
          isDefaultValue={!!getSearch}
        />

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setOriginalBudgetStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(originalBudgetStatus ?? 'ACTIVE')}
        />
      </div>

      <ContentWrapper float>
        <List className="core-list">
          {newFilteredList === undefined || newFilteredList?.length === 0
            ? !BudgetLoading && (
                <EmptyList
                  title="No Record found"
                  subtitle="Add a new record now."
                />
              )
            : !BudgetLoading &&
              newFilteredList.map((el, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <>
                          <span className="mdLabel">
                            <EventIcon
                              style={{
                                fontSize: 'small',
                                paddingRight: '2px',
                                marginBottom: '-1px',
                                paddingTop: '2px',
                              }}
                            />
                          </span>
                          <span
                            className=" mdLabel"
                            style={{
                              paddingRight: '3px',
                              color: handleYearStatusColor(el?.ApprovalStatus),
                            }}
                          >
                            {el?.Fyear}
                          </span>
                          <span
                            className="mdLabel"
                            style={{ paddingRight: '3px' }}
                          >
                            {divider}
                          </span>
                          <span
                            className="flex-space mdLabel"
                            style={{
                              color: handleYearStatusColor(el?.ApprovalStatus),
                            }}
                          >
                            {el?.Version}
                          </span>
                          {el.ApprovalStatus === 'COMPLETED' &&
                          el.IsDefault === true ? (
                            <span>
                              <CheckCircleIcon
                                style={{
                                  color: 'limegreen',
                                  fontSize: 'medium',
                                }}
                              />
                            </span>
                          ) : null}
                        </>
                      }
                      secondary={
                        <>
                          <span className="desc flex-space">
                            <span
                              className="fw-medium"
                              style={{
                                textDecoration:
                                  el.ApprovalStatus === 'REJECTED'
                                    ? 'line-through'
                                    : null,
                              }}
                            >
                              {`Latest updated ${formatDate(el.modTs)}.`}
                            </span>
                          </span>
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        disabled={handlePermDisabled({
                          companyID: CompanyID,
                          permEnum:
                            AcctPermission.GeneralLedgerBudgetRevisionUpdate,
                        })}
                      >
                        <KeyboardArrowRight
                          onClick={() =>
                            history.push({
                              pathname:
                                el.ApprovalStatus === 'ACTIVE'
                                  ? `/general-ledger/${CompanyID}/budget-revision/edit`
                                  : `/general-ledger/${CompanyID}/budget-revision/detail`,
                              state: el,
                            })
                          }
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
        </List>

        <FloatButton
          disabled={handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.GeneralLedgerBudgetRevisionCreate,
          })}
          copyright
          onClick={() =>
            history.push({
              pathname: `/general-ledger/${CompanyID}/budget-revision/add`,
            })
          }
        />
      </ContentWrapper>
      <ApprovalLogDialog
        data={menu2?.obj}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Budget'}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
