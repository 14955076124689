import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import React from 'react'
import { useHistory, useParams } from 'react-router'

export const DepreciationMain = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  let dummyDataDepreciation = [
    {
      DepreciationID: '1',
      Name: 'Generate Depreciation',
      LastGenerated: '23 Mar 2024',
      Action: 'generate-depreciation',
      path: `/fixed-assets/${CompanyID}/depreciation/generate-depreciation`,
    },
    {
      DepreciationID: '2',
      Name: 'History',
      LastGenerated: '23 Mar 2024',
      Action: 'history',
      path: `/fixed-assets/${CompanyID}/depreciation/history`,
    },
  ]

  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/fixed-assets/${CompanyID}`)
        }}
        smTitle="Fixed Assets"
        title={user?.companyName}
        routeSegments={[
          { name: 'Fixed Assets' },
          { name: 'Depreciation', current: true },
        ]}
      />

      <ContentWrapper float>
        <List className="core-list">
          {dummyDataDepreciation.map((el, index) => {
            return (
              <ListItem key={index} onClick={() => history.push(el?.path)}>
                <ListItemText
                  style={{
                    textAlign: 'right',
                    marginLeft: '5px',
                  }}
                  primary={<span className="xsTitle">{el?.Name}</span>}
                  secondary={
                    <>
                      <span className="desc">
                        <span className="text">{`Last Generated`}&nbsp;</span>
                        <span className="desc" style={{ fontWeight: 'bold' }}>
                          {`${el?.LastGenerated}`}&nbsp;
                        </span>
                      </span>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <KeyboardArrowRight
                      onClick={() =>
                        history.push({
                          pathname: el?.path,
                          state: el,
                        })
                      }
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      </ContentWrapper>
    </>
  )
}
