import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import { Grid } from '@material-ui/core'
import { formatDateAndTime } from 'helpers/StringNumberFunction/FormatDate'
import React from 'react'

interface Props {
  type: 'Supplier' | 'Recipient' | 'Buyer'
  data: any
}

export const InboundCompDetail = ({ type, data }: Props) => {
  const customCol1 = type === 'Recipient' ? 12 : 4
  const customCol2 = type === 'Buyer' ? 12 : 4
  return (
    <Grid container>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            {type} Name
          </div>
          <div className="mdDesc">{data?.CompanyName ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            {type} TIN
          </div>
          <div className="mdDesc">{data?.einvoice_TIN ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            {type} ID No
          </div>
          <div className="mdDesc">{data?.CompanyIDNo ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={customCol1}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            {type} Identity Type
          </div>
          <div className="mdDesc">{data?.IdentityType ?? '—'}</div>
        </div>
      </Grid>
      {type !== 'Recipient' && (
        <>
          <Grid item sm={12} md={4}>
            <div className="content-wrap full">
              <div className="desc" style={{ color: 'grey' }}>
                {type} SST Reg No
              </div>
              <div className="mdDesc">{data?.SSTRegNo ?? '—'}</div>
            </div>
          </Grid>
          <Grid item sm={12} md={4}>
            <div className="content-wrap full">
              <div className="desc" style={{ color: 'grey' }}>
                {type} Contact Number
              </div>
              <div className="mdDesc">{data?.ContactNo ?? '—'}</div>
            </div>
          </Grid>
        </>
      )}

      {type !== 'Recipient' && (
        <>
          <Grid item sm={12} md={customCol2}>
            <div className="content-wrap full">
              <div className="desc" style={{ color: 'grey' }}>
                {type} Email
              </div>
              <div className={` primary-click-text`}>{data?.Email ?? '—'}</div>
            </div>
          </Grid>
          {type === 'Supplier' && (
            <>
              <Grid item sm={12} md={4}>
                <div className="content-wrap full">
                  <div className="desc" style={{ color: 'grey' }}>
                    {type} Bank Account
                  </div>
                  <div className="mdDesc">{data?.BankAccountNo ?? '—'}</div>
                </div>
              </Grid>
              <Grid item sm={12} md={4}>
                <div className="content-wrap full">
                  <div className="desc" style={{ color: 'grey' }}>
                    {type} MSIC Code
                  </div>
                  <div className="mdDesc">{data?.MSICCode ?? '—'}</div>
                </div>
              </Grid>
            </>
          )}
        </>
      )}

      {type === 'Supplier' && (
        <>
          <Grid item sm={12} md={4}>
            <div className="content-wrap full">
              <div className="desc" style={{ color: 'grey' }}>
                Supplier Tourist Reg No
              </div>
              <div className="mdDesc">{data?.eInvType ?? '—'}</div>
            </div>
          </Grid>
          <Grid item sm={12} md={8}>
            <div className="content-wrap full">
              <div className="desc" style={{ color: 'grey' }}>
                Supplier Business Activity Description
              </div>
              <div className="mdDesc">
                {data?.BusinessActivityDescription ?? '—'}
              </div>
            </div>
          </Grid>
        </>
      )}

      <Grid item sm={12}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Address 1
          </div>
          <div className="mdDesc">{data?.Address1 ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Address 2
          </div>
          <div className="mdDesc">{data?.Address2 ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Address 3
          </div>
          <div className="mdDesc">{data?.Address3 ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            {type} Country
          </div>
          <div className="mdDesc">{data?.Country ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            {type} State
          </div>
          <div className="mdDesc">{data?.State ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            {type} State Code
          </div>
          <div className="mdDesc">{data?.StateCode ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            {type} City
          </div>
          <div className="mdDesc">{data?.City ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            {type} Postcode
          </div>
          <div className="mdDesc">{data?.Postcode ?? '—'}</div>
        </div>
      </Grid>
    </Grid>
  )
}
