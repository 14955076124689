import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'
import { Tune } from '@material-ui/icons'
import {
  useGetCompanyNameQuery,
  useGetLeaseContractLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { LeaseContractEntryList } from './LeaseContractListing/LeaseContractEntryList'

export const LeaseContractMain = (props: any) => {
  const { transactionType, transactionTypePrefix }: any = props
  let history = useHistory()
  const { CompanyID }: any = useParams()

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Doc Date',
      key: 'DocDate',
    },
  })

  /* -------------------------------------------- */
  /*                     ENUM                     */
  /* -------------------------------------------- */
  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
    { name: 'Terminated', ID: 'TERMINATED' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */
  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onCompleted: ({ getCompany }) => {
      if (getCompany?.length > 0)
        loadLeaseContract({
          variables: {
            statusArr: [listStatus],
            CompanyID: CompanyID,
            skip: 0,
            take: 30,
            searchValue:
              getSearch?.name === '' || !getSearch?.name
                ? undefined
                : getSearch?.name,
          },
        })
    },
  })

  const [
    loadLeaseContract,
    {
      loading: LeaseContractLoading,
      data: { getLeaseContract } = { getLeaseContract: [] },
    },
  ] = useGetLeaseContractLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getLeaseContract }) => {
      if (getLeaseContract?.length >= 30) {
        setOriginalListing([...filteredList, ...getLeaseContract])
      } else {
        setOriginalListing([...getLeaseContract])
      }
    },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  useEffect(() => {
    setOriginalListing([])
    loadLeaseContract({
      variables: {
        statusArr: [listStatus],
        CompanyID: CompanyID,
        skip: 0,
        take: 30,
        searchValue:
          getSearch?.name === '' || !getSearch?.name
            ? undefined
            : getSearch?.name,
      },
    })
  }, [listStatus])
  //to make delay for search function for the query to load
  const [timer, setTimer] = useState(null)

  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadLeaseContract({
          variables: {
            statusArr: [listStatus],
            CompanyID: CompanyID,
            skip: 0,
            take: 30,
            searchValue:
              getSearch?.name === '' || !getSearch?.name
                ? undefined
                : getSearch?.name,
          },
        })
      }, 1000)
    )
  }

  return (
    <>
      {/* {loading && <Loading />} */}
      {CompanyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/mfrs16/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }}
        smTitle={'MFRS 16'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'MFRS 16' },
          { name: 'Lease Contract', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, [''])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
            localStorage.removeItem('searchFilter')
            loadLeaseContract({
              variables: {
                statusArr: [listStatus],
                CompanyID: CompanyID,
                skip: 0,
                take: 30,
              },
            })
          }}
          option={{
            icon: <Tune />,
            onClick: openFilterDialog,
          }}
        />
        {filterDialog}

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      <LeaseContractEntryList
        loading={LeaseContractLoading}
        filteredList={filteredList}
        setOriginalListing={setOriginalListing}
        listStatus={listStatus}
        loadData={loadLeaseContract}
        CompanyID={CompanyID}
        getSearch={getSearch}
        transactionTypePrefix={transactionTypePrefix}
      />

      {/* {listStatus === 'SUBMIT' && !loading && (
        <APPostingList
          list={filteredList}
          filteredList={filteredList}
          listLoading={loading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={userList}
          handleFilterDate={handleFilterDate}
          approveRejectPermissionCheck={approveRejectPermissionCheck}
          setOriginalListing={setOriginalListing}
          apSubmenu={apSubmenu}
          loadData={loadData}
          CompanyID={CompanyID}
        />
      )} */}
    </>
  )
}
