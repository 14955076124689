import React, { lazy } from 'react'

const APApprovalPolicySubmenu = lazy(() =>
  import('./APApprovalPolicySubmenu').then(module => ({
    default: module.APApprovalPolicySubmenu,
  }))
)

const APAdvanceApprovalPolicyForm = lazy(() =>
  import('./ApprovalPolicy/APAdvanceApprovalPolicyForm').then(module => ({
    default: module.APAdvanceApprovalPolicyForm,
  }))
)

const APPaymentApprovalPolicyForm = lazy(() =>
  import('./ApprovalPolicy/APPaymentApprovalPolicyForm').then(module => ({
    default: module.APPaymentApprovalPolicyForm,
  }))
)

const accountPayableApprovalRoutes = [
  {
    props: {
      exact: true,
      path: '/account-payable/general-setting/approval-policy',
    },
    component: <APApprovalPolicySubmenu />,
  },
  {
    props: {
      exact: true,
      path: `/account-payable/general-setting/approval-policy/advance`,
    },
    component: <APAdvanceApprovalPolicyForm />,
  },
  {
    props: {
      exact: true,
      path: `/account-payable/general-setting/approval-policy/payment`,
    },
    component: <APPaymentApprovalPolicyForm />,
  },
]

export default accountPayableApprovalRoutes
