import React, { lazy } from 'react'

const DepreciationMethodListing = lazy(() =>
  import('./DepreciationMethodListing').then(module => ({
    default: module.DepreciationMethodListing,
  }))
)

const DepreciationMethodForm = lazy(() =>
  import('./DepreciationMethodForm').then(module => ({
    default: module.DepreciationMethodForm,
  }))
)

const DepreciationMethodDetail = lazy(() =>
  import('./DepreciationMethodDetail').then(module => ({
    default: module.DepreciationMethodDetail,
  }))
)
const depreciationMethodRoutes = [
  {
    props: {
      exact: true,
      path: '/fixed-assets/general-setting/depreciation-method',
    },
    component: <DepreciationMethodListing />,
  },
  {
    props: {
      exact: true,
      path: '/fixed-assets/general-setting/depreciation-method/add',
    },
    component: <DepreciationMethodForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/fixed-assets/general-setting/depreciation-method/:dmID/edit',
    },
    component: <DepreciationMethodForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/fixed-assets/general-setting/depreciation-method/:dmID/detail',
    },
    component: <DepreciationMethodDetail />,
  },
]

export default depreciationMethodRoutes
