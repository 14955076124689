import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { Grid, List, ListItem, ListItemText } from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Visibility'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import {
  useDocumentListingQuery,
  useGetJournalQuery,
  useGetRecurringJournalQuery,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const RecurringJournalJournalDetail = (props: any) => {
  const { CompanyID, RecurringJournalID, JournalID }: any = useParams()
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openWorkFlow, setWorkFlow] = useState(false)
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    loading: JournalLoading,
    error: JournalError,
    data: { getJournal } = { getJournal: [] },
  } = useGetJournalQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    variables: { JournalID: JournalID },
  })

  const {
    loading: RecurringJournalLoading,
    error: RecurringJournalError,
    data: { getRecurringJournal } = { getRecurringJournal: [] },
  } = useGetRecurringJournalQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    variables: { RecurringJournalID: RecurringJournalID },
  })

  const {
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      refID: JournalID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  const originalDate = getRecurringJournal?.[0]?.TransactionDate
  if (originalDate) {
    const date = new Date(originalDate)
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    if (date.getDate() === lastDayOfMonth.getDate()) {
      date.setMonth(date.getMonth() + 1)
      date.setDate(0)
    }
  }

  return (
    <>
      {JournalLoading && <Loading />}
      {RecurringJournalLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(
            `/general-ledger/${CompanyID}/recurring-journal/${RecurringJournalID}/journal-listing`
          )
        }
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Main Menu' },
          { name: 'Recurring Journal', current: true },
        ]}
      />

      <DynamicSubHeader
        title={
          <span style={{ fontWeight: 'bold' }}>
            {getJournal[0]?.JournalNo ?? getJournal[0]?.RefNo}{' '}
          </span>
        }
        rightText={
          <span className="c-orange">
            {formatDate(getJournal[0]?.JournalDate)}
          </span>
        }
        infoLine={
          <>
            <span
              className="xxTitle highlight-text flex-space"
              style={{ color: 'black' }}
            >
              {getJournal[0]?.Description}
            </span>
          </>
        }
        rightInfoLine={
          <span className="desc ">
            <span className="desc c-orange">
              {amtStr(
                getJournal[0]?.JournalItem?.filter(
                  item => item?.DocAmt > 0
                )?.reduce((x, y) => x + y?.DocAmt, 0)
              )}
            </span>
          </span>
        }
      />
      <ContentWrapper multiDynamicInfo float footer>
        <CardContents>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Journal Type
            </div>
            <div className="xsTitle">
              {' '}
              {getJournal?.[0]?.JournalType?.JournalType}{' '}
            </div>
          </div>

          <div className="content-wrap right">
            <span
              style={{ float: 'right' }}
              onClick={() => {
                history.push({
                  pathname: `/general-ledger/${CompanyID}/recurring-journal/${RecurringJournalID}/${JournalID}/preview`,
                  state: {
                    ...getRecurringJournal[0],
                    mode: 'detail',
                    JournalNo: getJournal[0]?.JournalNo,
                  },
                })
              }}
            >
              <PrintIcon
                style={{
                  fontSize: 'medium',
                  padding: '0px 10px 2px 0px',
                }}
              />
            </span>
          </div>

          <div className="content-wrap full ">
            <div className="desc " style={{ color: 'grey' }}>
              Description
            </div>
            <div className="xsTitle text-noflow">
              {' '}
              {getJournal?.[0]?.Description}{' '}
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Reference No.
            </div>
            <div className="xsTitle"> {getJournal?.[0]?.RefNo} </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Remark
            </div>
            <div className="xsTitle"> {getRecurringJournal?.[0]?.Remark} </div>{' '}
          </div>
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Journal Detail',
            },
          }}
        >
          <div className="rm-padding table-wrap">
            <List className="core-list content-wrap full " disablePadding>
              {getJournal?.[0]?.JournalItem?.map((el, index) => (
                <ListItem
                  className="table-listItem "
                  key={index}
                  divider
                  style={{ backgroundColor: 'white' }}
                >
                  <ListItemText
                    primary={
                      <Grid container justify="flex-start" spacing={1}>
                        <Grid
                          item
                          xs={6}
                          className="fw-medium desc "
                          style={{ overflow: 'visible' }}
                        >
                          {index + 1}.&nbsp;&nbsp;&nbsp;
                          {el.MasterCOA.Code} | {el.MasterCOA.Name}
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          className="fw-medium desc text-right text-overflow"
                          style={{
                            textAlign: 'end',
                            overflow: 'visible',
                          }}
                        >
                          {el.DocAmt < 0
                            ? `(${amtStr(el.DocAmt * -1)})`
                            : amtStr(el.DocAmt)}
                        </Grid>
                      </Grid>
                    }
                    secondary={
                      <Grid container justify="flex-start">
                        <Grid
                          item
                          xs={12}
                          className="fw-medium desc "
                          style={{ color: '#858585' }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span></span>
                          {el?.CostCentre?.Name}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className="fw-medium desc "
                          style={{ color: '#858585' }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {el?.Remark}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
          <div
            className="footer-label"
            style={{
              background: '#bfd3ff',
            }}
          ></div>
        </CardContents>
      </ContentWrapper>
      <ApprovalLogDialog
        data={getJournal[0]}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Journal'}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
