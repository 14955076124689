import React, { lazy } from 'react'
import { ROUAssetsLeaseLiabilityScheduleMain } from './ROUAssetsLiabilityScheduleMain'
import { ROUAssetsLeaseLiabilityScheduleDetail } from './ROUAssetsLeaseLiabilityScheduleDetail/ROUAssetsLeaseLiabilityScheduleDetail'

const rouAssetsLiabilityScheduleRoutes = [
  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/mfrs16/:CompanyID/rou-assets-lease-liability-schedule',
    },
    component: (
      <ROUAssetsLeaseLiabilityScheduleMain
        transactionType="ROU Assets & Lease Liability Schedule"
        transactionTypePrefix="rou-assets-lease-liability-schedule"
      />
    ),
  },
  /* -------------------------------------------- */
  /*                   DETAIL                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: `/mfrs16/:CompanyID/rou-assets-lease-liability-schedule/:LeaseContractID/detail`,
    },
    component: (
      <ROUAssetsLeaseLiabilityScheduleDetail
        transactionType="ROU Assets & Lease Liability Schedule"
        transactionTypePrefix="rou-assets-lease-liability-schedule"
      />
    ),
  },
]

export default rouAssetsLiabilityScheduleRoutes
