import theme from '@ifca-root/react-component/src/assets/theme'
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { DepreciationDetail } from './DepreciationDetail'
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter'
import { DepreciationGenerateSummary } from './DepreciationGenerateSummary'

export const DepreciationGenerateList = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  let dummyDataGenerate = [
    {
      ID: '1',
      Name: 'Computer Peripheral Inteli5',
      Date: '09/2020',
      AssetNo: 'AS-123232',
      DepreciationMethod: 'Unit',
      OriginalAmt: 3900,
      AccumulatedDepreciation: 3000,
      NetAmt: 3000,
      ResidualValue: null,
      LatestPendingInfo: '09/2020',
      GenerateDepreciation: [
        {
          GenerateID: '001',
          GenerateDate: '10/2020',
        },
        {
          GenrateID: '',
        },
      ],
    },
    {
      ID: '2',
      Name: 'Computer Peripheral',
      Date: '09/2020',
      AssetNo: 'AS-123232',
      DepreciationMethod: 'Unit',
      OriginalAmt: 3900,
      AccumulatedDepreciation: 3000,
      NetAmt: 3000,
      ResidualValue: null,
      LatestPendingInfo: '09/2020',
    },
    {
      ID: '3',
      Name: 'Machine A12342',
      Date: '08/2020',
      AssetNo: 'AS-123232',
      DepreciationMethod: 'Unit',
      OriginalAmt: 3900,
      AccumulatedDepreciation: 3000,
      NetAmt: 3000,
      ResidualValue: null,
      LatestPendingInfo: '09/2020',
    },
    {
      ID: '4',
      Name: 'Motor Kawasaki Model',
      Date: '08/2020',
      AssetNo: 'AS-123232',
      DepreciationMethod: 'Unit',
      OriginalAmt: 3900,
      AccumulatedDepreciation: 3000,
      NetAmt: 3000,
      ResidualValue: null,
      LatestPendingInfo: '09/2020',
    },
    {
      ID: '5',
      Name: 'Hilux 4x4 Mountain Sport',
      Date: '07/2020',
      AssetNo: 'AS-123232',
      DepreciationMethod: 'Unit',
      OriginalAmt: 3900,
      AccumulatedDepreciation: 3000,
      NetAmt: 3000,
      ResidualValue: null,
      LatestPendingInfo: '09/2020',
    },
    {
      ID: '6',
      Name: 'Monitor LG Version 3',
      Date: '07/2020',
      AssetNo: 'AS-123232',
      DepreciationMethod: 'Unit',
      OriginalAmt: 3900,
      AccumulatedDepreciation: 3000,
      NetAmt: 3000,
      ResidualValue: null,
      LatestPendingInfo: '09/2020',
    },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [depreciateIDs, setDepreciateIDs] = useState<Set<any>>(new Set([]))
  const [openExitConf, setOpenExitConf] = useState(null)
  const [selectedDepreciation, setSelectedDepreciation] = useState<any[]>([])
  const selectedDepreciate = Array.from(depreciateIDs)

  /* -------------------------------------------- */
  /*                   USEEFFECT                  */
  /* -------------------------------------------- */

  useEffect(() => {
    if (dummyDataGenerate?.length > 0) {
      setOriginalListing(dummyDataGenerate) // Set the original list with the dummy data
    }
  }, [])

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  const handleSelectAll = e => {
    let temp = depreciateIDs
    if (e.target.checked) {
      filteredList.map(v => {
        temp.add(v?.ID)
      })
    } else {
      temp.clear()
    }
    setDepreciateIDs(new Set(temp))
  }

  const handleCheckBoxChange = (e: any, depreciateID) => {
    let temp = depreciateIDs
    if (!temp.has(depreciateID)) temp.add(depreciateID)
    else temp.delete(depreciateID)

    setDepreciateIDs(new Set(temp))
  }

  let listDepreciateIDs: any = Array.from(depreciateIDs || [])

  /* -------------------------------------------- */
  /*                   EXIT CONF                  */
  /* -------------------------------------------- */

  const hasChanges = () => {
    if (depreciateIDs?.size === 0) return false
    else return true
  }

  // const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
  //   dateRange: {
  //     title: 'Doc Date',
  //     key: 'DocDate',
  //   },
  // })

  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        // loadJournalProcessing({
        //   variables: {
        //     CompanyID: CompanyID,
        //     StatusArr: [listStatus],
        //     take: 30,
        //     skip: 0,
        //     searchValue: change,
        //   },
        // })
      }, 1000)
    )
  }

  /* -------------------------------------------- */
  /*                    ONSUBMIT                  */
  /* -------------------------------------------- */

  const onSubmit = () => {
    let selectedDepreciate = Array.from(depreciateIDs)

    if (selectedDepreciate.length === 0) {
      alert('Please select at least one asset to generate depreciation.')
      return
    }

    const depreciationData = filteredList.filter(item =>
      depreciateIDs.has(item.ID)
    )

    localStorage.setItem(
      'selectedDepreciationGenerate',
      JSON.stringify(depreciationData)
    )

    setSelectedDepreciation(depreciationData)

    history.push(
      `/fixed-assets/${CompanyID}/depreciation/generate-depreciation/summary-depreciation`
    )

    // Reset selected IDs after submission
    setDepreciateIDs(new Set([]))
  }

  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true
            ? setOpenExitConf(true)
            : history.push(`/fixed-assets/${CompanyID}/depreciation`)
          localStorage.removeItem(`searchFilter`)
        }}
        smTitle="Fixed Assets"
        title={user?.companyName}
        routeSegments={[
          { name: 'Fixed Assets' },
          { name: 'Generate Depreciation', current: true },
        ]}
        rightRouteSegments={[{ name: 'New', current: true }]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            changeDelay(e.target.value)
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
          }}
          isDefaultValue={!!getSearch}
          // option={{
          //   icon: <Tune />,
          //   onClick: openFilterDialog,
          // }}
        />
      </div>

      <ContentWrapper
        footer
        overflow
        style={{ marginTop: isDesktop ? '70px' : '-7px' }}
      >
        <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
          <Checkbox
            onChange={handleSelectAll}
            color="primary"
            value={''}
            checked={depreciateIDs?.size === filteredList?.length}
            style={{ marginLeft: '-5px', marginTop: '-5px' }}
          />
          <span style={{ marginLeft: '9px' }}>Select All</span>
        </span>
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <>
              {advancedFilterList(filteredList)
                // ?.filter(handleFilterDate)
                .map((el, index) => {
                  return (
                    <CardExpansion
                      summary={
                        <ListItem key={index}>
                          <FormControl
                            component="fieldset"
                            style={{ width: '16px' }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={el?.ID}
                                  onChange={e => {
                                    handleCheckBoxChange(e, el?.ID)
                                  }}
                                  onClick={e => e.stopPropagation()}
                                  checked={depreciateIDs?.has(el?.ID)}
                                  color="primary"
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '-5px',
                                  }}
                                />
                              }
                              label=""
                              name="check"
                            />
                          </FormControl>

                          <ListItemText
                            style={{ marginLeft: '25px' }}
                            primary={
                              <>
                                <span
                                  className="desc"
                                  style={{
                                    whiteSpace: 'break-spaces',
                                    lineBreak: 'auto',
                                  }}
                                ></span>
                                <span className="xxTitle flex-space">
                                  {el?.Name}
                                </span>
                                <span
                                  className="xxTitle"
                                  style={{ color: 'orange' }}
                                >
                                  {el?.Date}
                                </span>
                              </>
                            }
                          />
                        </ListItem>
                      }
                    >
                      {
                        <>
                          <DepreciationDetail
                            CompanyID={CompanyID}
                            listEl={el}
                          />
                        </>
                      }
                    </CardExpansion>
                  )
                })}
            </>
          )}
        </List>
      </ContentWrapper>

      {/* <DepreciationGenerateSummary
        CompanyID={CompanyID}
        listEl={selectedDepreciation}
      /> */}

      <AccountFooter
        options={[
          {
            name: (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                Next
                {selectedDepreciate.length > 0 ? (
                  <span
                    style={{
                      marginLeft: '8px',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      color: '#000',
                      width: '24px',
                      height: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    {depreciateIDs.size}
                  </span>
                ) : null}
              </span>
            ),
            onClick: () => {
              onSubmit()
            },
            color: 'primary',
            props: {
              disabled: selectedDepreciate.length === 0,
            }, // Any additional props
          },
        ]}
      />
    </>
  )
}
