import React from 'react'
import { InvoiceStateForm } from './ARInvoiceForm/ARInvoiceStateForm'
import { PDFPreviewV2 } from '../Hooks/PDFPreviewV2'
import { ARMain } from '../ARMain'
import { ARDetail } from '../ARDetail/ARDetail'
import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import { AuditLogListing } from 'components/Listing/AuditLog'

export const arInvoiceRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/invoice/filter',
    },
    component: (
      <AdvancedFilter
        mode="invoice"
        app="account-receivable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },
  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/invoice',
    },
    component: (
      <ARMain
        arSubmenu="invoice"
        routeSegments="Invoice"
        accountType="account-receivable"
        docType="invoice"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/invoice/:DocumentID/detail`,
    },
    component: (
      <ARDetail
        arSubmenu="invoice"
        routeSegments="Invoice"
        accountType="account-receivable"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/invoice/:DocumentID/detail`,
    },
    component: (
      <ARDetail
        arSubmenu="invoice"
        routeSegments="Invoice"
        accountType="account-receivable"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/invoice/:InvoiceID/preview`,
    },
    component: (
      <PDFPreviewV2 accountType="account-receivable" docType="invoice" />
    ),
  },

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/invoice/:InvoiceID/preview`,
    },
    component: (
      <PDFPreviewV2 accountType="account-receivable" docType="invoice" />
    ),
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/invoice/add`,
    },
    component: (
      <InvoiceStateForm
        accountType="account-receivable"
        docType="invoice"
        routeSegments="Invoice"
        formMode="add"
        // detailMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/invoice/:InvoiceID/edit`,
    },
    component: <InvoiceStateForm formMode="edit" />,
  },

  /* -------------------------------------------- */
  /*                AUDIT LOG                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/invoice/:InvoiceID/detail/audit-log',
    },
    component: (
      <AuditLogListing
        module="Invoice"
        routeSegments="Invoices"
        smTitle="Account Receivable "
        docType="Invoice"
      />
    ),
  },
]

export default arInvoiceRoutes
