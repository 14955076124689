import BigNumber from 'bignumber.js'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  mode?: string
}

export const ARScheduledBillingDetailContent = (props: DetailProps) => {
  const { listEl, listStatus, userList } = props

  const taxAmt = new BigNumber(listEl?.TaxScheme?.LatestTax?.Rate)
    .dividedBy(100)
    .multipliedBy(listEl?.BillingAmt)
    .toNumber()

  return (
    <>
      <div className="content-wrap left ">
        <div className="desc " style={{ color: 'grey' }}>
          Debtor Name
        </div>
        <div className="mdDesc text-noflow">
          {listEl?.DebtorAccount?.DebtorName}
        </div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Billing Date
        </div>
        <div className="mdDesc"> {listEl?.BillingDate} </div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Billing Amount
        </div>
        <div className="mdDesc"> {listEl?.BillingAmt} </div>
      </div>

      <div className="content-wrap full ">
        <div className="desc " style={{ color: 'grey' }}>
          Sales Order
        </div>
        <div className="mdDesc text-noflow">{listEl?.SalesOrderName}</div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Tax Code
        </div>
        <div className="mdDesc"> {listEl?.TaxScheme?.Code} </div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Tax Rate
        </div>
        <div className="mdDesc">{`${listEl?.TaxScheme?.LatestTax?.Rate}`}</div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Tax Amount
        </div>
        <div className="mdDesc"> {amtStr(taxAmt)} </div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Cost Centre
        </div>
        <div className="mdDesc"> {listEl?.CostCentre?.Name} </div>
      </div>

      <div className="content-wrap full ">
        <div className="desc " style={{ color: 'grey' }}>
          Billing Description
        </div>
        <div className="mdDesc text-noflow">{listEl?.Description}</div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}
    </>
  )
}
