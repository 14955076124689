import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import pending from 'assets/einvoice/einvoice-pending.svg'
import rejected from 'assets/einvoice/einvoice-reject.svg'
import completed from 'assets/einvoice/einvoice-completed.svg'
import React, { useState } from 'react'
import { EInvoiceDialogStatus } from './EInvoiceDialog'

export const EInvoiceStatus = (props: any) => {
  const { isEinvoice, einvoiceStatus, data, isPost = false } = props
  const [openEinvStatusDialog, setOpenEinvStatusDialog] = useState(false)

  return (
    <>
      {((isEinvoice === true && isPost) ||
        (isEinvoice === true &&
          (einvoiceStatus === 'NEW' ||
            einvoiceStatus === 'RECORDED' ||
            einvoiceStatus === 'PENDING_SEND' ||
            einvoiceStatus === 'PENDING_VALIDATION'))) && (
        <>
          <span
            onClick={() =>
              isPost
                ? setOpenEinvStatusDialog(false)
                : setOpenEinvStatusDialog(true)
            }
            style={{ float: 'right' }}
          >
            <IconText
              labelStyle={{ marginRight: '10px' }}
              label={data?.SB?.DocNo}
              icon={
                <img
                  src={pending}
                  style={{
                    width: '20px',
                  }}
                />
              }
            ></IconText>
          </span>
        </>
      )}
      {isEinvoice === true &&
        (einvoiceStatus === 'CANCELLED_SEND' ||
          einvoiceStatus === 'FAILED_VALIDATION' ||
          einvoiceStatus === 'REJECTED' ||
          einvoiceStatus === 'FAILED_SEND' ||
          einvoiceStatus === 'CANCELLED_INVOICE') && (
          <span
            onClick={() => setOpenEinvStatusDialog(true)}
            style={{ float: 'right' }}
          >
            <IconText
              labelStyle={{ marginRight: '10px' }}
              label={data?.SB?.DocNo}
              icon={
                <img
                  src={rejected}
                  style={{
                    width: '20px',
                  }}
                />
              }
            />
          </span>
        )}
      {isEinvoice === true &&
        (einvoiceStatus === 'VALIDATED' ||
          einvoiceStatus === 'CONSOLIDATED' ||
          einvoiceStatus === 'REJECT_REQUEST') && (
          <span
            onClick={() => setOpenEinvStatusDialog(true)}
            style={{ float: 'right' }}
          >
            <IconText
              labelStyle={{ marginRight: '10px' }}
              label={data?.SB?.DocNo}
              icon={
                <img
                  src={completed}
                  style={{
                    width: '20px',
                  }}
                />
              }
            />
          </span>
        )}

      <EInvoiceDialogStatus
        openDialog={openEinvStatusDialog}
        setOpenDialog={setOpenEinvStatusDialog}
        data={data}
        status={data?.einvoice?.einvoice_status}
        date={data?.einvoice?.einvoice_date}
      />
    </>
  )
}
