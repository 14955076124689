import { ApprovalStatus } from 'generated/graphql'

export function APDocByPermission(data, refTable) {
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  let entityID
  switch (refTable) {
    case 'payment':
      entityID = 'PaymentID'
      break
    case 'advance':
      entityID = 'AdvanceID'
      break
    default:
      break
  }

  const removeDuplicates = data => {
    const uniqueData = []
    const uniqueIds = new Set()

    data.forEach(item => {
      if (!uniqueIds.has(item[entityID])) {
        uniqueData.push(item)
        uniqueIds.add(item[entityID])
      }
    })

    return uniqueData
  }

  let filteredDocs = []
  if (data?.length > 0) {
    // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Payment & Advance  - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
    if (refTable === 'payment' || refTable === 'advance') {
      if (!user?.superUser) {
        data.forEach(v => {
          if (v?.ApprovalWorkflow?.length > 1) {
            const steps = v?.ApprovalWorkflow[0]?.WorkflowStep
            const stepCount = steps.length

            // Allow document owner to see the document
            if (user?.ID === v?.submittedBy) filteredDocs.push(v)

            // Case when there is only 1 step in approval workflow
            if (stepCount === 1) {
              if (steps[0]?.ApproverRoleID === user?.roleDetail?.RoleID) {
                filteredDocs.push(v)
              }
            } else {
              // More than 1 step in approval workflow
              steps.forEach((step, index) => {
                if (step?.ApproverRoleID === user?.roleDetail?.RoleID) {
                  if (
                    step?.ApprovalStatus !== ApprovalStatus.Approved &&
                    (index === 0 ||
                      steps[index - 1]?.ApprovalStatus ===
                        ApprovalStatus.Approved)
                  ) {
                    filteredDocs.push(v)
                  }
                }
              })
            }
          } else if (v?.ApprovalWorkflow?.length === 1) {
            // Approval workflow with only 1 step
            v?.ApprovalWorkflow?.forEach(workflow => {
              const steps = workflow?.WorkflowStep
              const stepCount = steps.length

              if (user?.ID === v?.submittedBy) filteredDocs.push(v)

              if (stepCount === 1) {
                if (steps[0]?.ApproverRoleID === user?.roleDetail?.RoleID) {
                  filteredDocs.push(v)
                }
              } else {
                steps.forEach((step, index) => {
                  if (step?.ApproverRoleID === user?.roleDetail?.RoleID) {
                    if (
                      step?.ApprovalStatus !== ApprovalStatus.Approved &&
                      (index === 0 ||
                        steps[index - 1]?.ApprovalStatus ===
                          ApprovalStatus.Approved)
                    ) {
                      filteredDocs.push(v)
                    }
                  }
                })
              }
            })
          } else {
            filteredDocs = data
          }
        })
      } else {
        filteredDocs = data
      }
    }
  }

  return filteredDocs.length > 0 ? removeDuplicates(filteredDocs) : []
}
