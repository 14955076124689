import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  useGetAssignedCompanyQuery,
  useGetCompanyNameQuery,
  useGetCreditorAccountCompanyAssignmentNameLazyQuery,
} from 'generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

interface APCreditorControlDetailParamsProps {
  CompanyID: string
  DocStartDate: Date
  DocEndDate: Date
  TrxStartDate: Date
  TrxEndDate: Date
  CreditorAccountID: string
}

interface CompanyOption {
  Name: string
  CompanyID: string
}

export const APCreditorControlDetailForms = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('Company is required'),
  })
  const latestCompany = localStorage.getItem('latestCompany')
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  let user = JSON.parse(localStorage.getItem('loggedInUser'))

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<APCreditorControlDetailParamsProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  const [
    loadCreditorAccountCompanyAssignment,
    {
      loading: CreditorAccountLoading,
      data: { getCreditorAccountCompanyAssignment } = {
        getCreditorAccountCompanyAssignment: [],
      },
    },
  ] = useGetCreditorAccountCompanyAssignmentNameLazyQuery({
    fetchPolicy: 'network-only',
  })

  let getCreditorAccount = getCreditorAccountCompanyAssignment?.map(x => {
    return x?.CreditorAccount
  })

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    variables: { CompanyID },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadCreditorAccountCompanyAssignment({
          variables: {
            CompanyID: latestCompany,
          },
        })
      }
    },
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadCreditorAccountCompanyAssignment({
          variables: {
            CompanyID: latestCompany,
          },
        })
      }
    },
  })

  const [selectedCreditAccount, setSelectedCreditAccount]: any = useState(
    new Set()
  )

  const handleCheckboxCreditorAccountChange = (event: any) => {
    let creditorAccount = selectedCreditAccount

    if (event?.target?.checked) {
      creditorAccount.add(event?.target?.value)
    } else {
      creditorAccount.delete(event?.target?.value)
    }
    setSelectedCreditAccount(new Set(creditorAccount))
  }

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/digital-reports/account-payable/ap-creditor-control-detail/generated`,
      state: {
        CompanyID: data?.CompanyID,
        DocStartDate: new Date(data.DocStartDate),
        DocEndDate: new Date(data.DocEndDate),
        TrxStartDate: data.TrxStartDate ? new Date(data.TrxStartDate) : null,
        TrxEndDate: data.TrxEndDate ? new Date(data.TrxEndDate) : null,
        CreditorAccountID: selectedCreditAccount
          ? Array.from(selectedCreditAccount)
          : undefined,
      },
    })
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {CompanyAssignedLoading && <Loading />}
      {CreditorAccountLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/digital-reports/account-payable`)}
        smTitle={'Accounts Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Creditor Control Detail', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          {!CompanyAssignedLoading && !CompanyLoading && (
            <Controller
              render={({ value, onChange }) => {
                const options: CompanyOption[] =
                  !!user?.superUser === false
                    ? getAssignedCompanyByUser
                    : getCompany
                const defVal = getCompany?.filter(
                  x => x?.CompanyID === latestCompany
                )[0]
                return (
                  <Autocomplete
                    options={options || []}
                    getOptionLabel={(option: CompanyOption) => option.Name}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('CompanyID', newValue?.CompanyID)

                      loadCreditorAccountCompanyAssignment({
                        variables: {
                          CompanyID: newValue?.CompanyID,
                        },
                      })
                    }}
                    defaultValue={defVal}
                    renderOption={(props, option) => {
                      return <span>{props?.Name}</span>
                    }}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CompanyID?.message}
                            error={errors?.CompanyID ? true : false}
                            label="Company"
                            style={{ width: '100%' }}
                            margin="normal"
                            required
                            defaultValue={defVal}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Company"
              name="CompanyID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CompanyID?.message}
              error={errors?.CompanyID ? true : false}
              required
              defaultValue={latestCompany}
            />
          )}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocStartDate"
              label="Document Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('DocStartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.DocStartDate ?? new Date()}
              helperText={errors?.DocStartDate?.message}
              error={errors?.DocStartDate ? true : false}
              showTodayButton
              fullWidth
              className="left"
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocEndDate"
              label="Document End Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('DocEndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.DocEndDate ?? new Date()}
              helperText={errors?.DocEndDate?.message}
              error={errors?.DocEndDate ? true : false}
              showTodayButton
              fullWidth
              className="right"
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxStartDate"
              label="Transaction Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('TrxStartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.TrxStartDate ?? null}
              helperText={errors?.TrxStartDate?.message}
              error={errors?.TrxStartDate ? true : false}
              showTodayButton
              fullWidth
              className="left"
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxEndDate"
              label="Transaction End Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('TrxEndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.TrxEndDate ?? null}
              helperText={errors?.TrxEndDate?.message}
              error={errors?.TrxEndDate ? true : false}
              showTodayButton
              fullWidth
              className="right"
            />
          </MuiPickersUtilsProvider>

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getCreditorAccount || []}
                  getOptionLabel={option => option?.CompanyName}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.CreditorAccountID)
                    })

                    setSelectedCreditAccount(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.CreditorAccountID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxCreditorAccountChange(event)
                        }}
                      />
                      {option?.CompanyName}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Creditor Account"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="CreditorAccountID"
            label="Creditor Account"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}
