import React, { lazy } from 'react'
import { APDetail } from '../APDetail/APDetail'
import { APMain } from '../APMain'
import { AuditLogListing } from 'components/Listing/AuditLog'
import { InboundTab } from './APInboundTab'

export const inboundInvoiceRoutes = [
  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/inbound-einvoice',
    },
    component: <InboundTab />,
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/invoice/:DocumentID/detail`,
    },
    component: (
      <APDetail
        apSubmenu="invoice"
        routeSegments="Invoice"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  /* -------------------------------------------- */
  /*                AUDIT LOG                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/invoice/:InvoiceID/detail/audit-log',
    },
    component: (
      <AuditLogListing
        module="ledger"
        routeSegments="Invoice"
        smTitle="Account Payable"
        docType="invoice"
      />
    ),
  },
]

export default inboundInvoiceRoutes
