import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Checkbox,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import { ARItemDetail } from 'containers/ARAPModule/Components/Detail/ARItemDetail'
import { dueDateChecker } from 'containers/ARAPModule/Helper/DateCalculation'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  DocDateValidationAfter,
  DocDateValidationBefore,
} from 'containers/CashBookModule/DocDateValidation'
import {
  AcctPermission,
  ApprovalStatus,
  DocumentListingDocument,
  GetArDebitNoteDocument,
  GetArDebitNotebyStatusDocument,
  RecordStatus,
  useCreateArDebitNoteMutation,
  useDocumentListingLazyQuery,
  useGetBillItemListingLazyQuery,
  useGetClassificationCodeQuery,
  useGetCompanyNameQuery,
  useGetDebtorAccountQuery,
  useGetDocNumTitleQuery,
  useGetDocumentDateValidationQuery,
  useGetEInvoiceCompanyStartDateLazyQuery,
  useGetMasterCoaQuery,
  useGetTaxEffectiveDateQuery,
  useGetTaxSchemeQuery,
  useGetValidatedDocRefLazyQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateArDebitNoteMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDashDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import { ARDebitNoteItemDialog } from './ARDebitNoteItemDialog'
interface ARDebitNoteProps {
  DocDate: string
  TrxDate: string
  DebtorAccountID: string
  Description: string
  Remark: string
  DebitNoteID: string
  DocAmt: number
  RefNo: string
  is_einvoice: boolean
  DocumentReference: string
}

interface FavDebitNoteProps {
  Name: string
}

export const DebitNoteStateForm = (props: any) => {
  const { CompanyID, DebitNoteID }: any = useParams()
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any
  const [taxEffectiveList, setTaxEffectiveList] = useState<any>()
  const [debitNoteItemData, setDebitNoteItemData] = useState([])
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(SnackBarContext)
  const [onSub, setSub] = useState(false)
  // const [due, setDue] = useState(new Date())
  const [initDocs, setInitDocs] = useState([])
  const [openExitConf, setOpenExitConf] = useState(null)
  const [openFavoriteDialog, setOpenFavoriteDialog] = useState(false)
  const [openFavoriteDeleteDialog, setOpenFavoriteDeleteDialog] = useState(
    false
  )
  const [audioInput, setAudioInput] = useState(editData?.Remark ?? '')
  const [text, setText] = useState(editData?.Remark ?? '')
  const [singleRemark, setSingleRemark] = useState(null)
  const [voiceRemark, setVoiceRemark] = useState('')
  const [itemVoiceRemark, setItemVoiceRemark] = useState('')
  const [record, setRecord] = useState(false)
  const [itemRecord, setItemRecord] = useState(false)
  const [openItemDialog, setOpenItemDialog] = useState<boolean>(false)
  const [edit, setEdit] = useState(false)
  const [selectedBillItem, setSelectedBillItem] = useState(null)

  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [isSubmit, setIsSubmit] = useState(false)
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  // Adaptive Disabling for InvoiceID Doc Ref
  const [notDSDocs, setNotDSDocs] = useState(new Set())
  const [dsDocs, setDSDocs] = useState(new Set())
  const [toDisable, setToDisable] = useState(new Set())

  const [enableEInvoice, setEnableEInvoice] = useState(false)
  const { formMode } = props

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()

  const [directSend, setDirectSend] = useState(
    mode === 'edit' ? editData?.einvoice_directsend : false
  )
  const [selectedDocs, setSelectedDocs] = useState<Set<any>>(new Set([]))
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const DebitNoteFormSchema = yup.object().shape({
    DebtorAccountID: CommonYupValidation.requireField(
      'Debtor Name is required'
    ),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    DocDate: yup.string().required('Doc Date is Required'),
    TrxDate: yup.string().required('Transaction Date is Required'),
    RefNo: yup.string().required('Reference No is Required'),
  })

  const FavDebitNoteFormSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
  })

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
    clearErrors,
    formState: { isSubmitted },
  } = useForm<ARDebitNoteProps>({
    mode: 'onSubmit',
    resolver: yupResolver(DebitNoteFormSchema),
  })

  const handleCheckBoxChange = (event: any) => {
    let temp = selectedDocs || new Set()
    if (event?.target?.checked) {
      temp.add(event?.target?.value)
      setSelectedDocs(new Set(temp))
    } else {
      temp.delete(event?.target?.value)
      setSelectedDocs(new Set(temp))
    }
  }

  /* -------------------------------------------- */
  /*                    QUERY                     */
  /* -------------------------------------------- */
  const {
    loading: CompanyLoading,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onCompleted: ({ getCompany }) => {
      if (getCompany[0]?.eInvIntegrated!!) {
        const startDate = new Date(getCompany[0]?.eInvStartDate)
        const endDate = new Date(getCompany[0]?.eInvEndDate)
        const today = new Date()

        if (today >= startDate && today <= endDate) {
          setEnableEInvoice(true)
          if (!!watch('DebtorAccountID') && !!CompanyID) {
            fetchDocRef({
              variables: {
                CompanyID: CompanyID,
                DebtorAccountID: watch('DebtorAccountID'),
              },
            })
          }
        }
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: masterCOALoading,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: TaxLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: DocDateValidationLoading,
    data: { getDocumentDateValidation } = { getDocumentDateValidation: [] },
  } = useGetDocumentDateValidationQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: docNumHeaderLoading,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, RefTable: 'AR_DebitNote' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: DebtorAccountLoading,
    data: { getDebtorAccount } = { getDebtorAccount: [] },
  } = useGetDebtorAccountQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [term, setTerm] = useState(mode === 'edit' ? editData?.CreditTerm : 0)

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    fetchBillItem,
    { loading: BillItemLoading, data: { getBillItem } = { getBillItem: [] } },
  ] = useGetBillItemListingLazyQuery({
    fetchPolicy: 'network-only',
    variables: { orderByAsc: 'Name', RecordStatus: RecordStatus.Active },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: TaxEffectiveDateLoading,
    data: { getTaxEffectiveDate } = { getTaxEffectiveDate: [] },
  } = useGetTaxEffectiveDateQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (getTaxEffectiveDate?.length > 0) {
        let temp = getTaxEffectiveDate?.filter(
          tax =>
            Number(new Date(tax?.Date)) <
              DocDateValidationAfter(
                getDocumentDateValidation?.map(x => x?.MonthsAfter)
              ) &&
            Number(new Date(tax?.Date)) >
              DocDateValidationBefore(
                getDocumentDateValidation?.map(x => x?.MonthsBefore)
              )
        )
        setTaxEffectiveList(temp)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  // Get e-Invoice Status
  const [
    fetchEInvoiceCompanyStartDate,
    { loading: getEInvoiceCompanyStartDateLoading },
  ] = useGetEInvoiceCompanyStartDateLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(`${errorMessage}`)
      setValue('is_einvoice', false)
    },
    onCompleted(data) {
      setValue('is_einvoice', data?.getEInvoiceCompanyStartDate)
    },
  })

  const {
    loading: classificationCodeLoading,
    data: { getClassificationCode } = { getClassificationCode: [] },
  } = useGetClassificationCodeQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    fetchDocRef,
    {
      loading: ValidatedDocRefLoading,
      data: { getValidatedDocRef } = { getValidatedDocRef: [] },
    },
  ] = useGetValidatedDocRefLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getValidatedDocRef }) => {
      let notDirectSendSet = new Set()
      let directSendSet = new Set()
      setNotDSDocs(new Set())
      setDSDocs(new Set())
      for (let i = 0; i < getValidatedDocRef?.length; i++) {
        if (!getValidatedDocRef[i]?.DirectSend) {
          notDirectSendSet.add(getValidatedDocRef[i])
        } else {
          directSendSet.add(getValidatedDocRef[i])
        }
      }

      if (notDirectSendSet.size > 0) {
        setNotDSDocs(new Set(notDirectSendSet))
      }

      if (directSendSet.size > 0) {
        setDSDocs(new Set(directSendSet))
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const trxDateTimestamp = new Date(watch('TrxDate'))
  const yearTrxDate = trxDateTimestamp.getFullYear()
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`

  const openPeriod1 =
    transferDate <= latestOpenPeriodCheckingDate?.StartDate ||
    transferDate >= latestOpenPeriodCheckingDate?.EndDate

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? true : false
  const checkingYearClose2 = openPeriod2 ? false : true

  /* -------------------------------------------- */
  /*                   USEEFECT                   */
  /* -------------------------------------------- */
  useEffect(() => {
    if (formMode === 'edit' && editData) {
      const DebitNoteItems = editData?.ARDebitNoteItem?.map((el, index) => {
        return {
          DebitNoteItemID: el?.DebitNoteItemID,
          BillItemName: el?.BillItem?.Name,
          BillItemID: el?.BillItemID,
          CostCentreName: el?.CostCentre?.Code,
          CostCentreID: el?.CostCentreID,
          Amount: el?.Amount,
          TaxSchemeID: el?.TaxScheme?.TaxSchemeID,
          TaxCode: el?.TaxScheme?.Code,
          TaxAmt: el?.TaxAmt,
          TaxRate: el?.TaxRate,
          Remark: el?.Remark,
          DocAmt: el?.DocAmt,
          einvoice_classification_code_id: el?.einvoice_classification_code_id,
        }
      })
      debitNoteItemData.push(...DebitNoteItems)

      if (!!editData?.is_einvoice) {
        fetchDocRef({
          variables: {
            CompanyID: CompanyID,
            DebtorAccountID: editData?.DebtorAccountID,
          },
        })
      }

      if (editData?.DNDocRef.length > 0) {
        const selected = new Set()
        editData?.DNDocRef?.map(x => {
          selected.add(x?.DocRefID)
        })
        setSelectedDocs(new Set(selected))
      }

      if (editData?.Attachment) setInitDocs(editData?.Attachment)
      if (DebitNoteID && editData?.Remark) setValue('Remark', editData?.Remark)
    }
  }, [formMode, editData])

  useEffect(() => {
    if (!documentLoading && mode !== 'add') {
      fetchDocuments({ variables: { refID: editData?.DebitNoteID } })
    }

    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [mode, editData?.DebitNoteID, editData])

  /* -------------------------------------------- */
  /*                  FUNCTION                    */
  /* -------------------------------------------- */
  let statusInput = formMode => {
    let temp
    switch (formMode) {
      case 'submit':
        temp = ApprovalStatus.Submit
        break
      case 'draft':
        temp = ApprovalStatus.Active
        break
      case 'approve':
        temp = ApprovalStatus.Approved
        break
      case 'reject':
        temp = ApprovalStatus.Rejected
        break
    }
    return temp
  }

  let totalAmount = debitNoteItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.DocAmt),
    0
  )

  let totalTaxAmt = debitNoteItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.TaxAmt),
    0
  )

  let amtBeforeTax = totalAmount - totalTaxAmt

  let calcTotal = items => {
    return items?.reduce(
      (total, currentValue) => (total = total + currentValue.DocAmt),
      0
    )
  }

  const updateNewDebitNoteItem = (newDate, TEList) => {
    const arr = []
    debitNoteItemData?.map(x => {
      let NewTax =
        getTaxEffectiveDate?.filter(
          j =>
            j?.TaxSchemeID === x?.TaxSchemeID &&
            new Date(j?.Date) <= new Date(newDate)
        )?.length > 0
          ? getTaxEffectiveDate
              ?.filter(
                j =>
                  j?.TaxSchemeID === x?.TaxSchemeID &&
                  new Date(j?.Date) <= new Date(newDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })
          : null
      if (NewTax !== null) {
        arr.push({
          DebitNoteItemID: x?.DebitNoteItemID,
          BillItemID: x?.BillItemID,
          RevenueCode: x?.RevenueCode,
          RevenueName: x?.RevenueName,
          Amount: x?.Amount,
          TaxSchemeID: x?.BillItem?.TaxSchemeID,
          TaxCode: x?.TaxCode,
          TaxRate: NewTax?.Rate,
          TaxAmt: x?.Amount * (NewTax?.Rate / 100),
          DocAmt: x?.Amount + x?.Amount * (NewTax?.Rate / 100),
          CostCentreID: x?.CostCentreID,
          CostCentreCode: x?.CostCentreCode,
          Remark: x?.Remark,
        })
      }
    })
  }

  const taxEffectiveDate: any =
    !!watch('DocDate') && watch('DocDate').toString() !== 'Invalid Date'
      ? new Date(new Date(watch('DocDate')).getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      : null

  /* -------------------------------------------- */
  /*         CREATE & UPDATE MUTATION             */
  /* -------------------------------------------- */
  const [
    createARDebitNote,
    { loading: createARDebitNoteLoading, called: createARDebitNoteCalled },
  ] = useCreateArDebitNoteMutation({
    onCompleted: ({ createARDebitNote }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-receivable/${CompanyID}/debit-note/${createARDebitNote?.DebitNoteID}/preview`,
          })
        } else {
          history.push({
            pathname: `/account-receivable/${CompanyID}/debit-note`,
          })
        }
      }, 500)
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    updateARDebitNote,
    { loading: updateARDebitNoteLoading, called: updateARDebitNoteCalled },
  ] = useUpdateArDebitNoteMutation({
    onCompleted: ({ updateARDebitNote }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-receivable/${CompanyID}/debit-note/${DebitNoteID}/preview`,
          })
        } else {
          history.push({
            pathname: `/account-receivable/${CompanyID}/debit-note`,
          })
        }
      }, 500)
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  /* -------------------------------------------- */
  /*                  ATTACHMENT                  */
  /* -------------------------------------------- */

  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    fetchDocuments,
    { loading: documentLoading, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  /* -------------------------------------------- */
  /*                   DELETE                     */
  /* -------------------------------------------- */

  const onDeleteDebitNoteItem = index => {
    debitNoteItemData.splice(index, 1)
  }

  /* -------------------------------------------- */
  /*                  ONSUBMIT                    */
  /* -------------------------------------------- */

  const onSubmit = (data, status) => {
    if (onSub === false) {
      setSub(true)

      if (formMode === 'add') {
        if (
          getDocumentNumberHeader?.filter(x => x?.RefTable === 'AR_DebitNote')
            ?.length === 0
        ) {
          setOpenSnackBar(true)
          setSnackBarMsg(SystemMsgs.errorNumberingStructure())
        } else {
          //add the data with GraphQL mutation
          createARDebitNote({
            variables: {
              input: {
                DebitNoteID: DebitNoteID,
                CompanyID: CompanyID,
                DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
                TransactionDate: formatDashDate(
                  new Date(data?.TrxDate)?.toISOString()
                ),
                DueDate: new Date(
                  dueDateChecker(data?.DocDate, term)
                ).toISOString(),
                RefNo: data?.RefNo,
                Remark: data?.Remark,
                ApprovalStatus: statusInput(status),
                Attachment: files,
                DebtorAccountID: data?.DebtorAccountID,
                Description: data?.Description,
                CreditTerm: term,
                DocAmt: totalAmount ?? 0,
                DocAmtBeforeTax: amtBeforeTax ?? 0,
                is_einvoice: !!enableEInvoice ? watch('is_einvoice') : false,
                einvoice_status: !!watch('is_einvoice')
                  ? 'NEW'
                  : 'NOT_EINVOICE',
                einvoice_directsend: directSend,
              },
              itemInput: debitNoteItemData?.map(x => {
                return {
                  BillItemID: x?.BillItemID,
                  Amount: parseFloat(x?.Amount),
                  TaxSchemeID: x?.TaxSchemeID,
                  TaxRate: parseFloat(x?.TaxRate),
                  TaxAmt: parseFloat(x?.TaxAmt),
                  DocAmt: parseFloat(x?.DocAmt),
                  CostCentreID: x?.CostCentreID,
                  Remark: x?.Remark,
                  Sequence: x?.Sequence,
                  einvoice_classification_code_id:
                    x?.einvoice_classification_code_id ?? null,
                }
              }),
              docRefInput: !!watch('is_einvoice')
                ? Array.from(selectedDocs).map(doc => ({
                    DocRefID: doc,
                  }))
                : [],
            },
            refetchQueries: [
              {
                query: GetArDebitNoteDocument,
                variables: { CompanyID, StatusArr: ['ACTIVE'] },
              },
              {
                query: DocumentListingDocument,
                variables: {
                  refTable: 'S_Attachment',
                  refID: editData?.DebitNoteID,
                },
              },
            ],
          })
        }
      } else if (formMode !== 'add') {
        // update the data
        updateARDebitNote({
          variables: {
            DebitNoteID,
            input: {
              DebitNoteID,
              CompanyID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              RefNo: data?.RefNo,
              Remark: data?.Remark,
              ApprovalStatus: statusInput(status),
              Attachment: files,
              DebtorAccountID: data?.DebtorAccountID,
              DocAmt: totalAmount ?? 0,
              Description: data?.Description,
              CreditTerm: term,
              DueDate: new Date(
                dueDateChecker(data?.DocDate, term)
              ).toISOString(),
              DocAmtBeforeTax: amtBeforeTax ?? 0,
              is_einvoice: !!enableEInvoice ? watch('is_einvoice') : false,
              einvoice_status: !!watch('is_einvoice') ? 'NEW' : 'NOT_EINVOICE',
              einvoice_directsend: directSend,
            },
            itemInput: debitNoteItemData?.map(x => {
              return {
                BillItemID: x?.BillItemID,
                Amount: parseFloat(x?.Amount),
                TaxSchemeID: x?.TaxSchemeID,
                TaxRate: parseFloat(x?.TaxRate),
                TaxAmt: parseFloat(x?.TaxAmt),
                DocAmt: parseFloat(x?.DocAmt),
                CostCentreID: x?.CostCentreID,
                Remark: x?.Remark,
                Sequence: x?.Sequence,
                einvoice_classification_code_id:
                  x?.einvoice_classification_code_id ?? null,
              }
            }),
            docRefInput: !!watch('is_einvoice')
              ? Array.from(selectedDocs).map(doc => ({
                  DocRefID: doc,
                }))
              : [],
          },
          refetchQueries: [
            {
              query: GetArDebitNotebyStatusDocument,
              variables: { CompanyID, StatusArr: ['ACTIVE'] },
            },
            {
              query: DocumentListingDocument,
              variables: {
                refTable: 'S_Attachment',
                refID: editData?.DebitNoteID,
              },
            },
          ],
        })
      }
    }
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      itemArr: debitNoteItemData,
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
      itemSuffixID: 'DebitNoteItemID',
      itemTableName: 'ARDebitNoteItem',
      formMode: formMode,
    })

  const { handlePermDisabled } = usePermissionChecker()

  const createUpdateLoading = editData
    ? updateARDebitNoteLoading
    : createARDebitNoteLoading
  const createUpdateCalled = editData
    ? updateARDebitNoteCalled
    : createARDebitNoteCalled

  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: { type: 'submit' },
    disabled:
      checkingYearClose1 || checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableDebitNotesDraft,
          }),
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateLoading && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: { type: 'submit' },
    disabled:
      !!errors?.DocDate ||
      !!errors?.TrxDate ||
      !!errors?.RefNo ||
      !!errors?.DebtorAccountID ||
      !!errors?.Description ||
      checkingYearClose1 ||
      checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableDebitNotesUpdate,
          }),
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
      setIsSubmit(true)
    },
    color: 'primary',
    props: { type: 'submit' },
    disabled:
      calcTotal(debitNoteItemData) === 0 ||
      calcTotal(debitNoteItemData) === undefined ||
      checkingYearClose1 ||
      checkingYearClose2
        ? true
        : false,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  return (
    <>
      {TaxLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {documentLoading && <Loading />}
      {BillItemLoading && <Loading />}
      {masterCOALoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {ValidatedDocRefLoading && <Loading />}
      {TaxEffectiveDateLoading && <Loading />}
      {DocDateValidationLoading && <Loading />}
      {classificationCodeLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}
      {getEInvoiceCompanyStartDateLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            history.push({
              pathname: `/account-receivable/${CompanyID}/debit-note`,
            })
          }
        }}
        smTitle={'Accounts Receivable'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Accounts Receivable Main Menu' },
          { name: 'Accounts Receivable Submenu' },
          { name: 'Debit Note' },
          { name: 'Debit Note', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : formMode === 'edit'
                ? 'Edit'
                : 'Draft',
            current: true,
          },
        ]}
      />

      <ContentWrapper footer float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocDate"
              label="Document Date"
              control={control}
              onChange={(date: Date | null) => {}}
              onAccept={(date: Date | null) => {
                updateNewDebitNoteItem(date, taxEffectiveList)
                if (
                  date < new Date(curCompany[0]?.eInvStartDate) ||
                  date > new Date(curCompany[0]?.eInvEndDate)
                ) {
                  setEnableEInvoice(false)
                  setValue('is_einvoice', false)
                } else {
                  setEnableEInvoice(true)
                  setValue('is_einvoice', true)
                }
              }}
              format="dd/MM/yyyy"
              value={watch(formMode === 'add' ? new Date() : editData?.DocDate)}
              margin="dense"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData ? editData?.DocDate : new Date()}
              showTodayButton
              className="left"
              disabled={formMode === 'approve-reject'}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose1
                  ? 'Financial Period already closed'
                  : errors?.DocDate?.message
              }
              error={errors?.DocDate || checkingYearClose1 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxDate"
              required
              label="Transaction Date"
              control={control}
              format="dd/MM/yyyy"
              margin="dense"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose2
                  ? 'Financial Period already closed'
                  : errors?.TrxDate?.message
              }
              error={errors?.TrxDate || checkingYearClose2 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            defaultValue={editData?.RefNo}
            disabled={mode == 'approve-reject'}
          />

          {!DebtorAccountLoading && (
            <>
              <Controller
                render={({ value, onChange }) => {
                  const defVal = getDebtorAccount?.filter(
                    x => x?.DebtorAccountID === editData?.DebtorAccountID
                  )[0]
                  return (
                    <Autocomplete
                      options={
                        getDebtorAccount?.sort((a, b) => {
                          return a?.DebtorName?.localeCompare(b?.DebtorName)
                        }) || []
                      }
                      getOptionLabel={option => `${option?.DebtorName}`}
                      fullWidth
                      onChange={(value, newValue: any) => {
                        setValue('DebtorAccountID', newValue?.DebtorAccountID)
                        setTerm(newValue?.CreditTerm)
                        newValue?.einvoice_send_individually === true
                          ? setDirectSend(true)
                          : setDirectSend(false)
                        fetchDocRef({
                          variables: {
                            CompanyID,
                            DebtorAccountID: watch('DebtorAccountID'),
                          },
                        })
                      }}
                      renderOption={(props, option) => {
                        return <span>{props?.DebtorName}</span>
                      }}
                      defaultValue={defVal}
                      disabled={mode === 'approve-reject'}
                      renderInput={(params: any) => {
                        return (
                          <div>
                            <TextField
                              {...params}
                              helperText={errors?.DebtorAccountID?.message}
                              error={errors?.DebtorAccountID ? true : false}
                              label="Debtor Name"
                              style={{ width: '100%' }}
                              margin="dense"
                              required
                            />
                          </div>
                        )
                      }}
                    />
                  )
                }}
                label="Debtor Name"
                name="DebtorAccountID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="dense"
                ref={register}
                helperText={errors?.DebtorAccountID?.message}
                error={errors?.DebtorAccountID ? true : false}
                defaultValue={editData?.DebtorAccountID}
                required
                disabled={mode === 'approve-reject'}
              />
            </>
          )}

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            ref={register}
            defaultValue={editData ? editData?.Description : ''}
            disabled={mode === 'approve-reject'}
          />

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />

          {/* e-Invoice Implementation */}
          {!!enableEInvoice && (
            <>
              <Grid container style={{ height: '50px' }}>
                <>
                  <Grid item xs={4} style={{ alignContent: 'center' }}>
                    <span> e-Invoice</span>
                  </Grid>

                  <Grid item xs={2} style={{ alignContent: 'center' }}>
                    <Switch
                      name="is_einvoice"
                      color="primary"
                      inputRef={register()}
                      defaultChecked={
                        formMode === 'edit'
                          ? editData?.is_einvoice
                          : enableEInvoice
                      }
                      onChange={event => {
                        if (event.target.checked === true) {
                          fetchEInvoiceCompanyStartDate({
                            variables: { CompanyID: CompanyID },
                          })
                          fetchDocRef({
                            variables: {
                              CompanyID: CompanyID,
                              DebtorAccountID: watch('DebtorAccountID'),
                            },
                          })
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} />
                </>
              </Grid>

              {!ValidatedDocRefLoading && !!watch('is_einvoice') && (
              <>
                <Grid item xs={12}>
                  <Controller
                    render={({ value, onChange }) => {
                      return (
                        <Autocomplete
                          multiple
                          disableCloseOnSelect
                          options={
                            getValidatedDocRef?.sort((a, b) => {
                              return a?.DocNo?.localeCompare(b?.DocNo)
                            }) || []
                          }
                          autoHighlight={false}
                          getOptionLabel={option => `${option?.DocNo}`}
                          fullWidth
                          onChange={(event, value, reason) => {
                            const selected = new Set()
                            let toFilter = new Set()
                            if (value[0]?.DirectSend === true) {
                              toFilter = notDSDocs
                              setDirectSend(true)
                            } else if (value[0]?.DirectSend === false) {
                              toFilter = dsDocs
                              setDirectSend(false)
                            }
                            setToDisable(new Set(toFilter))
                            for (let index = 0; index < value.length; index++) {
                              !toFilter.has(value[index]) &&
                                selected.add(value[index]?.DocRefID)
                            }
                            setSelectedDocs(new Set(selected))

                            selectedDocs.clear()
                            if (reason === 'remove-option') {
                              for (let index = 0; index < value.length; index++) {
                                selectedDocs.add(value[index]?.DocRefID)
                              }
                            } else if (reason === 'clear') {
                              setSelectedDocs(new Set())
                            }
                          }}
                          value={getValidatedDocRef?.filter(f =>
                            Array.from(selectedDocs).includes(f?.DocRefID)
                          )}
                          defaultValue={editData?.CNDocRef}
                          renderOption={option => (
                            <Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                value={option?.DocRefID}
                                style={{ marginRight: 8 }}
                                checked={selectedDocs.has(option?.DocRefID)}
                                color="primary"
                                onChange={event => {
                                  let toFilter = new Set()
                                  if (option?.DirectSend === true) {
                                    toFilter = notDSDocs
                                  } else if (option?.DirectSend === false) {
                                    toFilter = dsDocs
                                  }
                                  setToDisable(new Set(toFilter))
                                  toFilter.has(option) &&
                                    handleCheckBoxChange(event)
                                }}
                                disabled={
                                  selectedDocs?.size > 0 &&
                                  !!toDisable.has(option)
                                }
                              />
                              <ListItemText
                                primary={
                                  <Grid container>
                                    <Grid
                                      item
                                      component="span"
                                      className="xsTitle "
                                    >
                                      {option?.DocNo}
                                    </Grid>
                                  </Grid>
                                }
                              />
                            </Fragment>
                          )}
                          renderInput={(params: any) => {
                            return (
                              <div>
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  style={{ width: '100%' }}
                                  label="Doc Ref"
                                  margin="normal"
                                  onKeyDown={(event: any) => {
                                    if (event.key === 'Backspace') {
                                      event.stopPropagation()
                                      setSelectedDocs(new Set())
                                    }
                                  }}
                                />
                              </div>
                            )
                          }}
                        />
                      )
                    }}
                    select
                    fullWidth
                    ref={register}
                    margin="normal"
                    label="Doc Ref"
                    name="DocumentReference"
                    multiline={true}
                    control={control}
                    autoComplete="off"
                  />
                </Grid>
              </>
            )}
            </>
          )}

          <div style={{ width: '100%', marginTop: '24px' }}>
            <FileUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              name="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </div>
        </CardContents>

        <ARItemDetail
          titleLabel={'Debit Note'}
          itemData={debitNoteItemData}
          setOpenDialog={setOpenItemDialog}
          resetMenu={resetMenu}
          formMode={formMode}
          handleClick={handleClick}
          keyItemID={'DebitNoteItemID'}
          getClassificationCode={getClassificationCode}
          watch={watch}
          fetchBillItem={fetchBillItem}
        />
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setEdit(true)
            setOpenItemDialog(true)
            fetchBillItem()
          }}
        >
          <span>Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteDebitNoteItem(menu?.index)
          }}
        >
          <span>Delete</span>
        </MenuItem>
      </Menu>
      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-receivable/${CompanyID}/debit-note`)
          localStorage.removeItem('debitNote')
          localStorage.removeItem('debitNoteItem')
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />

      {footerOptions?.length > 0 ? (
        <Footer options={[...footerOptions]} />
      ) : null}

      {!BillItemLoading && (
        <ARDebitNoteItemDialog
          menu={menu}
          watch={watch}
          CompanyID={CompanyID}
          getBillItem={getBillItem}
          openItemDialog={openItemDialog}
          BillItemLoading={BillItemLoading}
          taxEffectiveDate={taxEffectiveDate}
          setOpenItemDialog={setOpenItemDialog}
          debitNoteItemData={debitNoteItemData}
        />
      )}

      <TooltipAmountFooter
        data={debitNoteItemData}
        module={'debit-note-draft'}
      />
    </>
  )
}
