import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  useCreateArCreditNoteMutation,
  useUpdateArCreditNoteMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'

interface ARCNForm {
  docType: string
  CompanyID: string
  mode?: string
  setErrorDia?
  setErrMsg?
}
export const useARCreateUpdateMutation = (
  isSubmit,
  { docType, mode, CompanyID, setErrorDia, setErrMsg }: ARCNForm
) => {
  const { CreditNoteID }: any = useParams()
  let history = useHistory()

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const path = `/account-receivable/${CompanyID}/${docType}`

  /* -------------------------------------------- */
  /*                  MUTATION                    */
  /* -------------------------------------------- */

  /* -------------------------------------------- */
  /*                  MUTATION                    */
  /* -------------------------------------------- */

  const [
    createARCreditNote,
    {
      loading: createARCreditNoteLoading,
      called: createARCreditNoteCalled,
      error: createARCreditNoteError,
    },
  ] = useCreateArCreditNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: ({ createARCreditNote }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-receivable/${CompanyID}/${docType}/${createARCreditNote?.CreditNoteID}/preview`,
          })
        } else {
          history.push({
            pathname: path,
          })
        }
      }, 500)
    },
  })
  const [
    updateARCreditNote,
    {
      loading: updateARCreditNoteLoading,
      called: updateARCreditNoteCalled,
      error: updateARCreditNoteError,
    },
  ] = useUpdateArCreditNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-receivable/${CompanyID}/${docType}/${CreditNoteID}/preview`,
          })
        } else {
          history.push({
            pathname: path,
          })
        }
      }, 500)
    },
  })
  const handleMutation =
    mode === 'add' && docType === 'credit-note'
      ? createARCreditNote
      : updateARCreditNote

  const mutationLoading =
    mode === 'add' && docType === 'credit-note'
      ? createARCreditNoteLoading
      : updateARCreditNoteLoading

  const mutationCalled =
    mode === 'add' && docType === 'credit-note'
      ? createARCreditNoteCalled
      : updateARCreditNoteCalled

  return {
    handleMutation,
    mutationLoading,
    mutationCalled,
  }
}
