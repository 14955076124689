import DateFnsUtils from '@date-io/date-fns'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { Grid, TextField } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { add } from 'date-fns'
import { useDocumentListingLazyQuery } from 'generated/graphql'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { uuid } from 'uuidv4'

interface ValueData {
  sequence: number
  value: any // Replace 'any' with a more specific type if known
}

export const AssetsRegisterAddInfoComponent = (props: any) => {
  const {
    listEl,
    fetchSelectedAccountPeriod,
    CompanyID,
    latestOpenPeriodCheckingDate,
    AssetsRegistrationID,
    editData,
    formMode,
    detailMode,
    additionalInfoData,
    openAdditonalInfo,
    setOpenAdditionalInfo,
    setAdditionalInfoData,
    menu,
    setIsAdditionalInformation,
  } = props

  let form, mode
  switch (detailMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Edit'
      mode = 'edit'
  }

  const defaultValues = listEl.reduce((acc, item) => {
    const fieldName = item.ColumnName.replace(/\s+/g, '_').toLowerCase()
    acc[fieldName] = ''
    return acc
  }, {})

  const {
    register,
    handleSubmit,
    control,
    errors,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: defaultValues,
    // mode: 'onBlur',
  })

  const [additionalInformationAmt, setAdditionalInformationAmt] = useState(
    amtNumStr(AssetsRegistrationID && editData?.AdditionalInformation) ?? 0
  )
  const handleAdditionalInformationAmtChange = event => {
    setAdditionalInformationAmt(event.value)
  }

  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    fetchDocuments,
    { loading: documentLoading, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  useEffect(() => {
    listEl.forEach(field => {
      if (field?.DataType === 'Date') {
        const FieldName = field.ColumnName.replace(/\s+/g, '_').toLowerCase()
        const defaultDate = editData
          ? new Date(editData[FieldName])
          : new Date()
        setValue(FieldName, defaultDate)
      }
    })
  }, [listEl, editData, setValue])

  useEffect(() => {
    if (
      formMode === 'edit' ||
      formMode === 'approve-reject' ||
      formMode === 'resubmit'
    )
      if (!!!editData?.files) {
        fetchDocuments({
          variables: {
            refID: AssetsRegistrationID,
          },
        })
      }
  }, [formMode])

  useEffect(() => {
    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [editData])

  // const formatData = data => {
  //   const formattedData = {}

  //   Object.keys(data).forEach(key => {
  //     const value = data[key]

  //     if (value instanceof Date) {
  //       const formattedDate = value.toLocaleDateString('en-CA')
  //       formattedData[key] = formattedDate
  //     }
  //     else if (typeof value === 'number') {
  //       const formattedNumber = new Intl.NumberFormat('en-US', {
  //         minimumFractionDigits: 0,
  //         maximumFractionDigits: 2,
  //       }).format(value)
  //       formattedData[key] = formattedNumber
  //     }
  //     else {
  //       formattedData[key] = value
  //     }
  //   })

  //   return formattedData
  // }

  // const onSubmit = (data, mode, index) => {
  //   const additionalInfoData = {
  //     ...data,
  //     AdditionalInfoID:
  //       detailMode === 'add'
  //         ? uuid()
  //         : detailMode === 'edit' && formMode === 'edit'
  //         ? data?.AdditionalInfoID
  //         : undefined,
  //   }

  //   const formattedData = formatData(additionalInfoData)

  //   setAdditionalInfoData(prev => ({ ...prev, ...formattedData }))

  //   if (mode === 'new') {
  //     setOpenAdditionalInfo(false)
  //     setOpenAdditionalInfo(true)
  //   } else {
  //     setOpenAdditionalInfo(false)
  //   }
  // }

  interface ValueData {
    value: any
    Sequence: number | null
    SubGroup: boolean
    DataType: string
  }

  const onSubmit = (data: any, mode: string, index: number) => {
    let additionalInfoID = ''

    if (detailMode === 'add') {
      additionalInfoID = uuid()
    } else if (detailMode === 'edit' && formMode === 'edit') {
      additionalInfoID = data?.AdditionalInfoID
    }

    const formattedDataArray: {
      Name: string
      SubGroup: boolean | null
      Sequence: number | null
      value: any
      DataType: string | null
      AdditionalInfoID: string // Include AdditionalInfoID field
    }[] = []

    const listData = listEl?.map(item => item) || []

    for (const [key, value] of Object.entries(data)) {
      const matchedData = listData.find(
        item =>
          item.ColumnName.replace(/_/g, ' ').toLowerCase() ===
          key.replace(/_/g, ' ').toLowerCase()
      )

      const sequenceValue = matchedData ? matchedData.Sequence : null
      const subGroupValue = matchedData ? matchedData.SubGroup : null
      const dataTypeValue = matchedData ? matchedData.DataType : null

      if (
        typeof value === 'object' &&
        value !== null &&
        (value as ValueData).hasOwnProperty('value') &&
        (value as ValueData).hasOwnProperty('Sequence')
      ) {
        formattedDataArray.push({
          Name: key
            .replace(/_/g, ' ')
            .replace(/\b\w/g, char => char.toUpperCase()),
          Sequence: (value as ValueData).Sequence || sequenceValue,
          value: (value as ValueData).value,
          SubGroup: (value as ValueData).SubGroup || subGroupValue,
          DataType: (value as ValueData).DataType || dataTypeValue,
          AdditionalInfoID: additionalInfoID, // Add the same AdditionalInfoID to every entry
        })
      } else {
        formattedDataArray.push({
          Name: key
            .replace(/_/g, ' ')
            .replace(/\b\w/g, char => char.toUpperCase()),
          Sequence: sequenceValue,
          value: value,
          SubGroup: subGroupValue,
          DataType: dataTypeValue,
          AdditionalInfoID: additionalInfoID, // Add the same AdditionalInfoID to every entry
        })
      }
    }

    const combinedDataArray = [...formattedDataArray]

    setAdditionalInfoData(combinedDataArray)

    if (mode === 'new') {
      setOpenAdditionalInfo(false)
      setOpenAdditionalInfo(true)
    } else {
      setOpenAdditionalInfo(false)
    }
  }

  const checkRequiredFields = listEl => {
    return listEl
      ?.filter(s => s?.Require === true)
      ?.some(infoData => {
        const FieldName = infoData.ColumnName.replace(/\s+/g, '_').toLowerCase()
        return watch(FieldName)?.length <= 0
      })
  }
  const hasEmptyRequiredFields = checkRequiredFields(listEl)

  // Object.entries(additionalInfoData).map(([key, value]) => {
  //   console.log(`Key: ${key}, Value: ${value}`)
  // })

  return (
    <>
      <CommonDialog
        fullWidth={true}
        open={openAdditonalInfo}
        onClose={() => {
          setOpenAdditionalInfo(false)
        }}
        sections={{
          header: {
            title: 'Additional Information',
            rightText: detailMode === 'add' ? 'New' : 'Edit',
          },
          body: () => (
            <>
              <CardContents>
                {listEl
                  ?.sort(function(a, b) {
                    return a?.Sequence < b?.Sequence ? -1 : 1
                  })
                  ?.map((infoData, index) => {
                    // const FieldName = infoData.ColumnName.replace(/\s+/g, '_') // Replace spaces with underscores
                    //   .toLowerCase() // Convert to lowercase
                    //   .split('_') // Split the string by underscores
                    //   .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
                    //   .join('') // Join the words back together without spaces
                    const FieldName = infoData.ColumnName.replace(
                      /\s+/g,
                      '_'
                    ).toLowerCase()

                    const style = {
                      fontWeight: infoData.IsBold === true ? 'bold' : 'normal',
                      textDecoration:
                        infoData.IsUnderline === true ? 'underline' : 'none',
                      fontStyle:
                        infoData.IsItalic === true ? 'italic' : 'normal',
                    }

                    return (
                      <>
                        {infoData?.SubGroup === true ? (
                          <Grid spacing={1} container className="table-content">
                            <Grid item xs={12}>
                              <span style={style}>{infoData?.ColumnName}</span>
                              <input
                                type="hidden"
                                name={`${FieldName}`}
                                value={infoData?.ColumnName}
                                ref={register}
                              />
                            </Grid>
                          </Grid>
                        ) : infoData?.ControlType === 'Text' ? (
                          <Controller
                            key={infoData.ID}
                            as={TextField}
                            id={`standard-basic-${infoData.ID}`}
                            name={FieldName}
                            label={infoData.ColumnName}
                            required={infoData.Require}
                            autoComplete="off"
                            control={control}
                            fullWidth
                            margin="dense"
                            helperText={
                              infoData.Require
                                ? errors[FieldName]
                                  ? `${infoData.ColumnName} is required`
                                  : null
                                : null
                            }
                            error={errors[FieldName] ? true : false}
                            ref={register}
                          />
                        ) : infoData?.DataType === 'Date' ? (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Controller
                              required
                              as={KeyboardDatePicker}
                              name={FieldName}
                              label={infoData.ColumnName}
                              control={control}
                              onChange={(date: Date | null) => {}}
                              format="dd/MM/yyyy"
                              margin="dense"
                              allowKeyboardControl
                              ref={register}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              defaultValue={new Date()}
                              showTodayButton
                              minDate={
                                new Date(
                                  latestOpenPeriodCheckingDate?.StartDate
                                )
                              }
                              maxDate={
                                new Date(latestOpenPeriodCheckingDate?.EndDate)
                              }
                            />
                          </MuiPickersUtilsProvider>
                        ) : infoData?.DataType === 'Number' ? (
                          <Controller
                            as={<NumberFormat />}
                            thousandSeparator
                            customInput={TextField}
                            key={infoData.ID}
                            id={`standard-basic-${infoData.ID}`}
                            name={FieldName}
                            label={infoData.ColumnName}
                            value={additionalInformationAmt}
                            autoComplete="off"
                            control={control}
                            onValueChange={e => {
                              handleAdditionalInformationAmtChange(e)
                            }}
                            decimalScale={infoData?.Decimal}
                            fixedDecimalScale
                            margin="normal"
                            required={infoData.Require}
                            helperText={
                              infoData.Require
                                ? errors[FieldName]
                                  ? `${infoData.ColumnName} is required`
                                  : null
                                : null
                            }
                            error={errors[FieldName] ? true : false}
                            ref={register}
                          />
                        ) : infoData?.DataType === 'Attachment' ? (
                          <div
                            style={{
                              width: '100%',
                              marginTop: '24px',
                              marginBottom: '12px',
                            }}
                          >
                            <FileUploadInput
                              placeholder={
                                previewFiles.length === 0
                                  ? infoData.ColumnName
                                  : null
                              }
                              label={
                                previewFiles.length > 0
                                  ? infoData.ColumnName
                                  : null
                              }
                              files={files}
                              onHandleUploadChange={handleUploadChange}
                              multiple
                              accept={
                                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                              }
                              imagePreview={
                                <>
                                  {previewFiles?.map((v, i) => (
                                    <UploadPreview
                                      remove
                                      key={v}
                                      src={v}
                                      onClick={() => removeFile(i)}
                                      mediaType={
                                        files[i]?.type ??
                                        DocData?.DocumentListing[i]?.mediaType
                                      }
                                    />
                                  ))}
                                </>
                              }
                            />
                          </div>
                        ) : infoData?.ControlType === 'Multiple-row Text' ? (
                          <Controller
                            as={TextField}
                            key={infoData.ID}
                            id={`standard-basic-${infoData.ID}`}
                            name={FieldName}
                            label={infoData.ColumnName}
                            required={infoData.Require}
                            autoComplete="off"
                            control={control}
                            fullWidth
                            margin="dense"
                            helperText={
                              infoData.Require
                                ? errors[FieldName]
                                  ? `${infoData.ColumnName} is required`
                                  : null
                                : null
                            }
                            error={errors[FieldName] ? true : false}
                            ref={register}
                            multiline={true}
                            disabled={formMode === 'approve-reject'}
                          />
                        ) : null}
                      </>
                    )
                  })}
              </CardContents>
              <ErrorDialog
                errorDia={errorDia}
                setErrorDia={setErrorDia}
                errorMsg={errMsg}
                errorHeaderMsg={'Error!'}
              />
            </>
          ),
          footer: {
            actions:
              detailMode === 'add'
                ? [
                    {
                      displayText: 'Close',
                      props: {
                        onClick: () => {
                          setOpenAdditionalInfo(false)
                          setIsAdditionalInformation(false)
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () => {
                          handleSubmit(data =>
                            onSubmit(data, 'save', menu?.index)
                          )()
                        },
                        // disabled: hasEmptyRequiredFields === true,
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ]
                : [
                    {
                      displayText: 'Close',
                      props: {
                        onClick: () => {
                          setOpenAdditionalInfo(false)
                          setIsAdditionalInformation(false)
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },

                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () => {
                          handleSubmit(data =>
                            onSubmit(data, 'save', menu?.index)
                          )()
                        },
                        // disabled: hasEmptyRequiredFields === true,
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ],
          },
        }}
      />
    </>
  )
}
