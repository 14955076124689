import React from 'react'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { List, ListItem, ListItemText } from '@material-ui/core'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'

export const AllocatedDialog = (props: any) => {
  const {
    allocDialog,
    setAllocDialog,
    docNo,
    BalAmt,
    allocatedArr,
    isPost = false,
  } = props

  let totalAllocAmt = allocatedArr?.reduce(
    (total, currentValue) => (total = total + currentValue.AllocationAmt),
    0
  )
  let totalAmt = totalAllocAmt + Number(amtNumStr(BalAmt))

  return (
    <CommonDialog
      fullWidth={true}
      open={allocDialog}
      onClose={() => setAllocDialog(false)}
      sections={{
        header: {
          title: docNo,
          rightText: amtStr(totalAmt),
          infoLine: 'Outstanding Balance',
          rightInfoLine: BalAmt,
        },
        body: () => (
          <>
            <List className="core-list">
              {allocatedArr?.filter(x => !!x?.AllocationAmt)?.length === 0 ||
              allocatedArr === undefined ? (
                <EmptyList title={'No Allocated Document'} />
              ) : (
                allocatedArr
                  ?.filter(x => !!x?.AllocationAmt)
                  ?.map((el, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <>
                            <span className="desc date-width">
                              {isPost
                                ? formatDate(el?.DebitDocDate)
                                : formatDate(el?.DocDate)}
                            </span>
                            <span className="mdLabel flex-space">
                              {el?.DebitDocNo}
                            </span>
                            <span className="xxTitle">
                              <IconText
                                font="desc xxTitle"
                                children={amtStr(el?.AllocationAmt)}
                              />
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            <span className="desc flex-space text-overflow">
                              {el?.Description}
                            </span>
                          </>
                        }
                      />
                    </ListItem>
                  ))
              )}
            </List>
          </>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () => setAllocDialog(false),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}
