import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccountFooter } from 'components/Footer/AccountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  useDocumentListingLazyQuery,
  useGetAccountPeriodQuery,
  useGetSelectedAccountPeriodLazyQuery,
  useGetUomQuery,
  useLatestOpenPeriodCheckingDateQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import './AssetsRegistrationComponent/AssetsRegistration.scss'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import { AssetsRegisterAddInfoComponent } from './AssetsRegistrationComponent/AssetsRegisterAddInfoComponent'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { AssetsRegisterDepreciationComponent } from './AssetsRegistrationComponent/AssetsRegisterDepreciationComponent'
import { AssetsRegisterUsedDepartment } from './AssetsRegistrationComponent/AssetsRegisterUsedDepartment'
import {
  formatDashDate,
  formatDate,
} from 'helpers/StringNumberFunction/FormatDate'

interface AssetsRegistrationProps {
  CompanyID: string
  AssetsRegistrationID: string
  RegistrationDate: string
  StartUsingDate: string
  AssetName: string
  AssetCategoryID: string
  SourceID: string
  BaseOriginalAmount: number
  UnitPrice: number
  Quantity: number
  Residual: number
  ResidualValue: number
  NetAmount: number
  UOMID: string
  ModelNo: string
  ReferenceNo: string
  Remarks: string
  Depreciable?: boolean
  AdditionalInformation?: boolean
  Depreciation: Depreciation[]
}

interface Depreciation {
  MethodID: string
  StartYear: any
  StartPeriod: any
  DepreciationAmt: number
  UsefulLife: number
}

export const AssetsRegistrationForm = (props: any) => {
  let history = useHistory()
  let location = useLocation()
  const editData: any = location?.state
  const { formMode, type } = props
  const { CompanyID, AssetsRegistrationID }: any = useParams()
  const [usedDepartmentDataState, setUsedDepartmentData] = useState([])
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [val, setVal] = useState(false)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [openExitConf, setOpenExitConf] = useState(null)
  const [initDocs, setInitDocs] = useState([])
  const [openUsedDepartment, setOpenUsedDepartment] = useState<boolean>(false)
  const [DepartmentMode, setDepartmentMode] = useState('')
  const [isSubmit, setIsSubmit] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [accPeriodYears, setAccPeriodYears] = useState(new Set())
  const [record, setRecord] = useState(false)
  const [, setIsFormDirty] = useState(true)
  const [openAdditonalInfo, setOpenAdditionalInfo] = useState<boolean>(false)
  const [AdditonalInfoMode, setAdditionalInfoMode] = useState('')
  const [additionalInfoDataState, setAdditionalInfoData] = useState([])

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }
  const [selectedAssetCategory, setSelectedAssetCategory] = useState(null)
  const [AssetCategoryID, setAssetCategoryID] = useState(
    mode === 'edit' ? editData?.AssetCategoryID : ''
  )
  const [selectedSource, setSelectedSource] = useState(null)
  const [SourceID, setSourceID] = useState(
    mode === 'edit' ? editData?.AssetCategoryID : ''
  )
  const [selectedUOM, setSelectedUOM] = useState(null)
  const [fetchUOMID, setFetchUOMID] = useState(
    mode === 'edit' ? editData?.UOMID : ''
  )
  const [baseOriginalAmount, setBaseOriginalAmount] = useState(
    amtNumStr(AssetsRegistrationID && editData?.BaseOriginalAmount) ?? 0
  )
  const handleBaseOriginalAmtChange = event => {
    setBaseOriginalAmount(event.value)
  }

  const [isDepreciable, setIsDepreciable] = useState(
    editData ? editData?.Depreciable : false
  )
  const [selectedMethod, setSelectedMethod] = useState(null)
  const [MethodID, setMethodID] = useState(
    mode === 'edit' ? editData?.MethodID : ''
  )
  const [StartYear, setStartYear] = useState(
    mode === 'edit' ? editData?.StartYear : ''
  )
  const [StartPeriod, setStartPeriod] = useState(
    mode === 'edit' ? editData?.StartPeriod : ''
  )

  const [usefulLife, setUsefulLife] = useState(
    amtNumStr(AssetsRegistrationID && editData?.UsefulLife) ?? 0
  )
  const [depreciationAmt, setDepreciationAmt] = useState(
    amtNumStr(AssetsRegistrationID && editData?.DepreciationAmt) ?? 0
  )
  const {
    anchorEl,
    setAnchorEl,
    menu,
    handleClick,
    handleClose,
  } = useMenuOption()

  const {
    anchorEl: anchorElInfo,
    setAnchorEl: setAnchorElInfo,
    menu: menuInfo,
    handleClick: handleClickInfo,
    handleClose: handleCloseInfo,
  } = useMenuOption()

  const [isAdditionalInformation, setIsAdditionalInformation] = useState(
    editData ? editData?.AdditionalInformation : false
  )

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */
  const AssetsRegistrationFormSchema = yup.object().shape({
    RegistrationDate: yup.string().required('Registration Date is Required'),
    StartUsingDate: yup.string().required('Start Using Date is Required'),
    AssetName: CommonYupValidation.requireField(SystemMsgs.AssetName()),
    AssetCategoryID: CommonYupValidation.requireField(
      SystemMsgs.AssetCategory()
    ),
    SourceID: CommonYupValidation.requireField(SystemMsgs.Source()),
    BaseOriginalAmount: CommonYupValidation.requireField(
      SystemMsgs.BaseOriginalAmount()
    ),
    ResidualValue: CommonYupValidation.requireField(SystemMsgs.ResidualValue()),
    UOMID: CommonYupValidation.requireField(SystemMsgs.uom()),
    Remarks: CommonYupValidation.requireField(SystemMsgs.remark()),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    getValues,
    formState: { isDirty },
  } = useForm<AssetsRegistrationProps>({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(AssetsRegistrationFormSchema),
  })

  const DepreciationFormSchema = yup.object().shape({
    MethodID: CommonYupValidation.requireField(SystemMsgs.description()),
    StartYear: yup.string().required('Doc Date is Required'),
    StartPeriod: yup.string().required('Doc Date is Required'),
    DepreciationAmt: CommonYupValidation.requireField(SystemMsgs.description()),
    UsefulLife: CommonYupValidation.requireField(SystemMsgs.description()),
  })

  const {
    handleSubmit: handleDepreciationSubmit,
    register: registerDepreciation,
    setValue: setDepreciationValue,
    control: controlDepreciation,
    errors: errorsDepreciation,
    watch: watchDepreciation,
    getValues: getDepreciationValues,
  } = useForm<Depreciation>({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(DepreciationFormSchema),
  })

  /* -------------------------------------------- */
  /*                    QUEARY                    */
  /* -------------------------------------------- */

  const [
    fetchSelectedAccountPeriod,
    {
      loading: selectedAccountPeriodLoading,
      data: { getSelectedAccountPeriod } = {
        getSelectedAccountPeriod: {} as any,
      },
    },
  ] = useGetSelectedAccountPeriodLazyQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: accountPeriodLoading,
    error: accountPeriodError,
    data: { getAccountPeriod } = { getAccountPeriod: [] },
  } = useGetAccountPeriodQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, orderByAsc: 'FPeriod' },
    onCompleted: ({ getAccountPeriod }) => {
      let temp = new Set()
      getAccountPeriod?.map(x => {
        temp.add(x?.FYear)
      })
      setAccPeriodYears(temp)
    },
  })

  const {
    loading: uomLoading,
    error: uomError,
    data: { getUOM } = { getUOM: [] },
  } = useGetUomQuery({
    fetchPolicy: 'network-only',
    variables: { orderByAsc: 'Name' },
  })

  let dummyDataCategory = [
    { AssetCategoryID: '1', category: 'Category 1' },
    { AssetCategoryID: '2', category: 'Category 2' },
    { AssetCategoryID: '3', category: 'Category 3' },
    { AssetCategoryID: '4', category: 'Category 4' },
  ]

  let dummyDataSource = [
    { SourceID: '1', source: 'From Investment' },
    { SourceID: '2', source: 'Direct Purchase' },
    { SourceID: '3', source: 'Donation' },
    { SourceID: '4', source: 'Inventory Profit' },
    { SourceID: '5', source: 'Form Project' },
    { SourceID: '6', source: 'From Merger' },
  ]

  let dummyDataMethod = [
    { MethodID: '1', method: 'Method 1' },
    { MethodID: '2', method: 'Method 2' },
    { MethodID: '3', method: 'Method 3' },
    { MethodID: '4', method: 'Method 4' },
    { MethodID: '5', method: 'Method 5' },
    { MethodID: '6', method: 'Method 6' },
  ]

  let dummyDataAdditionalInformation = [
    {
      ID: '1',
      ColumnName: 'Activity Code String',
      IsBold: true,
      IsUnderline: true,
      IsItalic: true,
      SubGroup: true,
      Require: false,
      DataType: null,
      Decimal: null,
      Sequence: 1,
      ControlType: null,
    },
    {
      ID: '2',
      ColumnName: 'Activity Code Text',
      IsBold: false,
      IsUnderline: false,
      IsItalic: false,
      SubGroup: false,
      Require: true,
      DataType: 'Text',
      Decimal: null,
      Sequence: 2,
      ControlType: 'Text',
    },
    {
      ID: '3',
      ColumnName: 'Activity Code Date',
      IsBold: false,
      IsUnderline: false,
      IsItalic: false,
      SubGroup: false,
      Require: true,
      DataType: 'Date',
      Decimal: null,
      Sequence: 3,
      ControlType: null,
    },
    {
      ID: '4',
      ColumnName: 'Asset Number',
      IsBold: false,
      IsUnderline: false,
      IsItalic: false,
      SubGroup: false,
      Require: false,
      DataType: 'Number',
      Decimal: 2,
      Sequence: 4,
      ControlType: null,
    },
    {
      ID: '5',
      ColumnName: 'Attachment',
      IsBold: false,
      IsUnderline: false,
      IsItalic: false,
      SubGroup: false,
      Require: false,
      DataType: 'Attachment',
      Decimal: null,
      Sequence: 5,
      ControlType: null,
    },
    {
      ID: '6',
      ColumnName: 'Multiple Text',
      IsBold: false,
      IsUnderline: false,
      IsItalic: false,
      SubGroup: false,
      Require: true,
      DataType: 'Text',
      Decimal: null,
      Sequence: 6,
      ControlType: 'Multiple-row Text',
    },
    {
      ID: '7',
      ColumnName: 'Activity Code Subgroup',
      IsBold: true,
      IsUnderline: true,
      IsItalic: true,
      SubGroup: true,
      Require: false,
      DataType: null,
      Decimal: null,
      Sequence: 7,
      ControlType: null,
    },
  ]

  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {},
    color: 'primary',
    props: { type: 'submit' },
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {},
    color: 'primary',
    props: { type: 'submit' },
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: e => {},
    color: 'primary',
    props: { type: 'submit' },
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  const handleDeleteItem = index => {
    if (menu?.index > -1) {
      usedDepartmentDataState.splice(menu?.index, 1)
    }
  }

  const handleDeleteAdditionalInfo = index => {
    if (menu?.index > -1) {
      additionalInfoDataState.splice(menu?.index, 1)
    }
  }

  const handleAdditionalInformationToggle = e => {
    setValue('AdditionalInformation', e.target.checked)
    setIsAdditionalInformation(e.target.checked)
  }
  const handleDepreciableToggle = e => {
    setValue('Depreciable', e.target.checked)
    setIsDepreciable(e.target.checked)
  }

  const getStartYearOptions = () => {
    const startYears = Array.from(accPeriodYears)
    const stringStartYears = startYears.map(year => String(year))
    stringStartYears.sort((a: any, b: any) => b - a)
    return stringStartYears
  }

  const getFinancialAccountPeriod = getAccountPeriod?.filter(
    x =>
      x?.FYear === watchDepreciation('StartYear') && x?.MonthEndClose === false
  )

  const handleStartYearChange = (value, newValue: any) => {
    setDepreciationValue('StartYear', newValue)
  }

  /* -------------------------------------------- */
  /*                  ATTACHMENT                  */
  /* -------------------------------------------- */

  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    fetchDocuments,
    {
      loading: DocumentLoading,
      data: { DocumentListing } = { DocumentListing: [] },
    },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    variables: { refID: AssetsRegistrationID },
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  useEffect(() => {
    if (
      formMode === 'edit' ||
      formMode === 'approve-reject' ||
      formMode === 'resubmit'
    )
      if (!!!editData?.files) {
        fetchDocuments({
          variables: {
            refID: AssetsRegistrationID,
          },
        })
      }
  }, [formMode])

  useEffect(() => {
    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [editData])

  /* -------------------------------------------- */
  /*                   CHECKING                   */
  /* -------------------------------------------- */
  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const openPeriod1 =
    transferDate >= latestOpenPeriodCheckingDate?.StartDate &&
    transferDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? false : true

  const formatDateInfo = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  return (
    <>
      {accountPeriodLoading && <Loading />}
      {selectedAccountPeriodLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          history.push(`/fixed-assets/${CompanyID}/assets-registration`)
          localStorage.removeItem('assetsRegistration')
          localStorage.removeItem('usedDepartment')
        }}
        smTitle={'Fixed Assets'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Fixed Assets' },
          { name: 'Fixed Assets' },
          { name: 'Assets Registration', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : 'Edit',
            current: true,
          },
        ]}
      />

      <ContentWrapper float footer>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="RegistrationDate"
              required
              label="Registration Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onAccept={(date: Date | null) => {
                fetchSelectedAccountPeriod({
                  variables: {
                    CompanyID: CompanyID,
                    selectedDate: new Date(date),
                  },
                })
              }}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="left"
              value={new Date()}
              defaultValue={editData ? editData?.RegistrationDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose1
                  ? 'Financial Period already closed'
                  : errors?.RegistrationDate?.message
              }
              error={
                errors?.RegistrationDate || checkingYearClose1 ? true : false
              }
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="StartUsingDate"
              required
              label="Start Using Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onAccept={(date: Date | null) => {
                fetchSelectedAccountPeriod({
                  variables: {
                    CompanyID: CompanyID,
                    selectedDate: new Date(date),
                  },
                })
              }}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.StartUsingDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose1
                  ? 'Financial Period already closed'
                  : errors?.StartUsingDate?.message
              }
              error={
                errors?.StartUsingDate || checkingYearClose1 ? true : false
              }
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="AssetName"
            label="Asset Name"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.AssetName?.message}
            error={errors?.AssetName ? true : false}
          />

          <Controller
            render={({ value, onChange }) => {
              const defVal = dummyDataCategory?.filter(
                coa => coa?.AssetCategoryID === editData?.AssetCategoryID
              )[0]
              return (
                <Autocomplete
                  options={
                    dummyDataCategory?.sort((a, b) => {
                      return a?.category?.localeCompare(b?.category)
                    }) || []
                  }
                  getOptionLabel={option => {
                    return `${option?.category}`
                  }}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    if (!newValue) {
                      onChange(null)
                      setSelectedAssetCategory(null)
                      setAssetCategoryID(null)
                    } else {
                      onChange(newValue?.AssetCategoryID)
                      setSelectedAssetCategory(newValue)
                      setAssetCategoryID(newValue?.AssetCategoryID)
                    }
                  }}
                  defaultValue={defVal}
                  disabled={mode === 'approve-reject'}
                  renderOption={(props, option) => {
                    return (
                      <div>
                        <div>
                          <span className="xsTitle">{props?.category}</span>
                        </div>
                      </div>
                    )
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.AssetCategoryID?.message}
                          error={errors?.AssetCategoryID ? true : false}
                          label="Asset Category"
                          style={{ width: '100%' }}
                          required
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Asset Category"
            name="AssetCategoryID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            required
            helperText={errors?.AssetCategoryID?.message}
            error={errors?.AssetCategoryID ? true : false}
            defaultValue={editData?.AssetCategoryID}
          />

          <Controller
            render={({ value, onChange }) => {
              const defVal = dummyDataSource?.filter(
                coa => coa?.SourceID === editData?.SourceID
              )[0]
              return (
                <Autocomplete
                  options={
                    dummyDataSource?.sort((a, b) => {
                      return a?.source?.localeCompare(b?.source)
                    }) || []
                  }
                  getOptionLabel={option => {
                    return `${option?.source}`
                  }}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    if (!newValue) {
                      onChange(null)
                      setSelectedSource(null)
                      setSourceID(null)
                    } else {
                      onChange(newValue?.SourceID)
                      setSelectedSource(newValue)
                      setSourceID(newValue?.SourceID)
                    }
                  }}
                  defaultValue={defVal}
                  disabled={mode === 'approve-reject'}
                  renderOption={(props, option) => {
                    return (
                      <div>
                        <div>
                          <span className="xsTitle">{props?.source}</span>
                        </div>
                      </div>
                    )
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.SourceID?.message}
                          error={errors?.SourceID ? true : false}
                          label="Source"
                          style={{ width: '100%' }}
                          required
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Source"
            name="SourceID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            required
            helperText={errors?.SourceID?.message}
            error={errors?.SourceID ? true : false}
            defaultValue={editData?.SourceID}
          />

          <Controller
            as={<NumberFormat />}
            thousandSeparator
            customInput={TextField}
            id="standard-basic"
            name="BaseOriginalAmount"
            label="Base Original Amount"
            value={baseOriginalAmount}
            autoComplete="off"
            control={control}
            onValueChange={e => {
              handleBaseOriginalAmtChange(e)
            }}
            decimalScale={2}
            fixedDecimalScale
            margin="normal"
            required
            helperText={errors?.BaseOriginalAmount?.message}
            error={errors?.BaseOriginalAmount ? true : false}
            ref={register}
          />

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            ref={register}
            control={control}
            margin="dense"
            name="UnitPrice"
            label="Unit Price"
            className="left"
            // onValueChange={e => {
            //   handleTaxAmtChange(e?.floatValue)
            //   setTaxAmt(e?.floatValue)
            // }}
            // value={taxAmt}
            defaultValue={menu?.obj?.UnitPrice ?? 0}
            decimalScale={2}
            fixedDecimalScale
            InputProps={{
              disableUnderline: true,
            }}
          />

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            ref={register}
            control={control}
            margin="dense"
            name="Quantity"
            label="Quantity"
            className="right"
            // onValueChange={e => {
            //   handleTaxAmtChange(e?.floatValue)
            //   setTaxAmt(e?.floatValue)
            // }}
            // value={taxAmt}
            defaultValue={menu?.obj?.Quantity ?? 0}
            // decimalScale={2}
            fixedDecimalScale
            InputProps={{
              disableUnderline: true,
            }}
          />

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            ref={register}
            control={control}
            margin="dense"
            name="Residual"
            label="Residual (%)"
            className="left"
            // onValueChange={e => {
            //   handleTaxAmtChange(e?.floatValue)
            //   setTaxAmt(e?.floatValue)
            // }}
            // value={taxAmt}
            defaultValue={menu?.obj?.Residual ?? 0}
            decimalScale={4}
            fixedDecimalScale
            InputProps={{
              disableUnderline: true,
            }}
          />

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            ref={register}
            control={control}
            margin="dense"
            name="ResidualValue"
            label="Residual Value"
            className="right"
            // onValueChange={e => {
            //   handleTaxAmtChange(e?.floatValue)
            //   setTaxAmt(e?.floatValue)
            // }}
            // value={taxAmt}
            defaultValue={menu?.obj?.ResidualValue ?? 0}
            decimalScale={2}
            fixedDecimalScale
            required
            helperText={errors?.ResidualValue?.message}
            error={errors?.ResidualValue ? true : false}
          />
          <Grid spacing={1} container className="table-content">
            <Grid item xs={6} style={{ placeSelf: 'start' }}>
              <Controller
                as={<NumberFormat allowNegative={false} />}
                thousandSeparator
                customInput={TextField}
                ref={register}
                control={control}
                margin="dense"
                name="NetAmount"
                label="Net Amount"
                className="left"
                // onValueChange={e => {
                //   handleTaxAmtChange(e?.floatValue)
                //   setTaxAmt(e?.floatValue)
                // }}
                // value={taxAmt}
                defaultValue={menu?.obj?.NetAmount ?? 0}
                decimalScale={4}
                fixedDecimalScale
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={6} style={{ placeSelf: 'start' }}>
              {!uomLoading && (
                <Controller
                  render={({ value, onChange }) => {
                    const defVal = getUOM?.filter(
                      x => x?.UOMID === editData?.UOMID
                    )[0]
                    return (
                      <Autocomplete
                        options={
                          getUOM?.sort((a, b) => {
                            return a.Code.localeCompare(b.Code)
                          }) || []
                        }
                        getOptionLabel={option => `${option?.Code}`}
                        filterOptions={createFilterOptions({
                          stringify: option => option.Code + option.Name,
                        })}
                        fullWidth
                        onChange={(value, newValue: any) => {
                          if (!newValue) {
                            onChange(null)
                            setSelectedUOM(null)
                            setFetchUOMID(null)
                          } else {
                            onChange(newValue?.UOMID)
                            setSelectedUOM(newValue)
                            setFetchUOMID(newValue?.UOMID)
                          }
                        }}
                        renderOption={(props, option) => {
                          return (
                            <div>
                              <div>
                                <span className="xsTitle">{props?.Code}</span>
                              </div>
                              <div className="desc">{props?.Name}</div>
                            </div>
                          )
                        }}
                        defaultValue={defVal}
                        disabled={editData?.IsUse === true ? true : false}
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                helperText={errors?.UOMID?.message}
                                error={errors?.UOMID ? true : false}
                                label="UOM"
                                margin="dense"
                                required
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  label="UOM"
                  name="UOMID"
                  autoComplete="off"
                  control={control}
                  multiline={true}
                  fullWidth
                  margin="dense"
                  className="right"
                  ref={register}
                  helperText={errors?.UOMID?.message}
                  error={errors?.UOMID ? true : false}
                  defaultValue={editData?.UOMID}
                  required
                />
              )}
            </Grid>
          </Grid>

          <Controller
            as={TextField}
            id="standard-basic"
            name="ModelNo"
            label="Model No."
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            className="left"
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="ReferenceNo"
            label="Reference No."
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            className="right"
          />

          <VoiceTextField
            mounted={true}
            label="Remarks"
            name="Remarks"
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            setIsFormDirty={setIsFormDirty}
            control={control}
            controllerProps={{
              error: errors?.Remarks ? true : false,
              helperText: errors?.Remarks?.message,
              ref: register,
              autoComplete: 'off',
            }}
            required
          />

          <div
            style={{ width: '100%', marginTop: '24px', marginBottom: '12px' }}
          >
            <FileUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </div>
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Depreciation',
              icon:
                formMode === 'add' || formMode === 'edit' ? (
                  <div style={{ marginBottom: '-8px' }}>
                    <Switch
                      name="Depreciable"
                      color="primary"
                      inputRef={register()}
                      checked={isDepreciable}
                      onChange={handleDepreciableToggle}
                      style={{
                        width: '1.2rem',
                        height: '1.1rem',
                        background: theme.palette.primary.main,
                        boxShadow: `1px 1px 2px 0px ${theme.palette.primary.main}`,
                        borderRadius: '-1px',
                        color: 'rgba(224,234,254,100)',
                        marginTop: `10px`,
                        marginLeft: `10px`,
                        marginBottom: '-8px',
                      }}
                    />
                  </div>
                ) : null,
            },
          }}
        >
          {isDepreciable && (
            <AssetsRegisterDepreciationComponent
              listMethod={dummyDataMethod}
              watch={watchDepreciation}
              setValue={setDepreciationValue}
              mode={mode}
              errors={errorsDepreciation}
              control={controlDepreciation}
              register={registerDepreciation}
              accPeriodYears={accPeriodYears}
              getAccountPeriod={getAccountPeriod}
              AssetsRegistrationID={AssetsRegistrationID}
              editData={editData}
              setSelectedMethod={setSelectedMethod}
              setMethodID={setMethodID}
              setStartYear={setStartYear}
              setStartPeriod={setStartPeriod}
              setDepreciationAmt={setDepreciationAmt}
              depreciationAmt={depreciationAmt}
              usefulLife={usefulLife}
              setUsefulLife={setUsefulLife}
            />
          )}
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Used Department',
              onClickAction: () => {
                setDepartmentMode('add')
                setOpenUsedDepartment(true)
              },

              icon:
                formMode === 'add' || formMode === 'edit' ? (
                  <AddIcon
                    htmlColor="white"
                    fontSize="default"
                    style={{
                      width: '1.3rem',
                      height: '1.3rem',
                      margin: '0',
                      background: theme.palette.primary.main,
                      boxShadow: `1px 1px 4px 0px ${theme.palette.primary.main}`,
                      borderRadius: '3px',
                      color: 'rgba(224,234,254,100)',
                      marginTop: '10px',
                    }}
                  />
                ) : null,
            },
          }}
        >
          <div className="rm-padding table-wrap ">
            {usedDepartmentDataState === undefined ||
            usedDepartmentDataState?.length === 0 ? (
              <EmptyList
                title="No Record Found"
                subtitle="Add New Record now."
              />
            ) : (
              usedDepartmentDataState
                ?.sort(function(a, b) {
                  return a?.Sequence < b?.Sequence ? -1 : 1
                })
                ?.map((v, index) => {
                  return (
                    <>
                      <Grid spacing={1} container className="table-content">
                        <Grid item xs={1} style={{ placeSelf: 'start' }}>
                          <span className="xxTitle">{`${index + 1}.`}</span>
                        </Grid>

                        <Grid
                          item
                          xs={7}
                          style={{
                            placeSelf: 'start',
                            marginTop: '8px',
                          }}
                        >
                          <div className="xxTitle text-noflow">
                            {v?.CostCentreName}
                          </div>
                          <div className="desc text-noflow">
                            {v?.LocationName}
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={{
                            placeSelf: 'start',
                            marginTop: '8px',
                            marginRight: 'auto',
                            textAlign: 'right',
                            flexWrap: 'wrap',
                            display: 'flex',
                            justifyContent: 'end',
                          }}
                        >
                          <div className="desc" style={{ color: 'grey' }}>
                            {'Qty: '}
                          </div>
                          <div className="xxTitle">{v?.Quantity}</div>
                          <div className="xxTitle">{v?.UOMID}</div>
                        </Grid>

                        <Grid item xs={1} style={{ marginTop: '0px' }}>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            aria-controls="menu-list"
                            aria-haspopup="true"
                            onClick={e =>
                              handleClick(e, v?.UsedDepartmentID, index, v)
                            }
                          >
                            <MoreVert />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Divider
                        variant="fullWidth"
                        style={{ background: '#E4E4E4' }}
                      />
                    </>
                  )
                })
            )}
          </div>
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Additional Information',
              onClickAction: () => {
                setAdditionalInfoMode('add')
                setOpenAdditionalInfo(true)
              },
              icon:
                formMode === 'add' || formMode === 'edit' ? (
                  additionalInfoDataState?.length === 0 ? (
                    <div style={{ marginBottom: '-8px' }}>
                      <Switch
                        name="AdditionalInformation"
                        color="primary"
                        inputRef={register()}
                        checked={isAdditionalInformation}
                        onChange={handleAdditionalInformationToggle}
                        style={{
                          width: '1.2rem',
                          height: '1.1rem',
                          background: theme.palette.primary.main,
                          boxShadow: `1px 1px 2px 0px ${theme.palette.primary.main}`,
                          borderRadius: '-1px',
                          color: 'rgba(224,234,254,100)',
                          marginTop: `10px`,
                          marginLeft: `10px`,
                          marginBottom: '-8px',
                        }}
                      />
                    </div>
                  ) : null
                ) : null,
            },
          }}
        >
          <>
            {isAdditionalInformation && (
              <AssetsRegisterAddInfoComponent
                openAdditonalInfo={openAdditonalInfo}
                setOpenAdditionalInfo={setOpenAdditionalInfo}
                listEl={dummyDataAdditionalInformation}
                fetchSelectedAccountPeriod={fetchSelectedAccountPeriod}
                CompanyID={CompanyID}
                editData={editData}
                formMode={formMode}
                AssetsRegistrationID={AssetsRegistrationID}
                latestOpenPeriodCheckingDate={latestOpenPeriodCheckingDate}
                detailMode={AdditonalInfoMode}
                additionalInfoData={additionalInfoDataState}
                setAdditionalInfoData={setAdditionalInfoData}
                menu={menuInfo}
                setIsAdditionalInformation={setIsAdditionalInformation}
              />
            )}

            <div className="rm-padding table-wrap ">
              {additionalInfoDataState === undefined ||
              additionalInfoDataState?.length === 0 ? (
                <EmptyList
                  title="No Record Found"
                  subtitle="Add New Record now."
                />
              ) : (
                additionalInfoDataState
                  ?.sort(function(a, b) {
                    return a?.Sequence < b?.Sequence ? -1 : 1
                  })
                  ?.map((v, index) => {
                    return (
                      <>
                        <Grid spacing={1} container className="table-content">
                          <Grid
                            spacing={1}
                            container
                            className="table-content"
                            style={{ paddingTop: '0px', paddingBottom: '0px' }}
                          >
                            {v?.SubGroup === true ? (
                              <Grid item xs={10} style={{ padding: '0px' }}>
                                <span style={{ fontSize: '12px' }}>
                                  {v?.value}
                                </span>
                              </Grid>
                            ) : v?.DataType === 'Text' ? (
                              <Grid item xs={10} style={{ padding: '0px' }}>
                                <span style={{ fontSize: '10px' }}>
                                  {v?.value}
                                </span>
                              </Grid>
                            ) : v?.DataType === 'Date' ? (
                              <Grid item xs={10} style={{ padding: '0px' }}>
                                <span style={{ fontSize: '10px' }}>
                                  {formatDateInfo(v?.value)}
                                </span>
                              </Grid>
                            ) : v?.DataType === 'Number' ? (
                              <Grid item xs={10} style={{ padding: '0px' }}>
                                <span style={{ fontSize: '10px' }}>
                                  {v?.value}
                                </span>
                              </Grid>
                            ) : v?.DataType === 'Multiple-row Text' ? (
                              <Grid item xs={10} style={{ padding: '0px' }}>
                                <span style={{ fontSize: '10px' }}>
                                  {v?.value}
                                </span>
                              </Grid>
                            ) : null}
                            {index === 2 && (
                              <Grid
                                item
                                xs={2}
                                style={{ padding: '0px', textAlign: 'right' }}
                              >
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  aria-controls="menu-list"
                                  aria-haspopup="true"
                                  style={{ padding: '0px' }}
                                  onClick={e =>
                                    handleClickInfo(
                                      e,
                                      v?.AdditionalInfoID,
                                      index,
                                      v
                                    )
                                  }
                                >
                                  <MoreVert />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        {v?.SubGroup === true ? (
                          <Divider
                            variant="fullWidth"
                            style={{ background: '#E4E4E4' }}
                          />
                        ) : null}
                      </>
                    )
                  })
              )}
            </div>
          </>
        </CardContents>
      </ContentWrapper>
      {/* {additionalInfoDataState
        ?.sort(function(a, b) {
          return a?.Sequence < b?.Sequence ? -1 : 1
        })
        ?.map((v, index) => {
          return (
            <Grid item xs={1} style={{ marginTop: '0px' }}>
              <IconButton
                edge="end"
                aria-label="delete"
                aria-controls="menu-list"
                aria-haspopup="true"
                onClick={e => handleClickInfo(e, v?.UsedDepartmentID, index, v)}
              >
                <MoreVert />
              </IconButton>
            </Grid>
          )
        })} */}

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setDepartmentMode('edit')
            setOpenUsedDepartment(true)
          }}
        >
          <span className="mdDesc">Edit</span>
        </MenuItem>
        <MenuItem onClick={() => handleDeleteItem(menu?.index)}>
          <span className="mdDesc">Delete</span>
        </MenuItem>
      </Menu>

      <Menu
        id="menu-list"
        anchorEl={anchorElInfo}
        keepMounted
        open={Boolean(anchorElInfo)}
        onClose={handleCloseInfo}
        onClick={handleCloseInfo}
      >
        <MenuItem
          onClick={() => {
            setAdditionalInfoMode('edit')
            setOpenAdditionalInfo(true)
          }}
        >
          <span className="mdDesc">Edit</span>
        </MenuItem>
        <MenuItem onClick={() => handleDeleteAdditionalInfo(menu?.index)}>
          <span className="mdDesc">Delete</span>
        </MenuItem>
      </Menu>

      {!!openUsedDepartment && (
        <AssetsRegisterUsedDepartment
          openUsedDepartment={openUsedDepartment}
          setOpenUsedDepartment={setOpenUsedDepartment}
          usedDepartmentData={usedDepartmentDataState}
          CompanyID={CompanyID}
          AssetsRegistrationID={AssetsRegistrationID}
          formMode={formMode}
          detailMode={DepartmentMode}
          menu={menu}
          UsedDepartmentID={
            DepartmentMode === 'edit' ? menu?.obj?.UsedDepartmentID : ''
          }
          setFetchUOMID={setFetchUOMID}
          fetchUOMID={fetchUOMID}
          getUOM={getUOM}
        />
      )}

      {footerOptions?.length > 0 && (
        <AccountFooter options={[...footerOptions]} />
      )}

      <CommonDialog
        fullWidth={true}
        open={errorDia}
        onClose={() => setErrorDia(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        {'Document Numbering is empty'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>{'Please setup Document Numbering to Submit'}</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setErrorDia(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/general-ledger/${CompanyID}/journal-processing`)
          localStorage.removeItem('assetsRegistration')
          localStorage.removeItem('usedDepartment')
          localStorage.removeItem('attachment')
        }}
      />
    </>
  )
}
