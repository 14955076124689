import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import React from 'react'
import { AROfficialReceiptAllocation } from './AROfficialReceiptAllocation/AROfficialReceiptAllocation'
import { AROfficialReceiptDetail } from './AROfficialReceiptDetail/AROfficialReceiptDetail'
import { AROfficialReceiptForm } from './AROfficialReceiptForm/AROfficialReceiptForm'
import { PDFPreviewV2 } from '../Hooks/PDFPreviewV2'
import { ARMain } from '../ARMain'
import { ARDetail } from '../ARDetail/ARDetail'
import { AuditLogListing } from 'components/Listing/AuditLog'

const receiptRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/official-receipt/filter',
    },
    component: (
      <AdvancedFilter
        mode="official-receipt"
        app="account-receivable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/official-receipt',
    },
    component: (
      <ARMain
        arSubmenu="official-receipt"
        routeSegments="Official Receipt"
        accountType="account-receivable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/official-receipt/:DocumentID/detail',
    },
    component: (
      <ARDetail
        arSubmenu="official-receipt"
        routeSegments="Official Receipt"
        accountType="account-receivable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/official-receipt/:DocumentID/detail`,
    },
    component: (
      <ARDetail
        arSubmenu="official-receipt"
        routeSegments="Official Receipt"
        accountType="account-receivable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/official-receipt/:ReceiptID/detail/workdesk',
    },
    component: <AROfficialReceiptDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/official-receipt/:ReceiptID/detail/workdesk-history',
    },
    component: <AROfficialReceiptDetail mode="workdesk-history" />,
  },

  /* -------------------------------------------- */
  /*                ALLOCATION                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/official-receipt/:ReceiptID/allocation',
    },
    component: <AROfficialReceiptAllocation />,
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/official-receipt/add',
    },
    component: <AROfficialReceiptForm formMode="add" type="ADVANCE_AP" />,
  },
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/official-receipt/:ReceiptID/edit',
    },
    component: <AROfficialReceiptForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/official-receipt/:ReceiptID/approve-reject',
    },
    component: <AROfficialReceiptForm mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/official-receipt/:ReceiptID/resubmit',
    },
    component: <AROfficialReceiptForm formMode="resubmit" />,
  },

  /* -------------------------------------------- */
  /*                     PREVIEW                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/official-receipt/:ReceiptID/preview`,
    },
    component: (
      <PDFPreviewV2
        accountType="account-receivable"
        docType="official-receipt"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/official-receipt/:ReceiptID/preview`,
    },
    component: (
      <PDFPreviewV2
        accountType="account-receivable"
        docType="official-receipt"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                AUDIT LOG                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/official-receipt/:ReceiptID/detail/audit-log',
    },
    component: (
      <AuditLogListing
        module="official Receipt"
        routeSegments="official Receipt"
        smTitle="Account Receivable "
        docType="official Receipt"
      />
    ),
  },
]

export default receiptRoutes
