import theme from '@ifca-root/react-component/src/assets/theme'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { Tune } from '@material-ui/icons'
import EventIcon from '@material-ui/icons/Event'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import {
  useAuditLogDetailsLazyQuery,
  useGetCompanyNameQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDateAndTime } from 'helpers/StringNumberFunction/FormatDate'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useLocation, useParams } from 'react-router'

export const AuditLogListing = (props: any) => {
  const { smTitle, routeSegments, module } = props
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  let data: any = location.state

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const [
    loadAuditLog,
    {
      loading: AuditLogDetailsLoading,
      data: { AuditLogDetails } = { AuditLogDetails: [] },
    },
  ] = useAuditLogDetailsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ AuditLogDetails }) => {
      if (AuditLogDetails?.length > 0) {
        setOriginalListing([...filteredList, ...AuditLogDetails])
      }
    },
  })

  useEffect(() => {
    loadAuditLog({
      variables: {
        TableName: data?.tableName,
        primaryKey: data?.primaryKey,
        FieldID: data?.ID,
      },
    })
  }, [])

  //to make delay for search function for the query to load
  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadAuditLog({
          variables: {
            TableName: data?.tableName,
            primaryKey: data?.primaryKey,
            FieldID: data?.ID,
          },
        })
      }, 1000)
    )
  }

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Doc Date',
      key: 'DocDate',
    },
  })

  return (
    <>
      {UserLoading && <Loading />}
      {CompanyLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.goBack()
          localStorage.removeItem(`searchFilter`)
          localStorage.removeItem(`advancedFilter`)
        }} //<- back button action
        smTitle={smTitle}
        title={user?.companyName}
        routeSegments={
          module === 'mfrs16' ||
          module === 'cash-book' ||
          module === 'account-receivable' ||
          module === 'general-purchases' ||
          module === 'inventory control'
            ? [{ name: 'Main Menu' }, { name: routeSegments, current: true }]
            : [
                { name: 'Main Menu' },
                { name: 'Previous Path' },
                { name: 'Previous Path' },
                { name: routeSegments, current: true },
              ]
        }
      />

      {module === 'ledger' ? (
        <DynamicSubHeader
          title={
            <span className="c-orange">
              <span style={{ fontWeight: 'bold' }}>
                {data?.DocNo ?? data?.RefNo}
              </span>
            </span>
          }
          rightText={
            <span>
              <span style={{ fontWeight: 'bold' }}>{'Audit Log'}</span>
            </span>
          }
        />
      ) : (
        <DynamicSubHeader
          title={
            <span>
              <span style={{ fontWeight: 'bold' }}>{'Audit Log'}</span>
            </span>
          }
        />
      )}

      <div>
        <SearchHeader
          title={`Search here`}
          value={`${advancedFilterList(filteredList)?.length}`}
          search
          fixed
          dynamicInfo
          onChangeAction={e => {
            handleSearch(e?.target?.value, [
              'DocNo',
              'RefNo',
              'DocDate',
              'Description',
            ])
            changeDelay(e?.target?.value)
          }}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
            loadAuditLog({
              variables: {
                TableName: data?.tableName,
                primaryKey: data?.primaryKey,
                FieldID: data?.ID,
                take: 30,
                skip: 0,
              },
            })
          }}
          option={{
            icon: <Tune />,
            onClick: openFilterDialog,
          }}
          isDefaultValue={!!getSearch}
        />
        {filterDialog}
      </div>
      <ContentWrapper style={{ marginTop: isDesktop ? '153px' : '90px' }} float>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                loadAuditLog({
                  variables: {
                    TableName: data?.tableName,
                    primaryKey: data?.primaryKey,
                    FieldID: data?.ID,
                    take: 30,
                    skip: currentLength,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                AuditLogDetailsLoading && (
                  <div style={{ textAlign: 'center' }}>
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {advancedFilterList(filteredList)
                // ?.filter(handleFilterDate)
                ?.map((el, index) => {
                  let refundAlloc = el?.Allocation?.find(
                    x => x?.CreditRefTable === 'AP_Refund'
                  )

                  return (
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            <span
                              className="flex-space mdLabel"
                              style={{ fontSize: '12px' }}
                            >
                              {el?.ReadableFieldName}
                            </span>
                            <span className="desc" style={{ fontSize: '12px' }}>
                              <span className="mdLabel">
                                <IconText
                                  icon={<EventIcon />}
                                  font="mdLabel"
                                  children={`${formatDateAndTime(
                                    el?.ActionDate
                                  )}`}
                                />
                              </span>
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            <Grid
                              spacing={1}
                              container
                              className="table-content"
                            >
                              <Grid item xs={6}>
                                <span
                                  style={{ fontSize: '10px' }}
                                  className="flex-space mdLabel"
                                >
                                  {el?.column}
                                </span>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{
                                  textAlign: 'end',
                                }}
                              >
                                <span className="desc">
                                  <span
                                    style={{
                                      fontSize: '10px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {el?.Action}
                                  </span>
                                </span>
                              </Grid>
                            </Grid>

                            <Grid
                              spacing={1}
                              container
                              className="table-content"
                            >
                              <Grid item xs={6}>
                                <span style={{ fontSize: '10px' }}>
                                  Old Value: {el?.OldValue}
                                </span>
                              </Grid>
                              <Grid item xs={6} style={{ textAlign: 'end' }}>
                                <span
                                  style={{
                                    fontSize: '10px',
                                    color: 'grey',
                                    fontWeight: 'bold',
                                  }}
                                ></span>
                              </Grid>
                            </Grid>
                            <div>
                              <div
                                className="desc text-overflow"
                                style={{ width: '340px' }}
                              >
                                New Value:{el?.NewValue}
                              </div>
                            </div>
                          </>
                        }
                      />
                    </ListItem>
                  )
                })}
            </InfiniteScroll>
          )}
        </List>
      </ContentWrapper>
    </>
  )
}
