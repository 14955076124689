import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Avatar,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { AdvanceFilterSearchExpansion } from 'containers/ARAPModule/Components/Dialog/EInvoiceComponent/AdvanceFilterCardExpansion'
import { GreyTooltip } from 'containers/ARAPModule/Components/Tooltip/CustomTooltip'
import { useGetCompanyNameQuery } from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import {
  amtStr,
  replaceUnderscoresWithSpaces,
} from 'helpers/StringNumberFunction/NumFormatters'
import React, { Fragment, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useParams } from 'react-router'
import { EInvoiceStatus } from '../Dialog/EInvoiceComponent/EinvoiceStatusComponent'

export const ConsolidateListing = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const {
    module,
    title,
    accountType,
    documentType,
    page,
    fetchConsolidateData,
    loading,
    setOriginalListing,
    filteredList,
    createPerm,
  } = props

  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const {
    loading: CompanyLoading,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onCompleted: ({ getCompany }) => {
      fetchConsolidateData({
        variables: {
          CompanyID: CompanyID,
          skip: 0,
          take: 30,
          searchValue: '',
        },
      })
    },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  return (
    <>
      {CompanyLoading && <Loading />}
      {loading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/${accountType}/${CompanyID}`)
        }}
        smTitle={module}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: module },
          { name: `Consolidate ${title}`, current: true },
        ]}
      />

      <ContentWrapper footer>
        <List className="core-list">
          <AdvanceFilterSearchExpansion
            CompanyID={CompanyID}
            setOriginalListing={setOriginalListing}
            fetchData={fetchConsolidateData}
            documentType={documentType}
          />

          <List className="core-list">
            {filteredList === undefined || filteredList?.length === 0 ? (
              <EmptyList
                title="No Record found"
                subtitle="Add a new record now."
              />
            ) : (
              <InfiniteScroll
                dataLength={filteredList?.length}
                next={() => {
                  const currentLength = filteredList?.length
                  fetchConsolidateData({
                    variables: {
                      CompanyID: CompanyID,
                      skip: currentLength,
                      take: 30,
                      //   searchValue: getValueFilter('DocNo') ?? null,
                      //   DocType: selectedDocType,
                      //   StartDate: getDateCondition('StartDate'),
                      //   EndDate: getDateCondition('EndDate'),
                    },
                  })
                }}
                hasMore={true}
                className=""
                style={{ padding: '8px 6px' }}
                loader={
                  CompanyLoading && (
                    <div style={{ textAlign: 'center' }}>
                      <CircularProgress />
                    </div>
                  )
                }
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>-</b>
                  </p>
                }
              >
                {filteredList?.map(el => {
                  return (
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            <span className="desc date-width">
                              {formatDate(el?.DocDate)}
                            </span>
                            <span className="flex-space mdLabel">
                              {el?.DocNo}
                            </span>
                            <span className="xsTitle c-orange">
                              {amtStr(el?.DocAmt)}
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            <span className="desc" style={{ marginTop: '5px' }}>
                              {replaceUnderscoresWithSpaces(
                                el?.einvoice_trx_type
                              )}
                            </span>
                            <div
                              className="content-wrap full"
                              style={{ marginBottom: '-5px' }}
                            >
                              <Grid
                                spacing={1}
                                container
                                className="table-content"
                              >
                                <Grid item xs={10}>
                                  <div className="icon-text">
                                    <GreyTooltip
                                      disableFocusListener
                                      title={
                                        <Fragment>
                                          <Grid
                                            container
                                            wrap="nowrap"
                                            spacing={2}
                                          >
                                            <Grid item>
                                              <Avatar
                                                style={{
                                                  width: '20px',
                                                  height: '20px',
                                                }}
                                              >
                                                <PersonIcon
                                                  style={{ fontSize: 'small' }}
                                                />
                                              </Avatar>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                              <div style={{ fontSize: '12px' }}>
                                                {el?.ProfileInfo?.name}
                                              </div>
                                              <div style={{ fontSize: '10px' }}>
                                                {formatDate(el?.createdTs)}
                                                &nbsp;
                                                {formatTime(el?.createdTs)}
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </Fragment>
                                      }
                                      arrow
                                      enterTouchDelay={0}
                                    >
                                      <img
                                        alt=""
                                        src={SubmitterLog}
                                        style={{
                                          width: '12px',
                                          marginRight: '3px',
                                        }}
                                      />
                                    </GreyTooltip>
                                    <span className="desc text-noflow">
                                      {el?.Description}
                                    </span>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  style={{ justifyContent: 'end' }}
                                >
                                  <EInvoiceStatus
                                    isEinvoice={true}
                                    einvoiceStatus={el?.einvoice_status}
                                    data={el}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete">
                          <KeyboardArrowRight
                            onClick={() => {
                              history.push({
                                pathname: `/${accountType}/${CompanyID}/${page}/${el?.ConsolidationID}`,
                                state: el,
                              })
                            }}
                          />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </InfiniteScroll>
            )}
          </List>
        </List>

        <FloatButton
          disabled={handlePermDisabled({
            companyID: CompanyID,
            permEnum: createPerm,
          })}
          onClick={() =>
            history.push(`/${accountType}/${CompanyID}/${page}/add`)
          }
        />
      </ContentWrapper>

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
