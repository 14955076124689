import React, { lazy } from 'react'
import { PDFPreviewGP } from '@account-root/procurement-react/src/components/PDFTemplate/PDFPreviewGP'
import { AuditLogListing } from 'components/Listing/AuditLog'

const GeneralRFQListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralRFQModule/GeneralRFQListing'
  ).then(module => ({
    default: module.RFQListing,
  }))
)

const GeneralRFQMain = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralRFQModule/GeneralRFQMain'
  ).then(module => ({
    default: module.GeneralRFQMain,
  }))
)

const GeneralRFQForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralRFQModule/GeneralRFQForm'
  ).then(module => ({
    default: module.GeneralRFQForm,
  }))
)

const GeneralRFQDetails = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralRFQModule/GeneralRFQDetails'
  ).then(module => ({
    default: module.GeneralRFQDetails,
  }))
)

// RFQInvitess Submenu
const GeneralRFQInviteesListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralRFQModule/GeneralRFQInvitees/GeneralRFQInviteesListing'
  ).then(module => ({
    default: module.RFQInviteesListing,
  }))
)

const GeneralRFQInviteesForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralRFQModule/GeneralRFQInvitees/GeneralRFQInviteesForm'
  ).then(module => ({
    default: module.RFQInviteesForm,
  }))
)

const GeneralRFQQuotedListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralRFQModule/GeneralRFQInvitees/QuotedRFQ/QuotedRFQListing'
  ).then(module => ({
    default: module.QuotedRFQListing,
  }))
)

const GeneralRFQQuotedDetails = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralRFQModule/GeneralRFQInvitees/QuotedRFQ/QuotedRFQDetails'
  ).then(module => ({
    default: module.QuotedRFQDetails,
  }))
)

const GeneralRFQQuotedForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralRFQModule/GeneralRFQInvitees/QuotedRFQ/QuotedRFQForm'
  ).then(module => ({
    default: module.QuotedRFQForm,
  }))
)

const GeneralRFQEvaluationSelection = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralRFQModule/GeneralRFQEvaluationSelection'
  ).then(module => ({
    default: module.GeneralRFQEvaluationSelection,
  }))
)

const generalRFQRoutes = [
  {
    props: { exact: true, path: '/general-purchase/:CompanyID/rfq' },
    component: <GeneralRFQMain />,
  },
  {
    props: { exact: true, path: '/general-purchase/:CompanyID/rfq' },
    component: <GeneralRFQListing />,
  },
  {
    props: { exact: true, path: '/general-purchase/:CompanyID/rfq/add' },
    component: <GeneralRFQForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/rfq/edit/:RFQHeaderID',
    },
    component: <GeneralRFQForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/:CompanyID/rfq/:RFQHeaderID`,
    },
    component: <GeneralRFQDetails />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/:CompanyID/rfq/:RFQHeaderID/detail/preview`,
    },
    component: <PDFPreviewGP accountType="/" docType="rfq" backPath="rfq" />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/rfq/:RFQHeaderID/invitees',
    },
    component: <GeneralRFQInviteesListing />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/rfq/:RFQHeaderID/invitees/add',
    },
    component: <GeneralRFQInviteesForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/rfq/:RFQHeaderID/invitees/:SupplierID/quoted-rfq/:RFQSupplierInvitationID',
    },
    component: <GeneralRFQQuotedListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/rfq/:RFQHeaderID/invitees/:SupplierID/quoted-rfq/:RFQSupplierInvitationID/view/:RFQSupplierSubmissionID',
    },
    component: <GeneralRFQQuotedDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/rfq/:RFQHeaderID/invitees/:SupplierID/quoted-rfq/:RFQSupplierInvitationID/add',
    },
    component: <GeneralRFQQuotedForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/rfq/:RFQHeaderID/invitees/:SupplierID/quoted-rfq/:RFQSupplierInvitationID/edit/:RFQSupplierSubmissionID',
    },
    component: <GeneralRFQQuotedForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/rfq/:RFQHeaderID/Evaluation-&-Selection',
    },
    component: <GeneralRFQEvaluationSelection mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/rfq/:RFQHeaderID/Evaluation-&-Selection/resubmit',
    },
    component: <GeneralRFQEvaluationSelection mode="resubmit" />,
  },

  /* -------------------------------------------- */
  /*                AUDIT LOG                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/rfq/:RFQHeaderID/detail/audit-log',
    },
    component: (
      <AuditLogListing
        module="RFQ"
        routeSegments="RFQ"
        smTitle="generalPR"
        docType="RFQ"
      />
    ),
  },
]

export default generalRFQRoutes
