import theme from '@ifca-root/react-component/src/assets/theme'
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter'
import StandardTabs from '@ifca-root/react-component/src/components/Tabs/StandardTabs'
import { useMediaQuery } from '@material-ui/core'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { MFRS16JournalTable } from './MFRS16JournalTable'

export const MFRS16JournalTabs = (props: any) => {
  //=====================================
  //==============VARIABLES==============
  //=====================================
  const { data } = props
  const [tabVal, setTabVal] = useState(0)
  const [getLength, setGetLength] = useState({
    pending: 0,
    generated: 0,
    approved: 0,
  })

  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  //=====================================
  //===========TAB / CHECKBOX============
  //=====================================
  const [submitType, setSubmitType] = useState<'Generate' | 'Approve'>(
    'Generate'
  )
  const [generateValue, setGenerateValue] = useState<Set<string>>(new Set())
  const [approveValue, setApproveValue] = useState<Set<string>>(new Set())
  useEffect(() => {
    const pendingList = data?.filter(ve => ve?.Status === 'Pending')
    const generatedList = data?.filter(ve => ve?.Status === 'Generated')
    const approvedList = data?.filter(ve => ve?.Status === 'Approved')

    setGetLength({
      pending: pendingList?.length,
      generated: generatedList?.length,
      approved: approvedList?.length,
    })

    if (tabVal === 0) {
      setOriginalListing(pendingList)
      setSubmitType('Generate')
    } else if (tabVal === 1) {
      setOriginalListing(generatedList)
      setSubmitType('Approve')
    } else {
      setOriginalListing(approvedList)
    }
  }, [tabVal])

  const handleChange = (event, newValue) => {
    setTabVal(newValue)
    setGenerateValue(new Set())
    setApproveValue(new Set())
  }

  const handleCheckSeparate = (event, type: 'Generate' | 'Approve') => {
    const checkID = event?.value
    const checked = event?.checked

    if (type === 'Generate') {
      const selected = generateValue

      if (!!checked && checkID) {
        selected.add(checkID)
      } else {
        selected.delete(checkID)
      }

      setGenerateValue(new Set(generateValue))
    }

    if (type === 'Approve') {
      const selected = approveValue

      if (!!checked && checkID) {
        selected.add(checkID)
      } else {
        selected.delete(checkID)
      }

      setApproveValue(new Set(approveValue))
    }
  }
  //=====================================
  //==============FUNCTION===============
  //=====================================
  const onSubmit = (type: 'Generate' | 'Approve') => {
    if (type === 'Generate') {
      console.log(generateValue)
    } else {
      console.log(approveValue)
    }
  }

  //=====================================
  //===============FOOTER================
  //=====================================
  const accountFooter: any[] = [
    {
      name: `${submitType} (${
        tabVal === 0 ? generateValue?.size : approveValue?.size
      })`,
      onClick: () => {
        if (tabVal < 2) {
          return (
            (generateValue?.size > 0 || approveValue?.size > 0) &&
            onSubmit(submitType)
          )
        }
      },
      color: 'primary',
      props: { type: 'submit' },
      disabled:
        tabVal === 0
          ? !!(generateValue?.size === 0)
          : !!(approveValue?.size === 0),
    },
  ]

  return (
    <>
      <StandardTabs
        value={tabVal}
        onChange={handleChange}
        responsive
        fullWidth
        subHeader
        style={{
          boxShadow: 'none',
          ...(isDesktop
            ? {
                marginTop: '119px',
              }
            : {
                marginTop: '53px',
              }),
        }}
        tabs={[
          { label: `Pending (${getLength?.pending ?? 0})` },
          { label: `Generated (${getLength?.generated ?? 0})` },
          { label: `Approved (${getLength?.approved ?? 0})` },
        ]}
      />
      <MFRS16JournalTable
        showCheckbox={!!(tabVal < 2)}
        tableData={filteredList}
        handleCheckSeparate={handleCheckSeparate}
        checkboxValues={tabVal === 0 ? generateValue : approveValue}
        checkboxCondition={tabVal === 0 ? 'Generate' : 'Approve'}
      />
      {tabVal < 2 && <AccountFooter options={[...accountFooter]} />}
    </>
  )
}
