import React, { Fragment, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, ListItem, MenuItem, TextField } from '@material-ui/core'
import { useGetCostCentreQuery } from 'generated/graphql'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import theme from '@ifca-root/react-component/src/assets/theme'
import { FormStockTableContent } from './AssetsCountFormComponent/FormStockTableContent'

interface BankAccountProps {
  Date: string
  AssetCategory: any
  Remark: string
}

export const AssetsCountForm = (props: any) => {
  const isGLSubscribe = JSON.parse(localStorage.getItem('isGLSubscribe'))
  let history = useHistory()
  let location = useLocation()
  const { CompanyID }: any = useParams()
  const { mode } = props
  const editData: any = location?.state
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [voiceRemark, setVoiceRemark] = useState('')
  const [record, setRecord] = useState(false)
  const [AssetList, setAssetList] = useState([])

  const [selectedAssetCategory, setSelectedAssetCategory] = useState('')

  const dummyData = [
    {
      Name: 'Motor Vehicle',
      Amount: 'Net Amount',
      RegistrationDate: '2021-05-27T12:44:01.162Z',
      Remark: 'Remark here',
      ApprovalStatus: 'DRAFT',
      AssetNo: null,
      Description: null,
    },
    {
      Name: 'Office Equipment',
      Amount: 4000,
      RegistrationDate: '2023-11-10',
      Remark: 'Remark here',
      ApprovalStatus: 'SUBMIT',
      AssetNo: null,
      Description: null,
    },
  ]

  const bankAccountScheme = yup.object().shape({})
  const {
    handleSubmit,
    control,
    register,
    watch,
    setError,
    clearErrors,
    errors,
    setValue,
    formState: { isDirty },
  } = useForm<BankAccountProps>({
    mode: 'onSubmit',
    resolver: yupResolver(bankAccountScheme),
  })

  const {
    loading: CostCentreLoading,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      orderByAsc: 'CostCentreID',
      IsLastNode: true,
    },
  })

  return (
    <>
      {CostCentreLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          history.push(`/fixed-assets/${CompanyID}/assets-count`)
        }}
        smTitle={'Assets Count'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Company Setting' },
          { name: 'Company' },
          { name: 'Company' },
          { name: 'Asset Count', current: true },
        ]}
        rightRouteSegments={[
          { name: mode === 'add' ? 'Edit' : 'New', current: true },
        ]}
      />

      <ContentWrapper footer>
        <CardContents
          section={{
            header: {
              title: 'Asset Count Details',
            },
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="Date"
              label="Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd MMM yyyy"
              value={mode === 'add' ? new Date() : editData?.Date}
              margin="normal"
              allowKeyboardControl
              inputRef={register({})}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData ? editData?.Date : new Date()}
              helperText={errors?.Date?.message}
              error={errors?.Date ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={
              <TextField select>
                {dummyData?.map((el, index) => (
                  <MenuItem
                    style={{ whiteSpace: 'normal' }}
                    value={el.Name}
                    key={index}
                    onClick={() => setSelectedAssetCategory(el.Name)}
                  >
                    <span className="text-noflow">{el.Name}</span>
                  </MenuItem>
                ))}
              </TextField>
            }
            name="Asset Category"
            label="Asset Category"
            autoComplete="on"
            margin="normal"
            fullWidth
            ref={register}
            control={control}
            required
            helperText={errors?.AssetCategory?.message}
            error={errors?.AssetCategory ? true : false}
            defaultValue={editData?.AssetCategory}
          />

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            required
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />
        </CardContents>

        {selectedAssetCategory === 'Motor Vehicle' && (
          <FormStockTableContent
            mode={mode}
            editData={editData}
            useFormProps={{
              clearErrors,
              setError,
              errors,
              watch,
              register,
              control,
              setValue,
            }}
          />
        )}
      </ContentWrapper>

      <Footer
        options={[
          {
            name: 'Save As Draft',
            onClick: () => {},
            color: 'primary',
          },
          {
            name: 'Submit',
            onClick: () => {},
            color: 'primary',
          },
        ]}
      />
    </>
  )
}
