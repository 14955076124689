import React, { lazy } from 'react'
import { ApprovalPolicyAPSubmenu } from '../CompanyApprovalPolicy/ApprovalPolicyAPSubmenu'
import { PaymentApprovalPolicyForm } from '../CompanyApprovalPolicy/PaymentApprovalPolicyForm'
import { AdvanceApprovalPolicyAPForm } from '../CompanyApprovalPolicy/AdvanceApprovalPolicyAPForm'

const apApprovalPolicyRoutes = [
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/company-setting/approval-policy',
    },
    component: <ApprovalPolicyAPSubmenu module="account-payable" />,
  },
  //AP Payment Approval Policy

  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/company-setting/approval-policy/payment',
    },
    component: <PaymentApprovalPolicyForm />,
  },
  // //AP Advance Approval Policy
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/company-setting/approval-policy/advance',
    },
    component: <AdvanceApprovalPolicyAPForm />,
  },
]

export default apApprovalPolicyRoutes
