import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import withStyles from '@material-ui/core/styles/withStyles'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'

export const TrxTaxTable = ({ TransactionTax, IsSubtable }) => {
  if (TransactionTax?.length === 0) {
    return IsSubtable ? (
      <TinyTabEmptyList title="No Record Found" />
    ) : (
      <EmptyList title="No Record Found" />
    )
  } else {
    return (
      <StyledTableContainer component={Paper}>
        <Table style={{ minWidth: '700' }} size="small">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  width: '20%',
                }}
              >
                Tax Type
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  textAlign: 'right',
                  width: '40%',
                }}
              >
                Taxable Amount
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  textAlign: 'right',
                  width: '40%',
                }}
              >
                Tax Amount
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {TransactionTax?.map((v, i) => {
              return (
                <StyledTableRow>
                  <StyledTableCell size="small">{v?.TaxType}</StyledTableCell>
                  <StyledTableCell size="small" style={{ textAlign: 'right' }}>
                    {amtStr(v?.TaxableAmt)}
                  </StyledTableCell>
                  <StyledTableCell size="small" style={{ textAlign: 'right' }}>
                    {amtStr(v?.TaxAmt)}
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    )
  }
}

export const TrxRefNoTable = ({ TransactionRefNo }) => {
  if (TransactionRefNo?.length === 0) {
    return <EmptyList title="No Record Found" />
  } else {
    return (
      <StyledTableContainer component={Paper} style={{ maxWidth: '70%' }}>
        <Table size="small">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  width: '20%',
                }}
              >
                Transaction Document Ref No
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  width: '40%',
                }}
              >
                Irbm Ref Transaction No
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {TransactionRefNo?.map((v, i) => {
              return (
                <StyledTableRow>
                  <StyledTableCell size="small">
                    {v?.TrxDocRefNo}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {v?.IrbmRefTrxNo}
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    )
  }
}

const StyledTableCell = withStyles(theme => ({
  head: {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: 12,
  },
  body: { color: theme.palette.common.black, fontWeight: 600, fontSize: 12 },
}))(TableCell)

const StyledTableContainer = withStyles({
  root: { boxShadow: 'none', borderRadius: 'none', marginBottom: '8px' },
})(TableContainer)

const StyledTableRow = withStyles({
  root: {
    '&:nth-of-type(odd)': { backgroundColor: 'transparent' },
    '&:nth-of-type(even)': { backgroundColor: '#FFF1DE' },
  },
})(TableRow)

export const TinyTabEmptyList = ({ title }) => {
  return (
    <div className="smTitle" style={{ textAlign: 'left' }}>
      {title}
    </div>
  )
}
