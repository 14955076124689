import React, { lazy } from 'react'
import assetsRegistrationRoutes from './AssetsRegistration/AssetsRegistrationRoutes'
import assetsCountRoutes from './AssetsCount/route'
import RevaluationRoutes from './Revaluation/RevaluationRoutes'
import WriteOffDisposalRoutes from './WriteOffDisposal/WriteOffDisposalRoutes'
import depreciationRoutes from './Depreciaion/DepreciationRoutes'

const FixedAssetsSubmenu = lazy(() =>
  import('./FixedAssetsSubmenu').then(module => ({
    default: module.FixedAssetsSubmenu,
  }))
)

const fixedAssetsRoutes = [
  {
    props: { exact: true, path: '/fixed-assets/:CompanyID/' },
    component: <FixedAssetsSubmenu />,
  },
  ...assetsRegistrationRoutes,
  ...assetsCountRoutes,
  ...RevaluationRoutes,
  ...WriteOffDisposalRoutes,
  ...depreciationRoutes,
]

export default fixedAssetsRoutes
