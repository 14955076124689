import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  useDeleteArAdvanceMutation,
  useDeleteArCreditNoteMutation,
  useDeleteArDebitNoteMutation,
  useDeleteArInvoiceMutation,
  useDeleteArOfficialReceiptMutation,
  useDeleteArRefundMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'

interface ARDeleteMutation {
  arSubmenu: string
  loadData: any
  CompanyID: string
  setOriginalListing: any
  setOpenDeleteDialog: any
  setErrDialog?
  setErrMessage?
}

export const useARDeleteMutation = ({
  arSubmenu,
  loadData,
  CompanyID,
  setOriginalListing,
  setOpenDeleteDialog,
  setErrDialog,
  setErrMessage,
}: ARDeleteMutation) => {
  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  /* -------------------------------------------- */
  /*                POST MUTATION               */
  /* -------------------------------------------- */

  const [
    deleteARAdvance,
    {
      loading: deleteARAdvanceLoading,
      error: deleteARAdvanceError,
      called: deleteAdvanceCalled,
    },
  ] = useDeleteArAdvanceMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })
  const [
    deleteARInvoice,
    {
      loading: deleteARInvoiceLoading,
      error: deleteARInvoiceError,
      called: deleteInvoiceCalled,
    },
  ] = useDeleteArInvoiceMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    deleteARDebitNote,
    {
      loading: deleteARDebitNoteLoading,
      error: deleteARDebitNoteError,
      called: deleteDebitNoteCalled,
    },
  ] = useDeleteArDebitNoteMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })
  const [
    deleteARCreditNote,
    {
      loading: deleteARCreditNoteLoading,
      error: deleteARCreditNoteError,
      called: deleteCreditNoteCalled,
    },
  ] = useDeleteArCreditNoteMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })
  const [
    deleteARRefund,
    {
      loading: deleteARRefundLoading,
      error: deleteARRefundError,
      called: deleteRefundCalled,
    },
  ] = useDeleteArRefundMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    deleteAROfficialReceipt,
    {
      loading: deleteAROfficialReceiptLoading,
      error: deleteAROfficialReceiptError,
      called: deleteReceiptCalled,
    },
  ] = useDeleteArOfficialReceiptMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const handleDelete =
    arSubmenu === 'advance'
      ? deleteARAdvance
      : arSubmenu === 'invoice'
      ? deleteARInvoice
      : arSubmenu === 'debit-note'
      ? deleteARDebitNote
      : arSubmenu === 'credit-note'
      ? deleteARCreditNote
      : arSubmenu === 'refund'
      ? deleteARRefund
      : arSubmenu === 'official-receipt'
      ? deleteAROfficialReceipt
      : ''

  const mutationLoading =
    arSubmenu === 'advance'
      ? deleteARAdvanceLoading
      : arSubmenu === 'invoice'
      ? deleteARInvoiceLoading
      : arSubmenu === 'debit-note'
      ? deleteARDebitNoteLoading
      : arSubmenu === 'credit-note'
      ? deleteARCreditNoteLoading
      : arSubmenu === 'refund'
      ? deleteARRefundLoading
      : arSubmenu === 'official-receipt'
      ? deleteAROfficialReceiptLoading
      : ''

  const deleteCalled =
    arSubmenu === 'advance'
      ? deleteAdvanceCalled
      : arSubmenu === 'invoice'
      ? deleteInvoiceCalled
      : arSubmenu === 'debit-note'
      ? deleteDebitNoteCalled
      : arSubmenu === 'debit-note'
      ? deleteCreditNoteCalled
      : arSubmenu === 'refund'
      ? deleteRefundCalled
      : arSubmenu === 'official-receipt'
      ? deleteReceiptCalled
      : ''

  return {
    handleDelete,
    mutationLoading,
    deleteCalled,
  }
}
