import React, { lazy } from 'react'

const ApprovalPolicySubmenu = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/ApprovalPolicy/ApprovalPolicySubmenu'
  ).then(module => ({
    default: module.ApprovalPolicySubmenu,
  }))
)
const PRForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/ApprovalPolicy/PRForm'
  ).then(module => ({
    default: module.PRForm,
  }))
)
const RFQForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/ApprovalPolicy/RFQForm'
  ).then(module => ({
    default: module.RFQForm,
  }))
)
const POApprovalPolicyGPForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/ApprovalPolicy/POApprovalPolicyGPForm'
  ).then(module => ({
    default: module.POApprovalPolicyGPForm,
  }))
)
const GPApprovalPolicyRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/company-settings/approval-policy',
    },
    component: <ApprovalPolicySubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/company-settings/approval-policy/pr',
    },
    component: <PRForm />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/company-settings/approval-policy/rfq',
    },
    component: <RFQForm />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/company-settings/approval-policy/po',
    },
    component: <POApprovalPolicyGPForm />,
  },
]

export default GPApprovalPolicyRoutes
