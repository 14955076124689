import { AttachFile } from '@material-ui/icons'
import { AttachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import PrintIcon from '@material-ui/icons/Visibility'
import React, { useState } from 'react'
import '../Payment.scss'
import { useHistory, useParams } from 'react-router'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { AttachmentDialog } from '../../../../components/Dialog/AttachmentDialog'
import { useDocumentListingQuery } from 'generated/graphql'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
  bankAccount: any
}

export const PaymentDetailContent = (props: DetailProps) => {
  const {
    listEl,
    documentListing,
    listStatus,
    userList,
    mode,
    bankAccount,
  } = props

  let history = useHistory()
  const { CompanyID, BankAccountID }: any = useParams()

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    loading: documentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: listEl?.PaymentID,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [openDoc, setOpenDoc] = useState<boolean>(false)
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu: resetVert,
  } = useMenuOption()

  return (
    <>
      {/* EXPANSION PAGE */}

      {mode === 'expansion' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Transaction Date
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.TransactionDate)}
            </div>
          </div>

          <div className="content-wrap right">
            <span>
              <div className="desc">
                <span className="desc" style={{ color: 'grey' }}>
                  Reference No.
                </span>
                <span
                  style={{ float: 'right', color: '#000' }}
                  onClick={() => {
                    history.push({
                      pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${listEl?.PaymentID}/detail/preview`,
                      state: {
                        ...listEl,
                        mode: 'detail',
                      },
                    })
                  }}
                >
                  <PrintIcon
                    style={{
                      fontSize: 'medium',
                      padding: '0px 10px 2px 0px',
                    }}
                  />
                </span>
                {documentListing && documentListing?.length > 0 && (
                  <>
                    <span
                      style={{ float: 'right', color: '#000' }}
                      onClick={() => {
                        setOpenDoc(true)
                      }}
                    >
                      <AttachFile
                        className="mdDesc"
                        style={{
                          fontSize: 12,
                          color: 'orange',
                          paddingTop: '2px',
                        }}
                      />

                      <span
                        className="mdDesc"
                        style={{
                          float: 'right',
                          color: 'orange',
                          textDecoration: 'underline',
                          paddingRight: '5px',
                        }}
                      >
                        {documentListing?.length}
                      </span>
                    </span>
                  </>
                )}
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {listEl?.RefNo}
                </div>
              </div>
            </span>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Payment Method
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.PaymentMethod?.Name ?? 'N/A'}
            </div>
          </div>

          {/* <div
            className="content-wrap right"
            style={{ alignSelf: 'end', float: 'right' }}
          >
            {documentListing && documentListing?.length > 0 && (
              <>
                <span
                  onClick={() =>
                    documentListing?.forEach(doc => {
                      window.open(doc?.fileURL)
                    })
                  }
                  style={{
                    position: 'relative',
                    top: '1px',
                    float: 'right',
                    paddingRight: '10px',
                  }}
                >
                  <AttachFile
                    className="mdDesc"
                    style={{
                      fontSize: 12,
                      color: 'orange',
                    }}
                  />
                  <span
                    className="mdDesc"
                    style={{ color: 'orange', textDecoration: 'underline' }}
                  >
                    {documentListing?.length}
                  </span>
                </span>
              </>
            )}
          </div> */}

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Payee Name
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.PayeeName ?? 'N/A'}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Description
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.Description ?? listEl?.Remark}
            </div>
          </div>
        </>
      )}

      {/* DETAIL PAGE */}

      {mode === 'detail' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Document Date
            </div>

            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {' '}
              {formatDate(listEl?.DocDate)}{' '}
            </div>
          </div>

          <div className="content-wrap right">
            <span>
              <div className="desc">
                <span style={{ color: '#96938e' }}> Transaction Date </span>
                <span
                  style={{ float: 'right', color: '#000' }}
                  onClick={() => {
                    history.push({
                      pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${listEl?.PaymentID}/detail/preview`,
                      state: {
                        ...listEl,
                        mode: 'detail',
                      },
                    })
                  }}
                >
                  <PrintIcon
                    style={{
                      fontSize: 'medium',
                      padding: '0px 10px 2px 0px',
                    }}
                  />
                </span>
                {documentListing && documentListing?.length > 0 && (
                  <>
                    <span
                      style={{ float: 'right', color: '#000' }}
                      onClick={() => {
                        setOpenDoc(true)
                      }}
                    >
                      <AttachFile
                        className="mdDesc"
                        style={{
                          fontSize: 12,
                          color: 'orange',
                        }}
                      />

                      <span
                        className="mdDesc"
                        style={{
                          float: 'right',
                          color: 'orange',
                          textDecoration: 'underline',
                          paddingRight: '5px',
                        }}
                      >
                        {documentListing?.length}
                      </span>
                    </span>
                  </>
                )}
                <div className="desc" style={{ marginTop: '2px' }}>
                  {formatDate(listEl?.TransactionDate)}
                </div>
              </div>
            </span>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Document Number
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.DocNo}
            </div>
          </div>

          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              Reference No.
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.RefNo}
            </div>
          </div>
        </>
      )}

      {mode === 'detail' && (
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Payment Method
          </div>
          <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
            {listEl?.PaymentMethod?.Name}
          </div>
        </div>
      )}

      {mode === 'detail' && (
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Payee Name
          </div>
          <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
            {listEl?.PayeeName ?? 'N/A'}
          </div>
        </div>
      )}

      {mode === 'detail' && (
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Description
          </div>
          <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
            {listEl?.Description ?? listEl?.Remark}
          </div>
        </div>
      )}

      {/* GENERAL DETAIL PAGE */}

      {listEl?.PaymentMethod?.Nam?.includes('Card') && (
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Card Type
          </div>
          <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
            {listEl?.CreditCardType?.Name}
          </div>
        </div>
      )}

      {listEl?.PaymentMethod?.Name === 'Cheque' && (
        <>
          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Cheque No.
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.ChequeNo}
            </div>
          </div>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Cheque Date
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.ChequeDate)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              Cheque Expiry Date
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.ChequeExpiryDate)}
            </div>
          </div>
        </>
      )}

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}

      <AttachmentDialog
        title="Payment"
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        AttachmentDetailView={AttachmentDetailView}
        DocumentListing={DocumentListing}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
