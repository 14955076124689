import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { useGetClassificationCodeQuery } from 'generated/graphql'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'

export const ARCNItemDetail = (props: any) => {
  const {
    CN = false,
    titleLabel,
    itemData,
    setOpenDialog,
    fetchCostCentre,
    resetMenu,
    setSelectedTax,
    setDetailMode,
    formMode,
    detailMode,
    fetchBillItem,
    getBillItem,
    getCostCentre,
    handleClick,
    keyItemID,
    watch,
    loadMasterCOA,
  } = props

  const {
    loading: classificationCodeLoading,
    data: { getClassificationCode } = { getClassificationCode: [] },
  } = useGetClassificationCodeQuery({
    fetchPolicy: 'network-only',
  })

  return (
    <CardContents
      section={{
        header: {
          title: CN
            ? `${titleLabel} (${itemData?.length})`
            : `${titleLabel} Detail (${itemData?.length})`,
          onClickAction: () => {
            loadMasterCOA({
              variables: { IsLastNode: true, orderByAsc: 'Name' },
            })
            setOpenDialog(true)
            fetchBillItem()
            fetchCostCentre()
            resetMenu()
            setSelectedTax(null)
            setDetailMode('add')
          },
          icon: (
            <AddIcon
              htmlColor="white"
              fontSize="small"
              style={{
                width: '20px',
                height: '20px',
                margin: '0',
                background: theme.palette.primary.main,
                borderRadius: '3px',
                color: 'rgba(224,234,254,100)',
                marginTop: '10px',
                marginRight: '10px',
              }}
            />
          ),
        },
      }}
    >
      <div className="rm-padding table-wrap ">
        {itemData === undefined || itemData?.length === 0 ? (
          <EmptyList title="No Record Found" subtitle="Add New Record now." />
        ) : (
          itemData
            ?.sort((a, b) => {
              return a?.Sequence < b?.Sequence ? -1 : 1
            })
            ?.map((v, index) => {
              return (
                <>
                  <List className="borderless-list content-wrap full text-noflow">
                    <ListItem
                      divider={index < itemData?.length - 1 ? true : false}
                    >
                      <Grid container className="table-content">
                        <Grid item xs={1} style={{ placeSelf: 'start' }}>
                          <span className="xxTitle">{`${index + 1}.`}</span>
                        </Grid>

                        <Grid item xs={8} style={{ placeSelf: 'start' }}>
                          <div className="xxTitle text-noflow">
                            {formMode === 'add'
                              ? getBillItem?.find(
                                  x => x?.BillItemID === v?.BillItemID
                                )?.Name
                              : formMode === 'edit' && detailMode === 'add'
                              ? getBillItem?.find(
                                  x => x?.BillItemID === v?.BillItemID
                                )?.Name
                              : v?.Name ?? v?.BillItemName}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{
                            placeSelf: 'start',
                            display: 'flex',
                            justifyContent: 'end',
                          }}
                        >
                          <div className="xxTitle">{amtStr(v?.DocAmt)}</div>
                        </Grid>

                        <Grid item xs={1} style={{ placeSelf: 'start' }} />
                        <Grid item xs={5} style={{ placeSelf: 'start' }}>
                          <div className="desc">
                            {formMode === 'add'
                              ? getCostCentre?.find(
                                  x => x?.CostCentreID === v?.CostCentreID
                                )?.Code
                              : v?.CostCentreName}{' '}
                            {watch('is_einvoice') === true
                              ? ` | ${
                                  getClassificationCode?.filter(
                                    x =>
                                      x?.id ===
                                      v?.einvoice_classification_code_id
                                  )[0]?.classificationCode
                                } (${
                                  getClassificationCode?.filter(
                                    x =>
                                      x?.id ===
                                      v?.einvoice_classification_code_id
                                  )[0]?.description
                                })`
                              : null}
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={{
                            placeSelf: 'start',
                            textAlign: 'right',
                            justifyContent: 'end',
                          }}
                        >
                          <div className="desc">
                            {`${v?.TaxCode} | ${parseInt(v?.TaxRate)}%`}
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={{
                            placeSelf: 'start',
                            display: 'flex',
                            justifyContent: 'end',
                          }}
                        >
                          <div className="desc">{amtStr(v?.TaxAmt)}</div>
                        </Grid>
                        <Grid item xs={1} style={{ placeSelf: 'start' }} />
                        <Grid item xs={11} style={{ placeSelf: 'start' }}>
                          <div className="desc text-noflow">{v?.Remark}</div>
                        </Grid>
                      </Grid>

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          onClick={e => {
                            handleClick(e, v[`${keyItemID}`], index, v)
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </>
              )
            })
        )}
      </div>
    </CardContents>
  )
}
