import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { RejectDialog } from 'components/Dialog/RejectDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  ApprovalStatus,
  GetJournalProcessingbyStatusDocument,
  useDocumentListingQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePostJournalProcessingMutation,
  UserSelection,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { default as React, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { AssetsRegistrationDetailContent } from './AssetsRegistrationDetailContent'
import { AssetsRegistrationUsedDepartmentDetailContent } from './AssetsRegistrationUsedDepartmentDetailContent'
import { AssetsRegistrationDepreciationDetailContent } from './AssetsRegistrationDepreciationDetailContent'

export const AssetsRegistrationDetail = (props: any) => {
  let history = useHistory()
  const { mode } = props
  const { CompanyID, AssetsRegistrationID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    clearErrors,
  } = useForm<any>()
  const [audioInput, setAudioInput] = useState('')
  const [record, setRecord] = useState(false)
  const [text, setText] = useState(mode === 'add' ? '' : editData?.remark)
  const [singleRemark, setSingleRemark] = useState(null)
  const handleSingleRemark = remark => {
    setSingleRemark(remark)
  }

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  let itemData = []
  itemData = editData?.AssetsRegistrationID

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const dummyData = [
    {
      AssetName: 'Computer',
      AssetCategory: 'Office Equipment',
      AssetNo: 'Asset No',
      RegistrationDate: '2023-11-01',
      StartUsingDate: '2023-11-12',
      Depreciable: true,
      Source: 'Direct Purchase',
      BaseOriginalAmount: 4000,
      UnitPrice: 4000,
      Quantity: 4,
      ResidualValue: 0,
      Residual: 0,
      NetAmount: 4000,
      UOM: 'Unit',
      ModelNo: '-',
      ReferenceNo: '-',
      SubmittedBy: 'Syima',
      SubmittedDate: '2023-11-10',
      ApprovedBy: 'Leong Koh',
      ApprovedDate: '2023-11-10',
      Remarks: 'Remarks here',
      submittedTs: '2023-12-13 13:10:34',
      submittedBy: '9d95b71a-6afb-11ed-b326-53d826021c96',
      approvedTs: '2023-12-13 13:10:39',
      approvedBy: '9d95b71a-6afb-11ed-b326-53d826021c96',
      UsedDepartment: [
        { Department: 'Finance', Quantity: 2, UOM: 'Unit', Location: 'Office' },
        { Department: 'General', Quantity: 2, UOM: 'L', Location: 'Office' },
      ],
      Depreciation: [
        {
          Method: '-',
          StartYear: '2024',
          StartPeriod: '11',
          AccDepreciationAmount: 1000,
          UsefulLife: 5,
          UtilizedUsefulLife: 0,
          AccRevaluationAmount: 0,
          AccProvisionImpairment: 0,
          AccReversalImpairment: 0,
        },
      ],
      ApprovalStatus: 'SUBMIT',
    },
  ]

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
  })

  const {
    loading: docLoading,
    error: docError,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: AssetsRegistrationID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  const rejectFooterOption = {
    name: 'Reject',
    onClick: () => {
      {
        setOpenRejectDialog(true)
      }
    },
    color: 'secondary',

    props: { type: 'submit' },
  }

  const approveFooterOption = {
    name: 'Approve',
    onClick: () => {
      handleSubmit(data => onSubmit(ApprovalStatus.Approved, 'APPROVED'))()
    },
    color: 'primary',

    props: { type: 'submit' },
  }
  const footerOptions: any[] = [rejectFooterOption, approveFooterOption]

  const [
    postJournalProcessing,
    {
      loading: postJournalProcessingLoading,
      called: postJournalProcessingCalled,
      error: postJournalProcessingError,
    },
  ] = usePostJournalProcessingMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/fixed-assets/${CompanyID}/assets-registration`,
          state: { success: true, msgMode: 'update' },
        })
      }, 500)
    },
  })

  const onSubmit = (data, status) => {
    postJournalProcessing({
      variables: {
        JournalProcessingIDs: AssetsRegistrationID,
        CompanyID,
      },
      refetchQueries: [
        {
          query: GetJournalProcessingbyStatusDocument,
          variables: { CompanyID },
        },
      ],
    })
  }

  return (
    <>
      {docLoading && <Loading />}
      {postJournalProcessingLoading && <Loading />}
      {getUsersByAccountAndSoftwareLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/fixed-assets/${CompanyID}/assets-registration`)
        }}
        smTitle="Fixed Assets"
        title={user?.companyName}
        routeSegments={[
          { name: 'Fixed Assets' },
          { name: 'Fixed Assets' },
          { name: 'Assets Registration', current: true },
        ]}
        rightRouteSegments={[{ name: `Approved`, current: true }]}
      />

      <DynamicSubHeader
        title={
          <span className="c-orange">
            <span style={{ fontWeight: 'bold' }}>
              {dummyData[0]?.AssetName}
            </span>
          </span>
        }
        rightText={<span className="c-orange">{dummyData[0]?.AssetNo}</span>}
        infoLine={
          <div
            className="text-overflow"
            style={{ fontSize: '11px', fontWeight: 'bold', width: '270px' }}
          >
            {dummyData[0]?.AssetCategory}
          </div>
        }
        rightInfoLine={
          <span className="desc c-orange">
            {dummyData[0]?.BaseOriginalAmount}
          </span>
        }
      />
      <ContentWrapper multiDynamicInfo float footer>
        <CardContents>
          <AssetsRegistrationDetailContent
            listEl={dummyData[0]}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>

        <CardContents
          section={{
            header: { title: 'Depreciation' },
          }}
        >
          <AssetsRegistrationDepreciationDetailContent
            listDepreciation={dummyData[0]?.Depreciation[0]}
          />
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Used Department',
            },
          }}
        >
          <AssetsRegistrationUsedDepartmentDetailContent
            listUsedDepartment={dummyData[0]?.UsedDepartment}
          />
        </CardContents>
      </ContentWrapper>
      {mode === 'workdesk' ? <Footer options={[...footerOptions]} /> : ''}

      {dummyData[0]?.ApprovalStatus === 'SUBMIT' ? (
        <Footer
          options={[
            {
              name: 'Post',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      ) : null}

      <RejectDialog
        data={dummyData[0]}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        audioInput={audioInput}
        record={record}
        setRecord={setRecord}
        setValue={setValue}
        errors={errors}
        register={register}
        control={control}
        clearErrors={clearErrors}
        setText={setText}
        handleSingleRemark={handleSingleRemark}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />
    </>
  )
}
