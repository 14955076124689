import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Grid, TextField } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import * as yup from 'yup'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import {
  amtStr,
  backDateNDays,
} from 'helpers/StringNumberFunction/NumFormatters'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React from 'react'

export const ConsolidateDialog = (props: any) => {
  const {
    title,
    onSubmit,
    openDialog,
    setOpenDialog,
    length,
    totalDocAmt,
  } = props

  const FormSchema = yup.object().shape({
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
  })

  const { handleSubmit, register, control, errors } = useForm({
    resolver: yupResolver(FormSchema),
  })

  return (
    <CommonDialog
      fullWidth={true}
      open={openDialog}
      onClose={() => {
        setOpenDialog(false)
      }}
      sections={{
        header: {
          title: `Consolidate ${title}`,
        },
        body: () => (
          <>
            <div
              className="content-container"
              style={{ marginTop: '-10px', fontSize: '16px' }}
            >
              <Grid
                xs={7}
                className="desc"
                style={{ marginTop: '5px', fontSize: '16px' }}
              >
                Doc Count:
              </Grid>

              <Grid xs={5}>
                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  thousandSeparator
                  customInput={TextField}
                  ref={register}
                  control={control}
                  margin="none"
                  name="DocCount"
                  defaultValue={length}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    inputProps: {
                      style: { textAlign: 'right', fontSize: '16px' },
                    },
                    class: 'xxTitle',
                  }}
                />
              </Grid>

              <Grid
                xs={7}
                className="desc"
                style={{ marginTop: '6px', fontSize: '16px' }}
              >
                <span>Total Amount:</span>
              </Grid>

              <Grid xs={5}>
                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  thousandSeparator
                  customInput={TextField}
                  ref={register}
                  control={control}
                  margin="none"
                  name="Amount"
                  defaultValue={amtStr(totalDocAmt)}
                  decimalScale={2}
                  fixedDecimalScale
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    inputProps: {
                      style: { textAlign: 'right', fontSize: '16px' },
                    },
                    class: 'xxTitle',
                  }}
                />
              </Grid>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  required
                  as={KeyboardDatePicker}
                  name="DocDate"
                  label="Document Date"
                  control={control}
                  onChange={(date: Date | null) => {}}
                  format="dd/MM/yyyy"
                  margin="dense"
                  allowKeyboardControl
                  ref={register}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  value={new Date()}
                  defaultValue={new Date()}
                  showTodayButton
                  minDate={backDateNDays(3)}
                />
              </MuiPickersUtilsProvider>

              <Controller
                as={TextField}
                id="standard-basic"
                name="Description"
                label="Description"
                required
                autoComplete="off"
                control={control}
                fullWidth
                margin="dense"
                helperText={errors?.Description?.message}
                error={errors?.Description ? true : false}
                ref={register}
              />
            </div>
          </>
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: () => {
                  setOpenDialog(false)
                },
                variant: 'contained',
                color: 'primary',
              },
            },

            {
              displayText: 'Proceed',
              props: {
                onClick: () => {
                  handleSubmit(data => onSubmit(data))()
                },
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}
