import React, { lazy } from 'react'
import costCentreDefinitionRoutes from '../GeneralSettingModule/CostCentreDefinition/CostCentreDefinitionRoutes'
import accountingPeriodRoutes from './AccountingPeriod/AccountingPeriodRoute'
import approvalPolicyAssignmentRoutes from './ApprovalPolicyAssignment/ApprovalPolicyAssignmentRoutes'
import bankAccountRoutes from './BankAccount/BankAccountRoutes'
import budgetSetupRoutes from './BudgetSetup/BudgetSetupRoutes'
import companyAuthorizationRoutes from './CompanyAuthorisation/CompanyAuthorisationRoutes'
import costCentreRoutes from './CostCentre/CostCentreRoutes'
import entityCOARoutes from './EntityCOA/EntityCOARoutes'
import GPApprovalPolicyRoutes from './GPApprovalPolicy/GPApprovalPolicyRoutes'
import GPDeliveryLocationRoutes from './GPDeliveriLocation/GPDeliveryLocationRoutes'
import journalTypeRoutes from './JournalType/JournalTypeRoutes'
import numberingStructureRoutes from './NumberingStructure/NumberingStructureRoutes'
import retainEarningAccountRoutes from './RetainEarningAccount/RetainEarningAccountRoutes'
import glCompanyPolicyRoutes from './GLCompanyPolicy/GLCompanyPolicyRoutes'
import apApprovalPolicyRoutes from './ApprovalPolicy/ApprovalPolicyRoutes'

const CompanyListing = lazy(() =>
  import('./CompanyListing').then(module => ({
    default: module.CompanyListing,
  }))
)

const CompanySettingListing = lazy(() =>
  import('./CompanySettingListing').then(module => ({
    default: module.CompanySettingListing,
  }))
)

const CompanySubmenu = lazy(() =>
  import('./CompanySubmenu').then(module => ({
    default: module.CompanySubmenu,
  }))
)

const GPCompanySubmenu = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/CompanySubmenu'
  ).then(module => ({
    default: module.CompanySubmenu,
  }))
)

const CompanyForm = lazy(() =>
  import('./CompanyForm').then(module => ({
    default: module.CompanyForm,
  }))
)

const CompanyProfile = lazy(() =>
  import('./CompanyProfile').then(module => ({
    default: module.CompanyProfile,
  }))
)

const CompanyExcelValidation = lazy(() =>
  import('./CompanyExcelValidation').then(module => ({
    default: module.CompanyExcelValidation,
  }))
)

const companyRoutes = [
  {
    props: { exact: true, path: '/general-ledger/company' },
    component: <CompanyListing mode="general-ledger" createAccess={false} />,
  },
  {
    props: { exact: true, path: '/cash-book/company' },
    component: <CompanyListing mode="cash-book" createAccess={false} />,
  },
  {
    props: { exact: true, path: '/inventory-control/company' },
    component: <CompanyListing mode="inventory-control" createAccess={false} />,
  },
  {
    props: { exact: true, path: '/account-payable/company' },
    component: <CompanyListing mode="account-payable" createAccess={false} />,
  },
  {
    props: { exact: true, path: '/account-receivable/company' },
    component: (
      <CompanyListing mode="account-receivable" createAccess={false} />
    ),
  },
  {
    props: { exact: true, path: '/general-purchase/company' },
    component: <CompanyListing mode="general-purchase" createAccess={false} />,
  },
  {
    props: { exact: true, path: '/fixed-assets/company' },
    component: <CompanyListing mode="fixed-assets" createAccess={false} />,
  },
  {
    props: { exact: true, path: '/general-ledger/:CompanyID/company-setting' },
    component: <CompanySettingListing mode="general-ledger" />,
  },
  {
    props: { exact: true, path: '/cash-book/:CompanyID/company-setting' },
    component: <CompanySettingListing mode="cash-book" />,
  },
  {
    props: {
      exact: true,
      path: '/inventory-control/:CompanyID/company-setting',
    },
    component: <CompanySettingListing mode="inventory-control" />,
  },
  {
    props: { exact: true, path: '/account-payable/:CompanyID/company-setting' },
    component: <CompanySettingListing mode="account-payable" />,
  },
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/company-setting',
    },
    component: <CompanySettingListing mode="account-receivable" />,
  },
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/company-setting',
    },
    component: <CompanySettingListing mode="fixed-assets" />,
  },
  {
    props: { exact: true, path: '/company/submenu/:CompanyID' },
    component: <CompanySubmenu />,
  },

  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/company-setting',
    },
    component: <GPCompanySubmenu mode="general-purchase" />,
  },

  //For Home's /CompanySetup > Company Profile
  {
    props: {
      exact: true,
      path: '/company-setup/:CompanyID/profile',
    },
    component: <CompanyProfile mode="company-setup" />,
  },
  {
    props: { exact: true, path: '/company-setup/add' },
    component: <CompanyForm mode="add" />,
  },
  {
    props: { exact: true, path: '/company-setup/:CompanyID/edit' },
    component: <CompanyForm mode="edit" />,
  },

  // Company Excel Import
  {
    props: { exact: true, path: '/company-setup/excel-validation' },
    component: <CompanyExcelValidation />,
  },
  ...numberingStructureRoutes,
  ...accountingPeriodRoutes,
  ...journalTypeRoutes,
  ...retainEarningAccountRoutes,
  ...glCompanyPolicyRoutes,
  ...budgetSetupRoutes,
  ...bankAccountRoutes,
  ...costCentreRoutes,
  ...costCentreDefinitionRoutes,
  ...entityCOARoutes,
  ...companyAuthorizationRoutes,
  ...approvalPolicyAssignmentRoutes,
  ...GPApprovalPolicyRoutes,
  ...GPDeliveryLocationRoutes,
  ...apApprovalPolicyRoutes,
]

export default companyRoutes
