import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons'
import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { AcctPermission, ApprovalStatus } from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtFloat, amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useParams } from 'react-router'

/* -------------------------------------------- */
/*            For ACTIVE and APPROVED           */
/* -------------------------------------------- */

export const AssetsRegistrationEntryList = (props: any) => {
  const {
    filteredList,
    listLoading,
    listStatus,
    userList,
    fetchMore,
    setOriginalListing,
  } = props

  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  let history = useHistory()
  const { CompanyID }: any = useParams()
  //search part -----------------------------------------

  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const { handlePermDisabled } = usePermissionChecker()
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const [deleteAA, setDeleteAA] = useState(false)

  return (
    <>
      {listLoading && <Loading />}

      <ContentWrapper advSearch float>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                fetchMore({
                  variables: {
                    CompanyID: CompanyID,
                    StatusArr: [listStatus],
                    take: 30,
                    skip: currentLength,
                    searchValue:
                      getSearch?.name === '' || getSearch?.name === undefined
                        ? undefined
                        : getSearch?.name,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                listLoading && (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {advancedFilterList(filteredList)?.map((el, index) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          {listStatus === 'COMPLETED' ? (
                            <>
                              <span className={`xsTitle flex-space`}>
                                {el?.Name}
                              </span>
                              <span className={`xsTitle`}>{el?.AssetNo}</span>
                            </>
                          ) : (
                            <>
                              <span
                                className="flex-space mdLabel"
                                style={{ fontSize: '12px' }}
                              >
                                {el?.Name}
                              </span>
                              <span
                                className={`xsTitle`}
                                style={{ color: 'orange' }}
                              >
                                {amtNumStr(el?.Amount)}
                              </span>
                            </>
                          )}
                        </>
                      }
                      secondary={
                        <>
                          <Grid container>
                            <Grid
                              spacing={1}
                              container
                              className="table-content"
                            >
                              <Grid item xs={6}>
                                <span className="desc flex-space">
                                  {el?.Category}
                                </span>
                              </Grid>
                              <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <span className="desc">
                                  {!!el?.RegistrationDate
                                    ? formatDate(el?.RegistrationDate)
                                    : ''}
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container>
                            <Grid
                              spacing={1}
                              container
                              className="table-content"
                            >
                              <Grid item xs={12}>
                                <span className="desc">{el?.Remark}</span>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      {listStatus === 'ACTIVE' || listStatus === 'REJECTED' ? (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            aria-controls="menu-list"
                            aria-haspopup="true"
                            onClick={e =>
                              handleClick(e, el?.AuditAdjustmentID, index, el)
                            }
                          >
                            <MoreVert />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton edge="end" aria-label="arrow">
                          <KeyboardArrowRight
                            onClick={() =>
                              history.push({
                                pathname: `/fixed-assets/${CompanyID}/assets-registration/${el?.AssetsRegistrationID}/detail`,
                                state: el,
                              })
                            }
                          />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </InfiniteScroll>
          )}
        </List>
        {listStatus === 'ACTIVE' && (
          <FloatButton
            onClick={() => {
              history.push(`/fixed-assets/${CompanyID}/assets-registration/add`)
            }}
          />
        )}
      </ContentWrapper>

      {listStatus === 'ACTIVE' ? (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.GeneralLedgerAuditAdjustmentUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/general-ledger/${CompanyID}/audit-adjustment/${menu?.obj?.AuditAdjustmentID}/edit`,
                state: menu?.obj,
              })
            }}
          >
            <span className="">Edit</span>
          </MenuItem>
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.GeneralLedgerAuditAdjustmentDelete,
            })}
            onClick={() => setDeleteAA(true)}
          >
            <span>Delete</span>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.GeneralLedgerAuditAdjustmentUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/general-ledger/${CompanyID}/audit-adjustment/${menu?.obj?.AuditAdjustmentID}/edit`,
                state: { ...menu?.obj, mode: 'resubmit' },
              })
            }}
          >
            <span className="">Resubmit</span>
          </MenuItem>
        </Menu>
      )}

      <DeleteDialog
        ID={menu?.ID}
        openDeleteDialog={deleteAA}
        setOpenDeleteDialog={setDeleteAA}
        title={'Audit Adjustment'}
        menu={menu?.obj}
        // handleDelete={handleDelete}
        passID={menu?.ID}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={
          'You are not allowed to delete this as there are transactions executed already'
        }
      />
    </>
  )
}
