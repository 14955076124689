import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  useDocumentListingQuery,
  useGetLeaseContractLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePostLeaseContractMutation,
  useRejectLeaseContractMutation,
  UserSelection,
} from 'generated/graphql'
import { dateFormat, formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { LeaseContractDetailContent } from '../LeaseContractDetail/LeaseContractDetailContent'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { RejectDialog } from 'components/Dialog/RejectDialog'

export const LeaseContractDetail = props => {
  let history = useHistory()
  let location = useLocation()
  const listData = location?.state as any
  const { mode } = props
  const { CompanyID, LeaseContractID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [rejectDialogInput, setRejectDialogInput] = useState({
    RejectionRemark: '',
  })
  const [clickReject, setIsReject] = useState(false)

  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()
  const {
    loading: userLoading,
    error: userError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: { superUserBool: UserSelection.All },
  })

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    loading: docLoading,
    error: docError,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: { refID: LeaseContractID },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  const [
    postLeaseContract,
    {
      loading: postLeaseContractLoading,
      called: postLeaseContractCalled,
      error: postLeaseContractError,
    },
  ] = usePostLeaseContractMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setOriginalListing([])
      setTimeout(() => {
        history.push({
          pathname: `/mfrs16/${CompanyID}/lease-contract`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })
  const [
    rejectLeaseContract,
    {
      loading: rejectLeaseContracLoading,
      called: rejectLeaseContracCalled,
      error: rejectLeaseContracError,
    },
  ] = useRejectLeaseContractMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setOriginalListing([])
      setTimeout(() => {
        history.push({
          pathname: `/mfrs16/${CompanyID}/lease-contract`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const onSubmit = (listPostID, status) => {
    if (status === 'approve') {
      postLeaseContract({
        variables: {
          LeaseContractID: listPostID,
        },
      })
    }
  }

  useEffect(() => {
    if (clickReject) {
      rejectLeaseContract({
        variables: {
          LeaseContractID: LeaseContractID,
          input: rejectDialogInput,
        },
      })
    }
  }, [clickReject])

  return (
    <>
      {docLoading && <Loading />}
      {postLeaseContractLoading && <Loading />}
      {userLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/mfrs16/${CompanyID}/lease-contract`)}
        smTitle={smTitle.ACCOUNT_PAYABLE}
        title={user?.companyName}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Accounts Payable' },
          { name: `Lease Contract`, current: true },
        ]}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle c-orange" style={{ color: '#ff9800' }}>
              {listData?.ContractNo}
            </span>
          </>
        }
        rightText={
          <span className="xsTitle">{amtStr(listData?.LeasePayment)}</span>
        }
        infoLine={
          <>
            <span className={'flex-space'} style={{ fontSize: '10px' }}>
              {`${dateFormat(listData?.StartDate)} - ${dateFormat(
                listData?.EndDate
              )} ${listData?.LeaseMonth} Month`}
            </span>
          </>
        }
        rightInfoLine={
          <span className="xsTitle flex-space">
            {amtStr(listData?.TotalLeaseAmount)}
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <CardContents>
          <LeaseContractDetailContent
            listEl={listData}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>
      </ContentWrapper>

      {mode === 'post' && (
        <>
          <AccountFooter
            options={[
              {
                name: 'Reject',
                onClick: () => {
                  setOpenRejectDialog(true)
                },
                color: 'secondary',
                props: {},
              },
              {
                name: 'Approve',
                onClick: () => {
                  onSubmit(LeaseContractID, 'approve')
                },
                color: 'primary',
                props: {},
              },
            ]}
          />
          <ErrorDialog
            errorDia={errorDia}
            setErrorDia={setErrorDia}
            errorMsg={errMsg}
            errorHeaderMsg={'Error!'}
          />
          <RejectDialog
            data={filteredList}
            openRejectDialog={openRejectDialog}
            setOpenRejectDialog={setOpenRejectDialog}
            setRejectDialogInput={setRejectDialogInput}
            setIsReject={setIsReject}
          />
        </>
      )}
    </>
  )
}
