import ApprovedIcon from '@ifca-root/react-component/src/assets/icons/approved-check.svg'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import StandardTabs from '@ifca-root/react-component/src/components/Tabs/StandardTabs'
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log-orange.svg'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import { CopyrightFooter } from 'components/Footer/Copyright'
import Fuse from 'fuse.js'
import {
  ApprovalStatus,
  useDocumentListingQuery,
  useGetHomeSummaryQuery,
  useGetStockItemQuery,
  useGetStockLedgerQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import {
  amtFloat,
  amtNumStr,
  amtStr,
  amtStr4Dec,
} from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const StockEnquiryListing3 = (props: any) => {
  const { mode }: any = props
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let history = useHistory()
  const { StockItemID, WarehouseID }: any = useParams()
  const { CompanyID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const [warehouseData, setWarehouseData] = useState([])
  const [value, setVal] = useState(0)
  const getSearch = localStorage?.getItem('searchFilter')
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  // QUERY //

  const {
    loading,
    called,
    data: {
      loggedInUserProfile,
      getAccountPermission,
      getCompany: curCompany,
      getRolePermission,
    } = {
      loggedInUserProfile: null,
      getAccountPermission: [],
      getCompany: [],
      getRolePermission: [],
    },
  } = useGetHomeSummaryQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID,
    },
  })

  const {
    loading: StockItemLoading,
    error: StockItemError,
    data: { getStockItem } = {
      getStockItem: [],
    },
  } = useGetStockItemQuery({
    fetchPolicy: 'network-only',
    variables: {
      StockItemID: StockItemID,
    },
  })

  const {
    loading: StockLedgerLoading,
    error: StockLedgerError,
    data: { getStockLedger } = {
      getStockLedger: [],
    },
  } = useGetStockLedgerQuery({
    fetchPolicy: 'network-only',
    variables: {
      StockItemID: StockItemID,
      WarehouseID: WarehouseID,
    },
  })

  const {
    loading: documentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: StockItemID,
    },
  })

  //total qty
  const AllQty = val => {
    let status
    if (val === 0) {
      status = ApprovalStatus.Completed
    } else {
      status = ApprovalStatus.Submit
    }

    return getStockLedger
      ?.filter(x => x?.ApprovalStatus === status)
      ?.reduce((prevValue, currValue) => {
        return prevValue + Number(currValue?.qty)
      }, 0)
      .toFixed(4)
  }

  const AllTotalCost = val => {
    let status
    if (val === 0) {
      status = ApprovalStatus.Completed
    } else {
      status = ApprovalStatus.Submit
    }

    return getStockLedger
      ?.filter(x => x?.ApprovalStatus === status)
      ?.reduce((prevValue, currValue) => {
        return prevValue + Number(currValue?.totalcost)
      }, 0)
      .toFixed(2)
  }

  useEffect(() => {
    if (getStockLedger && getStockLedger?.length > 0) {
      let listSortDocDate: any = []

      getStockLedger
        ?.sort((a, b) => {
          return a.DocDate.localeCompare(b.DocDate)
        })
        ?.map(x => {
          if (!listSortDocDate.includes(x?.DocDate)) {
            listSortDocDate.push(x?.DocDate)
          }
        })

      let sortByCTS: any = []

      listSortDocDate?.map(el => {
        const filterNSort = getStockLedger
          ?.filter(m => m?.DocDate === el)
          ?.sort((a, b) => {
            return a.createdTs.localeCompare(b.createdTs)
          })
          ?.map(p => {
            sortByCTS.push(p)
          })
      })

      setOriginalListing(sortByCTS)
    }
  }, [getStockLedger])

  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = ['type', 'Description', 'qty']
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getSearch)
      const val = result?.map(x => x.item)
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys)
      }
    }
  }, [getSearch, originalList])

  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)

  const listing = status => (
    <ContentWrapper footer>
      <List className="core-list">
        {filteredList?.filter(x => x?.ApprovalStatus === status) ===
          undefined ||
        filteredList?.filter(x => x?.ApprovalStatus === status)?.length ===
          0 ? (
          <EmptyList title="No Record found" subtitle="Add a new record now." />
        ) : (
          filteredList
            ?.filter(x => x?.ApprovalStatus === status)
            ?.map((el, index) => {
              return (
                <ListItem
                // onClick={e => handleClick(e, el.StockItemID, index, el)}
                >
                  <ListItemText
                    primary={
                      <>
                        <span
                          style={{
                            fontSize: '11px',
                          }}
                        >
                          {formatDate(el.DocDate)}&nbsp;{' '}
                        </span>
                        <span className="xsTitle flex-space">
                          &nbsp;{el.DocNo ?? el?.RefNo}
                        </span>

                        <span
                          className="xsTitle"
                          style={{
                            paddingLeft: '2px',
                            fontSize: '12px',
                            color:
                              el?.type === 'Cost Adjustment' &&
                              el?.totalcost > 0
                                ? '#13BF00'
                                : el?.type === 'Cost Adjustment' &&
                                  el?.totalcost < 0
                                ? '#F70E0E'
                                : el?.type === 'Cost Adjustment' &&
                                  el?.totalcost == 0
                                ? 'black'
                                : el?.qty > 0
                                ? '#13BF00'
                                : el?.qty == 0
                                ? 'black'
                                : '#F70E0E',

                            //verticalAlign: '-13px'
                            //paddingTop: '13px'
                            //width: '10px'
                          }}
                        >
                          <b>
                            {el?.qty < 0
                              ? amtStr4Dec(el?.qty).substring(1)
                              : amtStr4Dec(el?.qty)}{' '}
                            {getStockItem[0]?.UOM?.Name}
                          </b>
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        <div style={{ display: 'block', width: '100%' }}>
                          <div style={{ display: 'flex' }}>
                            <span>
                              <GreyTooltip
                                disableFocusListener
                                title={
                                  <React.Fragment>
                                    <Grid container wrap="nowrap" spacing={2}>
                                      <Grid item>
                                        <Avatar
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                        >
                                          <PersonIcon
                                            style={{
                                              fontSize: 'small',
                                            }}
                                          />
                                        </Avatar>
                                      </Grid>
                                      <Grid item xs zeroMinWidth>
                                        <div style={{ fontSize: '12px' }}>
                                          {el?.name}
                                        </div>
                                        <div style={{ fontSize: '10px' }}>
                                          {formatDate(
                                            new Date(
                                              el?.createdTs
                                            ).toISOString()
                                          )}
                                          &nbsp;
                                          {formatTime(
                                            new Date(
                                              el?.createdTs
                                            ).toISOString()
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                }
                                arrow
                                enterTouchDelay={0}
                              >
                                <img
                                  src={SubmitterLog}
                                  style={{
                                    width: '12px',
                                    marginRight: '3px',
                                    color: 'orange',
                                  }}
                                />
                              </GreyTooltip>
                            </span>
                            <span
                              className="desc flex-space"
                              style={{
                                paddingTop: '3px',
                              }}
                            >
                              {el?.Description}
                            </span>

                            <span
                              className="xsTitle"
                              style={{
                                paddingLeft: '2px',
                                fontSize: '12px',
                                display: 'contents',
                                float: 'right',
                                color:
                                  el?.type === 'Cost Adjustment' &&
                                  el?.totalcost > 0
                                    ? '#13BF00'
                                    : el?.type === 'Cost Adjustment' &&
                                      el?.totalcost < 0
                                    ? '#F70E0E'
                                    : el?.type === 'Cost Adjustment' &&
                                      el?.totalcost === 0.0
                                    ? 'black'
                                    : el?.qty > 0
                                    ? '#13BF00'
                                    : el?.qty == 0
                                    ? 'black'
                                    : '#F70E0E',

                                //verticalAlign: '-13px'
                                //paddingTop: '13px'
                                //width: '10px'
                              }}
                            >
                              <b>
                                {el?.unitcost < 0
                                  ? amtStr4Dec(el?.unitcost).substring(1)
                                  : amtStr4Dec(el?.unitcost)}
                              </b>
                            </span>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <span
                              className="desc flex-space"
                              style={{
                                paddingTop: '3px',
                              }}
                            >
                              {el?.type}
                            </span>
                            <span
                              className="xsTitle"
                              style={{
                                paddingLeft: '2px',
                                fontSize: '12px',
                                display: 'contents',
                                float: 'right',
                                color:
                                  el?.type === 'Cost Adjustment' &&
                                  el?.totalcost > 0
                                    ? '#13BF00'
                                    : el?.type === 'Cost Adjustment' &&
                                      el?.totalcost < 0
                                    ? '#F70E0E'
                                    : el?.type === 'Cost Adjustment' &&
                                      el?.totalcost === 0.0
                                    ? 'black'
                                    : el?.qty > 0
                                    ? '#13BF00'
                                    : el?.qty == 0
                                    ? 'black'
                                    : '#F70E0E',

                                //verticalAlign: '-13px'
                                //paddingTop: '13px'
                                //width: '10px'
                              }}
                            >
                              <b>
                                {el?.totalcost < 0
                                  ? amtStr(el?.totalcost).substring(1)
                                  : amtStr(el?.totalcost)}
                              </b>
                            </span>
                          </div>
                        </div>
                      </>
                    }
                  />
                </ListItem>
              )
            })
        )}
      </List>
    </ContentWrapper>
  )

  const handleChange = (event, newValue) => {
    setVal(newValue)
  }

  const dataLength =
    value === 0
      ? advancedFilterList(
          filteredList?.filter(
            x => x?.ApprovalStatus === ApprovalStatus.Completed
          )
        )?.length
      : advancedFilterList(
          filteredList?.filter(x => x?.ApprovalStatus === ApprovalStatus.Submit)
        )?.length

  return (
    <>
      {loading && <Loading />}
      {StockItemLoading && <Loading />}
      {documentLoading && <Loading />}
      {StockLedgerLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push({
            pathname: `/inventory-control/${WarehouseID}/stock-enquiry`,
          })
        }} //<- back button action
        smTitle={'Inventory Control'}
        title={user?.warehouseName}
        //currency={`MYR`}
        routeSegments={[
          { name: 'IC' },
          { name: 'Stock Enquiry' },
          { name: 'Stock Enquiry' },
          { name: 'Stock Ledger', current: true },
        ]}
        //rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />

      <DynamicSubHeader
        title={
          <>
            <span style={{ paddingTop: '-10px' }}>{getStockItem[0]?.Name}</span>
          </>
        }
        infoLine={
          <div style={{ display: 'flex' }}>
            <span className="flex-space">
              <b>{getStockItem[0]?.StockCategory?.Name}</b>
            </span>
            <span>
              <b>{amtStr(AllTotalCost(value))}</b>
            </span>
          </div>
        }
        rightText={
          <>
            <span className="xsTitle c-orange">
              {amtStr4Dec(AllQty(value))}
            </span>
            <span>
              {' '}
              <b>{getStockItem[0]?.UOM?.Name}</b>
            </span>
          </>
        }
        //style={{paddingBottom: '100px'}}
      />
      <SearchHeader
        title="Stock Ledger"
        value={`${dataLength}`}
        multiDynamicInfo
        search
        onChangeAction={e => {
          handleSearch(e?.target?.value, [
            'DocNo',
            'type',
            'Description',
            'qty',
          ])
          localStorage.setItem('searchFilter', e.target.value)
        }}
        defaultValue={getSearch ? getSearch : ''}
        isDefaultValue={!!getSearch}
        onCloseAction={() => handleSearch('', [])}
        fixed
        option={{
          // icon: <Tune />,
          onClick: () => {},
          // history.push(
          // //   `/cash-book/${WarehouseID}/submenu/${BankAccountID}/receipt/filter`
          // ),
        }}
      />

      <StandardTabs
        value={value}
        onChange={handleChange}
        responsive
        subHeader
        multiDynamicInfoSearch
        fullWidth
        tabs={[
          {
            label: 'Approved',
            content: !StockLedgerLoading && listing(ApprovalStatus.Completed),
          },
          {
            label: 'Submitted',
            content: !StockLedgerLoading && listing(ApprovalStatus.Submit),
          },
        ]}
      />
    </>
  )
}
// export const StockEnquiryListing3 = () => {
//   return (
//     <div>StockEnquiryListing3</div>
//   )
// }
