import { yupResolver } from '@hookform/resolvers'
import Empty from '@ifca-root/react-component/src/assets/hrIcons/no-records.svg'
import theme from '@ifca-root/react-component/src/assets/theme'
import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import { MainHeader } from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetApprovalPolicyAssignmentApDocument,
  useCreateApprovalPolicyAssignmentMutation,
  useGetApprovalPolicyAssignmentApLazyQuery,
  useGetCompanySummaryQuery,
  useGetRoleQuery,
  useUpdateApprovalPolicyAssignmentMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'

export interface ApprovalPolicyProps {
  approvalList: any[]
}

const styles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },

  item: {
    padding: '0',
    paddingRight: '10px',
    borderBottom: '2px solid rgb(224,224,224)',
  },
  lastItem: {
    padding: '0',
    paddingRight: '10px',
  },
  deleteIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  list: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '80%',
      margin: 'Auto',
    },
  },
}))

export const ApprovalPolicyAsgmtRoleForm = (props: any) => {
  let history = useHistory()
  let location = useLocation()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { docType, CompanyID }: any = useParams()
  const { module, mode, budgetType }: any = props
  const classesVaria = styles()
  const editData = location?.state as any
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  let approvalCategory, refTable
  switch (docType) {
    case 'budget':
      approvalCategory =
        budgetType === 'original' ? 'Original Budget' : 'Budget Revision'
      refTable =
        budgetType === 'original' ? 'GL_OriginalBudget' : 'GL_BudgetRevision'
      break
    case 'recurring-journal':
      approvalCategory = 'Recurring Journal'
      refTable = 'GL_RecurringJournal'
      break
    case 'cancel-recurring-journal':
      approvalCategory = 'Cancel Recurring Journal'
      refTable = 'GL_CancelRecurringJournal'
      break
    case 'journal-processing':
      approvalCategory = 'Journal Processing'
      refTable = 'GL_JournalProcessing'
      break
    case 'audit-adjustment':
      approvalCategory = 'Audit Adjustment'
      refTable = 'GL_AuditAdjustment'
      break
    case 'payment':
      approvalCategory = 'Payment'
      refTable = 'CB_Payment'
      break
    case 'receipt':
      approvalCategory = 'Receipt'
      refTable = 'CB_Receipt'
      break
    case 'bank-transfer':
      approvalCategory = 'Bank Transfer'
      refTable = 'CB_BankTransfer'
      break
    case 'invoice':
      approvalCategory = 'Invoices'
      refTable = module === 'account-payable' ? 'AP_Invoice' : 'AR_Invoice'
      break
    case 'credit-note':
      approvalCategory = 'Credit Note'
      refTable =
        module === 'account-payable' ? 'AP_CreditNote' : 'AR_CreditNote'
      break
    case 'debit-note':
      approvalCategory = 'Debit Note'
      refTable = module === 'account-payable' ? 'AP_DebitNote' : 'AR_DebitNote'
      break
    case 'official-receipt':
      approvalCategory = 'Official Receipt'
      refTable =
        module === 'account-payable'
          ? 'AP_OfficialReceipt'
          : 'AR_OfficialReceipt'
      break
    case 'advance':
      approvalCategory = 'Advance'
      refTable = module === 'account-payable' ? 'AP_Advance' : 'AR_Advance'
      break
    case 'refund':
      approvalCategory = 'Refund'
      refTable = module === 'account-payable' ? 'AP_Refund' : 'AR_Refund'
      break
  }

  let assignmentRoute, smTitle
  switch (module) {
    case 'general-ledger':
      assignmentRoute = 'approval-policy-asgmt-gl'
      smTitle = 'General Ledger'
      break
    case 'general-ledger':
      assignmentRoute = 'approval-policy-asgmt-gl'
      smTitle = 'General Ledger'
      break
    case 'cash-book':
      assignmentRoute = 'approval-policy-asgmt-cb'
      smTitle = 'Cash Book'
      break
    case 'account-payable':
      assignmentRoute = 'approval-policy-asgmt-ap'
      smTitle = 'Accounts Payable'
      break
    case 'account-receivable':
      assignmentRoute = 'approval-policy-asgmt-ar'
      smTitle = 'Accounts Receivable'
      break
  }

  const formValidation = yup.object().shape({
    // title: CommonYupValidation?.requireField(SystemMsgs?.title()).nullable(),
    approvalList: yup.array().of(
      yup.object().shape({
        roleID: CommonYupValidation?.requireField(
          SystemMsgs?.role()
        ).nullable(),
      })
    ),
  })

  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [IsInputExist, setIsInputExist] = useState(false)
  const [IsUpdate, setIsUpdate] = useState(mode === 'add' ? false : true)
  const [openExitDialog, setopenExitDialog] = useState(false)

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    setError,
    getValues,
    reset,
    formState,
    clearErrors,
    watch,
  } = useForm<ApprovalPolicyProps>({
    shouldUnregister: false, // unmount input state will be remained
    resolver: yupResolver(formValidation),
  })

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'approvalList',
  })

  //CREATE APPROVAL POLICY
  const [
    createApprovalPolicyAssignment,
    {
      loading: createApprovalPolicyAssignmentLoading,
      error: createApprovalPolicyAssignmentError,
    },
  ] = useCreateApprovalPolicyAssignmentMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/${module}/${CompanyID}/company-setting/${assignmentRoute}`,
        })
      })
    },
  })
  //UPDATE APPROVAL POLICY
  const [
    updateApprovalPolicyAssignment,
    {
      loading: updateApprovalPolicyAssignmentLoading,
      error: updateApprovalPolicyAssignmentError,
    },
  ] = useUpdateApprovalPolicyAssignmentMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        history.push({
          pathname: `/${module}/${CompanyID}/company-setting/${assignmentRoute}`,
        })
      })
    },
  })

  const {
    loading: companyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanySummaryQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const [
    loadApprovalPolicyAssignment,
    {
      loading: approvalPolicyAssignmentLoading,
      error: approvalPolicyAssignmentError,
      data: { getApprovalPolicyAssignmentAP } = {
        getApprovalPolicyAssignmentAP: {} as any,
      },
    },
  ] = useGetApprovalPolicyAssignmentApLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getApprovalPolicyAssignmentAP }) => {
      let approvalPolicyList = getApprovalPolicyAssignmentAP[0]?.ApprovalList
      if (approvalPolicyList?.length > 0) {
        let arr = []
        for (const v of approvalPolicyList) {
          arr.push({
            stepNo: v?.StepNo,
            roleID: v?.RoleID,
            roleName: v?.RoleName,
          })
        }
        approvalPolicyList?.map((x, index) => {
          register(`aprrovalList[${index}].roleID`)
          setValue(`aprrovalList[${index}].roleID`, x?.RoleID)
          return x
        })
        append(arr)
      }
    },
  })

  useEffect(() => {
    loadApprovalPolicyAssignment({
      variables: {
        Category: refTable,
        CompanyID: CompanyID,
      },
    })
  }, [])

  const {
    data: { getRole } = { getRole: [] },
    loading,
    error,
  } = useGetRoleQuery({
    fetchPolicy: 'network-only',
  })

  const handleMutation = !getApprovalPolicyAssignmentAP?.ApprovalPolicyAssignmentID
    ? createApprovalPolicyAssignment
    : updateApprovalPolicyAssignment

  const onSubmit = data => {
    const approvalList = data?.approvalList
    let isCanSubmit: any

    // if (isCanSubmit !== false) {
    handleMutation({
      variables: {
        input: {
          ...(getApprovalPolicyAssignmentAP?.ApprovalPolicyAssignmentID
            ? {
                ApprovalPolicyAssignmentID:
                  getApprovalPolicyAssignmentAP?.ApprovalPolicyAssignmentID,
              }
            : null),
          Category: refTable,
          ApprovalList: approvalList?.map((x, index) => {
            return {
              Category: refTable,
              StepNo: index + 1,
              RoleID: x?.roleID,
              RoleName: getRole?.filter(role => role?.ID === x?.roleID)[0]
                ?.name,
            }
          }),
        },
      },
      refetchQueries: [
        {
          query: GetApprovalPolicyAssignmentApDocument,
          variables: {
            Category: refTable,
            CompanyID: CompanyID,
          },
        },
      ],
    })
    // }
  }

  const checkRemainingRole = index => {
    let selectedRole = []
    if (watch(`approvalList[${0}].roleID`) !== undefined) {
      for (var i = index - 1; i >= 0; i--) {
        selectedRole.push(watch(`approvalList[${i}].roleID`))
      }
    }
    return getRole?.filter(x => !selectedRole.includes(x?.ID))
  }

  return (
    <>
      {loading && <Loading />}
      {companyLoading && <Loading />}
      {approvalPolicyAssignmentLoading && <Loading />}
      {createApprovalPolicyAssignmentLoading && <Loading />}
      {updateApprovalPolicyAssignmentLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          history.push(
            `/${module}/${CompanyID}/company-setting/${assignmentRoute}`
          )
        }}
        smTitle={`${smTitle}`}
        title={user?.accountName}
        routeSegments={[
          // { name: '...' },
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Submenu' },
          { name: 'Standard Approval Flow', current: true },
        ]}
        rightRouteSegments={[
          { name: mode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />

      <DynamicSubHeader
        title={`${getCompany[0]?.Name}`}
        infoLine={
          <span style={{ fontWeight: 'bold' }}>{approvalCategory}</span>
        }
      />

      <ContentWrapper singleDynamicInfo footer>
        <CardContents
          style={{ marginTop: '20px' }}
          section={{
            header: {
              title: 'Approval List',
              onClickAction: () => {
                append({
                  stepNo: fields?.length + 1,
                  roleID: '',
                  roleName: '',
                })
              },
              icon: (
                <AddIcon
                  htmlColor="white"
                  fontSize="default"
                  style={{
                    width: '1.2rem',
                    height: '1.2rem',
                    margin: '10px 5px 0px 0px',
                    background: theme.palette.primary.main,
                    boxShadow: `0px 2px 5px 0px ${theme.palette.primary.main}`,
                    borderRadius: '3px',
                    color: 'rgba(224,234,254,100)',
                  }}
                />
              ),
            },
          }}
        >
          {(!approvalPolicyAssignmentLoading && fields.length == undefined) ||
          fields.length == 0 ||
          fields.length == 0 ? (
            <>
              <List className="avatar-rounding">
                <ListItem>
                  <IconItem
                    image={`${Empty}`}
                    imageType="svg"
                    badgeType="default"
                  />

                  <ListItemText
                    primary={
                      <>
                        <span className="desc  ">
                          <span className="xsTitle">No Records</span>
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        <span className="desc subheader-set wrap">
                          <span>{`Click add button to create a format.`} </span>
                        </span>
                      </>
                    }
                  />
                </ListItem>
              </List>
            </>
          ) : (
            <>
              <div className="rm-padding">
                <List
                  style={{ width: '100%', paddingBottom: '0px' }}
                  className={classesVaria.list}
                >
                  {fields?.map((x, index) => {
                    const approvalList = `approvalList[${index}]`
                    return (
                      <>
                        <div key={x?.id}>
                          <ListItem
                            key={`${approvalList}_ListItem`}
                            role={undefined}
                            classes={{
                              root:
                                index !== fields?.length - 1
                                  ? classesVaria.item
                                  : classesVaria.lastItem,
                            }}
                          >
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  textAlign: 'center',
                                  paddingTop: '15px',
                                }}
                              >{`${+index + 1}.`}</Grid>
                              <Grid container item xs={10}>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingRight: '10px' }}
                                >
                                  <Controller
                                    ref={register}
                                    name={`${approvalList}.roleID`}
                                    required
                                    control={control}
                                    defaultValue={x?.roleID}
                                    style={{
                                      marginTop: '5px',
                                    }}
                                    render={({ onChange, onBlur, value }) => (
                                      <TextField
                                        select
                                        variant="outlined"
                                        className="round-input"
                                        name={`${approvalList}.roleID`}
                                        label={`Level ${index + 1} Approval`}
                                        required
                                        margin="dense"
                                        helperText={
                                          errors?.approvalList?.[index]?.roleID
                                            ?.message
                                        }
                                        error={
                                          errors?.approvalList?.[index]?.roleID
                                            ? true
                                            : false
                                        }
                                        onChange={e => {
                                          onChange(
                                            e.target.value,
                                            register(`${approvalList}.roleID`),
                                            setValue(
                                              `${approvalList[index]}.roleID`,
                                              e.target.value
                                            )
                                          )
                                        }}
                                        value={value}
                                        disabled={
                                          index !== 0 &&
                                          watch(
                                            `approvalList[${index - 1}].roleID`
                                          ) === ''
                                        }
                                      >
                                        {checkRemainingRole(index).map(role => (
                                          <MenuItem
                                            key={role?.ID}
                                            value={role?.ID}
                                          >
                                            {role?.name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                className={classesVaria.deleteIcon}
                                style={{ justifyContent: 'center' }}
                              >
                                <IconButton
                                  className="delete-icon"
                                  onClick={() => {
                                    remove(index)
                                  }}
                                  style={{ scale: 0.7 }}
                                  question-uid={`${approvalList}.uid`}
                                >
                                  <Delete
                                    htmlColor="white"
                                    fontSize="default"
                                    style={{
                                      width: '1.4rem',
                                      height: '1.4rem',
                                      margin: '0',
                                      background: theme.palette.error.main,
                                      boxShadow: `0px 2px 5px 0px ${theme.palette.error.main}`,
                                      borderRadius: '3px',
                                      color: 'white',
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>

                            <ListItemSecondaryAction></ListItemSecondaryAction>
                          </ListItem>
                        </div>
                      </>
                    )
                  })}
                </List>
              </div>
            </>
          )}
        </CardContents>
      </ContentWrapper>
      {fields?.length > 0 && (
        <Footer
          copyright
          options={[
            {
              name: 'Save',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      )}
    </>
  )
}
