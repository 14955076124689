import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { dateFormat } from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate'
import { LeaseContractSubheader } from 'containers/MFRS16Module/LeaseContract/LeaseContractSubheader'
import { useGetLeaseContractQuery } from 'generated/graphql'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { MFRS16JournalTabs } from './MFRS16JournalTabs'

export const MFRS16JournalForm = (props: any) => {
  //=====================================
  //==============VARIABLES==============
  //=====================================
  let history = useHistory()
  const { CompanyID, LeaseContractID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const routeSegments = [
    { name: 'MFRS 16' },
    { name: 'Generate MFRS 16 Journal' },
    { name: 'Generate MFRS 16 Journal', current: true },
  ]
  const [contractNo, setContractNo] = useState('')
  const [leasePayment, setLeasePayment] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [leaseMonth, setLeaseMonth] = useState(0)
  const [totalLeaseAmount, setTotalLeaseAmount] = useState('')

  //=====================================
  //================QUERY================
  //=====================================
  const {
    loading: LeaseContractLoading,
    data: { getLeaseContract } = { getLeaseContract: [] },
  } = useGetLeaseContractQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, LeaseContractID },
    onCompleted: ({ getLeaseContract }) => {
      setContractNo(getLeaseContract[0]?.ContractNo)
      setLeasePayment(amtStr(getLeaseContract[0]?.LeasePayment))
      setStartDate(dateFormat(getLeaseContract[0]?.StartDate))
      setEndDate(dateFormat(getLeaseContract[0]?.EndDate))
      setLeaseMonth(getLeaseContract[0]?.LeaseMonth)
      setTotalLeaseAmount(amtStr(getLeaseContract[0]?.TotalLeaseAmount))
    },
  })

  // DUMMY DATA
  const dummyData = [
    {
      ID: '5cc81b69-5a47-4727-bd7a-a7b82f5d8d08',
      Year: '2023',
      Month: '0',
      Value: 700000,
      Status: 'Approved',
    },
    {
      ID: '41bca8b9-f32f-458e-bd96-ccb00dbbe9cd',
      Year: '2023',
      Month: '1',
      Value: 700000,
      Status: 'Generated',
    },
    {
      ID: 'b028b324-9ca1-4591-b178-819b0072a53e',
      Year: '2023',
      Month: '2',
      Value: 700000,
      Status: 'Pending',
    },
    {
      ID: 'b6ac4017-195b-4441-a6c7-4427591b8923',
      Year: '2023',
      Month: '3',
      Value: 700000,
      Status: 'Pending',
    },
    {
      ID: '4dd1658d-1c44-4bc8-b1e2-a663f0e8a270',
      Year: '2023',
      Month: '4',
      Value: 700000,
      Status: 'Pending',
    },
    {
      ID: 'f3d1d388-257e-403c-8647-9331f970702a',
      Year: '2023',
      Month: '5',
      Value: 700000,
      Status: 'Pending',
    },
    {
      ID: '4eba733b-9cc7-4e59-94ff-0ffd897ca99e',
      Year: '2023',
      Month: '6',
      Value: 700000,
      Status: 'Pending',
    },
    {
      ID: '129bb82e-7b89-49df-9ce0-cd5ff9df8b2f',
      Year: '2023',
      Month: '7',
      Value: 700000,
      Status: 'Pending',
    },
    {
      ID: 'd44a056d-35dc-469f-af22-d6e63df8d9c3',
      Year: '2023',
      Month: '8',
      Value: 700000,
      Status: 'Pending',
    },
    {
      ID: '5e55aaa8-d089-4b55-9491-2f3a7c82064b',
      Year: '2023',
      Month: '9',
      Value: 700000,
      Status: 'Pending',
    },
    {
      ID: '50cfa401-e87b-444c-9bb2-63a0127cee27',
      Year: '2023',
      Month: '10',
      Value: 700000,
      Status: 'Pending',
    },
    {
      ID: '94454fc3-c09c-4e0d-a201-f9fdbe27f120',
      Year: '2023',
      Month: '11',
      Value: 700000,
      Status: 'Pending',
    },
  ]

  return (
    <>
      {LeaseContractLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/mfrs16/${CompanyID}/generate-mfrs16-journal`)
        }}
        smTitle={'MFRS 16'}
        title={user?.companyName}
        routeSegments={routeSegments}
        rightRouteSegments={[{ name: 'View', current: true }]}
      />
      <LeaseContractSubheader
        getLeaseContract={getLeaseContract}
        contractNo={contractNo}
        leasePayment={leasePayment}
        startDate={startDate}
        endDate={endDate}
        leaseMonth={leaseMonth}
        totalLeaseAmount={totalLeaseAmount}
      />
      {/* KIV for API integration */}
      <MFRS16JournalTabs data={dummyData} />
    </>
  )
}
