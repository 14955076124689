import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import {
  useGetUsersByAccountAndSoftwareQuery,
  UserSelection,
} from 'generated/graphql'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { ARScheduledBillingDetailContent } from './ARScheduledBillingDetailContent'

export const ARSchedullingBillingDetail = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const listData = location?.state as any

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  /* -------------------------------------------- */
  /*                     CONSTANT                 */
  /* -------------------------------------------- */

  return (
    <>
      {getUsersByAccountAndSoftwareLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/account-receivable/${CompanyID}/scheduled-billing`)
        }}
        smTitle="Accounts Receivable"
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Accounts Receivable' },
          { name: 'Scheduled Billing', current: true },
        ]}
        rightRouteSegments={[{ name: `Approved`, current: true }]}
      />

      <DynamicSubHeader
        title={
          <span className="c-orange">
            <span style={{ fontWeight: 'bold' }}>
              {listData?.DebtorAccount?.DebtorName}
            </span>
          </span>
        }
        infoLine={
          <div
            className="text-overflow"
            style={{ fontSize: '11px', fontWeight: 'bold', width: '270px' }}
          >
            {listData?.BillingAmt}
          </div>
        }
      />
      <ContentWrapper multiDynamicInfo float footer>
        <CardContents>
          <ARScheduledBillingDetailContent
            listEl={listData}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>
      </ContentWrapper>

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
