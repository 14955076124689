import {
  AcctPermission,
  useGetArAdvancebyStatusListingLazyQuery,
  useGetArCreditNotebyStatusListingLazyQuery,
  useGetArDebitNotebyStatusListingLazyQuery,
  useGetArInvoicebyStatusListingLazyQuery,
  useGetArOfficialReceiptbyStatusListingLazyQuery,
  useGetArRefundbyStatusListingLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useState } from 'react'

export const useARListingData: any = ({
  accountType,
  arSubmenu,
  CompanyID,
  setOriginalListing,
  filteredList,
  setErrorDia,
  setErrMsg,
}) => {
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  let listStatus = getSearch?.option ?? 'ACTIVE'

  const [TotalLength, setTotalLength] = useState(0)

  //----------AR Queries----------//
  const {
    loading: getUsersLoading,
    error: getUsersError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    loadArAdvance,
    {
      loading: ArAdvancebyStatusLoading,
      called: ArAdvanceCalled,
      data: { getARAdvancebyStatusListing } = {
        getARAdvancebyStatusListing: [],
      },
    },
  ] = useGetArAdvancebyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARAdvancebyStatusListing }) => {
      if (getARAdvancebyStatusListing?.Data?.length > 0) {
        setOriginalListing(
          [...filteredList, ...getARAdvancebyStatusListing?.Data],
          v => {
            v['DocDateFormatted'] = formatDate(v.DocDate)
          }
        )
        setTotalLength(getARAdvancebyStatusListing?.TotalLength)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    loadArInvoice,
    {
      loading: ArInvoicebyStatusLoading,
      called: ArInvoiceCalled,
      data: { getARInvoicebyStatusListing } = {
        getARInvoicebyStatusListing: [],
      },
    },
  ] = useGetArInvoicebyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARInvoicebyStatusListing }) => {
      if (getARInvoicebyStatusListing?.Data?.length > 0) {
        setOriginalListing(
          [...filteredList, ...getARInvoicebyStatusListing?.Data],
          v => {
            v['DocDateFormatted'] = formatDate(v.DocDate)
          }
        )
        setTotalLength(getARInvoicebyStatusListing?.TotalLength)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    loadArRefund,
    {
      loading: ArRefundbyStatusLoading,
      called: ArRefundCalled,
      data: { getARRefundbyStatusListing } = {
        getARRefundbyStatusListing: [],
      },
    },
  ] = useGetArRefundbyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARRefundbyStatusListing }) => {
      if (getARRefundbyStatusListing?.Data?.length > 0) {
        setOriginalListing(
          [...filteredList, ...getARRefundbyStatusListing?.Data],
          v => {
            v['DocDateFormatted'] = formatDate(v.DocDate)
          }
        )
        setTotalLength(getARRefundbyStatusListing?.TotalLength)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    loadARCreditNote,
    {
      loading: ARCreditNotebyStatusLoading,
      called: ArCreditNoteCalled,
      data: { getARCreditNotebyStatusListing } = {
        getARCreditNotebyStatusListing: [],
      },
    },
  ] = useGetArCreditNotebyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARCreditNotebyStatusListing }) => {
      if (getARCreditNotebyStatusListing?.Data?.length > 0) {
        setOriginalListing(
          [...filteredList, ...getARCreditNotebyStatusListing?.Data],
          v => {
            v['DocDateFormatted'] = formatDate(v.DocDate)
          }
        )
        setTotalLength(getARCreditNotebyStatusListing?.TotalLength)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    loadArDebitNote,
    {
      loading: ArDebitNotebyStatusLoading,
      called: ArDebitNoteCalled,
      data: { getARDebitNotebyStatusListing } = {
        getARDebitNotebyStatusListing: [],
      },
    },
  ] = useGetArDebitNotebyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARDebitNotebyStatusListing }) => {
      if (getARDebitNotebyStatusListing?.Data?.length > 0) {
        setOriginalListing(
          [...filteredList, ...getARDebitNotebyStatusListing?.Data],
          v => {
            v['DocDateFormatted'] = formatDate(v.DocDate)
          }
        )
        setTotalLength(getARDebitNotebyStatusListing?.TotalLength)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    loadArReceipt,
    {
      loading: ArReceiptbyStatusLoading,
      called: ArReceiptCalled,
      data: { getAROfficialReceiptbyStatusListing } = {
        getAROfficialReceiptbyStatusListing: [],
      },
    },
  ] = useGetArOfficialReceiptbyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAROfficialReceiptbyStatusListing }) => {
      if (getAROfficialReceiptbyStatusListing?.Data?.length > 0) {
        setOriginalListing(
          [...filteredList, ...getAROfficialReceiptbyStatusListing?.Data],
          v => {
            v['DocDateFormatted'] = formatDate(v.DocDate)
          }
        )
        setTotalLength(getAROfficialReceiptbyStatusListing?.TotalLength)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  let loading,
    called,
    listData,
    loadData,
    subMenuName,
    primaryKey,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    approveRejectPermissionCheck,
    userList = getUsersByAccountAndSoftware

  switch (arSubmenu) {
    case 'advance':
      loading = ArAdvancebyStatusLoading
      called = ArAdvanceCalled
      listData = getARAdvancebyStatusListing
      loadData = loadArAdvance
      subMenuName = 'Advance'
      primaryKey = 'AdvanceID'
      createPermissionCheck = AcctPermission.AccReceivableAdvancesCreate
      updatePermissionCheck = AcctPermission.AccReceivableAdvancesUpdate
      deletePermissionCheck = AcctPermission.AccReceivableAdvancesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccReceivableAdvancesApproveReject
      break
    case 'invoice':
      loading = ArInvoicebyStatusLoading
      called = ArInvoiceCalled
      listData = getARInvoicebyStatusListing
      loadData = loadArInvoice
      subMenuName = 'Invoice'
      primaryKey = 'InvoiceID'
      createPermissionCheck = AcctPermission.AccReceivableInvoicesCreate
      updatePermissionCheck = AcctPermission.AccReceivableInvoicesUpdate
      deletePermissionCheck = AcctPermission.AccReceivableInvoicesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccReceivableInvoicesApproveReject
      break
    case 'refund':
      loading = ArRefundbyStatusLoading
      called = ArRefundCalled
      listData = getARRefundbyStatusListing
      loadData = loadArRefund
      subMenuName = 'Refund'
      primaryKey = 'RefundID'
      createPermissionCheck = AcctPermission.AccReceivableRefundCreate
      updatePermissionCheck = AcctPermission.AccReceivableRefundUpdate
      deletePermissionCheck = AcctPermission.AccReceivableRefundDelete
      approveRejectPermissionCheck =
        AcctPermission.AccReceivableRefundApproveReject
      break
    case 'debit-note':
      loading = ArDebitNotebyStatusLoading
      called = ArDebitNoteCalled
      listData = getARDebitNotebyStatusListing
      loadData = loadArDebitNote
      subMenuName = 'DebitNote'
      primaryKey = 'DebitNoteID'
      createPermissionCheck = AcctPermission.AccReceivableDebitNotesCreate
      updatePermissionCheck = AcctPermission.AccReceivableDebitNotesUpdate
      deletePermissionCheck = AcctPermission.AccReceivableDebitNotesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccReceivableDebitNotesApproveReject
      break
    case 'credit-note':
      loading = ARCreditNotebyStatusLoading
      called = ArCreditNoteCalled
      listData = getARCreditNotebyStatusListing
      loadData = loadARCreditNote
      subMenuName = 'CreditNote'
      primaryKey = 'CreditNoteID'
      createPermissionCheck = AcctPermission.AccReceivableCreditNotesCreate
      updatePermissionCheck = AcctPermission.AccReceivableCreditNotesUpdate
      deletePermissionCheck = AcctPermission.AccReceivableCreditNotesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccReceivableCreditNotesApproveReject
      break
    case 'official-receipt':
      loading = ArReceiptbyStatusLoading
      called = ArReceiptCalled
      listData = getAROfficialReceiptbyStatusListing
      loadData = loadArReceipt
      subMenuName = 'Receipts'
      primaryKey = 'ReceiptsID'
      createPermissionCheck = AcctPermission.AccReceivableReceiptsCreate
      updatePermissionCheck = AcctPermission.AccReceivableReceiptsUpdate
      deletePermissionCheck = AcctPermission.AccReceivableReceiptsDelete
      approveRejectPermissionCheck =
        AcctPermission.AccReceivableReceiptsApproveReject
      break
  }

  // const loadData = () => {
  //   switch (arSubmenu) {
  //     case 'advance':
  //       loadArAdvance({
  //         variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
  //       })
  //       break
  //     case 'invoice':
  //       loadArInvoice({
  //         variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
  //       })
  //       break
  //     case 'refund':
  //       loadArRefund({
  //         variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
  //       })
  //       break
  //     case 'debit-note':
  //       loadArDebitNote({
  //         variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
  //       })
  //       break
  //     case 'credit-note':
  //       loadARCreditNote({
  //         variables: {
  //           CompanyID: CompanyID,
  //           StatusArr: [listStatus],
  //           take: 30,
  //           skip: 0,
  //           searchValue:
  //             getSearch?.name !== '' || getSearch?.name === undefined
  //               ? undefined
  //               : getSearch?.name,
  //         },
  //       })
  //       break
  //     case 'official-receipt':
  //       loadArReceipt({
  //         variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
  //       })
  //       break
  //   }
  // }

  // useEffect(() => {
  //   setOriginalListing([])
  //   loadData({
  //     variables: {
  //       CompanyID: CompanyID,
  //       StatusArr: [listStatus],
  //       take: 30,
  //       skip: 0,
  //       searchValue:
  //         getSearch?.name !== '' || getSearch?.name === undefined
  //           ? undefined
  //           : getSearch?.name,
  //     },
  //   })
  // }, [arSubmenu, listStatus])

  return {
    loading,
    called,
    listData,
    subMenuName,
    primaryKey,
    userList,
    loadData,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    approveRejectPermissionCheck,
  }
}
