import { useDocumentListingLazyQuery } from '@account-root/procurement-react/src/generated/graphql'
import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { MenuItem, TextField } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { useGetCostCentreQuery } from 'generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'

interface RevaluationProps {
  AssetNo: any
  AssetName: any
  Location: any
  BalQtyAfterDisposal: string
  BalQtyBeforeDisposal: string
  unitPrice: string
  OriginalValue: string
  TotalDepreciate: string
  DisposalDate: string
  DisposalQty: any
  DisposalMethod: any
  DisposalExpenses: string
  BookValue: string
  DisposalValue: string
  DisposalGainLoss: string
  Reason: any
  Attachment: any
}

export const WriteOffDisposalForm = (props: any) => {
  const isGLSubscribe = JSON.parse(localStorage.getItem('isGLSubscribe'))
  let history = useHistory()
  let location = useLocation()
  const { CompanyID }: any = useParams()
  const { mode } = props
  const editData: any = location?.state
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [voiceRemark, setVoiceRemark] = useState('')
  const [record, setRecord] = useState(false)
  const bankAccountScheme = yup.object().shape({})
  const {
    handleSubmit,
    control,
    register,
    watch,
    setError,
    clearErrors,
    errors,
    setValue,
    formState: { isDirty },
  } = useForm<RevaluationProps>({
    mode: 'onSubmit',
    resolver: yupResolver(bankAccountScheme),
  })

  const {
    loading: CostCentreLoading,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      orderByAsc: 'CostCentreID',
      IsLastNode: true,
    },
  })

  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    fetchDocuments,
    { loading: documentLoading, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  let dummyData = [
    {
      AssetNo: 'A-000013',
      AssetName: 'Conference Table',
      Location: 'Project Site Office',
      BalQtyAfterDisposal: '2',
      BalQtyBeforeDisposal: '2',
      unitPrice: '2,500.00',
      OriginalValue: '2,500.00',
      TotalDepreciate: '0.00',
    },
    {
      DisposalDate: '12/06/2023',
      DisposalQTy: '0.00',
      DisposalMethod: 'Disposal',
      BalQtyAfterDisposal: '1',
      DisposalExpenses: '0.00',
      BookValue: '2,500.00',
      DisposalValue: '0.00',
      DisposalGainLoss: '-2,500.00',
      Reason: 'Reason',
      Attachment: 'Attachment',
    },
  ]

  function handleAmtChange(floatValue: any) {
    throw new Error('Function not implemented.')
  }

  return (
    <>
      {CostCentreLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          history.push(`/fixed-assets/${CompanyID}/write-off-disposal`)
        }}
        smTitle={'Fixed Assets'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Company Setting' },
          { name: 'Company' },
          { name: 'Company' },
          { name: 'Write-Off/Disposal', current: true },
        ]}
        rightRouteSegments={[
          { name: mode === 'add' ? 'Edit' : 'New', current: true },
        ]}
      />

      <ContentWrapper footer>
        <CardContents
          section={{
            header: {
              title: 'Assets Information',
            },
          }}
        >
          <Controller
            as={
              <TextField select>
                {dummyData?.map((el, index) => (
                  <MenuItem
                    style={{ whiteSpace: 'normal' }}
                    value={el.AssetNo}
                    key={index}
                  >
                    <span className="text-noflow">{el.AssetNo}</span>
                  </MenuItem>
                ))}
              </TextField>
            }
            name="Asset No"
            label="Asset No"
            autoComplete="on"
            margin="normal"
            fullWidth
            ref={register}
            control={control}
            required
            helperText={errors?.AssetNo?.message}
            error={errors?.AssetNo ? true : false}
            defaultValue={editData?.BankProfileID}
          ></Controller>

          <Controller
            as={TextField}
            name="Asset Name"
            label="Asset Name"
            autoComplete="off"
            fullWidth
            control={control}
            helperText={errors?.AssetName?.message}
            error={errors?.AssetName ? true : false}
            defaultValue="Conference Table"
            InputProps={{ disableUnderline: true }}
          ></Controller>

          <Controller
            as={TextField}
            name="Location"
            label="Location"
            autoComplete="off"
            fullWidth
            control={control}
            helperText={errors?.Location?.message}
            error={errors?.Location ? true : false}
            defaultValue="Project Site Office"
            InputProps={{ disableUnderline: true }}
          ></Controller>

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            ref={register}
            control={control}
            margin="dense"
            name="Bal Qty After Disposal"
            label="Bal Qty After Disposal"
            className="left"
            defaultValue={2}
            // decimalScale={2}
            fixedDecimalScale
            InputProps={{
              disableUnderline: true,
            }}
          />

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            ref={register}
            control={control}
            margin="dense"
            name="Bal Qty Before Disposal"
            label="Bal Qty Before Disposal"
            className="left"
            defaultValue={2}
            // decimalScale={2}
            fixedDecimalScale
            InputProps={{
              disableUnderline: true,
            }}
          />

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            name="Unit Price"
            label="Unit Price"
            autoComplete="off"
            margin="dense"
            className="left"
            control={control}
            helperText={errors?.unitPrice?.message}
            error={errors?.unitPrice ? true : false}
            defaultValue="2,500.00"
            InputProps={{ disableUnderline: true }}
          ></Controller>

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            name="Original Value"
            label="Original Value"
            autoComplete="off"
            fullWidth
            control={control}
            margin="dense"
            className="right"
            helperText={errors?.OriginalValue?.message}
            error={errors?.OriginalValue ? true : false}
            defaultValue="2,500.00"
            InputProps={{ disableUnderline: true }}
          ></Controller>

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            ref={register}
            control={control}
            margin="dense"
            name="Total Depreciate"
            label="Total Depreciate"
            className="left"
            defaultValue={0.0}
            decimalScale={2}
            fixedDecimalScale
            InputProps={{
              disableUnderline: true,
            }}
          />
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Write-Off/Disposal Details',
            },
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="Date"
              label="Disposal Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd MMM yyyy"
              value={mode === 'add' ? new Date() : editData?.Date}
              margin="dense"
              allowKeyboardControl
              inputRef={register({})}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData ? editData?.Date : new Date()}
              helperText={errors?.DisposalDate?.message}
              error={errors?.DisposalDate ? true : false}
              showTodayButton
              className="left"
            />
          </MuiPickersUtilsProvider>
          <Controller
            as={
              <TextField select>
                {dummyData?.map((el, index) => (
                  <MenuItem
                    style={{ whiteSpace: 'normal' }}
                    value={el.DisposalMethod}
                    key={index}
                  >
                    <span className="text-noflow">{el.DisposalMethod}</span>
                  </MenuItem>
                ))}
              </TextField>
            }
            id="Disposal Method"
            name="Disposal Method"
            label="Disposal Method "
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.DisposalMethod?.message}
            error={errors?.DisposalMethod ? true : false}
            ref={register}
            defaultValue={editData?.Code}
            className="right"
            disabled={mode === 'approve-reject'}
          />

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            name="Disposal Qty"
            label="Disposal Qty"
            autoComplete="off"
            multiline={true}
            margin="dense"
            className="left"
            ref={register}
            control={control}
            helperText={errors?.DisposalQty?.message}
            error={errors?.DisposalQty ? true : false}
            defaultValue={1}
          ></Controller>

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            name="Bal Qty After Disposal"
            label="Bal Qty After Disposal"
            autoComplete="off"
            fullWidth
            control={control}
            margin="dense"
            className="right"
            helperText={errors?.BalQtyAfterDisposal?.message}
            error={errors?.BalQtyAfterDisposal ? true : false}
            defaultValue={1}
            InputProps={{ disableUnderline: true }}
          ></Controller>

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            name="Disposal Expenses"
            label="Disposal Expenses"
            autoComplete="off"
            margin="dense"
            className="left"
            ref={register}
            control={control}
            defaultValue={0.0}
            decimalScale={2}
            fixedDecimalScale
          ></Controller>

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            name="Book Value"
            label="Book Value"
            autoComplete="off"
            margin="dense"
            className="right"
            control={control}
            helperText={errors?.BookValue?.message}
            error={errors?.BookValue ? true : false}
            defaultValue="2,500.00"
            InputProps={{ disableUnderline: true }}
          />

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            name="Disposal Value"
            label="Disposal Value"
            autoComplete="off"
            multiline={true}
            ref={register}
            control={control}
            margin="dense"
            className="left"
            defaultValue={0.0}
            decimalScale={2}
            fixedDecimalScale
          ></Controller>

          <Controller
            as={<NumberFormat allowNegative={true} />}
            thousandSeparator
            customInput={TextField}
            name="Disposal Gain/Loss"
            label="Disposal Gain/Loss"
            autoComplete="off"
            margin="dense"
            className="right"
            control={control}
            helperText={errors?.DisposalGainLoss?.message}
            error={errors?.DisposalGainLoss ? true : false}
            defaultValue="-2,500.00"
            InputProps={{ disableUnderline: true }}
          ></Controller>

          <VoiceTextField
            mounted={true}
            label="Reason"
            name="Reason"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            required
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Reason ? true : false,
              helperText: errors?.Reason?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />

          <div style={{ width: '100%', marginTop: '24px' }}>
            <FileUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              name="Attachment"
              required
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </div>
        </CardContents>
      </ContentWrapper>

      <Footer
        options={[
          {
            name: 'Save As Draft',
            onClick: () => {},
            color: 'primary',
          },
          {
            name: 'Submit',
            onClick: () => {},
            color: 'primary',
          },
        ]}
      />
    </>
  )
}
