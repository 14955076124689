import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const StockLedgerByTransactionDateReport = () => {
  const history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const editData = location?.state as any

  console.log(editData?.StartDocDate)

  const parameters = [
    { name: 'accountid', value: accountID },
    { name: 'companyid', value: editData?.CompanyID },
    {
      name: 'startdocdate',
      value: new Date(editData?.StartDocDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'enddocdate',
      value: new Date(editData?.EndDocDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'starttrxdate',
      value: new Date(editData?.StartTrxDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'endtrxdate',
      value: new Date(editData?.EndTrxDate.setHours(0, 0, 0)).toISOString(),
    },
    { name: 'warehouse', value: editData?.WarehouseID },
    { name: 'stockitem', value: editData?.StockItemID },
    { name: 'category', value: editData?.StockCategoryID },
    {
      name: 'trxtype',
      value: editData?.TrxType,
    },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  return (
    <>
      <MainHeader
        onClick={() => history.push(`/digital-reports/inventory-control`)}
        mainBtn="close"
        smTitle="Inventory Control"
        title={user?.accountName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Digital Reporting' },
          { name: 'Stock Ledger By Transaction Date', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('StockLedgerByTransactionDate', parameters),
          host: accountxReportUrl,
          // host: contractReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}
