import React, { lazy } from 'react'
import { PDFPreviewGP } from '@account-root/procurement-react/src/components/PDFTemplate/PDFPreviewGP'
import { AuditLogListing } from 'components/Listing/AuditLog'

const PurchaseOrderListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralPOModule/PurchaseOrderListing'
  ).then(module => ({
    default: module.PurchaseOrderListing,
  }))
)

const GeneratePOFromPRFormV4 = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralPOModule/GeneratePOFromPRFormV4'
  ).then(module => ({
    default: module.GeneratePOFromPRFormV4,
  }))
)

const PurchaseOrderDetail = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralPOModule/PurchaseOrderDetail'
  ).then(module => ({
    default: module.PurchaseOrderDetail,
  }))
)

const DirectPoForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/DirectPOModule/DirectPOForm'
  ).then(module => ({
    default: module.DirectPoForm,
  }))
)

const POForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/DirectPOModule/POForm'
  ).then(module => ({
    default: module.PurchaseOrderForm,
  }))
)

const POItemForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/DirectPOModule/POItemForm'
  ).then(module => ({
    default: module.PurchaseOrderItemForm,
  }))
)

const generalPORoutes = [
  {
    props: {
      exact: true,
      path: `/general-purchase/:CompanyID/generate-po-from-pr`,
    },
    component: <PurchaseOrderListing type="generate-po-from-pr" />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/generate-po-from-pr/add',
    },
    component: <GeneratePOFromPRFormV4 mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/generate-po-from-pr/draft/:poID',
    },
    component: <GeneratePOFromPRFormV4 mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/:CompanyID/generate-po-from-pr/:dpoID/:formMode`,
    },
    component: <POForm detailMode={'edit'} type="generate-po-from-pr" />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/generate-po-from-pr/resubmit',
    },
    component: <GeneratePOFromPRFormV4 mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/generate-po-from-pr/detail/:dpoID/view',
    },
    component: <PurchaseOrderDetail type="generate-po-from-pr" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/generate-po-from-pr/generate-po-from-pr-pr-list',
    },
    component: <PurchaseOrderDetail />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/:CompanyID/generate-po-from-pr/:POHeaderID/detail/preview`,
    },
    component: (
      <PDFPreviewGP
        accountType="/"
        docType="generate-po-from-pr"
        backPath="generate-po-from-pr"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/:CompanyID/generate-po-from-pr/:formMode/po-item/:detailMode`,
    },
    component: <POItemForm type="generate-po-from-pr" />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/:CompanyID/generate-po-from-pr/:dpoID/:formMode/po-item/:detailMode`,
    },
    component: <POItemForm type="generate-po-from-pr" />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/:CompanyID/generate-po-from-pr/:formMode/po-item/:dpoItemID/:detailMode`,
    },
    component: <POItemForm type="generate-po-from-pr" />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/:CompanyID/generate-po-from-pr/:dpoID/:formMode/po-item/:dpoItemID/:detailMode`,
    },
    component: <POItemForm type="generate-po-from-pr" />,
  },
  /* -------------------------------------------- */
  /*                AUDIT LOG                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/generate-po-from-pr/:dpoID/detail/audit-log',
    },
    component: (
      <AuditLogListing
        module="general-purchase"
        routeSegments="Purchase Order"
        smTitle="General Purchases"
        docType="generate-po-from-pr"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/direct-po/:dpoID/detail/audit-log',
    },
    component: (
      <AuditLogListing
        module="general-purchase"
        routeSegments="Purchase Order"
        smTitle="General Purchases"
        docType="direct-po"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/centralised-po/:dpoID/detail/audit-log',
    },
    component: (
      <AuditLogListing
        module="general-purchase"
        routeSegments="Purchase Order"
        smTitle="General Purchases"
        docType="centralised-po"
      />
    ),
  },
]

export default generalPORoutes
