import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { SortDropDown } from '@ifca-root/react-component/src/components/Sort/SortDropDown'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'
import { SwapVert, Tune } from '@material-ui/icons'
import { useGetCompanyNameQuery } from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { APEntryList } from './APListing/APEntryList'
import { APPostingList } from './APListing/APPostingList'
import { useAPListingData } from './Hooks/useAPListingData'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { SortDialog } from '@ifca-root/react-component/src/components/Sort/SortDialog'
import { SearchFilterV3 } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV3'
import { APDocByPermission } from 'containers/ARAPModule/Components/Listing/APDocByPermission'

export const APMain = (props: any) => {
  let history = useHistory()
  const { apSubmenu, routeSegments, accountType } = props
  const [isEinvoice, setIsEinvoice] = useState<boolean>(false)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const { CompanyID }: any = useParams()
  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  /* -------------------------------------------- */
  /*                     ENUM                     */
  /* -------------------------------------------- */

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onCompleted: ({ getCompany }) => {
      setIsEinvoice(getCompany[0]?.EInvoiceStarted)
    },
  })
  const {
    loading,
    primaryKey,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    approveRejectPermissionCheck,
    userList,
    loadData,
  } = useAPListingData({
    accountType,
    apSubmenu,
    CompanyID,
    setOriginalListing,
    filteredList,
    setErrMsg,
    setErrorDia,
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  useEffect(() => {
    setOriginalListing([])
    loadData({
      variables: {
        CompanyID: CompanyID,
        StatusArr: [listStatus],
        skip: 0,
        take: 30,
        searchValue:
          getSearch?.name === '' || !getSearch?.name
            ? undefined
            : getSearch?.name,
        sortField: selectedField === null ? undefined : selectedField,
        sortOrder: sortOrder === null ? undefined : sortOrder,
        startDate: selectedStartDate === null ? undefined : selectedStartDate,
        endDate: selectedEndDate === null ? undefined : selectedEndDate,
      },
    })
  }, [listStatus])

  //to make delay for search function for the query to load
  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadData({
          variables: {
            CompanyID: CompanyID,
            StatusArr: [listStatus],
            skip: 0,
            take: 30,
            searchValue: change,
            sortField: selectedField === null ? undefined : selectedField,
            sortOrder: sortOrder === null ? undefined : sortOrder,
            startDate:
              selectedStartDate === null ? undefined : selectedStartDate,
            endDate: selectedEndDate === null ? undefined : selectedEndDate,
          },
        })
      }, 1000)
    )
  }

  //*******************************************************//
  //-------------------SORTING AND DATE RANGE FILTER-------//
  //*******************************************************//

  const sortAPDataDialog = [
    { ID: '1', value: 'DocNo', Name: 'Document No' },
    { ID: '2', value: 'RefNo', Name: 'Reference No' },
    { ID: '3', value: 'DocDate', Name: 'Document Date' },
    { ID: '4', value: 'Description', Name: 'Description' },
    { ID: '5', value: 'DocAmt', Name: 'Amount' },
    { ID: '6', value: 'CreditorName', Name: 'Creditor' },
  ]

  const [openSortDialog, setOpenSortDialog] = useState(false)
  const [selectedField, setSelectedField] = useState('')
  const [sortOrder, setSortOrder] = useState(null)

  const [openFilterDateDialog, setOpenFilterDateDialog] = useState(false)
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [confirmedStartDate, setConfirmedStartDate] = useState(null)
  const [confirmedEndDate, setConfirmedEndDate] = useState(null)

  const handleClickSort = () => {
    setOpenSortDialog(true)
  }

  const handleListStatusChange = newStatus => {
    setListStatus(newStatus)
    setSelectedField(null)
    setSortOrder(null)
    setSelectedStartDate(null)
    setSelectedEndDate(null)
    setConfirmedStartDate(null)
    setConfirmedEndDate(null)
  }

  const handleSortConfirm = sortOption => {
    const { selectedField, sortOrder } = sortOption
    setOriginalListing([])
    loadData({
      variables: {
        CompanyID: CompanyID,
        StatusArr: [listStatus],
        take: 30,
        skip: 0,
        searchValue:
          getSearch?.name === '' || getSearch?.name === undefined
            ? undefined
            : getSearch?.name,
        sortField: selectedField,
        sortOrder: sortOrder,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      },
    })
  }

  const handleClickDateRange = () => {
    setOpenFilterDateDialog(true)
  }

  const handleDateConfirm = () => {
    setOriginalListing([])
    loadData({
      variables: {
        CompanyID: CompanyID,
        StatusArr: [listStatus],
        take: 30,
        skip: 0,
        searchValue:
          getSearch?.name === '' || getSearch?.name === undefined
            ? undefined
            : getSearch?.name,
        sortField: selectedField,
        sortOrder: sortOrder,
        startDate: selectedStartDate === null ? undefined : selectedStartDate,
        endDate: selectedEndDate === null ? undefined : selectedEndDate,
      },
    })
  }

  const handleDateReset = () => {
    setOriginalListing([])
    loadData({
      variables: {
        CompanyID: CompanyID,
        StatusArr: [listStatus],
        take: 30,
        skip: 0,
        searchValue:
          getSearch?.name === '' || getSearch?.name === undefined
            ? undefined
            : getSearch?.name,
        sortField: selectedField,
        sortOrder: sortOrder,
        startDate: null,
        endDate: null,
      },
    })
  }

  return (
    <>
      {loading && <Loading />}
      {CompanyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/account-payable/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }}
        smTitle={'Accounts Payable'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Accounts Payable' },
          { name: routeSegments, current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
            changeDelay(e?.target?.value)
          }}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
            localStorage.removeItem('searchFilter')
            loadData({
              variables: {
                CompanyID: CompanyID,
                StatusArr: [listStatus],
                skip: 0,
                take: 30,
                searchValue:
                  getSearch?.name !== '' || getSearch?.name === undefined
                    ? undefined
                    : getSearch?.name,
                sortField: selectedField === null ? undefined : selectedField,
                sortOrder: sortOrder === null ? undefined : sortOrder,
                startDate:
                  selectedStartDate === null ? undefined : selectedStartDate,
                endDate: selectedEndDate === null ? undefined : selectedEndDate,
              },
            })
          }}
          option={{
            icon: <Tune />,
            onClick: handleClickDateRange,
          }}
          sort={{
            icon: <SwapVert />,
            onClick: handleClickSort,
          }}
        />
        <SortDialog
          openSortDialog={openSortDialog}
          setOpenSortDialog={setOpenSortDialog}
          title="Sort Options"
          fieldList={sortAPDataDialog}
          onSortConfirm={handleSortConfirm}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
        <SearchFilterV3
          openFilterDateDialog={openFilterDateDialog}
          setOpenFilterDateDialog={setOpenFilterDateDialog}
          handleDateConfirm={handleDateConfirm}
          currentStartDate={selectedStartDate}
          currentEndDate={selectedEndDate}
          setCurrentStartDate={setSelectedStartDate}
          setCurrentEndDate={setSelectedEndDate}
          handleDateReset={handleDateReset}
          confirmedStartDate={confirmedStartDate}
          confirmedEndDate={confirmedEndDate}
          setConfirmedStartDate={setConfirmedStartDate}
          setConfirmedEndDate={setConfirmedEndDate}
        />

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={handleListStatusChange}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') && (
        <APEntryList
          apSubmenu={apSubmenu}
          loading={loading}
          setOriginalListing={setOriginalListing}
          filteredList={filteredList}
          listLoading={loading}
          listStatus={listStatus}
          setListStatus={setListStatus}
          userList={userList}
          primaryKey={primaryKey}
          createPermissionCheck={createPermissionCheck}
          updatePermissionCheck={updatePermissionCheck}
          deletePermissionCheck={deletePermissionCheck}
          loadData={loadData}
          CompanyID={CompanyID}
          getSearch={getSearch}
          selectedField={selectedField}
          sortOrder={sortOrder}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
        />
      )}

      {listStatus === 'SUBMIT' && (
        <APPostingList
          list={filteredList}
          loading={loading}
          filteredList={
            apSubmenu === 'payment' || apSubmenu === 'advance'
              ? APDocByPermission(filteredList, apSubmenu)
              : filteredList
          }
          listLoading={loading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={userList}
          approveRejectPermissionCheck={approveRejectPermissionCheck}
          setOriginalListing={setOriginalListing}
          apSubmenu={apSubmenu}
          loadData={loadData}
          CompanyID={CompanyID}
          getSearch={getSearch}
          selectedField={selectedField}
          sortOrder={sortOrder}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
        />
      )}

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
