import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { formatDashDate } from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const UserActivityLogReport = (props: any) => {
  const { submenu, smTitle } = props
  const history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const editData = location?.state as any

  const parameters = [
    {
      name: 'accountid',
      value: accountID,
    },
    {
      name: 'startdate',
      value: formatDashDate(new Date(editData?.StartDate).toISOString()),
    },
    {
      name: 'enddate',
      value: formatDashDate(new Date(editData?.EndDate).toISOString()),
    },
    {
      name: 'startlogindate',
      value: editData?.StartLoginDate
        ? formatDashDate(new Date(editData?.StartLoginDate).toISOString())
        : undefined,
    },
    {
      name: 'endlogindate',
      value: editData?.EndLoginDate
        ? formatDashDate(new Date(editData?.EndLoginDate).toISOString())
        : undefined,
    },
    {
      name: 'startlogoutdate',
      value: editData?.StartLogoutDate
        ? formatDashDate(new Date(editData?.StartLogoutDate).toISOString())
        : undefined,
    },
    {
      name: 'endlogoutdate',
      value: editData?.EndLogoutDate
        ? formatDashDate(new Date(editData?.EndLogoutDate).toISOString())
        : undefined,
    },
    {
      name: 'userid',
      value: editData?.UserID ?? undefined,
    },
    {
      name: 'superuser',
      value: editData?.SuperUser ?? undefined,
    },
    {
      name: 'modulename',
      value: editData?.ModuleName ?? undefined,
    },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  return (
    <>
      <MainHeader
        onClick={() => history.push(`/digital-reports/${submenu}`)}
        mainBtn="close"
        smTitle={smTitle}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Parameters' },
          { name: 'User Activity Log Report', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('UserActivityLogReport', parameters),
          host: accountxReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}
