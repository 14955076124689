import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { SubmitterDialog } from 'components/Dialog/SubmitterDialog'
import { AccountFooter } from 'components/Footer/AccountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  ApprovalStatus,
  GetBankTransferbyStatusDocument,
  useDocumentListingQuery,
  useGetBankAccountQuery,
  useGetBankTransferQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePostCbBankTransferMutation,
  UserSelection,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/CustomHooks/useMenuOption'
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import { BankTransferDetailContent } from './BankTransferDetailContent'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import AuditLog from 'assets/icons/MFRS16/auditlog.svg'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'

export const BankTransferDetail = (props: any) => {
  let history = useHistory()
  const { CompanyID, BankTransferID, BankAccountID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [Requisitions, SetRequisition] = useState([])
  const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption()

  const [openDialog, setOpenDialog] = useState(false)
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const [WorkFlowData, setWorkFlowData] = useState(null)
  const [openWorkFlow, setWorkFlow] = useState(false)
  const WorkFlowClicked = (ID: string) => {
    let Data = Requisitions?.find(x => x.RequisitionHeaderID === ID)
    setWorkFlowData(Data)
    // setWorkFlowIndex(index)
    setWorkFlow(true)
  }

  const {
    loading: BankTransferLoading,
    error: BankTransferError,
    data: { getBankTransfer } = { getBankTransfer: [] },
  } = useGetBankTransferQuery({
    variables: { BankTransferID: BankTransferID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: bankAccountLoading,
    error: bankAccountError,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    fetchPolicy: 'network-only',
    variables: { BankAccountID: BankAccountID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: getBankTransfer?.[0]?.BankTransferID,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })
  // Status Enum

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    clearErrors,
  } = useForm<any>()

  const bankTransferStatusText = pStatus => {
    switch (pStatus) {
      case ApprovalStatus.Submit:
        return 'Submitted'
      case ApprovalStatus.Active:
        return 'Draft'
      case ApprovalStatus.Approved:
        return 'Approved'
      case ApprovalStatus.Rejected:
        return 'Rejected'
      case ApprovalStatus.Cancelled:
        return 'Cancelled'
      case ApprovalStatus.Pending:
        return 'Pending'
      case ApprovalStatus.PendingClose:
        return 'Pending Close'
      case ApprovalStatus.Completed:
        return 'Approved'
      default:
        return ''
    }
  }
  // color text for status

  const { handleStatusColor } = useColorStatusList()

  const [
    postCBBankTransfer,
    {
      loading: postReceiptLoading,
      called: postReceiptCalled,
      error: postReceiptError,
    },
  ] = usePostCbBankTransferMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/bank-transfer-posting`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const onSubmit = (data, status) => {
    postCBBankTransfer({
      variables: {
        BankTransferIDs: BankTransferID,
        CompanyID,
        RefTable: 'CB_BankTransfer',
      },
      refetchQueries: [
        {
          query: GetBankTransferbyStatusDocument,
          variables: { CompanyID },
        },
      ],
    })
  }

  return (
    <>
      {BankTransferLoading && <Loading />}
      {bankAccountLoading && <Loading />}
      {postReceiptLoading && <Loading />}
      {getUsersByAccountAndSoftwareLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(
            `/cash-book/${CompanyID}/submenu/${BankAccountID}/bank-transfer`
          )
        }}
        smTitle="Cash Book"
        title={user?.companyName}
        routeSegments={[
          { name: 'Cash Book' },
          { name: 'Bank Transfer' },
          { name: 'Bank Transfer', current: true },
        ]}
        rightRouteSegments={[{ name: `Approved`, current: true }]}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle">
              {getBankAccount[0]?.BankProfile?.Name}
            </span>
          </>
        }
        infoLine={
          <>
            <span className="xxTitle">{'Account No.'}&nbsp;:&nbsp;</span>
            <span className="xxTitle">{getBankAccount[0]?.AccountNo}</span>
          </>
        }
        rightText={
          <IconText
            parentClassName={'c-orange'}
            icon={
              <img
                src={AuditLog}
                style={{
                  width: '17px',
                  marginRight: '5px',
                  marginLeft: '12px',
                }}
                onClick={() => {
                  history.push({
                    pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/bank-transfer/:BankTransferID/detail/audit-log`,
                    state: {
                      ID: getBankTransfer[0]?.BankTransferID,
                      tableName: 'CB_BankTransfer',
                      primaryKey: 'BankTransfer',
                      ...getBankTransfer[0],
                    },
                  })
                }}
              />
            }
            font="desc"
          ></IconText>
        }
      />
      <ContentWrapper singleDynamicInfo float style={{ marginTop: '60px' }}>
        <CardContents>
          <BankTransferDetailContent
            listEl={getBankTransfer[0]}
            documentListing={DocumentListing}
            listStatus={'SUBMIT'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
            bankAccount={getBankAccount[0]}
          />
        </CardContents>
      </ContentWrapper>
      {/* timeline popup */}

      <ApprovalLogDialog
        data={getBankTransfer[0]}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Bank Transfer'}
      />
      <SubmitterDialog
        data={getBankTransfer[0]}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        moduleName={'Payment'}
      />
      {getBankTransfer[0]?.ApprovalStatus === 'SUBMIT' ? (
        <AccountFooter
          options={[
            {
              name: 'Post',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      ) : null}

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />

      {/* <TotalAmountFooter detail={true} docAmt={getBankTransfer[0]?.Amount} /> */}
    </>
  )
}
