import React, { useContext, useState } from 'react'
import AppContext from 'containers/App/Store/AppContext'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  useGetAssignedCompanyQuery,
  useGetCompanyNameQuery,
  useGetDebtorAccountLazyQuery,
} from 'generated/graphql'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { AccountFooter } from 'components/Footer/AccountFooter'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

interface ARDebtorControlSummaryParamsProps {
  CompanyID: string
  StartDate: Date
  EndDate: Date
  DebtorAccountID: string
}

interface CompanyOption {
  Name: string
  CompanyID: string
}

export const ARDebtorControlSummaryForms = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const latestCompany = localStorage.getItem('latestCompany')
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('Company is required'),
  })

  const { handleSubmit, register, errors, control, watch, setValue } = useForm<
    ARDebtorControlSummaryParamsProps
  >({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  // ACCOUNTX API CALLS

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    variables: { CompanyID },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadDebtorAccount({
          variables: { CompanyID: latestCompany },
        })
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadDebtorAccount({
          variables: { CompanyID: latestCompany },
        })
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    loadDebtorAccount,
    {
      loading: DebtorAccountLoading,
      data: { getDebtorAccount } = { getDebtorAccount: [] },
    },
  ] = useGetDebtorAccountLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/digital-reports/account-receivable/ar-debtor-control-summary/generated`,
      state: {
        CompanyID: data?.CompanyID,
        DebtorAccountID: data.DebtorAccountID,
        StartDate: new Date(data.StartDate),
        EndDate: new Date(data.EndDate),
      },
    })
  }
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  return (
    <>
      {CompanyLoading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {CompanyAssignedLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/digital-reports/account-receivable`)}
        smTitle={'Accounts Receivable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Debtor Control Summary', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />

      <ContentWrapper float>
        <CardContents>
          {!CompanyAssignedLoading && !CompanyLoading && (
            <Controller
              render={({ value, onChange }) => {
                const options: CompanyOption[] =
                  !!user?.superUser === false
                    ? getAssignedCompanyByUser
                    : getCompany
                const defVal = getCompany?.filter(
                  x => x?.CompanyID === latestCompany
                )[0]
                return (
                  <Autocomplete
                    options={options || []}
                    getOptionLabel={(option: CompanyOption) => option.Name}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('CompanyID', newValue?.CompanyID)

                      loadDebtorAccount({
                        variables: {
                          CompanyID: newValue?.CompanyID,
                        },
                      })
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.Name}</span>
                    }}
                    defaultValue={defVal}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CompanyID?.message}
                            error={errors?.CompanyID ? true : false}
                            label="Company"
                            style={{ width: '100%' }}
                            margin="normal"
                            required
                            defaultValue={defVal}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Company"
              name="CompanyID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CompanyID?.message}
              error={errors?.CompanyID ? true : false}
              required
              defaultValue={latestCompany}
            />
          )}
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={getDebtorAccount || []}
                  getOptionLabel={option => `${option?.DebtorName}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('DebtorAccountID', newValue?.DebtorAccountID)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.DebtorName}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.DebtorAccountID?.message}
                          error={errors?.DebtorAccountID ? true : false}
                          label="Debtor Account"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Debtor Account"
            name="DebtorAccountID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.DebtorAccountID?.message}
            error={errors?.DebtorAccountID ? true : false}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Document Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd MMM yyyy"
              value={watch('StartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.DocDate ?? new Date()}
              helperText={errors?.StartDate?.message}
              error={errors?.StartDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="EndDate"
              label="End Document Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd MMM yyyy"
              value={watch('EndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.DocDate ?? new Date()}
              helperText={errors?.EndDate?.message}
              error={errors?.EndDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
