import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import { Grid } from '@material-ui/core'
import { useState } from 'react'
import { TrxTaxTable } from './Tables/TransactionTables'
import { TrxItemTable } from './Tables/TrxItemTable'
import React from 'react'

interface Props {
  type: 'Tax' | 'Item' | 'RefNo'
  data: any
}

export const InboundTrxDetail = ({ type, data }: Props) => {
  // ===== Use State ===== //
  const [tabVal, setTabVal] = useState<number>(0)
  if (type === 'Tax') {
    const sumTax = data?.reduce(
      (total, currentValue) => (total = total + currentValue?.TaxAmt),
      0
    )
    const sumTaxable = data?.reduce(
      (total, currentValue) => (total = total + currentValue?.TaxableAmt),
      0
    )

    return (
      <>
        <Grid container>
          <Grid item sm={12} md={6}>
            <div className="content-wrap full">
              <div
                className="desc"
                style={{
                  color: 'grey',
                }}
              >
                Σ Taxable Amount
              </div>
              <div className="mdDesc">{amtStr(sumTaxable) ?? '—'}</div>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className="content-wrap full">
              <div
                className="desc"
                style={{
                  color: 'grey',
                }}
              >
                Σ Tax Amount
              </div>
              <div className="mdDesc">{amtStr(sumTax) ?? '—'}</div>
            </div>
          </Grid>
        </Grid>
        {/* Definition for Transaction Tax Table */}
        <TrxTaxTable TransactionTax={data} IsSubtable={false} />
      </>
    )
  } else if (type === 'Item') {
    const sumDiscount = data?.reduce(
      (total, current) => (total = total + current?.DiscountAmt),
      0
    )
    const sumTax = data?.reduce(
      (total, current) => (total = total + current?.TaxAmt),
      0
    )
    const sumTaxExemp = data?.reduce(
      (total, current) => (total = total + current?.TaxExemp),
      0
    )
    const sumSubtotal = data?.reduce(
      (total, current) => (total = total + current?.Subtotal),
      0
    )
    const sumExcludeTax = data?.reduce(
      (total, current) => (total = total + current?.TotalExcludeTax),
      0
    )
    const sumCharge = data?.reduce(
      (total, current) => (total = total + current?.ChargeAmt),
      0
    )

    return (
      <>
        <Grid container>
          <Grid item sm={12} md={2}>
            <div className="content-wrap full">
              <div
                className="desc"
                style={{
                  color: 'grey',
                }}
              >
                Σ Discount Amount
              </div>
              <div className="mdDesc">{amtStr(sumDiscount) ?? '—'}</div>
            </div>
          </Grid>
          <Grid item sm={12} md={2}>
            <div className="content-wrap full">
              <div
                className="desc"
                style={{
                  color: 'grey',
                }}
              >
                Σ Tax Amount
              </div>
              <div className="mdDesc">{amtStr(sumTax) ?? '—'}</div>
            </div>
          </Grid>
          <Grid item sm={12} md={2}>
            <div className="content-wrap full">
              <div
                className="desc"
                style={{
                  color: 'grey',
                }}
              >
                Σ Tax Exemp
              </div>
              <div className="mdDesc">{amtStr(sumTaxExemp) ?? '—'}</div>
            </div>
          </Grid>
          <Grid item sm={12} md={2}>
            <div className="content-wrap full">
              <div
                className="desc"
                style={{
                  color: 'grey',
                }}
              >
                Σ Subtotal
              </div>
              <div className="mdDesc">{amtStr(sumSubtotal) ?? '—'}</div>
            </div>
          </Grid>
          <Grid item sm={12} md={2}>
            <div className="content-wrap full">
              <div
                className="desc"
                style={{
                  color: 'grey',
                }}
              >
                Σ Exclude Tax
              </div>
              <div className="mdDesc">{amtStr(sumExcludeTax) ?? '—'}</div>
            </div>
          </Grid>
          <Grid item sm={12} md={2}>
            <div className="content-wrap full">
              <div
                className="desc"
                style={{
                  color: 'grey',
                }}
              >
                Σ Charge Amount
              </div>
              <div className="mdDesc">{amtStr(sumCharge) ?? '—'}</div>
            </div>
          </Grid>
        </Grid>
        {/* Definition for Transaction Item Table */}
        <TrxItemTable TransactionItem={data} />
      </>
    )
  }
}
