import React, { lazy } from 'react'

// const BankProfileListing = lazy(() =>
//   import('./BankProfileListing').then(module => ({
//     default: module.BankProfileListing,
//   }))
// ) =-=-=-=-=-=-=-=-=-=-=-=-=- KIV
const BankProfileExcelValidation = lazy(() =>
  import('./BankProfileExcelValidation').then(module => ({
    default: module.BankProfileExcelValidation,
  }))
)
// New Listing and Form
const BankProfileListingV2 = lazy(() =>
  import('./BankProfileListingV2').then(module => ({
    default: module.BankProfileListingV2,
  }))
)
const BankProfileForm = lazy(() =>
  import('./BankProfileForm').then(module => ({
    default: module.BankProfileForm,
  }))
)

const bankProfileRoutes = [
  // {
  //   props: {
  //     exact: true,
  //     path: '/cash-book/general-setting/bank-profile',
  //   },
  //   component: <BankProfileListing />,
  // ) =-=-=-=-=-=-=-=-=-=-=-=-=- KIV
  {
    props: {
      exact: true,
      path: '/cash-book/general-setting/bank-profile/excel-validation',
    },
    component: <BankProfileExcelValidation />,
  },
  // =-=-=-=-=-= BankProfileListingV2 and BankProfileForm =-=-=-=-=-=-=
  {
    props: {
      exact: true,
      path: '/cash-book/general-setting/bank-profile',
    },
    component: <BankProfileListingV2 />,
  },
  {
    props: {
      exact: true,
      path: '/cash-book/general-setting/bank-profile/:mode',
    },
    component: <BankProfileForm />,
  },
]

export default bankProfileRoutes
