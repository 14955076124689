import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import React from 'react'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'

export const MFRS16JournalTable = (props: any) => {
  const {
    showCheckbox,
    tableData,
    handleCheckSeparate,
    checkboxValues,
    checkboxCondition,
  } = props

  // Use Scheduled Year(Month) value as control for the time being; try to use index in the future
  const coloredCell = (row: number) => {
    if (row === 0 || row % 2 === 0) {
      return '#FFFFFF'
    } else {
      return '#FFF1DE'
    }
  }

  return (
    <ContentWrapper style={{ marginTop: 0, marginBottom: '50px' }}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StickyTableCell>
                {!!showCheckbox && <StyledTableCell></StyledTableCell>}
                <StyledTableCell style={{ textAlign: 'center' }}>
                  Year
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>
                  Month
                </StyledTableCell>
              </StickyTableCell>
              <StyledTableCell>Lease Liability b/f</StyledTableCell>
              <StyledTableCell>Lease Payment</StyledTableCell>
              <StyledTableCell>Principal</StyledTableCell>
              <StyledTableCell>Interest</StyledTableCell>
              <StyledTableCell>Lease Liability c/f</StyledTableCell>
              <StyledTableCell>Lease Liability -NBV</StyledTableCell>
              <StyledTableCell>Right of Use Asset</StyledTableCell>
              <StyledTableCell>Depreciation Charge</StyledTableCell>
              <StyledTableCell>Accumulated Depreciation</StyledTableCell>
              <StyledTableCell>NBV</StyledTableCell>
              <StyledTableCell>Discounted Total Cash Flow</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((x, i) => {
              return (
                <StyledTableRow>
                  <StickyTableCell
                    style={{
                      backgroundColor: coloredCell(Number(i)),
                    }}
                  >
                    {!!showCheckbox && (
                      <StyledTableCell style={{ padding: 0 }}>
                        <Checkbox
                          color="primary"
                          value={x?.ID}
                          onChange={e => {
                            handleCheckSeparate(e?.target, checkboxCondition)
                          }}
                          checked={checkboxValues.has(x?.ID)}
                        />
                      </StyledTableCell>
                    )}
                    <StyledTableCell
                      style={{
                        fontWeight: 600,
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {x?.Year}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontWeight: 600,
                        textAlign: 'center',
                        width: '50%',
                      }}
                    >
                      {x?.Month}
                    </StyledTableCell>
                  </StickyTableCell>
                  <StyledTableCell>{amtStr(x?.Value)}</StyledTableCell>
                  <StyledTableCell>{amtStr(x?.Value)}</StyledTableCell>
                  <StyledTableCell>{amtStr(x?.Value)}</StyledTableCell>
                  <StyledTableCell>{amtStr(x?.Value)}</StyledTableCell>
                  <StyledTableCell>{amtStr(x?.Value)}</StyledTableCell>
                  <StyledTableCell>{amtStr(x?.Value)}</StyledTableCell>
                  <StyledTableCell>{amtStr(x?.Value)}</StyledTableCell>
                  <StyledTableCell>{amtStr(x?.Value)}</StyledTableCell>
                  <StyledTableCell>{amtStr(x?.Value)}</StyledTableCell>
                  <StyledTableCell>{amtStr(x?.Value)}</StyledTableCell>
                  <StyledTableCell>{amtStr(x?.Value)}</StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ContentWrapper>
  )
}

const StickyTableCell = withStyles(theme => ({
  head: {
    minWidth: '50px',
    left: 0,
    position: 'sticky',
    zIndex: 3, //IMPORTANT; AccountFooter's z-index is 4
    backgroundColor: '#FF9800',
    color: theme.palette.common.white,
    borderBottom: 'none',
  },
  body: {
    minWidth: '50px',
    left: 0,
    position: 'sticky',
    zIndex: 3, //IMPORTANT; AccountFooter's z-index is 4
    borderBottom: 'none',
    backgroundColor: '#FFFFFF',
  },
}))(TableCell)

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#FF9800',
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: 12,
    borderBottom: 'none',
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  body: {
    color: theme.palette.common.black,
    fontSize: 12,
    borderBottom: 'none',
    textAlign: 'right',
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#FFF1DE',
    },
  },
}))(TableRow)
