import React from 'react'
import { MFRS16JournalMain } from './MFRS16JournalMain'
import { MFRS16JournalForm } from './MFRS16JournalForm/MFRS16JournalForm'

export const generateMFRS16JournalRoutes = [
  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/mfrs16/:CompanyID/generate-mfrs16-journal',
    },
    component: (
      <MFRS16JournalMain
        transactionType="Generate MFRS 16 Journal"
        transactionTypePrefix="generate-mfrs16-journal"
      />
    ),
  },
  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/mfrs16/:CompanyID/generate-mfrs16-journal/:LeaseContractID/generate',
    },
    component: (
      <MFRS16JournalForm
        transactionType="Generate MFRS 16 Journal"
        transactionTypePrefix="generate-mfrs16-journal"
      />
    ),
  },
]
