import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput as DocUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { ListItem, ListItemText, TextField } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { statusInput } from 'containers/ARAPModule/Function/DocumentChecker'
import {
  calculateMonthDifference,
  calculatePV,
  calculateTotalLeasePayment,
} from 'containers/MFRS16Module/Helper/Calculation'
import {
  useCreateLeaseContractMutation,
  useDocumentListingLazyQuery,
  useGetCompanySummaryQuery,
  useGetLeaseContractLazyQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateLeaseContractMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDashDate } from 'helpers/StringNumberFunction/FormatDate'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'
// import '../APAdvance.scss'

interface LeaseContractProps {
  CompanyID: string
  LeaseContractID: string
  StartJournalDate: string
  StartDate: string
  EndDate: string
  AccountROU: string
  LeaseMonth: number
  ContractNo: string
  MonthlyLeasePayment: number
  TotalLeasePayment: number
  AnnualDiscountRate: number
  DiscountedTotalCashFlow: number
  Description: string
  Remark: string
  Attachment: null
  Amount: number
  uploadAttach: string
}

export const LeaseContractForm = (props: any) => {
  const { formMode, type } = props

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  let history = useHistory()
  let location = useLocation()

  // const editData = location?.state as any
  const { CompanyID, LeaseContractID }: any = useParams()
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [monthLease, setMonthLease] = useState(0)
  const [monthLeasePayment, setMonthLeasePayment] = useState(0)
  const [totalLeasePayment, setTotalLeasePayment] = useState(0)
  const [discountRate, setDiscountRate] = useState(0)
  const [initDocs, setInitDocs] = useState([])
  const [editData, setEditData] = useState<any>({})
  const [voiceRemark, setVoiceRemark] = useState('')
  const [record, setRecord] = useState(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [errorDia, setErrorDia] = useState<boolean>(false)

  const LeaseContractFormSchema = yup.object().shape({
    StartDate: yup.string().required('Start Date is Required'),
    EndDate: yup.string().required('End Date is Required'),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    ContractNo: CommonYupValidation.requireField(SystemMsgs.contractNo()),
    LeaseMonth: yup
      .number()
      .required('Amount is required')
      .moreThan(0)
      .typeError('Amount is required'),
    AnnualDiscountRate: yup
      .number()
      .required('Amount is required')
      .moreThan(0)
      .typeError('Amount is required'),
    MonthlyLeasePayment: yup
      .number()
      .transform((originalValue, originalObject) => {
        if (originalValue?.length > 3) {
          return parseInt(originalObject.replace(/,/g, ''))
        } else {
          return parseFloat(originalObject)
        }
      })
      .min(0.0001, 'Quantity must be at least 0.0001')
      .required('Monthly lease payment is required'),
    TotalLeasePayment: yup
      .number()
      .transform((originalValue, originalObject) => {
        if (originalValue?.length > 3) {
          return parseInt(originalObject.replace(/,/g, ''))
        } else {
          return parseFloat(originalObject)
        }
      })
      .min(0.0001, 'Quantity must be at least 0.0001')
      .required('Monthly lease payment is required'),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    getValues,
    clearErrors,
    formState,
  } = useForm<LeaseContractProps>({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(LeaseContractFormSchema),
  })

  /* ------------------------------------------ */
  /*                 QUERY                      */
  /* ------------------------------------------ */
  const menu = location?.state as any

  const [
    loadLeaseContract,
    {
      loading: LeaseContractLoading,
      data: { getLeaseContract } = { getLeaseContract: [] },
    },
  ] = useGetLeaseContractLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getLeaseContract }) => {
      if (getLeaseContract?.length > 0) {
        setEditData(getLeaseContract[0])
        setDiscountRate(getLeaseContract[0]?.AnnualDiscountRate)
        setMonthLeasePayment(getLeaseContract[0]?.LeasePayment)
        setValue('MonthlyLeasePayment', getLeaseContract[0]?.LeasePayment)
      }
    },
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanySummaryQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: ({ getCompany }) => {
      if (getCompany?.length > 0 && formMode === 'edit')
        loadLeaseContract({
          variables: {
            CompanyID: CompanyID,
            LeaseContractID: menu?.LeaseContractID,
          },
        })
    },
  })

  /* -------------------------------------------- */
  /*                   MUTATION                   */
  /* -------------------------------------------- */
  const [
    createLeaseContract,
    { loading: createLeaseContractLoading, error: createLeaseContractError },
  ] = useCreateLeaseContractMutation({
    onError: error => {
      setErrMsg(error.message.substr(15, error.message.length - 1))
      setErrorDia(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/mfrs16/${CompanyID}/lease-contract`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })
  const [
    updateLeaseContract,
    { loading: updateLeaseContractLoading, error: updateLeaseContractError },
  ] = useUpdateLeaseContractMutation({
    onError: error => {
      setErrMsg(error.message.substr(15, error.message.length - 1))
      setErrorDia(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        history.push({
          pathname: `/mfrs16/${CompanyID}/lease-contract`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  const totalLeaseMonthPayment = () => {
    let totalLease = 0
    totalLease = calculateTotalLeasePayment(
      watch('MonthlyLeasePayment'),
      watch('LeaseMonth')
    )
    setValue('TotalLeasePayment', totalLease)
    setTotalLeasePayment(totalLease)
  }

  let discountedCashFlow = 0
  discountedCashFlow = calculatePV(discountRate, monthLease, monthLeasePayment)

  useEffect(() => {
    if (!!getLeaseContract[0]?.Remark) {
      setValue('Remark', getLeaseContract[0]?.Remark)
    }
  }, [editData])

  const inputData = (data, status) => {
    return {
      LeaseContractID: LeaseContractID,
      CompanyID: CompanyID,
      ContractNo: data?.ContractNo,
      Description: data?.Description,
      Remark: data?.Remark,
      StartDate: formatDashDate(new Date(data?.StartDate)?.toISOString()),
      EndDate: formatDashDate(new Date(data?.EndDate)?.toISOString()),
      StartJournalDate: new Date(data?.StartJournalDate).toISOString(),
      LeasePayment: monthLeasePayment,
      LeaseMonth: monthLease,
      TotalLeaseAmount: data?.TotalLeasePayment,
      AnnualDiscountRate: discountRate,
      DiscountedTotalCashFlows: Number(discountedCashFlow?.toFixed(2)),
      DiscountedTotalCashFlowsValues:
        data?.TotalLeasePayment - Number(discountedCashFlow?.toFixed(2)),
      IsAccountROU: data?.AccountROU === 'Yes' ? true : false,
      ApprovalStatus: statusInput(status),
      Attachment: files,
    }
  }

  const handleMutation =
    formMode === 'add' ? createLeaseContract : updateLeaseContract

  /* ---------------------------------------------- */
  /*                   ATTACHMENT                   */
  /* ---------------------------------------------- */
  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    loadDoc,
    { loading: DocLoad, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      if (!menu?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  useEffect(() => {
    if (getLeaseContract[0]?.Attachment) {
      setInitDocs(getLeaseContract[0]?.Attachment)
    }
  }, [editData])

  useEffect(() => {
    if (!DocLoad && formMode !== 'add') {
      loadDoc({ variables: { refID: LeaseContractID } })
    }
    if (menu?.files) {
      setFiles(menu?.files)
      setPreviewFiles(
        menu?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [formMode, LeaseContractID, editData])

  const onSubmit = (data, status) => {
    handleMutation({
      variables: {
        input: inputData(data, status),
      },
    })
  }

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: () => {
      handleSubmit(data => onSubmit(data, 'submit'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
  }
  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
  }

  let footerOptions: any[]
  if (menu?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  const [openExitConf, setOpenExitConf] = useState(null)

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
      formMode: formMode,
    })

  return (
    <>
      {CompanyLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}
      {LeaseContractLoading && <Loading />}
      {createLeaseContractLoading && <Loading />}
      {updateLeaseContractLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          hasChanges() === true
            ? setOpenExitConf(true)
            : history.push(`/mfrs16/${CompanyID}/lease-contract`)
        }}
        smTitle={'MFRS 16'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'MFRS 16' },
          { name: 'Lease Contract' },
          { name: 'Lease Contract', current: true },
        ]}
        rightRouteSegments={[
          {
            name: 'New',
            //   mode === 'add'
            //     ? 'New'
            //     : mode === 'approve-reject'
            //     ? 'Approve/Reject'
            //     : 'Edit',
            current: true,
          },
        ]}
      />
      {!LeaseContractLoading && (
        <ContentWrapper float footer>
          <CardContents>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                required
                as={KeyboardDatePicker}
                name="StartDate"
                label="Start Date"
                control={control}
                onChange={(date: Date | null) => {
                  //date
                }}
                // onAccept={(date: Date | null) => {
                //   updateNewPaymentItem(date, taxEffectiveList)
                // }}
                format="dd/MM/yyyy"
                value={new Date()}
                margin="dense"
                allowKeyboardControl
                ref={register}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                defaultValue={
                  !!getLeaseContract[0]
                    ? getLeaseContract[0]?.StartDate
                    : new Date()
                }
                showTodayButton
                className="left"
                disabled={mode == 'approve-reject'}
                style={{
                  paddingTop: '10px',
                }}
                // minDate={
                //   //   StartDateValidationBefore(
                //   // getDocumentDateValidation[0]?.MonthsBefore
                //   //)
                //   formMode === 'add'
                //     ? StartDateValidationBefore(
                //         getDocumentDateValidation[0]?.MonthsBefore
                //       )
                //     : getLeaseContract[0]?.StartDate
                // }
                // maxDate={StartDateValidationAfter(
                //   getDocumentDateValidation[0]?.MonthsAfter
                // )}
              />
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="EndDate"
                required
                label="End Date"
                control={control}
                format="dd/MM/yyyy"
                margin="normal"
                allowKeyboardControl
                onChange={(date: Date | null) => {}}
                ref={register}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                showTodayButton
                className="right"
                value={new Date()}
                defaultValue={
                  !!getLeaseContract[0]
                    ? getLeaseContract[0]?.EndDate
                    : new Date()
                }
                // minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
                // maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              />
            </MuiPickersUtilsProvider>

            <Controller
              as={TextField}
              id="standard-basic"
              name="ContractNo"
              label="Contract No."
              className="left"
              required
              autoComplete="off"
              control={control}
              margin="dense"
              ref={register}
              helperText={errors?.ContractNo?.message}
              error={errors?.ContractNo ? true : false}
              defaultValue={
                !!getLeaseContract[0] ? getLeaseContract[0]?.ContractNo : ''
              }
              disabled={mode == 'approve-reject'}
            />

            <Controller
              render={({ onChange }) => (
                <NumberFormat
                  disabled
                  allowNegative={true}
                  customInput={TextField}
                  autoComplete="off"
                  thousandSeparator
                  isNumericString
                  className="right"
                  margin="dense"
                  value={calculateMonthDifference(
                    watch('StartDate'),
                    watch('EndDate')
                  )}
                  onValueChange={values => {
                    setMonthLease(values?.floatValue)
                    let { floatValue } = values
                    onChange(
                      values.floatValue,
                      register(`LeaseMonth`),
                      setValue(`LeaseMonth`, floatValue),
                      calculateMonthDifference(
                        watch('StartDate'),
                        watch('EndDate')
                      )
                    )
                  }}
                  label="Lease Month"
                  // InputLabelProps={{ shrink: true }}
                  defaultValue={
                    !!getLeaseContract ? getLeaseContract[0]?.LeaseMonth : 0
                  }
                />
              )}
              customInput={TextField}
              ref={register}
              control={control}
              disabled
              margin="dense"
              className="right"
              name="LeaseMonth"
              label="Lease Month"
              helperText={errors?.LeaseMonth?.message}
              error={errors?.LeaseMonth ? true : false}
              defaultValue={
                !!getLeaseContract ? getLeaseContract[0]?.LeaseMonth : 0
              }
            />
            <Controller
              as={
                <NumberFormat
                  allowNegative={false}
                  value={monthLeasePayment}
                  defaultValue={
                    !!getLeaseContract ? getLeaseContract[0]?.LeasePayment : 0
                  }
                  className="left"
                  required
                  customInput={TextField}
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  label="Monthly Lease Payment"
                  onValueChange={e => {
                    setMonthLeasePayment(e?.floatValue)
                    setValue('MonthlyLeasePayment', e?.floatValue)
                    totalLeaseMonthPayment()
                  }}
                />
              }
              name="MonthlyLeasePayment"
              control={control}
              margin="dense"
              required
              className="left"
              helperText={errors?.MonthlyLeasePayment?.message}
              error={errors?.MonthlyLeasePayment ? true : false}
              ref={register}
              defaultValue={0}
            />
            <Controller
              as={<NumberFormat allowNegative={false} defaultValue={0} />}
              onValueChange={(value, newValue: any) => {
                setTotalLeasePayment(value?.floatValue)
              }}
              value={totalLeasePayment}
              defaultValue={
                !!getLeaseContract ? getLeaseContract[0]?.TotalLeaseAmount : 0
              }
              className="left"
              required
              customInput={TextField}
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              name="TotalLeasePayment"
              label="Total Lease Payment"
              control={control}
              margin="dense"
              helperText={errors?.TotalLeasePayment?.message}
              error={errors?.TotalLeasePayment ? true : false}
              ref={register}
            />
            <Controller
              as={
                <NumberFormat
                  defaultValue={0}
                  allowNegative={false}
                  value={discountRate}
                  className="left"
                  required
                  customInput={TextField}
                  thousandSeparator
                  // decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  label="Annual Discount Rate"
                  onValueChange={e => {
                    setDiscountRate(e?.floatValue)
                    setValue('AnnualDiscountRate', e?.floatValue)
                    // handleAmtChange(e)
                  }}
                />
              }
              name="AnnualDiscountRate"
              control={control}
              margin="dense"
              required
              className="left"
              helperText={errors?.AnnualDiscountRate?.message}
              error={errors?.AnnualDiscountRate ? true : false}
              ref={register}
              defaultValue={
                !!getLeaseContract ? getLeaseContract[0]?.AnnualDiscountRate : 0
              }
            />
            <Controller
              render={({ onChange }) => (
                <NumberFormat
                  allowNegative={false}
                  disabled
                  value={discountedCashFlow?.toFixed(2) ?? 0}
                  onValueChange={values => {
                    let { floatValue } = values
                    onChange(
                      values.floatValue,
                      register(`DiscountedTotalCashFlow`),
                      setValue(`DiscountedTotalCashFlow`, floatValue),
                      discountedCashFlow?.toFixed(2)
                    )
                  }}
                  defaultValue={
                    !!getLeaseContract ? getLeaseContract[0]?.LeaseMonth : 0
                  }
                  className="left"
                  required
                  customInput={TextField}
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  label="Discounted Total Cash Flow"
                />
              )}
              disabled
              name="DiscountedTotalCashFlow"
              control={control}
              margin="dense"
              required
              className="left"
              value={discountedCashFlow?.toFixed(2) ?? 0}
              ref={register}
              defaultValue={0}
            />
            <Controller
              required
              as={KeyboardDatePicker}
              name="StartJournalDate"
              label="Start Journal Date"
              control={control}
              onChange={(date: Date | null) => {
                //date
              }}
              // onAccept={(date: Date | null) => {
              //   updateNewPaymentItem(date, taxEffectiveList)
              // }}
              format="dd/MM/yyyy"
              value={new Date()}
              margin="dense"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={
                !!getLeaseContract[0]
                  ? getLeaseContract[0]?.StartJournalDate
                  : new Date()
              }
              showTodayButton
              className="left"
              disabled={mode == 'approve-reject'}

              // minDate={
              //   //   StartJournalDateValidationBefore(
              //   // getDocumentDateValidation[0]?.MonthsBefore
              //   //)
              //   formMode === 'add'
              //     ? StartDateValidationBefore(
              //         getDocumentDateValidation[0]?.MonthsBefore
              //       )
              //     : getLeaseContract[0]?.StartDate
              // }
              // maxDate={StartDateValidationAfter(
              //   getDocumentDateValidation[0]?.MonthsAfter
              // )}
            />

            <Controller
              as={TextField}
              id="standard-basic"
              name="AccountROU"
              label="Account ROU"
              className="left"
              required
              autoComplete="off"
              control={control}
              margin="dense"
              ref={register}
              helperText={errors?.AccountROU?.message}
              error={errors?.AccountROU ? true : false}
              defaultValue={getLeaseContract[0]?.IsAccountROU ? 'Yes' : 'No'}
              disabled
            />

            <Controller
              as={TextField}
              id="standard-basic"
              name="Description"
              label="Description"
              required
              autoComplete="off"
              control={control}
              fullWidth
              margin="dense"
              helperText={errors?.Description?.message}
              error={errors?.Description ? true : false}
              ref={register}
              defaultValue={
                !!getLeaseContract ? getLeaseContract[0]?.Description : ''
              }
            />

            <VoiceTextField
              mounted={true}
              label="Remark"
              name="Remark"
              value={voiceRemark}
              setValue={setValue}
              record={record}
              setRecord={setRecord}
              control={control}
              controllerProps={{
                error: errors?.Remark ? true : false,
                helperText: errors?.Remark?.message,
                ref: register,
                autoComplete: 'off',
              }}
            />

            <DocUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                'application/msword, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              mapData={'application'}
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        // files[i]?.type
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
              disabled={mode === 'approve-reject'}
            />
          </CardContents>
        </ContentWrapper>
      )}

      {footerOptions?.length > 0 ? (
        <Footer options={[...footerOptions]} />
      ) : null}

      <CommonDialog
        fullWidth={true}
        open={openExitDialog}
        onClose={() => setopenExitDialog(false)}
        sections={{
          header: {
            title: 'Exit Confirmation',
          },
          body: () => (
            <>
              <div>
                Are you sure you want to exit? Your changes will not be saved.
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setopenExitDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => history.goBack(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth={true}
        open={errorDia}
        onClose={() => setErrorDia(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        {'Document Numbering is empty'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>{'Please setup Document Numbering to Submit'}</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setErrorDia(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/mfrs16/${CompanyID}/lease-contract`)
        }}
      />
    </>
  )
}
