import theme from '@ifca-root/react-component/src/assets/theme'
import CardContentsWithInput from '@ifca-root/react-component/src/components/CardList/CardContentsWithInput'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  InputBase,
  useMediaQuery,
  styled,
} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { default as React, useEffect } from 'react'

const dummyStockItems = [
  {
    Name: 'Kawasaki',
    Counts: { current: '3', total: '0' },
    Department: 'Department',
    Location: 'Location',
  },
  {
    Name: 'Lorry',
    Counts: { current: '6', total: '0' },
    Department: 'Department',
    Location: 'Location',
  },
  {
    Name: 'Van',
    Counts: { current: '11', total: '0' },
    Department: 'Department',
    Location: 'Location',
  },
]

const CustomInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: '4px',
    padding: '2px 6px',
    width: '40px',
    textAlign: 'right',

    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      display: 'none',
    },

    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  border: '1px solid lightgray',
  borderRadius: '4px',
  transition: 'border-color 0.3s',
  '&:focus-within': {
    borderColor: '#F39C12',
  },
}))

interface FormStockTableContentProps {
  listItem?: typeof dummyStockItems
  headerLeftTitle?: string
}

export const FormStockTableContent = ({
  listItem = dummyStockItems,
  headerLeftTitle = 'Stock Item (3)',
}: FormStockTableContentProps) => {
  useEffect(() => {
    console.log('FormStockTableContent')
  }, [])

  function handleChange(
    itemIndex: number
  ): React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> {
    return event => {
      console.log(`Changed value for item ${itemIndex}:`, event.target.value)
    }
  }

  return (
    <CardContentsWithInput
      section={{
        header: {
          title: headerLeftTitle,
        },
      }}
      style={{ padding: 'initial' }}
    >
      <div className="table-wrap rm-padding">
        <List className="core-list content-wrap full" disablePadding>
          {listItem.map((el, index) => (
            <ListItem
              key={index}
              className="table-listItem"
              divider
              style={{ backgroundColor: 'white' }}
            >
              <ListItemText
                primary={
                  <Grid
                    container
                    justifyContent="flex-start"
                    spacing={0}
                    alignItems="center"
                  >
                    <Grid item xs={8} style={{ placeSelf: 'start' }}>
                      <div
                        className="xsTitle"
                        style={{ lineBreak: 'anywhere' }}
                      >
                        {el.Name}
                      </div>
                    </Grid>
                  </Grid>
                }
                secondary={
                  <>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={6}>
                        <span style={{ fontSize: '12px' }}>
                          {el.Department}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          textAlign: 'right',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ fontSize: '12px', marginRight: '4px' }}>
                          {el.Counts.current} Unit /
                        </span>
                        <CustomInput
                          type="number"
                          value={el.Counts.total}
                          onChange={handleChange(index)}
                          inputProps={{
                            min: 0,
                            max: parseInt(el.Counts.total),
                          }}
                        />
                        <span style={{ fontSize: '12px', marginLeft: '4px' }}>
                          Unit
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={1}>
                      <LocationOnIcon
                        style={{
                          fontSize: 16,
                          marginRight: 4,
                          color: '#F39C12',
                        }}
                      />
                      <span style={{ fontSize: '12px' }}>{el.Location}</span>
                    </Grid>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
      <div className="footer-label" style={{ background: '#bfd3ff' }} />
    </CardContentsWithInput>
  )
}
