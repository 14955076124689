import theme from '@ifca-root/react-component/src/assets/theme'
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { DepreciationDetail } from './DepreciationDetail'
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter'

export const DepreciationGenerateSummary = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [depreciateIDs, setDepreciateIDs] = useState<Set<any>>(new Set([]))
  const [openExitConf, setOpenExitConf] = useState(null)
  const [
    selectedDepreciationSummary,
    setSelectedDepreciationSummary,
  ] = useState<any[]>([])

  useEffect(() => {
    const data = JSON.parse(
      localStorage.getItem('selectedDepreciationGenerate')
    )

    if (data) {
      setSelectedDepreciationSummary(data)
    }
  }, [])

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  const handleSelectAll = e => {
    let temp = depreciateIDs
    if (e.target.checked) {
      selectedDepreciationSummary.map(v => {
        temp.add(v?.ID)
      })
    } else {
      temp.clear()
    }
    setDepreciateIDs(new Set(temp))
  }

  const handleCheckBoxChange = (e: any, depreciateID) => {
    let temp = depreciateIDs
    if (!temp.has(depreciateID)) temp.add(depreciateID)
    else temp.delete(depreciateID)

    setDepreciateIDs(new Set(temp))
  }

  let listDepreciateIDs: any = Array.from(depreciateIDs || [])

  /* -------------------------------------------- */
  /*                   EXIT CONF                  */
  /* -------------------------------------------- */

  const hasChanges = () => {
    if (depreciateIDs?.size === 0) return false
    else return true
  }

  /* -------------------------------------------- */
  /*                    ONSUBMIT                  */
  /* -------------------------------------------- */

  const onSubmit = () => {
    let selectedDepreciate = Array.from(depreciateIDs)

    if (selectedDepreciate.length === 0) {
      alert('Please select at least one asset to generate depreciation.')
      return
    }

    const depreciationData = selectedDepreciationSummary.filter(item =>
      depreciateIDs.has(item.ID)
    )

    setSelectedDepreciationSummary(depreciationData)

    // history.push(`/fixed-assets/${CompanyID}/depreciation/confirmation`)

    // Reset selected IDs after submission
    setDepreciateIDs(new Set([]))
  }

  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true
            ? setOpenExitConf(true)
            : history.push(
                `/fixed-assets/${CompanyID}/depreciation/generate-depreciation`
              )
          localStorage.removeItem(`selectedDepreciationGenerate`)
        }}
        smTitle="Fixed Assets"
        title={user?.companyName}
        routeSegments={[
          { name: 'Fixed Assets' },
          { name: 'Generate Depreciation', current: true },
        ]}
        rightRouteSegments={[{ name: 'New', current: true }]}
      />

      <ContentWrapper
        footer
        overflow
        style={{ marginTop: isDesktop ? '70px' : '-7px' }}
      >
        <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
          <Checkbox
            onChange={handleSelectAll}
            color="primary"
            value={''}
            checked={
              depreciateIDs?.size === selectedDepreciationSummary?.length
            }
            style={{ marginLeft: '-5px', marginTop: '-5px' }}
          />
          <span style={{ marginLeft: '9px' }}>Select All</span>
        </span>
        <List className="core-list">
          {selectedDepreciationSummary === undefined ||
          selectedDepreciationSummary?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <>
              {advancedFilterList(selectedDepreciationSummary)
                // ?.filter(handleFilterDate)
                .map((el, index) => {
                  return (
                    <CardExpansion
                      summary={
                        <ListItem key={index}>
                          <FormControl
                            component="fieldset"
                            style={{ width: '16px' }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={el?.ID}
                                  onChange={e => {
                                    handleCheckBoxChange(e, el?.ID)
                                  }}
                                  onClick={e => e.stopPropagation()}
                                  checked={depreciateIDs?.has(el?.ID)}
                                  color="primary"
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '-5px',
                                  }}
                                />
                              }
                              label=""
                              name="check"
                            />
                          </FormControl>

                          <ListItemText
                            style={{ marginLeft: '25px' }}
                            primary={
                              <>
                                <span
                                  className="desc"
                                  style={{
                                    whiteSpace: 'break-spaces',
                                    lineBreak: 'auto',
                                  }}
                                ></span>
                                <span className="xxTitle flex-space">
                                  {el?.Name}
                                </span>
                                <span
                                  className="xxTitle"
                                  style={{ color: 'orange' }}
                                >
                                  {el?.Date}
                                </span>
                              </>
                            }
                          />
                        </ListItem>
                      }
                    >
                      {
                        <>
                          <DepreciationDetail
                            CompanyID={CompanyID}
                            listEl={el}
                          />
                        </>
                      }
                    </CardExpansion>
                  )
                })}
            </>
          )}
        </List>
      </ContentWrapper>

      <AccountFooter
        options={[
          {
            name: (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                Next
                <span
                  style={{
                    marginLeft: '8px',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    color: '#000',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  {depreciateIDs.size}
                </span>
              </span>
            ),
            onClick: () => {
              onSubmit()
            },
            color: 'primary',
            props: {}, // Any additional props
          },
        ]}
      />
    </>
  )
}
