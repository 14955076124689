import React, { useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TablePagination } from '@material-ui/core'
import { dateFormat } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import format from 'date-fns/format'

export const ConsolidateTable = (props: any) => {
  const { creditorOrDebtor, data } = props

  // ==== Table Pagination =====
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: '700' }} size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  width: '10%',
                }}
              >
                Doc Date
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  width: '15%',
                }}
              >
                Doc No
              </StyledTableCell>
              {creditorOrDebtor === 'Creditor' && (
                <StyledTableCell
                  style={{
                    whiteSpace: 'nowrap',
                    backgroundColor: '#ff9800',
                    width: '15%',
                  }}
                >
                  Original Doc No
                </StyledTableCell>
              )}
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  width: '40%',
                }}
              >
                {`${creditorOrDebtor} Name`}
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  textAlign: 'right',
                  width: '15%',
                }}
              >
                Doc Amount
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  textAlign: 'right',
                  width: '15%',
                }}
              >
                Tax Amount
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Important to dynamically slice list upon each onChange
              ?.map((v, i) => {
                return (
                  <TableRow>
                    <StyledTableCell size="small">
                      {!!v?.SB?.DocDate
                        ? format(new Date(v?.SB?.DocDate), 'dd/MM/yyyy')
                        : dateFormat(v?.DocDate)}
                    </StyledTableCell>
                    <StyledTableCell size="small">
                      {v?.DocNo || v?.SB?.DocNo}
                    </StyledTableCell>
                    {creditorOrDebtor === 'Creditor' && (
                      <StyledTableCell size="small">
                        {!!v?.APDocNo?.OriDocNo
                          ? v?.APDocNo?.OriDocNo
                          : !!v?.APLedger?.DocNo
                          ? v?.APLedger?.DocNo
                          : '-'}
                      </StyledTableCell>
                    )}
                    <StyledTableCell size="small">
                      {creditorOrDebtor === 'Debtor'
                        ? v?.DebtorName || v?.CreditorDebtorName
                        : v?.CreditorAccount?.CompanyName ||
                          v?.CreditorDebtorName}
                    </StyledTableCell>
                    <StyledTableCell
                      size="small"
                      style={{ textAlign: 'right' }}
                    >
                      {amtStr(v?.DocAmt)}
                    </StyledTableCell>
                    <StyledTableCell
                      size="small"
                      style={{ textAlign: 'right' }}
                    >
                      {amtStr(v?.TaxAmt)}
                    </StyledTableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#2F53E6',
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: 12,
  },
  body: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 12,
  },
}))(TableCell)
