import theme from '@ifca-root/react-component/src/assets/theme';
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import BigNumber from 'bignumber.js';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { formatDate } from '../../../../helpers/StringNumberFunction/formatDate';

export const GeneralGRTNContentListing = props => {
  const {
    GRTNLoading,
    doArr,
    mode,
    editData,
    setNoError,
    useFormProps: {
      clearErrors,
      setError,
      errors,
      watch,
      register,
      control,
      setValue,
    },
  } = props;

  const [isQtyDisabled, setIsQtyDisabled] = useState(false);
  const [isAmtDisabled, setIsAmtDisabled] = useState(false);

  const handleQtyChange = event => {
    const value = event.target.value;
    setIsAmtDisabled(value !== '');
  };

  const handleAmtChange = event => {
    const value = event.target.value;
    setIsQtyDisabled(value !== '');
  };

  //=============================================//
  //-------------------SEARCH--------------------//
  //=============================================//
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  function amtStrNoRounding(number) {
    if (typeof number !== 'number' || isNaN(number)) {
      return '';
    }
    const parts = number.toString().split('.');
    if (parts[1] && parts[1].length < 4) {
      parts[1] = parts[1].padEnd(4, '0');
    } else if (!parts[1]) {
      parts.push('0000');
    }

    return parts.join('.');
  }

  return (
    <>
      {GRTNLoading && <Loading />}
      <List className="core-list">
        {doArr?.map((el, i) => {
          const defaultDOHeaderIDs = editData?.GRTNHeaderItem?.map(
            x => x?.DOItem?.DOHeaderID,
          );

          return (
            <CardExpansion
              id={`${el?.DOHeaderID}`}
              expansion={
                mode === 'edit'
                  ? !!defaultDOHeaderIDs.includes(el?.DOHeaderID)
                  : false
              }
              key={`${el?.DOHeaderID}`}
              summary={
                <Grid item xs={12}>
                  <ListItemText
                    primary={
                      <>
                        <Grid container className="fw-medium desc">
                          <input
                            name={`getDO[${el?.DOHeaderID}].DOHeaderID`}
                            ref={register}
                            defaultValue={el?.DOHeaderID}
                            style={{ display: 'none' }}
                          />
                          <Grid item xs={9} className="mdLabel">
                            <span>{`${el?.DocNo}  | ${el?.DoNo}`}</span>
                          </Grid>
                          <Grid item xs={3} className="right-text">
                            <span>{formatDate(el?.DocDate)}</span>
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                </Grid>
              }
            >
              <div className="rm-padding table-wrap">
                <List
                  className="core-list content-wrap ;"
                  style={{ display: 'contents' }}
                >
                  {el?.DOItem?.filter(fi =>
                    mode === 'add' ? fi?.UpToDateQty > 0 : fi?.AcceptedQty > 0,
                  )?.map((inel, initem) => {
                    const OutstandingQty =
                      mode === 'edit'
                        ? editData?.GRTNHeaderItem?.find(
                            f => f?.DOItemID === inel?.DOItemID,
                          )?.DOItem?.UpToDateQty !== undefined
                          ? editData?.GRTNHeaderItem?.find(
                              f => f?.DOItemID === inel?.DOItemID,
                            )?.DOItem?.AcceptedQty
                          : inel?.UpToDateQty
                        : inel?.UpToDateQty ?? 0;
                    const returnQty = editData?.GRTNHeaderItem.find(
                      x =>
                        x?.DOItem?.DOHeaderID === inel?.DOHeaderID &&
                        x?.DOItem?.DOItemID === inel?.DOItemID,
                    )?.ReturnedQty;
                    const totalOrdQty = OutstandingQty + returnQty;

                    const balanceQty =
                      totalOrdQty !== undefined
                        ? totalOrdQty > inel?.AcceptedQty
                          ? inel?.AcceptedQty
                          : totalOrdQty
                        : inel?.UpToDateQty;

                    const ReturnedQty =
                      typeof watch(
                        `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                      ) === 'string' &&
                      watch(
                        `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                      ).includes(',')
                        ? watch(
                            `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                          ).replace(/,/g, '')
                        : watch(
                            `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                          );

                    return (
                      <ListItem
                        key={initem}
                        className="table-listItem"
                        divider={initem > 1 ? true : false}
                        style={{ padding: '0px', backgroundColor: 'white' }}
                      >
                        <ListItemText
                          primary={
                            <Grid container className="desc">
                              {/* Item Name */}
                              <Grid
                                item
                                xs={1}
                                component="span"
                                className="desc"
                                style={{
                                  paddingTop: '10px',
                                  paddingLeft: '8px',
                                }}
                              >
                                {initem + 1}.
                              </Grid>

                              <Grid
                                item
                                xs={11}
                                component="span"
                                className="desc fw-medium"
                                style={{ paddingTop: '10px' }}
                              >
                                <div
                                  className="text-noflow"
                                  style={{
                                    width: '100%',
                                    wordWrap: 'break-word',
                                  }}
                                >
                                  {inel?.POItem !== null
                                    ? inel?.POItem?.Item?.Name
                                    : inel?.ReplacedGRTNItem?.DOItem?.POItem
                                        ?.Item?.Name}
                                </div>
                              </Grid>

                              {/* UOM | Cost Centre Name | Warehouse */}
                              <Grid
                                item
                                xs={1}
                                component="span"
                                className="desc"
                              ></Grid>

                              <Grid
                                item
                                xs={11}
                                component="span"
                                className="desc fw-medium"
                              >
                                <div
                                  className="text-noflow"
                                  style={{
                                    width: '100%',
                                    wordWrap: 'break-word',
                                  }}
                                >
                                  <span className="desc fw-medium">
                                    {inel?.POItem !== null
                                      ? inel?.POItem?.UOM?.Code
                                      : inel?.ReplacedGRTNItem?.DOItem?.POItem
                                          ?.UOM?.Code}
                                  </span>{' '}
                                  {' | '}{' '}
                                  {inel?.POItem !== null
                                    ? inel?.CostCentre?.Name
                                    : inel?.ReplacedGRTNItem?.DOItem?.POItem
                                        ?.CostCentre?.Name ?? ''}{' '}
                                  {`(` +
                                    // (inel?.POItem?.Item?.IsInventory
                                    //   ? inel?.POItem?.Warehouse?.Name
                                    //   : inel?.POItem?.DeliveryLocation?.Name)
                                    (inel?.POItem !== null
                                      ? inel?.POItem?.Item?.IsInventory
                                        ? inel?.POItem?.Warehouse?.Name
                                        : inel?.POItem?.DeliveryLocation?.Name
                                      : inel?.ReplacedGRTNItem?.DOItem?.POItem
                                          ?.Warehouse !== null
                                      ? inel?.ReplacedGRTNItem?.DOItem?.POItem
                                          ?.Warehouse?.Name
                                      : inel?.ReplacedGRTNItem?.DOItem?.POItem
                                          ?.DeliveryLocation?.Name) +
                                    `)`}
                                </div>
                              </Grid>
                              {/* Remarks  */}
                              <Grid item xs={1} component="span" />
                              <Grid item xs={11} component="div">
                                <div className="desc text-noflow fw-medium">
                                  {!!inel?.POItem?.Remarks
                                    ? inel?.POItem?.POHeader?.DocNo +
                                      ` | ` +
                                      inel?.POItem?.Remarks
                                    : inel?.ReplacedGRTNItem?.DOItem?.POItem
                                        ?.POHeader?.DocNo +
                                      ` | ` +
                                      inel?.ReplacedGRTNItem?.DOItem?.POItem
                                        ?.Remarks}
                                </div>
                              </Grid>
                            </Grid>
                          }
                          secondary={
                            <>
                              <Grid container spacing={2} className="desc">
                                {/* Unit Price Columns */}
                                <Grid
                                  item
                                  xs={3}
                                  component="span"
                                  className="desc"
                                  style={{
                                    padding: '10px 8px 0 0',
                                  }}
                                >
                                  <td
                                    style={{
                                      paddingTop: '10px',
                                      width: isDesktop ? 'auto' : '100px',
                                    }}
                                  >
                                    <input
                                      name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].doItemID`}
                                      ref={register}
                                      defaultValue={inel?.DOItemID}
                                      style={{ display: 'none' }}
                                    />
                                    <input
                                      name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].UOMID`}
                                      ref={register}
                                      defaultValue={inel?.UOMID}
                                      style={{ display: 'none' }}
                                    />

                                    <Controller
                                      as={<NumberFormat />}
                                      thousandSeparator
                                      disabled
                                      variant="outlined"
                                      label="Unit Price"
                                      className="disabled-input"
                                      autoComplete="off"
                                      customInput={TextField}
                                      allowNegative={false}
                                      fixedDecimalScale
                                      name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].UnitPrice`}
                                      ref={register}
                                      control={control}
                                      decimalScale={4}
                                      defaultValue={inel?.UnitPrice}
                                    />
                                  </td>
                                </Grid>

                                {/* DO Qty Columns */}
                                <Grid
                                  item
                                  xs={3}
                                  component="span"
                                  className="desc"
                                  style={{
                                    padding: '10px 8px 0 0',
                                  }}
                                >
                                  <td
                                    style={{
                                      paddingTop: '10px',
                                      width: isDesktop ? 'auto' : '100px',
                                    }}
                                  >
                                    <input
                                      name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].doItemID`}
                                      ref={register}
                                      defaultValue={inel?.DOItemID}
                                      style={{ display: 'none' }}
                                    />
                                    <input
                                      name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].UOMID`}
                                      ref={register}
                                      defaultValue={inel?.UOMID}
                                      style={{ display: 'none' }}
                                    />

                                    <Controller
                                      as={<NumberFormat />}
                                      thousandSeparator
                                      disabled
                                      variant="outlined"
                                      label="DO Qty"
                                      className="disabled-input"
                                      autoComplete="off"
                                      customInput={TextField}
                                      allowNegative={false}
                                      fixedDecimalScale
                                      name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].UpToDateQty`}
                                      ref={register}
                                      control={control}
                                      decimalScale={4}
                                      defaultValue={
                                        mode === 'add'
                                          ? inel?.UpToDateQty
                                          : editData?.GRTNHeaderItem?.find(
                                              f =>
                                                f?.DOItemID === inel?.DOItemID,
                                            )?.DOItem?.UpToDateQty !== undefined
                                          ? editData?.GRTNHeaderItem?.find(
                                              f =>
                                                f?.DOItemID === inel?.DOItemID,
                                            )?.DOItem?.UpToDateQty +
                                            editData?.GRTNHeaderItem?.find(
                                              f =>
                                                f?.DOItemID === inel?.DOItemID,
                                            )?.ReturnedQty
                                          : inel?.UpToDateQty
                                      }
                                    />
                                  </td>
                                </Grid>

                                {/* ReturnedQty Column */}
                                <Grid
                                  item
                                  xs={3}
                                  component="span"
                                  className="desc"
                                >
                                  <td
                                    style={{
                                      paddingTop: '10px',
                                      width: '100px',
                                    }}
                                  >
                                    <Controller
                                      control={control}
                                      ref={register}
                                      label="Rtn Qty"
                                      defaultValue={
                                        mode !== 'add'
                                          ? editData?.GRTNHeaderItem?.find(
                                              x =>
                                                x?.DOItemID === inel?.DOItemID,
                                            )?.ReturnedQty
                                          : '.0000'
                                      }
                                      name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`}
                                      render={({ onChange, value }) => {
                                        return (
                                          <NumberFormat
                                            thousandSeparator
                                            label="Rtn Qty"
                                            value={
                                              value === '0.0000'
                                                ? '.0000'
                                                : watch(
                                                    `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                                                  ) ?? '.0000'
                                            }
                                            allowNegative={false}
                                            disabled={isQtyDisabled}
                                            // onChange={handleQtyChange}
                                            onChange={e => {
                                              onChange(e);
                                              handleQtyChange(e);
                                            }}
                                            customInput={TextField}
                                            className="disabled-input"
                                            name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`}
                                            variant="outlined"
                                            decimalScale={4}
                                            fixedDecimalScale
                                            defaultValue={
                                              !!editData
                                                ? editData?.GRTNHeaderItem?.find(
                                                    x =>
                                                      x?.DOItemID ===
                                                      inel?.DOItemID,
                                                  )?.ReturnedQty
                                                : '.0000'
                                            }
                                            onValueChange={({ floatValue }) => {
                                              const val = floatValue;
                                              let displayValue;
                                              if (
                                                val === 0 ||
                                                val === null ||
                                                val === undefined
                                              ) {
                                                displayValue = '.0000';
                                              } else {
                                                displayValue = val;
                                              }

                                              const unitPrice =
                                                inel?.UnitPrice || 0;

                                              const calculatedAmt =
                                                Number(
                                                  (val * unitPrice).toFixed(6),
                                                ) || 0;

                                              const roundedValue =
                                                Math.round(
                                                  calculatedAmt * 100,
                                                ) / 100;

                                              if (!isQtyDisabled) {
                                                register(
                                                  `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`,
                                                );

                                                setValue(
                                                  `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`,
                                                  roundedValue.toFixed(2),
                                                );
                                              }

                                              if (val !== 0.0) {
                                                if (
                                                  new BigNumber(value).gt(
                                                    inel?.UpToDateQty,
                                                  )
                                                ) {
                                                  setNoError(false);
                                                  setError(
                                                    `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                    {
                                                      type: 'manual',

                                                      message:
                                                        'Quantity exceeded delivered',
                                                    },
                                                  );
                                                } else if (
                                                  new BigNumber(value).lt(0)
                                                ) {
                                                  setNoError(false);
                                                  setError(
                                                    `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                    {
                                                      type: 'manual',
                                                      message:
                                                        'Quantity must be positive',
                                                    },
                                                  );
                                                } else {
                                                  setNoError(true);
                                                  clearErrors(
                                                    `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                  );
                                                }
                                                onChange(
                                                  isAmtDisabled
                                                    ? amtStrNoRounding(
                                                        displayValue,
                                                      )
                                                    : displayValue,
                                                );
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    />
                                  </td>
                                </Grid>

                                {/* Rtn Amt Column */}
                                <Grid
                                  item
                                  xs={3}
                                  component="span"
                                  className="desc"
                                >
                                  <td
                                    style={{
                                      paddingTop: '10px',
                                      width: '100px',
                                    }}
                                  >
                                    <Controller
                                      control={control}
                                      ref={register}
                                      label="Rtn Amt"
                                      defaultValue={
                                        !!editData
                                          ? editData?.GRTNHeaderItem?.filter(
                                              v =>
                                                v?.DOItemID === inel?.DOItemID,
                                            )?.length > 0
                                            ? editData?.GRTNHeaderItem.find(
                                                x =>
                                                  x?.DOItem?.DOHeaderID ===
                                                    inel?.DOHeaderID &&
                                                  x?.DOItem?.DOItemID ===
                                                    inel?.DOItemID,
                                              )?.ReturnedAmt || '.00'
                                            : '.00'
                                          : '.00'
                                      }
                                      name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`}
                                      render={({ onChange, value }) => {
                                        return (
                                          <NumberFormat
                                            thousandSeparator
                                            label="Rtn Amt"
                                            allowNegative={false}
                                            customInput={TextField}
                                            disabled={isAmtDisabled}
                                            onChange={e => {
                                              handleAmtChange(e);
                                            }}
                                            className="disabled-input"
                                            name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`}
                                            variant="outlined"
                                            value={
                                              value === '0.00'
                                                ? '.00'
                                                : watch(
                                                    `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`,
                                                  ) ?? '.00'
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale
                                            onValueChange={({ floatValue }) => {
                                              const val = floatValue;
                                              let displayValue;
                                              if (
                                                val === 0 ||
                                                val === null ||
                                                val === undefined
                                              ) {
                                                displayValue = '.00';
                                              } else {
                                                displayValue = Number(val);
                                              }

                                              const unitPrice =
                                                inel?.UnitPrice || 0;

                                              const calculatedQty =
                                                val / unitPrice || 0;

                                              if (!isAmtDisabled) {
                                                register(
                                                  `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                                                );

                                                setValue(
                                                  `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                                                  calculatedQty.toFixed(4),
                                                );
                                              }

                                              if (val !== 0.0) {
                                                if (
                                                  new BigNumber(value).gt(
                                                    inel?.ReturnedAmt,
                                                  )
                                                ) {
                                                  setNoError(false);
                                                  setError(
                                                    `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                    {
                                                      type: 'manual',

                                                      message:
                                                        'Quantity exceeded delivered',
                                                    },
                                                  );
                                                } else if (
                                                  new BigNumber(value).lt(0)
                                                ) {
                                                  setNoError(false);
                                                  setError(
                                                    `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                    {
                                                      type: 'manual',
                                                      message:
                                                        'Quantity must be positive',
                                                    },
                                                  );
                                                } else {
                                                  setNoError(true);
                                                  clearErrors(
                                                    `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                  );
                                                }
                                                onChange(
                                                  isQtyDisabled
                                                    ? amtStrNoRounding(
                                                        displayValue,
                                                      )
                                                    : displayValue,
                                                );
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    />
                                  </td>
                                </Grid>
                              </Grid>

                              {/* Checking Rtn Qty */}
                              <Grid container className="desc">
                                <Grid
                                  item
                                  xs={12}
                                  style={{ textAlignLast: 'end' }}
                                >
                                  <span style={{ fontSize: '10px' }}>
                                    {ReturnedQty > OutstandingQty ? (
                                      <span style={{ color: 'red' }}>
                                        Quantity exceeded ordered
                                      </span>
                                    ) : null}
                                  </span>
                                </Grid>
                              </Grid>

                              {/* Replacement Column */}
                              <Grid container className="desc">
                                <Grid
                                  item
                                  style={{
                                    marginLeft: '30px',
                                    marginTop: '10px',
                                    paddingBottom: '10px',
                                  }}
                                >
                                  <Controller
                                    render={({ onChange, value }) => (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            style={{ marginLeft: '12px' }}
                                            color="primary"
                                            onChange={e => {
                                              onChange(e.target.checked);
                                            }}
                                            checked={value}
                                          />
                                        }
                                        label={
                                          <div
                                            className="desc"
                                            style={{
                                              color: 'lightslategray',
                                            }}
                                          >
                                            Replacement
                                          </div>
                                        }
                                        ref={register}
                                      />
                                    )}
                                    name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].replacement`}
                                    control={control}
                                    defaultValue={
                                      mode !== 'add'
                                        ? editData?.GRTNHeaderItem?.filter(
                                            v => v?.DOItemID === inel?.DOItemID,
                                          )?.map(x => x?.Replacement)[0]
                                        : true
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </CardExpansion>
          );
        })}
      </List>
    </>
  );
};
