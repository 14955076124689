import DateFnsUtils from '@date-io/date-fns'
import theme from '@ifca-root/react-component/src/assets/theme'
import {
  Button,
  Checkbox,
  ListItem,
  ListItemText,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import AdvanceFilterCardExpansion from 'components/CardExpansion/AdvanceFilterCardExpansion'
import { EInvoiceTrxType } from 'generated/graphql'
import React, { Fragment, useState } from 'react'
import { Controller } from 'react-hook-form'

export const InboundAdvanceFilter = ({
  registerFilter,
  controlFilter,
  setValueFilter,
  watchFilter,
  docType,
  setDocType,
  creditorAcc,
}) => {
  const today = new Date()
  const [expanded, setExpanded] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  let docTitle
  if (docType?.length > 0 && docType?.length < 4) {
    docTitle = `${docType?.length} Document Types`
  } else {
    docTitle = 'All Document Types Selected'
  }

  const renderFilterHeader = () => {
    return (
      <span className={`xsTitle flex-space`}>
        {docTitle}
        {/* {startDate !== '' && endDate !== '' ? `, ` : null}
        {startDate !== '' && `From: `}
        <span className={`mdDesc`} style={{ color: '#000000' }}>
          {startDate}
        </span>
        {endDate !== '' && ` To: `}
        <span className={`mdDesc`} style={{ color: '#000000' }}>
          {endDate}
        </span> */}
      </span>
    )
  }

  const onSubmitFilter = () => {}

  const resetFilter = () => {
    setDocType([])
    setValueFilter('DocType')
    setValueFilter(
      'StartDate',
      new Date(today.getFullYear(), today.getMonth() - 1, 1)
    )
    setValueFilter(
      'EndDate',
      new Date(today.getFullYear(), today.getMonth(), 0)
    )
    setValueFilter('CreditorName', '')
    setValueFilter('DocNo', '')

    setStartDate('')
    setEndDate('')
  }

  const [selectedDocType, setSelectedDocType] = useState(new Set())

  const handleCheckboxDocTypeChange = (event: any) => {
    let docType = selectedDocType

    if (event?.target?.checked) {
      docType.add(event?.target?.value)
    } else {
      docType.delete(event?.target?.value)
    }
    setSelectedDocType(new Set(docType))
  }

  return (
    <span style={{ position: 'relative', top: !isDesktop && '6vh' }}>
      <AdvanceFilterCardExpansion
        customColor={true}
        customColorCode="#faf2e8"
        expansion={expanded}
        summary={
          <ListItem style={{ backgroundColor: '#faf2e8', color: '#ff9800' }}>
            <ListItemText primary={<>{renderFilterHeader()}</>} />
          </ListItem>
        }
      >
        <AdvanceFilterContent
          registerFilter={registerFilter}
          controlFilter={controlFilter}
          setValueFilter={setValueFilter}
          watchFilter={watchFilter}
          onSubmitFilter={onSubmitFilter}
          resetFilter={resetFilter}
          creditorAcc={creditorAcc}
          selectedDocType={selectedDocType}
          setSelectedDocType={setSelectedDocType}
          handleCheckboxDocTypeChange={handleCheckboxDocTypeChange}
        />
      </AdvanceFilterCardExpansion>
    </span>
  )
}

const AdvanceFilterContent = ({
  registerFilter,
  controlFilter,
  setValueFilter,
  watchFilter,
  onSubmitFilter,
  resetFilter,
  creditorAcc,
  selectedDocType,
  setSelectedDocType,
  handleCheckboxDocTypeChange,
}) => {
  const today = new Date()
  const buttonStyle = (float: 'left' | 'right') => {
    return { minWidth: '50px', float, marginBottom: '15px' }
  }
  const documentType = [
    {
      name: 'Self-Billed Invoice',
      value: 'INVOICE',
      einvoice_type: EInvoiceTrxType.SelfBilledInvoice,
    },
    {
      name: 'Self-Billed Credit Note',
      value: 'CREDIT_NOTE',
      einvoice_type: EInvoiceTrxType.SelfBilledCreditNote,
    },
    {
      name: 'Self-Billed Debit Note',
      value: 'DEBIT_NOTE',
      einvoice_type: EInvoiceTrxType.SelfBilledDebitNote,
    },
    {
      name: 'Self-Billed Refund Note',
      value: 'REFUND_NOTE',
      einvoice_type: EInvoiceTrxType.SelfBilledRefundNote,
    },
  ]

  const docType = [
    EInvoiceTrxType.SelfBilledInvoice,
    EInvoiceTrxType.SelfBilledCreditNote,
    EInvoiceTrxType.SelfBilledDebitNote,
    EInvoiceTrxType.SelfBilledRefundNote,
  ]

  return (
    <div style={{ fontSize: '12px', marginBottom: '15px', display: 'block' }}>
      <Controller
        render={({ value, onChange }) => {
          return (
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={docType}
              getOptionLabel={option =>
                `${documentType.find(x => x?.einvoice_type === option).name}`
              }
              fullWidth
              onChange={(value, newValue: any) => {
                const selected = new Set()

                newValue?.map(x => {
                  selected.add(x)
                })

                setSelectedDocType(selected)
              }}
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    checked={selectedDocType.has(option)}
                    color="primary"
                    value={option}
                    onChange={event => {
                      handleCheckboxDocTypeChange(event)
                    }}
                  />
                  {`${
                    documentType.find(x => x?.einvoice_type === option).name
                  }`}
                </Fragment>
              )}
              renderInput={(params: any) => {
                return (
                  <div>
                    <TextField
                      {...params}
                      variant="outlined"
                      style={{ width: '100%' }}
                      label="Doc Type"
                      margin="normal"
                    />
                  </div>
                )
              }}
            />
          )
        }}
        name="DocType"
        label="Doc Type"
        margin="normal"
        autoComplete="off"
        fullWidth
        multiline={true}
        control={controlFilter}
        select
        ref={registerFilter}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Controller
          as={KeyboardDatePicker}
          name="StartDate"
          label="Doc Start Date"
          control={controlFilter}
          onChange={(date: Date | null) => {}}
          format="dd/MM/yyyy"
          value={watchFilter('StartDate')}
          margin="normal"
          allowKeyboardControl
          ref={registerFilter}
          KeyboardButtonProps={{ 'aria-label': 'change date' }}
          className="left"
          defaultValue={new Date(today.getFullYear(), today.getMonth() - 1, 1)}
          showTodayButton
          fullWidth
        />
      </MuiPickersUtilsProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Controller
          as={KeyboardDatePicker}
          name="EndDate"
          label="Doc End Date"
          control={controlFilter}
          onChange={(date: Date | null) => {
            setValueFilter('EndDate', date)
          }}
          format="dd/MM/yyyy"
          value={watchFilter('EndDate')}
          margin="normal"
          allowKeyboardControl
          ref={registerFilter}
          KeyboardButtonProps={{ 'aria-label': 'change date' }}
          className="right"
          defaultValue={new Date(today.getFullYear(), today.getMonth(), 0)}
          showTodayButton
          fullWidth
        />
      </MuiPickersUtilsProvider>
      <Controller
        render={({ value, onChange }) => {
          const defVal = creditorAcc?.filter(
            x =>
              x?.CreditorAccountID ===
              watchFilter('CreditorAccountID')?.CreditorAccountID
          )[0]
          return (
            <Autocomplete
              options={
                creditorAcc?.sort((a, b) => {
                  return a.CompanyName.localeCompare(b.CompanyName)
                }) || []
              }
              getOptionLabel={option => `${option?.CompanyName}`}
              fullWidth
              onChange={(value, newValue: any) => {
                setValueFilter('CreditorAccountID', newValue?.CreditorAccountID)
              }}
              renderOption={(props, option) => {
                return <span>{props?.CompanyName}</span>
              }}
              value={defVal}
              renderInput={(params: any) => {
                return (
                  <div>
                    <TextField
                      {...params}
                      label="Creditor Name"
                      style={{ width: '100%' }}
                      margin="dense"
                      required
                    />
                  </div>
                )
              }}
            />
          )
        }}
        label="Creditor Name"
        name="CreditorAccountID"
        autoComplete="off"
        control={controlFilter}
        multiline={true}
        fullWidth
        margin="dense"
        ref={registerFilter}
        required
      />
      <Controller
        as={TextField}
        style={{ marginTop: '8px', marginBottom: '12px' }}
        id="standard-basic"
        name="TIN"
        label="Creditor TIN"
        autoComplete="off"
        control={controlFilter}
        fullWidth
        margin="dense"
        ref={registerFilter}
      />
      <Controller
        as={TextField}
        style={{ marginTop: '8px', marginBottom: '12px' }}
        id="standard-basic"
        name="DocNo"
        label="Document No."
        autoComplete="off"
        control={controlFilter}
        fullWidth
        margin="dense"
        ref={registerFilter}
      />
      <Button
        type="reset"
        color="secondary"
        style={buttonStyle('left')}
        variant="contained"
        onClick={() => {
          resetFilter()
        }}
      >
        Reset
      </Button>
      <Button
        type="submit"
        color="primary"
        style={buttonStyle('right')}
        variant="contained"
        onClick={() => {
          onSubmitFilter()
        }}
      >
        Filter
      </Button>
    </div>
  )
}
