import React, { lazy } from 'react'
import { BankReconciliationDetail } from './BankReconciliationDetail'
import { BankReconciliationItemDetail } from './BankReconciliationItemDetail'
import { BankReconciliationExcelValidation } from './BankReconcilliationExcelValidation'
import { AuditLogListing } from 'components/Listing/AuditLog'

const BankReconciliationListing = lazy(() =>
  import('./BankReconciliationListing').then(module => ({
    default: module.BankReconciliationListing,
  }))
)

const BankReconciliationStatementForm = lazy(() =>
  import('./BankReconciliationStatementForm').then(module => ({
    default: module.BankReconciliationStatementForm,
  }))
)

const bankreconciliationRoutes = [
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/submenu/:BankAccountID/bank-reconciliation',
    },
    component: <BankReconciliationListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/bank-reconciliation/validation',
    },
    component: <BankReconciliationExcelValidation />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/bank-reconciliation/:BankReconciliationID/match',
    },
    component: <BankReconciliationStatementForm formMode="Match" />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/bank-reconciliation/:BankReconciliationID/unmatch',
    },
    component: <BankReconciliationStatementForm formMode="UnMatch" />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/bank-reconciliation/:BankReconciliationID/details',
    },
    component: <BankReconciliationDetail />,
  },

  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/bank-reconciliation/:BankReconciliationID/:backpathmode/bank-statement/:BankReconciliationItemID/detail',
    },
    component: <BankReconciliationItemDetail formMode="bank-statement" />,
  },

  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/bank-reconciliation/:BankReconciliationID/:backpathmode/cash-book-ledger/:LedgerID/detail',
    },
    component: <BankReconciliationItemDetail formMode="cash-book-ledger" />,
  },
]

export default bankreconciliationRoutes
