import React from 'react'

export const exampleInboundDocument = () => {
  const exampleDocument = [
    {
      ID: '1',
      DocNo: 'INV0106',
      APDocDate: '2024-04-25T00:00:00Z',
      DocDate: '2024-04-20T00:00:00Z',
      DocAmt: 1000.0,
      TaxAmt: 100.0,
      xmlFileUrl: '/path/to/xml/file.xml',
      payloadUrl: '/path/to/payload.json',
      APDocNo: 'APINV0106',
      TrxCurrencyCode: 'MYR',
      DocType: 'Self-Billed Invoice',
      //Document Detail
      DocumentDetail: {
        ModuleName: 'AccountX Account Payable',
        DocNo: 'INV0106',
        DocType: 'INVOICE', //EnumType?
        DocumentType: 'Self-Billed Invoice',
        eInvRefNo: 'TRX 001',
        eInvDate: '2024-11-15',
        eInvSubmissionID: null,
        eInvTrxNo: null,
        eInvLongID: null,
        eInvType: '01',
        eInvQRUrl: null,
        CurrencyCode: 'MYR',
        BaseCurrencyCode: 'MYR',
        CurrencyRate: 1,
        BillFrequency: null,
        BillStartDate: '2024-11-15',
        BillEndDate: '2024-11-15',
        PaymentMode: null,
        PaymentTerm: null,
        TotalExcludingTax: 100,
        TotalIncludingTax: 100,
        TotalPayableAmount: 100,
        TotalNet: 100,
        TotalDiscount: null,
        TotalFee: 100,
        TotalTax: null,
        TotalRoundingAmt: null,
        PrePayDate: null,
        PrePayRefNo: null,
        PrePayAmt: null,
        CustomRefNo: null,
        CustomRefNoDocType: null,
        IncoTerms: null,
        FTA: null,
        ExporterNo: null,
        ExporterAgency: null,
        CustomRefNo2: null,
        CustomRefNo2DocType: null,
        OtherChargesDocNo: null,
        OtherChargesAmt: null,
        OtherChargesReason: null,
        TaxExemp: null,
        TaxExempAmt: null,
        SendTime: null,
        ValidateTime: null,
      },
      // Supplier / Creditor Detail
      CreditorAccount: {
        CompanyName: 'ABC Corp',
        einvoice_TIN: 'C6899578040',
        CompanyIDNo: '453392-T',
        IdentityType: 'BRN',
        SSTRegNo: null,
        ContactNo: '+60123456789',
        Email: 'test@abc.com',
        BankAccountNo: null,
        MSICCode: '01272',
        TouristRegNo: null,
        BusinessActivityDescription: 'Growing of tea',
        Address1:
          'IFCA MSC BHD, No. 19, Wisma IFCA, Jalan PJU 1/42a, Dataran Prima',
        Address2: null,
        Address3: null,
        Country: 'MYS',
        State: 'Selangor',
        StateCode: '10',
        City: 'Petaling Jaya',
        Postcode: '47301',
      },
      // Buyer Detail
      BuyerDetail: {
        CompanyName: 'IFCA Solutions Sdn Bhd',
        einvoice_TIN: 'C6899792020',
        CompanyIDNo: '199701036988',
        IdentityType: 'BRN',
        SSTRegNo: null,
        ContactNo: '+60123456789',
        Email: 'buyer@abc.com',
        Address1:
          'IFCA MSC BHD, No. 19, Wisma IFCA, Jalan PJU 1/42a, Dataran Prima',
        Address2: null,
        Address3: null,
        Country: 'MYS',
        State: 'Selangor',
        StateCode: '10',
        City: 'Petaling Jaya',
        Postcode: '47301',
      },
      // RecipientDetail
      RecipientDetail: {
        CompanyName: null,
        einvoice_TIN: null,
        CompanyIDNo: null,
        IdentityType: null,
        Address1: null,
        Address2: null,
        Address3: null,
        Country: null,
        State: null,
        StateCode: null,
        City: null,
        Postcode: null,
      },
      // TransactionTax
      TransactionTax: [
        {
          TaxType: '01',
          TaxableAmt: 102,
          TaxAmt: 5.05,
        },
        {
          TaxType: '03',
          TaxableAmt: 120,
          TaxAmt: 3.03,
        },
      ],
      // TransactionRefNo
      TransactionRefNo: [
        {
          TrxDocRefNo: 'ABC-098',
          IrbmRefTrxNo: 'X7D77HKQ9Y7FSWX3MTED4PDJ10',
        },
        {
          TrxDocRefNo: 'ABC-099',
          IrbmRefTrxNo: 'X&CHKJD9Y7FSHD^&TED4BV*R(&',
        },
      ],
      // TransactionItem
      TransactionItem: [
        {
          ClassificationCode: '003',
          Description: 'Service',
          UnitPrice: 200.0,
          Subtotal: 200.0,
          TotalExcludeTax: 202.0,
          TaxAmt: 8.08,
          Quantity: 1.0,
          UOM: { Code: 'AB' },
          ProductTariffCode: '1',
          OriginCountry: 'MYS',
          Tax: [
            {
              TaxType: '02',
              TaxAmt: 8.08,
              TaxableAmt: 0.0,
              Percent: 0.0,
            },
          ],
          DiscountRate: 0.0,
          DiscountAmt: 0.0,
          TaxExemp: null,
          TaxExempAmt: 0,
          TaxExempList: [],
          TaxAdditionalList: [],
        },
        {
          ClassificationCode: '003',
          Description: 'Service',
          UnitPrice: 230.0,
          Subtotal: 230.0,
          TotalExcludeTax: 212.0,
          TaxAmt: 18.0,
          Quantity: 1.0,
          UOM: { Code: 'AB' },
          ProductTariffCode: '1',
          OriginCountry: 'MYS',
          Tax: [
            {
              TaxType: '01',
              TaxAmt: 18.0,
              TaxableAmt: 0.0,
              Percent: 0.0,
            },
          ],
          DiscountRate: 0.0,
          DiscountAmt: 0.0,
          TaxExemp: null,
          TaxExempAmt: 0,
          TaxExempList: [],
          TaxAdditionalList: [],
        },
        {
          ClassificationCode: '003',
          Description: 'Service',
          UnitPrice: 230.0,
          Subtotal: 230.0,
          TotalExcludeTax: 212.0,
          TaxAmt: 0.0,
          Quantity: 1.0,
          UOM: { Code: 'AB' },
          ProductTariffCode: '1',
          OriginCountry: 'MYS',
          Tax: [],
          DiscountRate: 0.0,
          DiscountAmt: 0.0,
          TaxExemp: null,
          TaxExempAmt: 0,
          TaxExempList: [],
          TaxAdditionalList: [],
        },
      ],
    },
    {
      ID: '2',
      DocNo: 'CN0106',
      APDocDate: '2024-04-25T00:00:00Z',
      DocDate: '2024-04-20T00:00:00Z',
      DocAmt: 1000.0,
      TaxAmt: 100.0,
      xmlFileUrl: '/path/to/xml/file.xml',
      payloadUrl: '/path/to/payload.json',
      APDocNo: 'CN0106',
      TrxCurrencyCode: 'MYR',
      DocType: 'Self-Billed Credit Note',
      //Document Detail
      DocumentDetail: {
        ModuleName: 'AccountX Account Payable',
        DocNo: 'CN0106',
        DocType: 'CREDIT_NOTE', //EnumType?
        DocumentType: 'Self-Billed Credit Note',
        eInvRefNo: 'TRX 001',
        eInvDate: '2024-11-15',
        eInvSubmissionID: null,
        eInvTrxNo: null,
        eInvLongID: null,
        eInvType: '01',
        eInvQRUrl: null,
        CurrencyCode: 'MYR',
        BaseCurrencyCode: 'MYR',
        CurrencyRate: 1,
        BillFrequency: null,
        BillStartDate: '2024-11-15',
        BillEndDate: '2024-11-15',
        PaymentMode: null,
        PaymentTerm: null,
        TotalExcludingTax: 100,
        TotalIncludingTax: 100,
        TotalPayableAmount: 100,
        TotalNet: 100,
        TotalDiscount: null,
        TotalFee: 100,
        TotalTax: null,
        TotalRoundingAmt: null,
        PrePayDate: null,
        PrePayRefNo: null,
        PrePayAmt: null,
        CustomRefNo: null,
        CustomRefNoDocType: null,
        IncoTerms: null,
        FTA: null,
        ExporterNo: null,
        ExporterAgency: null,
        CustomRefNo2: null,
        CustomRefNo2DocType: null,
        OtherChargesDocNo: null,
        OtherChargesAmt: null,
        OtherChargesReason: null,
        TaxExemp: null,
        TaxExempAmt: null,
        SendTime: null,
        ValidateTime: null,
      },
      // Supplier / Creditor Detail
      CreditorAccount: {
        CompanyName: 'DEF Corp',
        einvoice_TIN: 'C6899578832',
        CompanyIDNo: '453392-T',
        IdentityType: 'BRN',
        SSTRegNo: null,
        ContactNo: '+60123456789',
        Email: 'test@abc.com',
        BankAccountNo: null,
        MSICCode: '01272',
        TouristRegNo: null,
        BusinessActivityDescription: 'Growing of tea',
        Address1:
          'IFCA MSC BHD, No. 19, Wisma IFCA, Jalan PJU 1/42a, Dataran Prima',
        Address2: null,
        Address3: null,
        Country: 'MYS',
        State: 'Selangor',
        StateCode: '10',
        City: 'Petaling Jaya',
        Postcode: '47301',
      },
      // Buyer Detail
      BuyerDetail: {
        CompanyName: 'IFCA Solutions Sdn Bhd',
        einvoice_TIN: 'C6899792020',
        CompanyIDNo: '199701036988',
        IdentityType: 'BRN',
        SSTRegNo: null,
        ContactNo: '+60123456789',
        Email: 'buyer@abc.com',
        Address1:
          'IFCA MSC BHD, No. 19, Wisma IFCA, Jalan PJU 1/42a, Dataran Prima',
        Address2: null,
        Address3: null,
        Country: 'MYS',
        State: 'Selangor',
        StateCode: '10',
        City: 'Petaling Jaya',
        Postcode: '47301',
      },
      // RecipientDetail
      RecipientDetail: {
        CompanyName: null,
        einvoice_TIN: null,
        CompanyIDNo: null,
        IdentityType: null,
        Address1: null,
        Address2: null,
        Address3: null,
        Country: null,
        State: null,
        StateCode: null,
        City: null,
        Postcode: null,
      },
      // TransactionTax
      TransactionTax: [
        {
          TaxType: '01',
          TaxableAmt: 102,
          TaxAmt: 5.05,
        },
        {
          TaxType: '03',
          TaxableAmt: 120,
          TaxAmt: 3.03,
        },
      ],
      // TransactionRefNo
      TransactionRefNo: [
        {
          TrxDocRefNo: 'ABC-098',
          IrbmRefTrxNo: 'X7D77HKQ9Y7FSWX3MTED4PDJ10',
        },
        {
          TrxDocRefNo: 'ABC-099',
          IrbmRefTrxNo: 'X&CHKJD9Y7FSHD^&TED4BV*R(&',
        },
      ],
      // TransactionItem
      TransactionItem: [
        {
          ClassificationCode: '003',
          Description: 'Service',
          UnitPrice: 200.0,
          Subtotal: 200.0,
          TotalExcludeTax: 202.0,
          TaxAmt: 8.08,
          Quantity: 1.0,
          UOM: { Code: 'AB' },
          ProductTariffCode: '1',
          OriginCountry: 'MYS',
          Tax: [
            {
              TaxType: '02',
              TaxAmt: 8.08,
              TaxableAmt: 0.0,
              Percent: 0.0,
            },
          ],
          DiscountRate: 0.0,
          DiscountAmt: 0.0,
          TaxExemp: null,
          TaxExempAmt: 0,
          TaxExempList: [],
          TaxAdditionalList: [],
        },
        {
          ClassificationCode: '003',
          Description: 'Service',
          UnitPrice: 230.0,
          Subtotal: 230.0,
          TotalExcludeTax: 212.0,
          TaxAmt: 18.0,
          Quantity: 1.0,
          UOM: { Code: 'AB' },
          ProductTariffCode: '1',
          OriginCountry: 'MYS',
          Tax: [
            {
              TaxType: '01',
              TaxAmt: 18.0,
              TaxableAmt: 0.0,
              Percent: 0.0,
            },
          ],
          DiscountRate: 0.0,
          DiscountAmt: 0.0,
          TaxExemp: null,
          TaxExempAmt: 0,
          TaxExempList: [],
          TaxAdditionalList: [],
        },
        {
          ClassificationCode: '003',
          Description: 'Service',
          UnitPrice: 230.0,
          Subtotal: 230.0,
          TotalExcludeTax: 212.0,
          TaxAmt: 0.0,
          Quantity: 1.0,
          UOM: { Code: 'AB' },
          ProductTariffCode: '1',
          OriginCountry: 'MYS',
          Tax: [],
          DiscountRate: 0.0,
          DiscountAmt: 0.0,
          TaxExemp: null,
          TaxExempAmt: 0,
          TaxExempList: [],
          TaxAdditionalList: [],
        },
      ],
    },
    {
      ID: '3',
      DocNo: 'DN0106',
      APDocDate: '2024-04-25T00:00:00Z',
      DocDate: '2024-04-20T00:00:00Z',
      DocAmt: 1000.0,
      TaxAmt: 100.0,
      xmlFileUrl: '/path/to/xml/file.xml',
      payloadUrl: '/path/to/payload.json',
      APDocNo: 'DN0106',
      TrxCurrencyCode: 'MYR',
      DocType: 'Self-Billed Debit Note',
      //Document Detail
      DocumentDetail: {
        ModuleName: 'AccountX Account Payable',
        DocNo: 'DN0106',
        DocType: 'DEBIT_NOTE', //EnumType?
        DocumentType: 'Self-Billed Debit Note',
        eInvRefNo: 'TRX 001',
        eInvDate: '2024-11-15',
        eInvSubmissionID: null,
        eInvTrxNo: null,
        eInvLongID: null,
        eInvType: '01',
        eInvQRUrl: null,
        CurrencyCode: 'MYR',
        BaseCurrencyCode: 'MYR',
        CurrencyRate: 1,
        BillFrequency: null,
        BillStartDate: '2024-11-15',
        BillEndDate: '2024-11-15',
        PaymentMode: null,
        PaymentTerm: null,
        TotalExcludingTax: 100,
        TotalIncludingTax: 100,
        TotalPayableAmount: 100,
        TotalNet: 100,
        TotalDiscount: null,
        TotalFee: 100,
        TotalTax: null,
        TotalRoundingAmt: null,
        PrePayDate: null,
        PrePayRefNo: null,
        PrePayAmt: null,
        CustomRefNo: null,
        CustomRefNoDocType: null,
        IncoTerms: null,
        FTA: null,
        ExporterNo: null,
        ExporterAgency: null,
        CustomRefNo2: null,
        CustomRefNo2DocType: null,
        OtherChargesDocNo: null,
        OtherChargesAmt: null,
        OtherChargesReason: null,
        TaxExemp: null,
        TaxExempAmt: null,
        SendTime: null,
        ValidateTime: null,
      },
      // Supplier / Creditor Detail
      CreditorAccount: {
        CompanyName: 'GHI Corp',
        einvoice_TIN: 'C6899578832',
        CompanyIDNo: '453392-T',
        IdentityType: 'BRN',
        SSTRegNo: null,
        ContactNo: '+60123456789',
        Email: 'test@abc.com',
        BankAccountNo: null,
        MSICCode: '01272',
        TouristRegNo: null,
        BusinessActivityDescription: 'Growing of tea',
        Address1:
          'IFCA MSC BHD, No. 19, Wisma IFCA, Jalan PJU 1/42a, Dataran Prima',
        Address2: null,
        Address3: null,
        Country: 'MYS',
        State: 'Selangor',
        StateCode: '10',
        City: 'Petaling Jaya',
        Postcode: '47301',
      },
      // Buyer Detail
      BuyerDetail: {
        CompanyName: 'IFCA Solutions Sdn Bhd',
        einvoice_TIN: 'C6899792020',
        CompanyIDNo: '199701036988',
        IdentityType: 'BRN',
        SSTRegNo: null,
        ContactNo: '+60123456789',
        Email: 'buyer@abc.com',
        Address1:
          'IFCA MSC BHD, No. 19, Wisma IFCA, Jalan PJU 1/42a, Dataran Prima',
        Address2: null,
        Address3: null,
        Country: 'MYS',
        State: 'Selangor',
        StateCode: '10',
        City: 'Petaling Jaya',
        Postcode: '47301',
      },
      // RecipientDetail
      RecipientDetail: {
        CompanyName: null,
        einvoice_TIN: null,
        CompanyIDNo: null,
        IdentityType: null,
        Address1: null,
        Address2: null,
        Address3: null,
        Country: null,
        State: null,
        StateCode: null,
        City: null,
        Postcode: null,
      },
      // TransactionTax
      TransactionTax: [
        {
          TaxType: '01',
          TaxableAmt: 102,
          TaxAmt: 5.05,
        },
        {
          TaxType: '03',
          TaxableAmt: 120,
          TaxAmt: 3.03,
        },
      ],
      // TransactionRefNo
      TransactionRefNo: [
        {
          TrxDocRefNo: 'ABC-098',
          IrbmRefTrxNo: 'X7D77HKQ9Y7FSWX3MTED4PDJ10',
        },
        {
          TrxDocRefNo: 'ABC-099',
          IrbmRefTrxNo: 'X&CHKJD9Y7FSHD^&TED4BV*R(&',
        },
      ],
      // TransactionItem
      TransactionItem: [
        {
          ClassificationCode: '003',
          Description: 'Service',
          UnitPrice: 200.0,
          Subtotal: 200.0,
          TotalExcludeTax: 202.0,
          TaxAmt: 8.08,
          Quantity: 1.0,
          UOM: { Code: 'AB' },
          ProductTariffCode: '1',
          OriginCountry: 'MYS',
          Tax: [
            {
              TaxType: '02',
              TaxAmt: 8.08,
              TaxableAmt: 0.0,
              Percent: 0.0,
            },
          ],
          DiscountRate: 0.0,
          DiscountAmt: 0.0,
          TaxExemp: null,
          TaxExempAmt: 0,
          TaxExempList: [],
          TaxAdditionalList: [],
        },
        {
          ClassificationCode: '003',
          Description: 'Service',
          UnitPrice: 230.0,
          Subtotal: 230.0,
          TotalExcludeTax: 212.0,
          TaxAmt: 18.0,
          Quantity: 1.0,
          UOM: { Code: 'AB' },
          ProductTariffCode: '1',
          OriginCountry: 'MYS',
          Tax: [
            {
              TaxType: '01',
              TaxAmt: 18.0,
              TaxableAmt: 0.0,
              Percent: 0.0,
            },
          ],
          DiscountRate: 0.0,
          DiscountAmt: 0.0,
          TaxExemp: null,
          TaxExempAmt: 0,
          TaxExempList: [],
          TaxAdditionalList: [],
        },
        {
          ClassificationCode: '003',
          Description: 'Service',
          UnitPrice: 230.0,
          Subtotal: 230.0,
          TotalExcludeTax: 212.0,
          TaxAmt: 0.0,
          Quantity: 1.0,
          UOM: { Code: 'AB' },
          ProductTariffCode: '1',
          OriginCountry: 'MYS',
          Tax: [],
          DiscountRate: 0.0,
          DiscountAmt: 0.0,
          TaxExemp: null,
          TaxExempAmt: 0,
          TaxExempList: [],
          TaxAdditionalList: [],
        },
      ],
    },
  ]
  return exampleDocument
}
