import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { useGetUsersByAccountAndSoftwareQuery } from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { AssetsRegistrationEntryList } from './AssetsRegistrationList/AssetsRegistrationEntryList'

export const AssetsRegistrationMain = (props: any) => {
  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Approved', ID: 'COMPLETED' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'All', ID: 'ALL' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))
  const [openExitConf, setOpenExitConf] = useState(null)
  const [TotalLength, setTotalLength] = useState(0)
  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  let dummyData = [
    {
      Name: 'Asset Name',
      Category: 'Asset Category',
      Amount: 'Net Amount',
      RegistrationDate: '2021-05-27T12:44:01.162Z',
      Remark: 'Remark here',
      ApprovalStatus: 'ACTIVE',
      AssetNo: null,
      Description: null,
    },
    {
      Name: 'Computer',
      Category: 'Office Equipment',
      Amount: 4000,
      RegistrationDate: '2023-11-10',
      Remark: 'Remark here',
      ApprovalStatus: 'SUBMIT',
      AssetNo: null,
      Description: null,
    },
    {
      Name: 'Asset Name',
      Category: 'Asset Category',
      Amount: 'Net Amount',
      RegistrationDate: '2021-05-27T12:44:01.162Z',
      Remark: 'Remark here',
      ApprovalStatus: 'SUBMIT',
      AssetNo: null,
      Description: null,
    },
    {
      Name: 'Computer',
      Category: 'Office Equipment',
      Amount: 4000,
      RegistrationDate: '2023-11-10',
      Remark: 'Remark here',
      ApprovalStatus: 'COMPLETED',
      AssetNo: 'Asset No.',
      Description: 'Direct Purchase',
    },
  ]

  useEffect(() => {
    setOriginalListing([])
    // loadAuditAdjustment({
    //   variables: {
    //     CompanyID: CompanyID,
    //     StatusArr: [listStatus],
    //     take: 30,
    //     skip: 0,
    //     searchValue:
    //       getSearch?.name !== '' || getSearch?.name === undefined
    //         ? undefined
    //         : getSearch?.name,
    //   },
    // })
  }, [listStatus])

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({ fetchPolicy: 'network-only' })

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  /* -------------------------------------------- */
  /*                   EXIT CONF                  */
  /* -------------------------------------------- */

  const hasChanges = () => {
    if (postIDs?.size === 0) return false
    else return true
  }

  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        // loadAuditAdjustment({
        //   variables: {
        //     CompanyID: CompanyID,
        //     StatusArr: [listStatus],
        //     take: 30,
        //     skip: 0,
        //     searchValue: change,
        //   },
        // })
      }, 1000)
    )
  }

  return (
    <>
      {UserLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true && listStatus === 'SUBMIT'
            ? setOpenExitConf(true)
            : history.push(`/fixed-assets/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }}
        smTitle="Fixed Assets"
        title={user?.companyName}
        routeSegments={[
          { name: 'Fixed Assets' },
          { name: 'Assets Registration', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            changeDelay(e?.target?.value)
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
            // loadAuditAdjustment({
            //   variables: {
            //     CompanyID: CompanyID,
            //     StatusArr: [listStatus],
            //     take: 30,
            //     skip: 0,
            //   },
            // })
          }}
          isDefaultValue={!!getSearch?.name}
        />

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') && (
        // !AuditAdjustmentbyStatusLoading &&
        <AssetsRegistrationEntryList
          filteredList={dummyData}
          //   listLoading={AuditAdjustmentbyStatusLoading}
          listStatus={listStatus}
          userList={getUsersByAccountAndSoftware}
          //   fetchMore={loadAuditAdjustment}
          setOriginalListing={setOriginalListing}
        />
      )}
      {/* {listStatus === 'SUBMIT' && (
        // !AuditAdjustmentbyStatusLoading &&
        <AuditAdjustmentPostingList
          filteredList={filteredList}
          listLoading={AuditAdjustmentbyStatusLoading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing}
          fetchMore={loadAuditAdjustment}
        />
      )} */}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/fixed-assets/${CompanyID}`)
        }}
      />
    </>
  )
}
