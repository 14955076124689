import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'
import { Tune } from '@material-ui/icons'
import {
  useGetCompanyNameQuery,
  useGetLeaseContractLazyQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { LeaseContractEntryList } from '../LeaseContract/LeaseContractListing/LeaseContractEntryList'
// import { APEntryList } from './APListing/APEntryList'
// import { APPostingList } from './APListing/APPostingList'

export const ROUAssetsLeaseLiabilityScheduleMain = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { transactionTypePrefix } = props

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: { title: 'Doc Date', key: 'DocDate' },
  })

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onCompleted: ({ getCompany }) => {
      if (getCompany?.length > 0)
        loadLeaseContract({
          variables: {
            statusArr: 'COMPLETED',
            CompanyID: CompanyID,
            skip: 0,
            take: 30,
            searchValue:
              getSearch?.name === '' || !getSearch?.name
                ? undefined
                : getSearch?.name,
          },
        })
    },
  })

  const [
    loadLeaseContract,
    {
      loading: LeaseContractLoading,
      data: { getLeaseContract } = { getLeaseContract: [] },
    },
  ] = useGetLeaseContractLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getLeaseContract }) => {
      if (getLeaseContract?.length >= 30) {
        setOriginalListing([...filteredList, ...getLeaseContract])
      } else {
        setOriginalListing([...getLeaseContract])
      }
    },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  //to make delay for search function for the query to load
  const [timer, setTimer] = useState(null)

  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadLeaseContract({
          variables: {
            statusArr: 'COMPLETED',
            CompanyID: CompanyID,
            skip: 0,
            take: 30,
            searchValue:
              getSearch?.name === '' || !getSearch?.name
                ? undefined
                : getSearch?.name,
          },
        })
      }, 1000)
    )
  }

  return (
    <>
      {/* {loading && <Loading />} */}
      {CompanyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/mfrs16/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }}
        smTitle={'MFRS 16'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'MFRS 16' },
          { name: 'ROU Assets & Lease Liability Schedule', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, [''])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({ option: 'COMPLETED', name: e?.target?.value })
            )
          }}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
            localStorage.removeItem('searchFilter')
            loadLeaseContract({
              variables: {
                statusArr: 'COMPLETED',
                CompanyID: CompanyID,
                skip: 0,
                take: 30,
              },
            })
          }}
          option={{ icon: <Tune />, onClick: openFilterDialog }}
        />
        {filterDialog}
      </div>

      <LeaseContractEntryList
        loading={LeaseContractLoading}
        filteredList={filteredList}
        setOriginalListing={setOriginalListing}
        listStatus={'COMPLETED'}
        loadData={loadLeaseContract}
        CompanyID={CompanyID}
        getSearch={getSearch}
        transactionTypePrefix={transactionTypePrefix}
      />
    </>
  )
}
