import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons'
import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  useDeleteArScheduledBillingMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useParams } from 'react-router'

export const ARScheduledBillingEntryList = (props: any) => {
  const {
    filteredList,
    ScheduledBillingbyStatusLoading,
    listStatus,
    fetchMore,
    setOriginalListing,
    setErrMessage,
    setErrDialog,
  } = props

  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  let history = useHistory()
  const { CompanyID }: any = useParams()

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const [deleteAA, setDeleteAA] = useState(false)

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    deleteScheduledBilling,
    { loading: deleteScheduledBillingLoading },
  ] = useDeleteArScheduledBillingMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      fetchMore({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name === '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  /* -------------------------------------------- */
  /*                     VARIABLE                 */
  /* -------------------------------------------- */
  const { handlePermDisabled } = usePermissionChecker()

  const handleDelete = data => {
    deleteScheduledBilling({
      variables: { ScheduledBillingID: data },
    })
    setDeleteAA(false)
  }

  return (
    <>
      {deleteScheduledBillingLoading && <Loading />}
      {ScheduledBillingbyStatusLoading && <Loading />}
      <ContentWrapper advSearch float>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                fetchMore({
                  variables: {
                    CompanyID: CompanyID,
                    StatusArr: [listStatus],
                    take: 30,
                    skip: currentLength,
                    searchValue:
                      getSearch?.name === '' || getSearch?.name === undefined
                        ? undefined
                        : getSearch?.name,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                ScheduledBillingbyStatusLoading && (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {advancedFilterList(filteredList)?.map((el, index) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          {listStatus === 'COMPLETED' ? (
                            <>
                              <span className={`flex-space mdLabel`}>
                                {el?.DebtorAccount?.DebtorName}
                              </span>
                              <span className={`desc`}>
                                {formatDate(el?.BillingDate)}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                className="flex-space mdLabel"
                                style={{ fontSize: '12px' }}
                              >
                                {el?.DebtorAccount?.DebtorName}
                              </span>
                              <span className="desc">
                                {!!el?.BillingDate
                                  ? formatDate(el?.BillingDate)
                                  : ''}
                              </span>
                            </>
                          )}
                        </>
                      }
                      secondary={
                        <>
                          <Grid container>
                            <Grid
                              spacing={1}
                              container
                              className="table-content"
                            >
                              <Grid item xs={6}>
                                <span className="desc flex-space">
                                  {el?.Description}
                                </span>
                              </Grid>
                              <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <span
                                  className={`xsTitle`}
                                  style={{ color: 'orange' }}
                                >
                                  {amtStr(el?.BillingAmt)}
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      {listStatus === 'ACTIVE' ? (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            aria-controls="menu-list"
                            aria-haspopup="true"
                            onClick={e =>
                              handleClick(e, el?.ScheduledBillingID, index, el)
                            }
                          >
                            <MoreVert />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton edge="end" aria-label="arrow">
                          <KeyboardArrowRight
                            onClick={() =>
                              history.push({
                                pathname: `/account-receivable/${CompanyID}/scheduled-billing/${el?.ScheduledBillingID}/detail`,
                                state: el,
                              })
                            }
                          />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </InfiniteScroll>
          )}
        </List>
        {listStatus === 'ACTIVE' && (
          <FloatButton
            onClick={() => {
              history.push(
                `/account-receivable/${CompanyID}/scheduled-billing/add`
              )
            }}
          />
        )}
      </ContentWrapper>

      {listStatus === 'ACTIVE' ? (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.AccReceivableScheduledBillingUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/account-receivable/${CompanyID}/scheduled-billing/${menu?.obj?.ScheduledBillingID}/edit`,
                state: menu?.obj,
              })
            }}
          >
            <span className="">Edit</span>
          </MenuItem>

          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.AccReceivableScheduledBillingDelete,
            })}
            onClick={() => setDeleteAA(true)}
          >
            <span>Delete</span>
          </MenuItem>
        </Menu>
      ) : null}

      <DeleteDialog
        ID={menu?.ID}
        openDeleteDialog={deleteAA}
        setOpenDeleteDialog={setDeleteAA}
        title={'Scheduled Billing'}
        menu={menu?.obj}
        handleDelete={handleDelete}
        passID={menu?.ID}
      />
    </>
  )
}
