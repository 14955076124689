import { AuditLogListing } from '../../../components/Listing/AuditLog'
import React, { lazy } from 'react'
const GLAccountCodeJournalTypeMappingMain = lazy(() =>
  import('./GLAccountCodeJournalTypeMappingTab').then(module => ({
    default: module.GLAccountCodeJournalTypeMappingMain,
  }))
)

const GLAccountCodeJournalTypeMappingRoutes = [
  {
    props: {
      exact: true,
      path: `/mfrs16/:CompanyID/gl-account-code-journal-type-mapping`,
    },
    component: <GLAccountCodeJournalTypeMappingMain />,
  },
  {
    props: {
      exact: true,
      path: '/mfrs16/:CompanyID/gl-account-code-journal-type-mapping/audit-log',
    },
    component: (
      <AuditLogListing
        module="mfrs16"
        routeSegments="GL Account Code & Journal Type Mapping"
        smTitle="MFRS 16"
      />
    ),
  },
]

export default GLAccountCodeJournalTypeMappingRoutes
