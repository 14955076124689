import React, { lazy } from 'react'

const GLExportDotNetForm = lazy(() =>
  import('./GLExportDotNetForm').then(module => ({
    default: module.GLExportDotNetForm,
  }))
)
const GLExportDotNetListing = lazy(() =>
  import('./GLExportDotNetListing').then(module => ({
    default: module.GLExportDotNetListing,
  }))
)
const GLExportDotNetJournalDetails = lazy(() =>
  import('./GLExportDotNetJournalDetails').then(module => ({
    default: module.GLExportDotNetJournalDetails,
  }))
)
const GLExportDotNetDetails = lazy(() =>
  import('./GLExportDotNetDetails').then(module => ({
    default: module.GLExportDotNetDetails,
  }))
)

const glExportDotNetRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/gl-export-dot-net',
    },
    component: <GLExportDotNetListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/gl-export-dot-net/:ExportDotNetID/detail',
    },
    component: <GLExportDotNetDetails />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/gl-export-dot-net/export',
    },
    component: <GLExportDotNetForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/gl-export-dot-net/export/journal/:JournalID/detail',
    },
    component: <GLExportDotNetJournalDetails mode="export" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/gl-export-dot-net/:ExportDotNetID/journal/:JournalID/detail',
    },
    component: <GLExportDotNetJournalDetails mode="view" />,
  },
]

export default glExportDotNetRoutes
