import React from 'react'
import { Tabs, Tab, Box, useMediaQuery } from '@material-ui/core'
import theme from '../../assets/theme'
import './TabSwitcher.scss'
interface ISwitchBar {
  options: any
  className?: React.CSSProperties
  borderRadius?: string
  setOptionID?: React.Dispatch<string>
  defaultOption?: number
  searchFilter?: any
  isSearchFilter?: boolean
}

export default function TabSwitcher(props: ISwitchBar) {
  const {
    options,
    borderRadius,
    setOptionID,
    defaultOption,
    searchFilter,
    isSearchFilter = true,
  } = props

  const [selectedIndex, setSelectedIndex] = React.useState(defaultOption ?? 0)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const handleTabChange = (event: React.ChangeEvent<{}>, index: number) => {
    const optionID = options[index]?.ID
    setOptionID?.(optionID)
    setSelectedIndex(index)

    if (isSearchFilter) {
      localStorage.setItem(
        'searchFilter',
        JSON.stringify({
          name: searchFilter?.name ?? '',
          option: optionID ?? defaultOption,
        })
      )
    }
  }

  React.useEffect(() => {
    setSelectedIndex(defaultOption ?? 0)
  }, [defaultOption])

  return (
    <Box
      className="filter-tabs"
      style={{
        borderRadius: borderRadius,
        paddingTop: isDesktop ? '60px' : '',
        boxShadow: '0 5px 8px rgba(0, 0, 0, 0.13)',
      }}
    >
      <Tabs
        value={selectedIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        {options?.map((option, index) => (
          <Tab
            key={index}
            label={option.name}
            disabled={!!option.unclickable}
          />
        ))}
      </Tabs>
    </Box>
  )
}
