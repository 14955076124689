import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import React from 'react'
import { APAdvanceDetail } from './APAdvanceDetail/APAdvanceDetail'
import { APAdvanceForm } from './APAdvanceForm/APAdvanceForm'
import { PDFPreviewV2 } from 'containers/ARModule/Hooks/PDFPreviewV2'
import { APMain } from '../APMain'
import { APDetail } from '../APDetail/APDetail'
import { APAllocation } from '../APAllocation/APAllocation'
import { AuditLogListing } from 'components/Listing/AuditLog'

const advancesRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/advance/filter',
    },
    component: (
      <AdvancedFilter
        mode="advance"
        app="account-payable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/advance',
    },
    component: (
      <APMain
        apSubmenu="advance"
        routeSegments="Advance"
        accountType="account-payable"
      />
    ),
    // component: <APAdvanceMain />,
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/advance/:DocumentID/detail',
    },
    component: (
      <APDetail
        apSubmenu="advance"
        routeSegments="Advance"
        accountType="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/advance/:AdvanceID/detail/workdesk',
    },
    component: <APAdvanceDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/advance/:AdvanceID/detail/workdesk-history',
    },
    component: <APAdvanceDetail mode="workdesk-history" />,
  },

  /* -------------------------------------------- */
  /*                PDF PREVIEW                   */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/advance/:AdvanceID/preview`,
    },
    component: <PDFPreviewV2 accountType="account-payable" docType="advance" />,
  },

  /* -------------------------------------------- */
  /*                ALLOCATION                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/advance/:ID/allocation',
    },
    component: (
      <APAllocation
        apSubmenu="advance"
        routeSegments="Advance"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/advance/add',
    },
    component: <APAdvanceForm formMode="add" type="ADVANCE_AP" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/advance/:AdvanceID/edit',
    },
    component: <APAdvanceForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/advance/:AdvanceID/approve-reject',
    },
    component: <APAdvanceForm mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/advance/:AdvanceID/resubmit',
    },
    component: <APAdvanceForm formMode="resubmit" />,
  },
  /* -------------------------------------------- */
  /*                AUDIT LOG                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/advance/:AdvanceID/detail/audit-log',
    },
    component: (
      <AuditLogListing
        module="ledger"
        routeSegments="Advance"
        smTitle="Accounts Payable"
        docType="advance"
      />
    ),
  },
]

export default advancesRoutes
