import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { KeyboardArrowDown, MoreVert } from '@material-ui/icons'
import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { AcctPermission, ApprovalStatus } from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useContext, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useParams } from 'react-router'

export const WODisposalEntryList = (props: any) => {
  const { filteredList, listLoading, listStatus, fetchMore } = props
  const { handleClick, anchorEl, menu, handleClose } = useMenuOption()
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const { handlePermDisabled } = usePermissionChecker()
  const [openUpload, setOpenUpload] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const [deleteAA, setDeleteAA] = useState(false)
  let history = useHistory()
  const { CompanyID }: any = useParams()

  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))

  return (
    <>
      {listLoading && <Loading />}

      <ContentWrapper advSearch float>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                fetchMore({
                  variables: {
                    CompanyID: CompanyID,
                    StatusArr: [listStatus],
                    take: 30,
                    skip: currentLength,
                    searchValue:
                      getSearch?.name === '' || getSearch?.name === undefined
                        ? undefined
                        : getSearch?.name,
                  },
                })
              }}
              hasMore={true}
              style={{ padding: '8px 6px' }}
              loader={
                listLoading && (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {advancedFilterList(filteredList)
                ?.sort((a, b) => {
                  return (
                    Object.values(ApprovalStatus).indexOf(a?.ApprovalStatus) -
                    Object.values(ApprovalStatus).indexOf(b?.ApprovalStatus)
                  )
                })
                ?.map((el, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <>
                            <span
                              className="flex-space mdLabel"
                              style={{ fontSize: '12px' }}
                            >
                              {el?.Name}
                            </span>
                            <span className={`xsTitle`}>{el?.AssetNo}</span>
                          </>
                        }
                        secondary={
                          <>
                            <Grid container spacing={0}>
                              <Grid container className="table-content">
                                <Grid item xs={6}>
                                  <span className="desc flex-space">
                                    {el?.Date}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  style={{ textAlign: 'right' }}
                                >
                                  <span className="desc">
                                    <span style={{ color: 'orange' }}>
                                      {el?.DisposalQuantity}
                                    </span>
                                    /2 Disposal qty
                                  </span>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12}>
                                  <span className="desc">
                                    {el?.Description}
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        }
                      />

                      <ListItemSecondaryAction>
                        {listStatus === 'ACTIVE' ||
                        listStatus === 'REJECTED' ? (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            aria-controls="menu-list"
                            aria-haspopup="true"
                            onClick={e =>
                              handleClick(e, el?.WriteOffDisposalID, index, el)
                            }
                          >
                            <MoreVert />
                          </IconButton>
                        ) : (
                          <IconButton edge="end" aria-label="arrow">
                            <KeyboardArrowDown
                              onClick={() =>
                                history.push({
                                  pathname: `/fixed-assets/${CompanyID}/write-off-disposal/detail`,
                                  state: el,
                                })
                              }
                            />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </InfiniteScroll>
          )}
        </List>
        {listStatus === 'ACTIVE' && (
          <FloatButton
            onClick={() =>
              history.push(`/fixed-assets/${CompanyID}/write-off-disposal/add`)
            }
          />
        )}
      </ContentWrapper>

      {listStatus === 'ACTIVE' ? (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.FixedAssetsWriteOffDisposalUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/fixed-assets/${CompanyID}/write-off-disposal/edit`,
                state: menu?.obj,
              })
            }}
          >
            <span className="">Edit</span>
          </MenuItem>
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.FixedAssetsWriteOffDisposalDelete,
            })}
            onClick={() => setDeleteAA(true)}
          >
            <span>Delete</span>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.FixedAssetsWriteOffDisposalUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/fixed-assets/${CompanyID}/write-off-disposal/resubmit`,
                state: { ...menu?.obj, mode: 'resubmit' },
              })
            }}
          >
            <span className="">Resubmit</span>
          </MenuItem>
        </Menu>
      )}

      <DeleteDialog
        ID={menu?.ID}
        openDeleteDialog={deleteAA}
        setOpenDeleteDialog={setDeleteAA}
        title={'WriteOffDisposal'}
        menu={menu?.obj}
        passID={menu?.ID}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error'}
      />
    </>
  )
}
