import React from 'react'
import { AssetsCountMain } from './AssetsCountMain'
import { AssetsCountDetail } from './AssetsCountDetail/AssetsCountDetail'
import { AssetsCountForm } from './AssetsCountForm/AssetsCountForm'

const assetsCountRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  // {
  //   props: {
  //     exact: true,
  //     path: '/fixed-assets/:CompanyID/assets-registration/filter',
  //   },
  //   component: (
  //     <AdvancedFilter
  //       mode="assets-registration"
  //       app="fixed-assets"
  //       hasStatus
  //       hasDocDate
  //       hasDateRange
  //     />
  //   ),
  // },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-count',
    },
    component: <AssetsCountMain />,
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-count/add',
    },
    component: <AssetsCountForm />,
  },

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-count/edit',
    },
    component: <AssetsCountForm formMode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-count/approve-reject',
    },
    component: <AssetsCountForm formMode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-count/resubmit',
    },
    component: <AssetsCountForm formMode="resubmit" />,
  },

  /* -------------------------------------------- */
  /*                     DETAIL                   */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-count/detail',
    },
    component: <AssetsCountDetail />,
  },
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-count/detail/workdesk',
    },
    component: <AssetsCountDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-count/detail/workdesk-history',
    },
    component: <AssetsCountDetail mode="workdesk-history" />,
  },
]

export default assetsCountRoutes
