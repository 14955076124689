import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { formatDashDate } from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Checkbox,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import { FormAllocationTableContent } from 'components/Table/FormAllocationTableContent'
import { statusInput } from 'containers/ARAPModule/Function/DocumentChecker'
import { useARCreateUpdateMutation } from 'containers/ARModule/Hooks/useARCreateUpdateMutation'
import { ARItemDialog } from 'containers/ARModule/components/ARItemDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  DocDateValidationAfter,
  DocDateValidationBefore,
} from 'containers/CashBookModule/DocDateValidation'
import {
  AcctPermission,
  RecordStatus,
  useDocumentListingLazyQuery,
  useGetArAllocationDocumentLazyQuery,
  useGetBillItemListingLazyQuery,
  useGetCompanyNameQuery,
  useGetCostCentreCodeLazyQuery,
  useGetDebtorAccountQuery,
  useGetDocNumTitleQuery,
  useGetDocumentDateValidationQuery,
  useGetEInvoiceCompanyStartDateLazyQuery,
  useGetMasterCoaLazyQuery,
  useGetTaxEffectiveDateQuery,
  useGetTaxSchemeQuery,
  useGetValidatedDocRefLazyQuery,
  useLatestOpenPeriodCheckingDateQuery,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { ARCNItemDetail } from '../ARCreditNoteDetail/ARCNDetail'

interface CreditNoteProps {
  DocDate: string
  TrxDate: string
  DocAmt: number
  Remark: string
  Description: string
  approval: string
  user: string
  DebtorAccountID: string
  CreditDebitID: string
  BillItemID: string
  RefNo: string
  CompanyID: string
  CreditNoteID: string
  TaxRate: number
  TaxSchemeID: string
  DocNo: string
  Allocation: any[]
  is_einvoice: boolean
}

export const ARCreditNoteFormWithAllocation = (props: any) => {
  const { formMode, docType } = props
  const { CompanyID, CreditNoteID }: any = useParams()
  const [taxEffectiveList, setTaxEffectiveList] = useState<any>()
  const [detailMode, setDetailMode] = useState('')

  // Adaptive Disabling for InvoiceID Doc Ref
  const [notDSDocs, setNotDSDocs] = useState(new Set())
  const [dsDocs, setDSDocs] = useState(new Set())
  const [toDisable, setToDisable] = useState(new Set())

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */
  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [selectedTax, setSelectedTax] = useState(null)

  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any

  const [openItemDialog, setOpenItemDialog] = useState<boolean>(false)
  const [openExitConf, setOpenExitConf] = useState(null)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [initDocs, setInitDocs] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)

  const [creditNoteItemData, setCreditNoteItemData] = useState([])
  const [edit, setEdit] = useState(false)
  const [voiceRemark, setVoiceRemark] = useState('')

  const [record, setRecord] = useState(false)

  const [directSend, setDirectSend] = useState(
    mode === 'edit' ? editData?.einvoice_directsend : false
  )

  const [enableEInvoice, setEnableEInvoice] = useState(false)
  const [refundNote, setRefundNote] = useState(false)
  const [selectedDocs, setSelectedDocs] = useState<Set<any>>(new Set([]))
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const handleCheckBoxChange = (event: any) => {
    let temp = selectedDocs || new Set()
    if (event?.target?.checked) {
      temp.add(event?.target?.value)
      setSelectedDocs(new Set(temp))
    } else {
      temp.delete(event?.target?.value)
      setSelectedDocs(new Set(temp))
    }
  }

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)
  // useEffect(() => {
  //   if (CreditNoteID && editData?.Remark) {
  //     setText(editData?.Remark)
  //     handleSingleRemark(CreditNoteID && editData?.Remark)
  //     setAudioInput(CreditNoteID && editData?.Remark)
  //   }
  // }, [editData])

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */
  let refTable, routeSegments, itemRefTable, itemRefTableKey
  switch (docType) {
    case 'credit-note':
      routeSegments = 'Credit Note'
      refTable = 'AR_CreditNote'
      itemRefTable = 'ARCreditNoteItem'
      itemRefTableKey = 'CreditNoteItemID'
      break
    default:
      break
  }

  const [docNoChecker, setDocNoChecker] = useState(false)

  const {
    loading: DocNumHeaderLoading,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RefTable: refTable },
    onCompleted: ({ getDocumentNumberHeader }) => {
      if (
        (getDocumentNumberHeader?.length > 0 &&
          getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true)) ||
        getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true) ||
        (getDocumentNumberHeader?.length === 0 && docType === 'credit-note')
      ) {
        setDocNoChecker(true)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: OpenPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const CreditNoteFormSchema = yup.object().shape({
    DebtorAccountID: CommonYupValidation.requireField(
      'Debtor Name is required'
    ),
    DocDate: yup.string().required('Doc Date is Required'),
    TrxDate: yup.string().required('Transaction Date is Required'),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    RefNo: yup.string().required('Reference No is Required'),
  })
  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    setError,
    watch,
    clearErrors,
  } = useForm<CreditNoteProps>({
    defaultValues: {
      RefNo: editData ? editData?.RefNo : '',
      DocDate: editData ? editData?.DocDate : new Date()?.toISOString(),
      Remark: editData ? editData?.Remark : '',
      Description: editData ? editData?.Description : '',
      DebtorAccountID: editData ? editData?.DebtorAccountID : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(CreditNoteFormSchema),
  })

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()

  // Upload Document
  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */
  const {
    loading: CompanyLoading,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RecordStatus: RecordStatus.Active },
    onCompleted: ({ getCompany }) => {
      if (getCompany[0]?.eInvIntegrated!!) {
        const startDate = new Date(getCompany[0]?.eInvStartDate)
        const endDate = new Date(getCompany[0]?.eInvEndDate)
        const today = new Date()
        if (today >= startDate && today <= endDate) {
          setEnableEInvoice(true)
        }
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    loadMasterCOA,
    {
      loading: MasterCOALoading,
      data: { getMasterCOA } = { getMasterCOA: [] },
    },
  ] = useGetMasterCoaLazyQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    fetchDocRef,
    {
      loading: ValidatedDocRefLoading,
      data: { getValidatedDocRef } = { getValidatedDocRef: [] },
    },
  ] = useGetValidatedDocRefLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getValidatedDocRef }) => {
      let notDirectSendSet = new Set()
      let directSendSet = new Set()
      setNotDSDocs(new Set())
      setDSDocs(new Set())
      for (let i = 0; i < getValidatedDocRef?.length; i++) {
        if (!getValidatedDocRef[i]?.DirectSend) {
          notDirectSendSet.add(getValidatedDocRef[i])
        } else {
          directSendSet.add(getValidatedDocRef[i])
        }
      }

      if (notDirectSendSet.size > 0) {
        setNotDSDocs(new Set(notDirectSendSet))
      }

      if (directSendSet.size > 0) {
        setDSDocs(new Set(directSendSet))
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: TaxLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: DocDateValidationLoading,
    data: { getDocumentDateValidation } = { getDocumentDateValidation: [] },
  } = useGetDocumentDateValidationQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: DebtorAccountLoading,
    data: { getDebtorAccount } = { getDebtorAccount: [] },
  } = useGetDebtorAccountQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })
  let debtorAcc = getDebtorAccount
  //   For allocation purposes
  const [allocatedObj, setAllocatedObj] = useState({})
  const [allocatedData, setAllocatedData] = useState([])
  const [
    loadAllocationDocument,
    {
      loading: getAllocationDocumentLoading,
      data: { getAllocationDocument } = { getAllocationDocument: [] },
    },
  ] = useGetArAllocationDocumentLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ getAllocationDocument }) => {
      const eInvoiceAllocDocument = getAllocationDocument.filter(
        item => item.is_einvoice === true
      )

      let arr = []
      if (
        (watch('is_einvoice') === true
          ? eInvoiceAllocDocument
          : getAllocationDocument
        )?.length > 0
      ) {
        ;(watch('is_einvoice') === true
          ? eInvoiceAllocDocument
          : getAllocationDocument
        )?.map((alloc, index) => {
          arr.push({
            AllocationID: alloc?.AllocationID,
            CreditOrDebit: alloc?.CreditOrDebit,
            DocDate: alloc?.DocDate,
            DocNo: alloc?.DocNo,
            Description: alloc?.Description,
            AllocationAmt: Number(
              allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0
            ),
            BalanceAmt:
              Number(alloc?.BalanceAmt) +
              Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0),
            [`${alloc?.CreditOrDebit}ID`]: alloc[`${alloc?.CreditOrDebit}ID`],
            [`${alloc?.CreditOrDebit}RefTable`]: alloc[
              `${alloc?.CreditOrDebit}RefTable`
            ],
            [`${alloc?.CreditOrDebit}DocNo`]: alloc[
              `${alloc?.CreditOrDebit}DocNo`
            ],
          })

          return alloc
        })
        setAllocatedData(arr)
        fields.map((_, index) => remove(index))
        append(arr)
      }
    },
  })

  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreCodeLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrMessage(errorMessage)
      setErrDialog(true)
    },
  })

  const [
    fetchBillItem,
    { loading: BillItemLoading, data: { getBillItem } = { getBillItem: [] } },
  ] = useGetBillItemListingLazyQuery({
    fetchPolicy: 'network-only',
    variables: { orderByAsc: 'Name', RecordStatus: RecordStatus.Active },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: TaxEffectiveDateLoading,
    data: { getTaxEffectiveDate } = { getTaxEffectiveDate: [] },
  } = useGetTaxEffectiveDateQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (getTaxEffectiveDate?.length > 0) {
        let temp = getTaxEffectiveDate?.filter(
          tax =>
            Number(new Date(tax?.Date)) <
              DocDateValidationAfter(
                getDocumentDateValidation?.map(x => x?.MonthsAfter)
              ) &&
            Number(new Date(tax?.Date)) >
              DocDateValidationBefore(
                getDocumentDateValidation?.map(x => x?.MonthsBefore)
              )
        )
        setTaxEffectiveList(temp)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    fetchDocuments,
    { loading: DocumentLoading, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        // previewFiles?.push(
        //   ...DocumentListing?.filter(
        //     doc => doc?.description !== 'document_pdf'
        //   )?.map(x => x?.fileURL)
        // )
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    fetchEInvoiceCompanyStartDate,
    {
      loading: GetEInvoiceCompanyStartDateLoading,
      data: { getEInvoiceCompanyStartDate } = {
        getEInvoiceCompanyStartDate: [],
      },
    },
  ] = useGetEInvoiceCompanyStartDateLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(`${errorMessage}`)
      setValue('is_einvoice', false)
    },
    onCompleted(data) {
      setValue('is_einvoice', data?.getEInvoiceCompanyStartDate)
    },
  })

  useEffect(() => {
    if (
      formMode === 'edit' ||
      formMode === 'approve-reject' ||
      formMode === 'resubmit'
    )
      if (!!!editData?.files) {
        fetchDocuments({
          variables: { refID: CreditNoteID },
        })
      }
  }, [formMode])

  useEffect(() => {
    if (!!menu?.obj?.BillItemID) {
      const findCoa = getBillItem
        ?.filter(x => {
          return x?.BillItemID === menu?.obj?.BillItemID
        })
        .map(y => {
          return y?.MasterCOAID
        })

      fetchCostCentre({
        variables: {
          CompanyID: CompanyID,
          MasterCOAID: findCoa[0],
          IsLastNode: true,
        },
      })
    }
  }, [menu?.obj])

  /* -------------------------------------------- */
  /*                  MUTATION                    */
  /* -------------------------------------------- */
  const {
    handleMutation,
    mutationLoading,
    mutationCalled,
  } = useARCreateUpdateMutation(isSubmit, {
    docType: docType,
    mode: mode,
    CompanyID: CompanyID,
    setErrorDia,
    setErrMsg,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Allocation',
  })

  /* -------------------------------------------------- */
  /*                   DOC NO CHECKING                  */
  /* -------------------------------------------------- */
  const str = watch('DocNo')

  const findDuplicates = arr => {
    if (
      (formMode === 'add' && arr?.includes(str)) ||
      (formMode === 'edit' &&
        arr?.filter(x => x !== editData?.DocNo)?.includes(str))
    ) {
      return true
    } else {
      return false
    }
  }

  const checkSetupNumStruc = () => {
    if (
      (getDocumentNumberHeader?.length > 0 &&
        getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true) &&
        getDocumentNumberHeader[0]?.SampleOutput === '') ||
      (getDocumentNumberHeader
        ?.filter(x => x?.RefTable === 'AR_CreditNote')
        ?.find(y => y?.SampleOutput === '') &&
        docNoChecker === true) ||
      (getDocumentNumberHeader?.length === 0 &&
        docNoChecker === true &&
        docType === 'credit-note')
    ) {
      return true
    } else return false
  }

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  const inputData = (data, status) => {
    return {
      CreditNoteID: editData?.CreditNoteID,
      CompanyID: CompanyID,
      DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
      TransactionDate: formatDashDate(new Date(data?.TrxDate)?.toISOString()),
      DocAmt: totalAmount,
      ...(docNoChecker === false ? { DocNo: data.DocNo } : null),
      DebtorAccountID: data?.DebtorAccountID,
      Remark: data?.Remark,
      Description: data?.Description,
      // RefTable: creditDebitRefTable,
      ...(watch('CreditDebitID') ? { RefRecordID: data?.CreditDebitID } : null),
      RefNo: data?.RefNo,
      ApprovalStatus: statusInput(status),
      Attachment: files,
      is_einvoice: !!enableEInvoice ? watch('is_einvoice') : false,
      einvoice_status: !!watch('is_einvoice') ? 'NEW' : 'NOT_EINVOICE',
      einvoice_directsend: directSend,
      einvoice_is_refund_note: !!watch('einvoice_is_refund_note')
        ? true
        : false,
    }
  }

  let totalAmount = creditNoteItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.DocAmt),
    0
  )
  let totalTaxAmt = creditNoteItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.TaxAmt),
    0
  )

  let amtBeforeTax = totalAmount - totalTaxAmt
  let calcTotal = items => {
    return items?.reduce(
      (total, currentValue) => (total = total + currentValue.DocAmt),
      0
    )
  }

  const handleCreditorDebtorChange = event => {
    remove()
    loadAllocationDocument({
      variables: {
        ...(editData && editData?.DebtorAccountID === event
          ? { entityID: [CreditNoteID] }
          : null),
        creditorDebtorID: event,
        companyID: CompanyID,
        refTable: 'AR_CreditNote',
      },
    })
  }

  const getTotalAmt = () => {
    if (creditNoteItemData?.length > 0) {
      return creditNoteItemData.reduce((prevValue, currValue) => {
        return prevValue + currValue?.DocAmt
      }, 0)
    } else {
      return 0
    }
  }
  const getAllocationTotal = () => {
    if (watch('Allocation')?.length > 0) {
      return watch('Allocation').reduce((prevValue, currValue) => {
        return prevValue + currValue?.AllocationAmt
      }, 0)
    } else {
      return 0
    }
  }

  const getBalanceAmt = () => {
    if (watch('Allocation')?.length > 0) {
      return watch('Allocation')?.reduce((prevValue, currValue) => {
        return prevValue + currValue?.BalanceAmt
      }, 0)
    } else {
      return 0
    }
  }

  const docAmtChecker = () => {
    if (getTotalAmt > getBalanceAmt) {
      return true
    } else {
      return false
    }
  }

  /* -------------------------------------------- */
  /*                USE EFFECT                    */
  /* -------------------------------------------- */
  useEffect(() => {
    if (editData?.DebtorAccountID) {
      loadAllocationDocument({
        variables: {
          entityID: CreditNoteID,
          creditorDebtorID: editData?.DebtorAccountID,
          companyID: CompanyID,
          refTable: 'AR_CreditNote',
        },
      })

      fetchDocRef({
        variables: {
          CompanyID: CompanyID,
          DebtorAccountID: watch('DebtorAccountID'),
        },
      })
    }

    if (editData?.Remark) setValue('Remark', editData?.Remark)

    if (editData?.Allocation) {
      let allocatedArr = editData?.Allocation
      allocatedArr?.map(alloc => {
        if (!(alloc[`${alloc?.CreditOrDebit}ID`] in allocatedObj)) {
          allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] =
            alloc?.AllocationAmt
        }
      })
    }

    if (editData?.Attachment) setInitDocs(editData?.Attachment)

    if (CreditNoteID && editData?.Remark) setValue('Remark', editData?.Remark)

    if (editData?.CNDocRef.length > 0) {
      const selected = new Set()
      editData?.CNDocRef?.map(x => {
        selected.add(x?.DocRefID)
      })
      setSelectedDocs(new Set(selected))
    }
  }, [allocatedObj, editData])

  useEffect(() => {
    if (formMode === 'edit' && editData) {
      const creditNoteItems = editData?.[itemRefTable]?.map((el, index) => {
        return {
          Sequence: el?.Sequence ?? index + 1,
          CreditNoteItemID: el?.CreditNoteItemID,
          BillItemName: el?.BillItem?.Name,
          BillItemID: el?.BillItemID,
          CostCentreName: el?.CostCentre?.Code,
          CostCentreID: el?.CostCentreID,
          Amount: el?.Amount,
          TaxSchemeID: el?.TaxSchemeID,
          TaxCode: el?.TaxScheme?.Code,
          TaxAmt: el?.TaxAmt,
          TaxRate: el?.TaxRate,
          Remark: el?.Remark,
          DocAmt: el?.DocAmt,
          einvoice_classification_code_id: el?.einvoice_classification_code_id,
        }
      })

      creditNoteItemData.push(...creditNoteItems)
    }
  }, [formMode, editData])

  /* -------------------------------------------- */
  /*                   DELETE                     */
  /* -------------------------------------------- */
  const onDeleteCreditNoteItem = (delInd: number) => {
    creditNoteItemData.splice(delInd, 1)
  }

  /* ------------------------------------------- */
  /*                ON SUBMIT                    */
  /* ------------------------------------------- */
  const updateNewCNItem = (newDate, tED) => {
    const arr = []
    creditNoteItemData?.map(x => {
      let newTax =
        getTaxEffectiveDate?.filter(
          j =>
            j?.TaxSchemeID === x?.TaxSchemeID &&
            new Date(j?.Date) <= new Date(newDate)
        )?.length > 0
          ? getTaxEffectiveDate
              ?.filter(
                j =>
                  j?.TaxSchemeID === x?.TaxSchemeID &&
                  new Date(j?.Date) <= new Date(newDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })
          : null

      if (newTax !== null) {
        arr.push({
          CreditNoteItemID: x?.CreditNoteItemID,
          BillItemID: x?.BillItemID,
          RevenueCode: x?.RevenueCode,
          RevenueName: x?.RevenueName,
          Amount: x?.Amount,
          TaxSchemeID: x?.BillItem?.TaxSchemeID,
          TaxCode: x?.TaxCode,
          TaxRate: newTax?.Rate,
          TaxAmt: x?.Amount * (newTax?.Rate / 100),
          DocAmt: x?.Amount + x?.Amount * (newTax?.Rate / 100),
          CostCentreID: x?.CostCentreID,
          CostCentreCode: x?.CostCentreCode,
          Remark: x?.Remark,
          einvoice_classification_code_id: x?.einvoice_classification_code_id,
        })
      }
    })
  }

  const onSubmit = (data, status) => {
    if (checkSetupNumStruc() === true) {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.errorNumberingStructure())
    } else {
      handleMutation({
        variables: {
          CreditNoteID: mode === 'edit' ? editData?.CreditNoteID : '',
          input: inputData(data, status),
          itemInput:
            creditNoteItemData?.map(x => {
              return {
                CreditNoteID: CreditNoteID,
                ItemID: x?.CreditItemID,
                BillItemID: x?.BillItemID,
                Amount: parseFloat(x?.Amount),
                TaxSchemeID: x?.TaxSchemeID,
                TaxRate: parseFloat(x?.TaxRate),
                TaxAmt: parseFloat(x?.TaxAmt),
                DocAmt: Number(x?.DocAmt),
                CostCentreID: x?.CostCentreID,
                Remark: x?.Remark,
                Sequence: x?.Sequence,
                einvoice_classification_code_id:
                  x?.einvoice_classification_code_id,
              }
            }) ?? [],
          allocationInput:
            watch('Allocation')
              ?.filter(
                alloc =>
                  Number(alloc?.AllocationAmt) !== 0 || alloc?.AllocationID
              )
              ?.map(x => {
                const mainCreditOrDebit =
                  x?.CreditOrDebit === 'Debit' ? 'Credit' : 'Debit'
                return {
                  ...(x?.AllocationID
                    ? { AllocationID: x?.AllocationID }
                    : null),
                  AllocationAmt: Number(x?.AllocationAmt),
                  CreditOrDebit: x?.CreditOrDebit,
                  CompanyID: CompanyID,
                  // for allocated table
                  [`${x?.CreditOrDebit}RefTable`]: x?.[
                    `${x?.CreditOrDebit}RefTable`
                  ],
                  [`${x?.CreditOrDebit}ID`]: x?.[`${x?.CreditOrDebit}ID`],
                  // main table
                  [`${mainCreditOrDebit}RefTable`]: refTable,
                }
              }) ?? [],
          docRefInput: !!watch('is_einvoice')
            ? Array.from(selectedDocs).map(doc => ({
                DocRefID: doc,
              }))
            : [],
        },
      })
    }
  }

  const createUpdateCalled = mutationCalled

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()

  const loading = getBillItem
    ? BillItemLoading
    : getCostCentre
    ? CostCentreLoading
    : ''

  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const trxDateTimestamp = new Date(watch('TrxDate'))
  const yearTrxDate = trxDateTimestamp.getFullYear()
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`

  const openPeriod1 =
    transferDate <= latestOpenPeriodCheckingDate?.StartDate ||
    transferDate >= latestOpenPeriodCheckingDate?.EndDate

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? true : false
  const checkingYearClose2 = openPeriod2 ? false : true
  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */
  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      checkingYearClose1 || checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableCreditNotesDraft,
          }),
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      !!errors?.DocDate ||
      !!errors?.TrxDate ||
      !!errors?.RefNo ||
      !!errors?.DebtorAccountID ||
      !!errors?.Description ||
      checkingYearClose1 ||
      checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableCreditNotesUpdate,
          }),
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: e => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
      setIsSubmit(true)
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      calcTotal(creditNoteItemData) === 0 ||
      calcTotal(creditNoteItemData) === undefined ||
      checkingYearClose1 ||
      checkingYearClose2 ||
      getAllocationTotal() !== calcTotal(creditNoteItemData)
        ? true
        : false,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */

  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      formMode: formMode,

      // for item
      itemSuffixID: itemRefTableKey,
      itemTableName: itemRefTable,
      // for attachment
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
    })

  return (
    <>
      {loading && <Loading />}
      {TaxLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {DocumentLoading && <Loading />}
      {mutationLoading && <Loading />}
      {MasterCOALoading && <Loading />}
      {DocNumHeaderLoading && <Loading />}
      {ValidatedDocRefLoading && <Loading />}
      {TaxEffectiveDateLoading && <Loading />}
      {DocDateValidationLoading && <Loading />}
      {OpenPeriodCheckDateLoading && <Loading />}
      {GetEInvoiceCompanyStartDateLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            history.push({
              pathname: `/account-receivable/${CompanyID}/${docType}`,
            })
          }
        }}
        smTitle={smTitle?.ACCOUNT_RECEIVABLE}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Accounts Receivable Main Menu' },
          { name: 'Accounts Receivable Submenu' },
          { name: routeSegments, current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : formMode === 'edit'
                ? 'Edit'
                : 'Draft',
            current: true,
          },
        ]}
      />
      <ContentWrapper footer float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="DocDate"
              required
              label="Doc Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              onAccept={(date: Date | null) => {
                updateNewCNItem(date, taxEffectiveList)

                if (
                  date < new Date(curCompany[0]?.eInvStartDate) ||
                  date > new Date(curCompany[0]?.eInvEndDate)
                ) {
                  setEnableEInvoice(false)
                  setValue('is_einvoice', false)
                } else {
                  setEnableEInvoice(true)
                  setValue('is_einvoice', true)
                }
              }}
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              showTodayButton
              className={'left'}
              value={watch(formMode === 'add' ? new Date() : editData?.DocDate)}
              defaultValue={editData ? editData?.DocDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose1
                  ? 'Financial Period already closed'
                  : errors?.DocDate?.message
              }
              error={errors?.DocDate || checkingYearClose1 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxDate"
              required
              label="Transaction Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose2
                  ? 'Financial Period already closed'
                  : errors?.TrxDate?.message
              }
              error={errors?.TrxDate || checkingYearClose2 ? true : false}
            />
          </MuiPickersUtilsProvider>
          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            defaultValue={editData ? editData?.RefNo : ''}
            disabled={mode === 'approve-reject'}
          />

          {!DebtorAccountLoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = debtorAcc?.filter(
                  x => x?.DebtorAccountID === editData?.DebtorAccountID
                )[0]

                return (
                  <Autocomplete
                    options={
                      debtorAcc?.sort((a, b) => {
                        return a.DebtorName.localeCompare(b.DebtorName)
                      }) || []
                    }
                    getOptionLabel={option => `${option?.DebtorName}`}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      handleCreditorDebtorChange(newValue?.DebtorAccountID)
                      setValue('DebtorAccountID', newValue?.DebtorAccountID)
                      clearErrors('DebtorAccountID')
                      fetchDocRef({
                        variables: {
                          CompanyID,
                          DebtorAccountID: watch('DebtorAccountID'),
                        },
                      })
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.DebtorName}</span>
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.DebtorAccountID?.message}
                            error={errors?.DebtorAccountID ? true : false}
                            label="Debtor Name"
                            style={{ width: '100%' }}
                            margin="dense"
                            required
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Debtor Name"
              name="DebtorAccountID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="dense"
              ref={register}
              helperText={errors?.DebtorAccountID?.message}
              error={errors?.DebtorAccountID ? true : false}
              defaultValue={editData?.DebtorAccountID}
              required
              disabled={mode === 'approve-reject'}
            />
          )}

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            defaultValue={editData?.Description}
            ref={register}
            disabled={mode === 'approve-reject'}
          />

          {/* <VoiceTextField
            name="Remark"
            mounted={true}
            label="Remark"
            value={audioInput}
            record={record}
            setRecord={setRecord}
            setValue={setValue}
            helperTextProps={{
              helperText: errors?.Remark?.message,
              error: errors?.Remark ? true : false,
            }}
            defaultValue={getValues('Remark')}
            customOnchange
            register={register}
            control={control}
            clearErrors={clearErrors}
            handleCustomOnChange={e => {
              setText(e.target.value)
              handleSingleRemark(e.target.value)
            }}
          /> */}

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />

          {/* e-Invoice Implementation */}
          {!!enableEInvoice!! && (
            <>
              <Grid item xs={4} style={{ alignContent: 'center' }}>
                <span>e-Invoice</span>
              </Grid>
              <Grid item xs={2} style={{ alignContent: 'center' }}>
                <Switch
                  name="is_einvoice"
                  color="primary"
                  inputRef={register()}
                  defaultChecked={
                    formMode === 'edit' ? editData?.is_einvoice : enableEInvoice
                  }
                  onChange={event => {
                    if (event.target.checked === true) {
                      fetchEInvoiceCompanyStartDate({
                        variables: { CompanyID },
                      })

                      fetchDocRef({
                        variables: {
                          CompanyID: CompanyID,
                          DebtorAccountID: watch('DebtorAccountID'),
                        },
                      })

                      if (watch('DebtorAccountID')) {
                        loadAllocationDocument({
                          variables: {
                            entityID: CreditNoteID,
                            creditorDebtorID: editData?.DebtorAccountID,
                            companyID: CompanyID,
                            refTable: 'AR_CreditNote',
                          },
                        })
                      }
                    } else {
                      if (watch('DebtorAccountID')) {
                        loadAllocationDocument({
                          variables: {
                            entityID: CreditNoteID,
                            creditorDebtorID: editData?.DebtorAccountID,
                            companyID: CompanyID,
                            refTable: 'AR_CreditNote',
                          },
                        })
                      }
                    }
                  }}
                />
              </Grid>
              {!!watch(`is_einvoice`)!! && (
                <>
                  <Grid item xs={4} style={{ alignContent: 'center' }}>
                    <span>Refund Note</span>
                  </Grid>
                  <Grid item xs={2} style={{ alignContent: 'center' }}>
                    <Switch
                      name="einvoice_is_refund_note"
                      color="primary"
                      inputRef={register()}
                      defaultChecked={
                        formMode === 'edit'
                          ? editData?.einvoice_is_refund_note
                          : false
                      }
                      onChange={event => {
                        if (event.target.checked === true) {
                          setRefundNote(true)
                        } else {
                          if (watch('DebtorAccountID') && !!enableEInvoice)
                            setRefundNote(false)
                        }
                      }}
                    />
                  </Grid>
                </>
              )}
            </>
          )}

          {!ValidatedDocRefLoading && !!watch('is_einvoice') && (
            <>
              <Grid item xs={12}>
                <Controller
                  render={({ value, onChange }) => {
                    return (
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={
                          getValidatedDocRef?.sort((a, b) => {
                            return a?.DocNo?.localeCompare(b?.DocNo)
                          }) || []
                        }
                        autoHighlight={false}
                        getOptionLabel={option => `${option?.DocNo}`}
                        fullWidth
                        onChange={(event, value, reason) => {
                          const selected = new Set()
                          let toFilter = new Set()
                          if (value[0]?.DirectSend === true) {
                            toFilter = notDSDocs
                            setDirectSend(true)
                          } else if (value[0]?.DirectSend === false) {
                            toFilter = dsDocs
                            setDirectSend(false)
                          }
                          setToDisable(new Set(toFilter))
                          for (let index = 0; index < value.length; index++) {
                            !toFilter.has(value[index]) &&
                              selected.add(value[index]?.DocRefID)
                          }
                          setSelectedDocs(new Set(selected))

                          selectedDocs.clear()
                          if (reason === 'remove-option') {
                            for (let index = 0; index < value.length; index++) {
                              selectedDocs.add(value[index]?.DocRefID)
                            }
                          } else if (reason === 'clear') {
                            setSelectedDocs(new Set())
                          }
                        }}
                        value={getValidatedDocRef?.filter(f =>
                          Array.from(selectedDocs).includes(f?.DocRefID)
                        )}
                        defaultValue={editData?.CNDocRef}
                        renderOption={option => (
                          <Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              value={option?.DocRefID}
                              style={{ marginRight: 8 }}
                              checked={selectedDocs.has(option?.DocRefID)}
                              color="primary"
                              onChange={event => {
                                let toFilter = new Set()
                                if (option?.DirectSend === true) {
                                  toFilter = notDSDocs
                                } else if (option?.DirectSend === false) {
                                  toFilter = dsDocs
                                }
                                setToDisable(new Set(toFilter))
                                toFilter.has(option) &&
                                  handleCheckBoxChange(event)
                              }}
                              disabled={
                                selectedDocs?.size > 0 &&
                                !!toDisable.has(option)
                              }
                            />
                            <ListItemText
                              primary={
                                <Grid container>
                                  <Grid
                                    item
                                    component="span"
                                    className="xsTitle "
                                  >
                                    {option?.DocNo}
                                  </Grid>
                                </Grid>
                              }
                            />
                          </Fragment>
                        )}
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                variant="outlined"
                                style={{ width: '100%' }}
                                label="Doc Ref"
                                margin="normal"
                                onKeyDown={(event: any) => {
                                  if (event.key === 'Backspace') {
                                    event.stopPropagation()
                                    setSelectedDocs(new Set())
                                  }
                                }}
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  select
                  fullWidth
                  ref={register}
                  margin="normal"
                  label="Doc Ref"
                  name="InvoiceID"
                  multiline={true}
                  control={control}
                  autoComplete="off"
                />
              </Grid>
            </>
          )}

          <div style={{ width: '100%', marginTop: '24px' }}>
            <FileUploadInput
              multiple
              files={files}
              name="Attachment"
              onHandleUploadChange={handleUploadChange}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              accept={
                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </div>
        </CardContents>
        <ARCNItemDetail
          CN={true}
          titleLabel={'Charge to'}
          itemData={creditNoteItemData}
          setOpenDialog={setOpenItemDialog}
          fetchBillItem={fetchBillItem}
          fetchCostCentre={fetchCostCentre}
          resetMenu={resetMenu}
          setSelectedTax={setSelectedTax}
          setDetailMode={setDetailMode}
          formMode={formMode}
          detailMode={detailMode}
          getBillItem={getBillItem}
          getCostCentre={getCostCentre}
          handleClick={handleClick}
          keyItemID={'CreditNoteItemID'}
          watch={watch}
          loadMasterCOA={loadMasterCOA}
        />

        {watch('DebtorAccountID') && !getAllocationDocumentLoading && (
          <FormAllocationTableContent
            headerLeftTitle={'Allocation'}
            fields={allocatedData}
            watch={watch}
            errors={errors}
            register={register}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            setError={setError}
            allocatedObj={allocatedObj}
            docAmt={getTotalAmt()}
            totalAmt={getAllocationTotal()}
            headerStyle={{
              backgroundColor: '#faf2e8',
              color: '#ff9800',
              fontWeight: '700',
            }}
            hasCheckbox={true}
          />
        )}
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setEdit(true)
            setOpenItemDialog(true)
            setDetailMode('edit')
            fetchBillItem()
            fetchCostCentre()
          }}
        >
          <span className="">Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteCreditNoteItem(menu?.index)
          }}
        >
          <span className="">Delete</span>
        </MenuItem>
      </Menu>

      {!BillItemLoading && (
        <ARItemDialog
          CompanyID={CompanyID}
          title={'Charge to'}
          fromAP={true}
          openItemDialog={openItemDialog}
          setOpenItemDialog={setOpenItemDialog}
          watch={watch}
          menu={menu}
          BillItemLoading={BillItemLoading}
          fetchBillItem={fetchBillItem}
          getBillItem={getBillItem}
          isEdit={edit}
          itemData={creditNoteItemData}
          selectedTax={selectedTax}
          setSelectedTax={setSelectedTax}
          detailMode={detailMode}
          setDetailMode={setDetailMode}
          resetMenu={resetMenu}
          primaryItemKey="CreditNoteItemID"
          is_einvoice={!!enableEInvoice ? watch('is_einvoice') : false}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-receivable/${CompanyID}/${docType}`)
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
      {footerOptions?.length > 0 ? (
        <Footer options={[...footerOptions]} />
      ) : null}

      <TooltipAmountFooter
        data={creditNoteItemData}
        module={'credit-note-draft'}
      />
    </>
  )
}
