import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { dateFormat } from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate'
import { LeaseContractSubheader } from 'containers/MFRS16Module/LeaseContract/LeaseContractSubheader'
import { useGetLeaseContractQuery } from 'generated/graphql'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ROUALLSTable } from './ROUALLSTable/ROUALLSTable'

export const ROUAssetsLeaseLiabilityScheduleDetail = (props: any) => {
  //=====================================
  //==============VARIABLES==============
  //=====================================
  let history = useHistory()
  const { CompanyID, LeaseContractID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const routeSegments = [
    { name: 'MFRS 16' },
    { name: 'ROU Assets & Lease Liability Schedule', current: true },
  ]
  const [contractNo, setContractNo] = useState('')
  const [leasePayment, setLeasePayment] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [leaseMonth, setLeaseMonth] = useState(0)
  const [totalLeaseAmount, setTotalLeaseAmount] = useState('')

  //=====================================
  //================QUERY================
  //=====================================
  const {
    loading: LeaseContractLoading,
    data: { getLeaseContract } = { getLeaseContract: [] },
  } = useGetLeaseContractQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, LeaseContractID },
    onCompleted: ({ getLeaseContract }) => {
      setContractNo(getLeaseContract[0]?.ContractNo)
      setLeasePayment(amtStr(getLeaseContract[0]?.LeasePayment))
      setStartDate(dateFormat(getLeaseContract[0]?.StartDate))
      setEndDate(dateFormat(getLeaseContract[0]?.EndDate))
      setLeaseMonth(getLeaseContract[0]?.LeaseMonth)
      setTotalLeaseAmount(amtStr(getLeaseContract[0]?.TotalLeaseAmount))
    },
  })

  //=====================================
  //===============FOOTER================
  //=====================================
  const accountFooter: any[] = [
    {
      name: 'Print',
      // onClick: () => {
      // },
      color: 'primary',
      props: { type: 'submit' },
    },
  ]

  const dummyData = [
    {
      Year: '2023',
      ScheduledYear: '0',
      Value: 700000,
      Status: 'Approved',
    },
    {
      Year: '2023',
      ScheduledYear: '1',
      Value: 700000,
      Status: 'Generated',
    },
    {
      Year: '2023',
      ScheduledYear: '2',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '3',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '4',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '5',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '6',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '7',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '8',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '9',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '10',
      Value: 700000,
      Status: 'Pending',
    },
    {
      Year: '2023',
      ScheduledYear: '11',
      Value: 700000,
      Status: 'Pending',
    },
  ]

  return (
    <>
      {LeaseContractLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(
            `/mfrs16/${CompanyID}/rou-assets-lease-liability-schedule`
          )
        }}
        smTitle={'MFRS 16'}
        title={user?.companyName}
        routeSegments={routeSegments}
        rightRouteSegments={[{ name: 'View', current: true }]}
      />
      <LeaseContractSubheader
        getLeaseContract={getLeaseContract}
        contractNo={contractNo}
        leasePayment={leasePayment}
        startDate={startDate}
        endDate={endDate}
        leaseMonth={leaseMonth}
        totalLeaseAmount={totalLeaseAmount}
      />
      <ROUALLSTable data={dummyData} />
      <AccountFooter options={[...accountFooter]} />
    </>
  )
}
