import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { useGetUsersByAccountAndSoftwareQuery } from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { WriteOffDisposalPostingList } from './WriteOffDisposalList/WriteOffDisposalPostingList'
import { WODisposalEntryList } from './WriteOffDisposalList/WODisposalEntryList'

export const WriteOffDisposalMain = (props: any) => {
  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Approved', ID: 'COMPLETED' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'All', ID: 'ALL' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))
  const [openExitConf, setOpenExitConf] = useState(null)
  const [TotalLength, setTotalLength] = useState(0)
  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  let dummyData = [
    {
      Name: 'Conference Table',
      Date: '12 Jan 2024',
      DisposalQuantity: '1',
      AssetNo: 'Asset No',
      Description: 'Remark here...',
    },
  ]

  useEffect(() => {
    setOriginalListing([])
  }, [listStatus])

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({ fetchPolicy: 'network-only' })

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  /* -------------------------------------------- */
  /*                   EXIT CONF                  */
  /* -------------------------------------------- */

  const hasChanges = () => {
    if (postIDs?.size === 0) return false
    else return true
  }

  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
      }, 1000)
    )
  }

  return (
    <>
      {UserLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true && listStatus === 'SUBMIT'
            ? setOpenExitConf(true)
            : history.push(`/fixed-assets/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }}
        smTitle="Fixed Assets"
        title={user?.companyName}
        routeSegments={[
          { name: 'Fixed Assets' },
          { name: 'WriteOffDisposal', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            changeDelay(e?.target?.value)
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
          }}
          isDefaultValue={!!getSearch?.name}
        />

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') && (
        <WODisposalEntryList
          filteredList={dummyData}
          listStatus={listStatus}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing}
        />
      )}

      {listStatus === 'SUBMIT' && (
        <WriteOffDisposalPostingList
          filteredList={dummyData}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/fixed-assets/${CompanyID}`)
        }}
      />
    </>
  )
}
