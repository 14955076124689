import React from 'react'
import { DepreciationMain } from './DepreciationMain'
import { DepreciationGenerateList } from './GenerateDepreciation/DepreciationGenerateList'
import { DepreciationGenerateSummary } from './GenerateDepreciation/DepreciationGenerateSummary'

const depreciationRoutes = [
  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/depreciation',
    },
    component: <DepreciationMain />,
  },

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/depreciation/generate-depreciation',
    },
    component: <DepreciationGenerateList />,
  },

  {
    props: {
      exact: true,
      path:
        '/fixed-assets/:CompanyID/depreciation/generate-depreciation/summary-depreciation',
    },
    component: <DepreciationGenerateSummary />,
  },
]

export default depreciationRoutes
