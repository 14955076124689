import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { PictureAsPdf } from '@material-ui/icons'
import { AttachmentDialog } from 'components/Dialog/AttachmentDialog'
import { useDocumentListingQuery } from 'generated/graphql'
import { AttachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
}

const dummyData = [
  {
    AssetName: 'Conference Table',
    DisposalValue: '0.00',
    DisposalExpenses: '0.00',
    DisposalGainLoss: '-2,500.00',
    BookValue: '2,500.00',
    Remark: 'Remark here...',
    SubmittedBy: 'Nabila',
    SubmiitedDate: '12 Jan 2023',
    Approvedby: 'Faris',
    ApprovedDate: '13 Jan 2023',
    Attachment: 'Lease-00000048.pdf',
  },
  {
    AssetName: 'Conference Table',
    DisposalValue: '0.00',
    DisposalExpenses: '0.00',
    DisposalGainLoss: '-2,500.00',
    BookValue: '2,500.00',
    Remark: 'Remark here...',
    SubmittedBy: 'Nabila',
    SubmiitedDate: '12 Jan 2023',
    Approvedby: 'Faris',
    ApprovedDate: '13 Jan 2023',
    Attachment: 'Lease-00000048.pdf',
  },
]

export const WriteOffDisposalDetailContent = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { listEl, documentListing, listStatus, userList } = props
  const [openDoc, setOpenDoc] = useState<boolean>(false)
  const { menu } = useMenuOption()

  const {
    loading: DocumentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: { refID: listEl?.AuditAdjustmentID },
  })

  return (
    <>
      {DocumentLoading && <Loading />}

      <div className="content-wrap full ">
        <div className="desc " style={{ color: 'grey' }}>
          {'Asset Name'}
        </div>
        <div className="mdDesc text-noflow"> {dummyData[0]?.AssetName} </div>
      </div>
      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Disposal Value'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.DisposalValue}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Disposal Expenses'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.DisposalExpenses}
        </div>
      </div>
      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Disposal Gain/loss'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.DisposalGainLoss}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Book Value'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.BookValue}
        </div>
      </div>
      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.SubmittedBy}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.SubmiitedDate}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {dummyData[0]?.Approvedby}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {dummyData[0]?.ApprovedDate}
            </div>
          </div>
        </>
      )}

      <div className="content-wrap full">
        <div className="desc" style={{ color: 'grey' }}>
          {'Remark'}
        </div>
        <div className="mdDesc text-noflow">{dummyData[0]?.Remark}</div>
      </div>
      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Attachments'}
        </div>
        <div className="mdDesc text-noflow">
          {dummyData[0]?.Attachment ? (
            <a
            // href={`/path/to/attachments/${dummyData[0].Attachment}`}
            // target="_blank"
            // rel="noopener noreferrer"
            // style={{
            //   display: 'flex',
            //   alignItems: 'center',
            //   textDecoration: 'none',
            //   color: 'inherit',
            // }}
            >
              <PictureAsPdf
                style={{ color: 'red', fontSize: '24px', marginRight: '8px' }}
              />
              {dummyData[0].Attachment}
            </a>
          ) : (
            'No attachments'
          )}
        </div>
      </div>

      <AttachmentDialog
        title="Revaluation"
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        AttachmentDetailView={AttachmentDetailView}
        DocumentListing={DocumentListing}
      />
    </>
  )
}
