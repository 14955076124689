import React from 'react'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'

export const DepreciationDetail = (props: any) => {
  const { CompanyID, listEl } = props

  return (
    <>
      <div className="content-wrap full">
        <div className="desc">Asset Name</div>
        <div className="xxTitle">{listEl?.Name}</div>
      </div>

      <div className="content-wrap left">
        <div className="desc">Asset No.</div>
        <div className="xxTitle">{listEl?.AssetNo}</div>
      </div>

      <div className="content-wrap right">
        <div className="desc">Depreciation Method</div>
        <div className="xxTitle">{listEl?.DepreciationMethod}</div>
      </div>

      <div className="content-wrap left">
        <div className="desc">Original Amount</div>
        <div className="xxTitle">{amtStr(listEl?.OriginalAmt)}</div>
      </div>

      <div className="content-wrap right">
        <div className="desc">Accumulated Depreciation Amount</div>
        <div className="xxTitle">{amtStr(listEl?.AccumulatedDepreciation)}</div>
      </div>

      <div className="content-wrap left">
        <div className="desc">Original Amount</div>
        <div className="xxTitle">{amtStr(listEl?.OriginalAmt)}</div>
      </div>

      <div className="content-wrap right">
        <div className="desc">Accumulated Depreciation Amount</div>
        <div className="xxTitle">{amtStr(listEl?.AccumulatedDepreciation)}</div>
      </div>

      <div className="content-wrap left">
        <div className="desc">Net Amount</div>
        <div className="xxTitle">{amtStr(listEl?.NetAmt)}</div>
      </div>

      <div className="content-wrap right">
        <div className="desc">Residual Value</div>
        <div className="xxTitle">
          {listEl?.ResidualValue !== null ? amtStr(listEl?.ResidualValue) : '-'}
        </div>
      </div>

      <div className="content-wrap full">
        <div className="desc">Latest pending period</div>
        <div className="xxTitle">{listEl?.LatestPendingInfo}</div>
      </div>
    </>
  )
}
