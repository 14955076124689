import React from 'react'
import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import { ARScheduledBillingMain } from './ARScheduledBillingMain'
import { ARScheduledBillingForm } from './ARScheduledBillingForm/ARScheduledBillingForm'
import { ARSchedullingBillingDetail } from './ARScheduledBillingDetail/ARScheduledBillingDetail'

const arSceduledBillingRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/scheduled-billing/filter',
    },
    component: (
      <AdvancedFilter
        mode="scheduled-billing"
        app="account-receivable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/scheduled-billing',
    },
    component: <ARScheduledBillingMain />,
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/scheduled-billing/add',
    },
    component: (
      <ARScheduledBillingForm formMode="add" type="SCHEDULED_BILLING" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/scheduled-billing/:ScheduledBillingID/edit',
    },
    component: <ARScheduledBillingForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/scheduled-billing/:ScheduledBillingID/approve-reject',
    },
    component: <ARScheduledBillingForm mode="approve-reject" />,
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/scheduled-billing/:ScheduledBillingID/detail',
    },
    component: <ARSchedullingBillingDetail />,
  },
]

export default arSceduledBillingRoutes
