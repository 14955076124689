import React, { lazy } from 'react'

const AssetsSourceListing = lazy(() =>
  import('./AssetsSourceListing').then(module => ({
    default: module.AssetsSourceListing,
  }))
)

const assetsSourceRoutes = [
  {
    props: {
      exact: true,
      path: '/fixed-assets/general-setting/assets-source',
    },
    component: <AssetsSourceListing />,
  },
]

export default assetsSourceRoutes
