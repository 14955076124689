import React, { lazy } from 'react'

const AssetsLocationListing = lazy(() =>
  import('./AssetsLocationListing').then(module => ({
    default: module.AssetsLocationListing,
  }))
)

const assetsLocationRoutes = [
  {
    props: {
      exact: true,
      path: '/fixed-assets/general-setting/assets-location',
    },
    component: <AssetsLocationListing />,
  },
]

export default assetsLocationRoutes
