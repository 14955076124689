import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { amtStr, amtStr4Dec } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import { TinyTabEmptyList, TrxTaxTable } from './TransactionTables'

// Use coloredCell function instead of withStyles to apply grouped coloring
const coloredCell = (index: number) => {
  if (index === 0 || index % 2 === 0) {
    return '#FFFFFF'
  } else {
    return '#F2F2F2'
  }
}

export const TrxItemTable = ({ TransactionItem }) => {
  return (
    <StyledTableContainer component={Paper} style={{ overflowX: 'scroll' }}>
      <Table size="small" style={{ width: '200%' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{ backgroundColor: '#ff9800', width: '150px' }}
            >
              Classification
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#ff9800',
                minWidth: '350px',
                maxWidth: '350px',
              }}
            >
              Description
            </StyledTableCell>
            <StyledTableCell
              style={{ backgroundColor: '#ff9800', textAlign: 'right' }}
            >
              Unit Price
            </StyledTableCell>
            <StyledTableCell
              style={{ backgroundColor: '#ff9800', textAlign: 'right' }}
            >
              Quantity
            </StyledTableCell>
            <StyledTableCell
              style={{ backgroundColor: '#ff9800', textAlign: 'right' }}
            >
              Discount Rate
            </StyledTableCell>
            <StyledTableCell
              style={{ backgroundColor: '#ff9800', textAlign: 'right' }}
            >
              Discount Amount
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#ff9800',
                minWidth: '200px',
                maxWidth: '200px',
              }}
            >
              Tax Exemp
            </StyledTableCell>
            <StyledTableCell
              style={{ backgroundColor: '#ff9800', textAlign: 'right' }}
            >
              Tax Exemp Amount
            </StyledTableCell>
            <StyledTableCell
              style={{ backgroundColor: '#ff9800', textAlign: 'right' }}
            >
              Subtotal
            </StyledTableCell>
            <StyledTableCell
              style={{ backgroundColor: '#ff9800', textAlign: 'right' }}
            >
              Total Exclude Tax
            </StyledTableCell>
            <StyledTableCell
              style={{ backgroundColor: '#ff9800', textAlign: 'right' }}
            >
              Tax Amount
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: '#ff9800' }}>
              Unit of Measure
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#ff9800',
                minWidth: '200px',
                maxWidth: '200px',
              }}
            >
              Product Tariff Code
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#ff9800',
                minWidth: '200px',
                maxWidth: '200px',
              }}
            >
              Country of Origin
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {TransactionItem?.map((v, i) => {
            return <TrxItemIteration v={v} i={i} />
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  )
}

const TrxItemIteration = ({ v, i }) => {
  const [tabVal, setTabVal] = useState<number>(3)
  const handleTabChange = index => {
    return setTabVal(tabVal !== index ? index : 3)
  }
  return (
    <>
      <TableRow style={{ borderTop: '1px solid rgb(224, 224, 224)' }}>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i) }}
        >
          {v?.ClassificationCode}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i) }}
        >
          {v?.Description}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i), textAlign: 'right' }}
        >
          {amtStr(v?.UnitPrice)}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i), textAlign: 'right' }}
        >
          {amtStr4Dec(v?.Quantity)}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i), textAlign: 'right' }}
        >
          {amtStr(v?.DiscountRate)}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i), textAlign: 'right' }}
        >
          {amtStr(v?.DiscountAmt)}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i) }}
        >
          {v?.TaxExemp ?? '—'}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i), textAlign: 'right' }}
        >
          {amtStr(v?.TaxExempAmt)}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i), textAlign: 'right' }}
        >
          {amtStr(v?.Subtotal)}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i), textAlign: 'right' }}
        >
          {amtStr(v?.TotalExcludeTax)}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i), textAlign: 'right' }}
        >
          {amtStr(v?.TaxAmt)}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i) }}
        >
          {v?.UOM?.Code}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i) }}
        >
          {v?.ProductTariffCode}
        </StyledTableCell>
        <StyledTableCell
          size="small"
          style={{ backgroundColor: coloredCell(i) }}
        >
          {v?.OriginCountry}
        </StyledTableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: coloredCell(i) }}>
        <SubTableCell colSpan={15} style={{ padding: '5px' }}>
          <TinyTabs indicatorColor="primary" textColor="primary" value={tabVal}>
            <TinyTab
              label="Tax"
              onClick={() => {
                handleTabChange(0)
              }}
            />
            <TinyTab
              label="Tax Exemp"
              onClick={() => {
                handleTabChange(1)
              }}
            />
            <TinyTab
              label="Additional Discount/Charge"
              onClick={() => {
                handleTabChange(2)
              }}
            />
          </TinyTabs>
        </SubTableCell>
      </TableRow>
      {tabVal < 3 && (
        <TableRow style={{ backgroundColor: coloredCell(i) }}>
          <SubTableCell size="small" colSpan={15} style={{ padding: '8px' }}>
            <TrxSubtable tabVal={tabVal} data={v} />
          </SubTableCell>
        </TableRow>
      )}
    </>
  )
}

const TrxSubtable = ({ tabVal, data }) => {
  if (tabVal === 0) {
    return <TrxTaxTable TransactionTax={data?.Tax} IsSubtable={true} />
  } else return <TinyTabEmptyList title="No Record Found" /> // Await
}

// Styling put at the bottom according to priority
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#2F53E6',
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
  body: { color: theme.palette.common.black, fontWeight: 600, fontSize: 12 },
}))(TableCell)

const SubTableCell = withStyles({
  root: {
    padding: '0',
    borderTop: '1px solid rgb(224, 224, 224)',
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
})(TableCell)

const StyledTableContainer = withStyles({
  root: { boxShadow: 'none', borderRadius: 'none', marginBottom: '8px' },
})(TableContainer)

const TinyTabs = withStyles({ root: { minHeight: '24px', height: '24px' } })(
  Tabs
)

const TinyTab = withStyles({
  root: {
    textTransform: 'none',
    fontSize: '11px',
    minWidth: '50px',
    minHeight: '24px',
    height: '24px',
    fontWeight: 400,
  },
})(Tab)
