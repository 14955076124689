import React from 'react'
import { APDetail } from '../APDetail/APDetail'
import { APMain } from '../APMain'
import { APSelfBilledForm } from './APSelfBilledForm'

const selfBilledRoutes = [
  //---------------------------------------------//
  //                 SELF BILLED                 //
  //---------------------------------------------//

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/self-billed',
    },

    component: (
      <APMain
        apSubmenu="self-billed"
        routeSegments="Self-Billed"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/self-billed/:DocumentID/detail',
    },
    // component: (
    //   <APDebitNoteMain accountType="account-payable" docType="debitNote" />
    // ),

    component: (
      <APDetail
        apSubmenu="self-billed"
        routeSegments="Self-Billed"
        accountType="account-payable"
      />
    ),
  },

  //---------------------------------------------//
  //                 SELF BILLED                 //
  //---------------------------------------------//

  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/self-billed/:formMode`,
    },
    component: (
      <APSelfBilledForm
        accountType="account-payable"
        docType="self-billed"
        routeSegments="Self-Billed"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/self-billed/:ID/edit',
    },

    component: (
      <APSelfBilledForm
        apSubmenu="self-billed"
        routeSegments="Self-Billed"
        accountType="account-payable"
        docType="self-billed"
        formMode="edit"
      />
    ),
  },
]

export default selfBilledRoutes
