import React, { lazy } from 'react'

const GLCompanyPolicyForm = lazy(() =>
  import('./GLCompanyPolicyForm').then(module => ({
    default: module.GLCompanyPolicyForm,
  }))
)

const glCompanyPolicyRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/company-setting/company-policy',
    },
    component: <GLCompanyPolicyForm />,
  },
]

export default glCompanyPolicyRoutes
