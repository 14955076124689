export const useColorStatusList = () => {
  let color, text
  const handleStatusColor = type => {
    switch (type) {
      case 'ACTIVE':
        color = ''
        break
      case 'COMPLETED':
        color = '#00bf0f'
        break

      case 'REJECTED':
        color = '#FF0000'
        break
      case 'CANCELLED':
        color = ''
        break
      case 'CLOSED':
        color = ''
        break
      case 'DRAFT':
        color = '#454545'
        break
      case 'SUBMIT':
        color = '#2f53e6'
        break
      case 'APPROVED':
        color = '#ff9800'
        break
      case 'PENDING':
        color = '#2924d9'
        break
      case 'PENDING_CLOSE':
        color = '#2924d9'
        break
      //FOR CANCEL ONLY
      case 'COMPLETED_CANCEL':
        color = '#00bf0f'
        break
      case 'SUBMIT_CANCEL':
        color = '#ff9800'
        break
      case 'APPROVED_CANCEL':
        color = '#ff9800'
        break
      case 'PENDING_CANCEL':
        color = '#2924d9'
        break
      case 'ACTIVATED':
        color = '#00bf0f'
        break
      case 'DEACTIVATED':
        color = '#FF0000'
        break
      case 'INACTIVATED':
        color = '#FF0000'
        break
      default:
        color = ''
        break
    }
    return color
  }
  const handleStatusRename = type => {
    switch (type) {
      case 'ACTIVE':
        text = 'Draft'
        break
      case 'APPROVED':
        text = 'Approved'
        break
      case 'COMPLETED':
        text = 'Approved'
        break
      case 'REJECTED':
        text = 'Rejected'
        break
      case 'CANCELLED':
        text = 'Cancelled'
        break
      case 'CLOSED':
        text = 'Closed'
        break
      case 'DRAFT':
        text = 'Draft'
        break
      case 'SUBMIT':
        text = 'Submitted'
        break
      case 'PENDING':
        text = 'Pending'
        break
      case 'PENDING_CLOSE':
        text = 'Pending'
        break
      case 'ACTIVATED':
        text = 'Activated'
        break
      case 'DEACTIVATED':
        text = 'Deactivated'
        break
      case 'INACTIVATED':
        text = 'Inactivated'
        break
      default:
        text = null
        break
    }
    return text
  }
  return {
    handleStatusColor,
    handleStatusRename,
  }
}
