import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { MenuItem, TextField } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { useGetCostCentreQuery } from 'generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'

interface RevaluationProps {
  Date: string
  RevisedNetAmount: any
  AssetNo: string
  AssetName: any
  OriginalAmount: number
  AccumulatedDepreciation: number
  NetAmount: number
  Remark: any
}

export const RevaluationForm = (props: any) => {
  const isGLSubscribe = JSON.parse(localStorage.getItem('isGLSubscribe'))
  let history = useHistory()
  let location = useLocation()
  const { CompanyID }: any = useParams()
  const { mode } = props
  const editData: any = location?.state
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [voiceRemark, setVoiceRemark] = useState('')
  const [record, setRecord] = useState(false)
  const bankAccountScheme = yup.object().shape({})
  const {
    handleSubmit,
    control,
    register,
    watch,
    setError,
    clearErrors,
    errors,
    setValue,
    formState: { isDirty },
  } = useForm<RevaluationProps>({
    mode: 'onSubmit',
    resolver: yupResolver(bankAccountScheme),
  })

  const {
    loading: CostCentreLoading,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      orderByAsc: 'CostCentreID',
      IsLastNode: true,
    },
  })

  let dummyData = [
    {
      Date: 'string',
      RevisedNetAmount: 'any',
      AssetNo: 'A-000013',
      AssetName: 'Computer Pheripheral',
      OriginalAmount: '20,000.00',
      AccumulatedDepreciation: '0.00',
      NetAmount: '20,000.00',
      Remark: 'any',
    },
    {
      Date: 'string',
      RevisedNetAmount: 'any',
      AssetNo: 'A-000013',
      AssetName: 'Computer Pheripheral',
      OriginalAmount: '20,000.00',
      AccumulatedDepreciation: '0.00',
      NetAmount: '20,000.00',
      Remark: 'any',
    },
  ]

  function handleAmtChange(floatValue: any) {
    throw new Error('Function not implemented.')
  }

  return (
    <>
      {CostCentreLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          history.push(`/fixed-assets/${CompanyID}/revaluation`)
        }}
        smTitle={'Fixed Assets'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Company Setting' },
          { name: 'Company' },
          { name: 'Company' },
          { name: 'Revaluation', current: true },
        ]}
        rightRouteSegments={[
          { name: mode === 'add' ? 'Edit' : 'New', current: true },
        ]}
      />

      <ContentWrapper footer>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="Date"
              label="Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd MMM yyyy"
              value={mode === 'add' ? new Date() : editData?.Date}
              margin="dense"
              allowKeyboardControl
              inputRef={register({})}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData ? editData?.Date : new Date()}
              helperText={errors?.Date?.message}
              error={errors?.Date ? true : false}
              showTodayButton
              className="left"
            />
          </MuiPickersUtilsProvider>
          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            id="standard-basic"
            name="Revised Net Amount"
            label=" Revised Net Amount"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.RevisedNetAmount?.message}
            error={errors?.RevisedNetAmount ? true : false}
            ref={register}
            defaultValue={0}
            className="right"
            disabled={mode === 'approve-reject'}
          />

          <Controller
            as={
              <TextField select>
                {dummyData?.map((el, index) => (
                  <MenuItem
                    style={{ whiteSpace: 'normal' }}
                    value={el.RevisedNetAmount}
                    key={index}
                  >
                    <span className="text-noflow">{el.AssetNo}</span>
                  </MenuItem>
                ))}
              </TextField>
            }
            name="Asset No"
            label="Asset No"
            autoComplete="on"
            margin="normal"
            fullWidth
            ref={register}
            control={control}
            required
            helperText={errors?.AssetNo?.message}
            error={errors?.AssetNo ? true : false}
            defaultValue={editData?.BankProfileID}
          ></Controller>

          <Controller
            as={TextField}
            name="Asset Name"
            label="Asset Name"
            autoComplete="off"
            fullWidth
            control={control}
            helperText={errors?.AssetName?.message}
            error={errors?.AssetName ? true : false}
            defaultValue="Computer Pheripheral"
            InputProps={{ disableUnderline: true }}
          ></Controller>

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            name="Original Amount"
            label="Original Amount"
            autoComplete="off"
            fullWidth
            control={control}
            margin="dense"
            className="left"
            helperText={errors?.OriginalAmount?.message}
            error={errors?.OriginalAmount ? true : false}
            defaultValue="20,000.00"
            InputProps={{ disableUnderline: true }}
          ></Controller>

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            name="Accumulated Depreciation"
            label="Accumulated Depreciation"
            autoComplete="off"
            fullWidth
            control={control}
            margin="dense"
            className="right"
            decimalScale={2}
            fixedDecimalScale
            defaultValue={0}
            InputProps={{ disableUnderline: true }}
          ></Controller>

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            name="Net Amount"
            label="Net Amount"
            autoComplete="off"
            fullWidth
            control={control}
            helperText={errors?.NetAmount?.message}
            error={errors?.NetAmount ? true : false}
            defaultValue="20,000.00"
            InputProps={{ disableUnderline: true }}
          ></Controller>

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            required
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />
        </CardContents>
      </ContentWrapper>

      <Footer
        options={[
          {
            name: 'Save As Draft',
            onClick: () => {},
            color: 'primary',
          },
          {
            name: 'Submit',
            onClick: () => {},
            color: 'primary',
          },
        ]}
      />
    </>
  )
}
