import { Button, Grid, List } from '@material-ui/core'
import ExpansionPanel, {
  ExpansionPanelProps,
} from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import SliderIcon from '@material-ui/icons/Tune'
import React from 'react'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

interface IExpansion extends ExpansionPanelProps {
  children: any
  summary?: any
  expansion?: boolean
  customColor?: boolean
  customColorCode?: string
  className?: string
  backColor?: any
  headerColor?: any
  onClick?: any
}
export default function AdvanceFilterCardExpansion({
  summary,
  children,
  expansion,
  customColor,
  customColorCode,
  className,
  backColor,
  headerColor,
  onClick,
  ...props
}: IExpansion) {
  // const [expanded, setExpanded] = React.useState<string | false>(expansion)
  const [expanded, setExpanded] = React.useState<boolean>(false)

  const handleChange = (panel?: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    // setExpanded(isExpanded ? panel : false)
    setExpanded(isExpanded)
  }

  const closeButtonStyle = { display: 'flex', justifyContent: 'center' }

  const closeExpansion = () => {
    return (
      <Grid style={closeButtonStyle}>
        <Button
          style={{ marginTop: '-15px' }}
          onClick={() => {
            return setExpanded(false)
          }}
        >
          <KeyboardArrowUpIcon />
        </Button>
      </Grid>
    )
  }

  return (
    <ExpansionPanel
      {...props}
      className={'expand-card ' + className}
      elevation={0}
      onClick={e => e.stopPropagation()}
      style={{
        backgroundColor: backColor ? backColor : null,
        borderRadius: '4px',
      }}
      expanded={expanded}
      onChange={handleChange()}
    >
      <ExpansionPanelSummary
        style={
          customColor
            ? { backgroundColor: customColorCode }
            : { backgroundColor: 'white' }
        }
        expandIcon={<SliderIcon onClick={onClick} />}
      >
        <List
          className={'core-list'}
          style={
            customColor
              ? { backgroundColor: customColorCode }
              : { backgroundColor: 'white' }
          }
        >
          {summary}
        </List>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: 'block' }}>
        {children}
        {closeExpansion()}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
