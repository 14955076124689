import React from 'react'
import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import { RevaluationMain } from './RevaluationMain'
import { RevaluationForm } from './RevaluationForm/RevaluationForm'
import { RevaluationDetail } from './RevaluationDetail/RevaluationDetail'

const RevaluationRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  // {
  //   props: {
  //     exact: true,
  //     path: '/fixed-assets/:CompanyID/revaluation/filter',
  //   },
  //   component: (
  //     <AdvancedFilter
  //       mode="revaluation"
  //       app="fixed-assets"
  //       hasStatus
  //       hasDocDate
  //       hasDateRange
  //     />
  //   ),
  // },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/revaluation',
    },
    component: <RevaluationMain />,
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/revaluation/add',
    },
    component: <RevaluationForm formMode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/revaluation/edit',
    },
    component: <RevaluationForm formMode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/revaluation/approve-reject',
    },
    component: <RevaluationForm mode="approve-reject" />,
  },

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/revaluation/resubmit',
    },
    component: <RevaluationForm formMode="resubmit" />,
  },

  /* -------------------------------------------- */
  /*                     DETAIL                   */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/revaluation/detail',
    },
    component: <RevaluationDetail />,
  },
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/revaluation/detail/workdesk',
    },
    component: <RevaluationDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/revaluation/detail/workdesk-history',
    },
    component: <RevaluationDetail mode="workdesk-history" />,
  },
]

export default RevaluationRoutes
