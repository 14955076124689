import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { MoreVert, SubdirectoryArrowRightOutlined } from '@material-ui/icons'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { TreeView } from '@material-ui/lab'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { CustomTreeItem } from 'containers/GeneralSettingModule/MasterCOA/RoleTreeStyle'
import {
  AcctPermission,
  GetCompanyNameDocument,
  GetCostCentreDocument,
  RecordStatus,
  useCreateCostCentreMutation,
  useDeleteCostCentreMutation,
  useGetCompanyNameQuery,
  useGetCostCentreTreeListingLazyQuery,
  useGetGlCompanyPolicyQuery,
  useSetDefaultCostCentreMutation,
  useUpdateCostCentreMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import { uuid } from 'uuidv4'
import * as yup from 'yup'

export interface CostCentreProps {
  Code: string
  Name: string
  CostCentreType: string
}

export const CostCentreFormV4 = (props: any) => {
  let history = useHistory()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))

  const { CompanyID }: any = useParams()
  const [open, setOpen] = useState<boolean>(false)

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [openExitConf, setOpenExitConf] = useState(null)

  const [useDefaultDept, setUseDefaultDept] = useState<boolean>(false)

  const [pageMode, setPageMode] = useState('')

  const [expandArray, setExpandArray] = useState([])
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [delDialog, setdelDialog] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  const [haveItem, setHaveItem] = useState(false)
  const [selectedCC, setSelectedCC] = useState(null)
  const { filteredList, setOriginalListing } = useFuseSearch()
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)
  const [confirmClicked, setConfirmClicked] = useState(false)

  const CostCentreFormSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
    Code: CommonYupValidation.requireField(SystemMsgs.code()),
  })

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    setError,
    getValues,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<CostCentreProps>({
    shouldUnregister: false, // unmount input state will be remained
    resolver: yupResolver(CostCentreFormSchema),
  })

  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
    refetch,
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, RecordStatus: RecordStatus.Active },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ getCompany }) => {
      setUseDefaultDept(
        getCompany[0]?.GLCompanyPolicy?.DefaultDepartmentBalanceSheetCOA
      )
      fetchCostCentre({ variables: { CompanyID } })
    },
  })

  const {
    loading: glCompanyPolicyLoading,
    data: { getGLCompanyPolicy } = { getGLCompanyPolicy: [] },
  } = useGetGlCompanyPolicyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ getGLCompanyPolicy }) => {
      setUseDefaultDept(getGLCompanyPolicy[0]?.DefaultDepartmentBalanceSheetCOA)
    },
  })

  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreTreeListingLazyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ getCostCentre }) => {
      if (getCostCentre?.length > 0) {
        setPageMode('Edit')
      } else {
        setPageMode('Add')
      }

      if (getCostCentre?.length === 0 && getCompany?.length > 0) {
        let ArrayWCompany = []
        const dataWCompany = {
          Name: getCompany[0]?.Name,
          CostCentreID: uuid(),
          ParentCostCentreID: null,
          Level: 0,
          CostCentreDefName: '',
          IsLastNode: null,
          IsDefault: null,
          CostCentreDefinitionDetailID: null,
          UsedDepartment: false,
          child: [],
        }
        ArrayWCompany.push(dataWCompany)
        setOriginalListing(ArrayWCompany)
      } else if (getCostCentre?.length > 0 && getCompany?.length > 0) {
        let initialArr = []
        let ArrayWCompany = []

        let level = getCostCentre.map(v => v?.Level)?.sort((a, b) => b - a)[0]

        initializeLevel(1, getCostCentre, initialArr, level, level)

        /// FILTER IF HAVE CHILD////
        const childEmpty = initialArr?.filter(x => x?.child?.length === 0)
        const AllArray = initialArr
          ?.filter(x => x?.child?.length > 0)
          ?.concat(childEmpty)

        const dataWCompany = {
          Name: getCompany[0]?.Name,
          CostCentreID: uuid(),
          ParentCostCentreID: null,
          Level: 0,
          CostCentreDefName: 'Company',
          IsLastNode: null,
          IsDefault: null,
          CostCentreDefinitionDetailID: null,
          UsedDepartment: false,
          child: AllArray,
        }

        ArrayWCompany.push(dataWCompany)

        setOriginalListing(ArrayWCompany)
        if (getCompany !== undefined || getCompany?.length !== 0)
          setExpandArray(ArrayWCompany.concat(getCostCentre))
      }
    },
  })

  const [
    createCostCentre,
    { loading: CreateLoading },
  ] = useCreateCostCentreMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ createCostCentre }) => {
      if (createCostCentre) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.createNewRecord())
        setOpen(false)
        fetchCostCentre({ variables: { CompanyID } })
        setConfirmClicked(false)
      }
    },
  })

  const [
    updateCostCentre,
    { loading: UpdateLoading },
  ] = useUpdateCostCentreMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ updateCostCentre }) => {
      if (updateCostCentre) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.updateRecord())
        setEditDialog(false)
        fetchCostCentre({ variables: { CompanyID } })
      }
    },
  })

  const [
    setDefaultCostCentre,
    { loading: SetDefaultLoading },
  ] = useSetDefaultCostCentreMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ setDefaultCostCentre }) => {
      if (setDefaultCostCentre) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.updateRecord())
        setEditDialog(false)
        fetchCostCentre({ variables: { CompanyID } })
        refetch()
      }
    },
  })

  const [
    deleteCostCentre,
    { loading: DeleteLoading },
  ] = useDeleteCostCentreMutation({
    onError: error => {
      setOpenSnackBar(true)
      setSnackBarMsg('Department already used')
      setdelDialog(false)
    },
    onCompleted: ({ deleteCostCentre }) => {
      if (deleteCostCentre) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.deleteRecord())
        setdelDialog(false)
        fetchCostCentre({ variables: { CompanyID } })
      }
    },
  })

  const initializeLevel = (level, data, arr, maxLevel, prtID) => {
    const dataToPush = data
      ?.filter(v => {
        if (v?.ParentCostCentreID !== null) {
          return v?.Level === level && v?.ParentCostCentreID === prtID
        } else {
          return v?.Level === level
        }
      })
      ?.sort((a, b) => a?.Code - b?.Code)
      ?.map(x => {
        return {
          Name: x?.Name,
          CostCentreID: x?.CostCentreID,
          ParentCostCentreID: x?.ParentCostCentreID,
          Level: x?.Level,
          CostCentreDefName: x?.CostCentreDefinitionDetail?.Name,
          IsLastNode: x?.IsLastNode,
          IsDefault: x?.IsDefault,
          CostCentreDefinitionDetailID: x?.CostCentreDefinitionDetailID,
          data: {
            FavoriteJournalItem: x?.FavoriteJournalItem,
            JournalItem: x?.JournalItem,
            JournalProcessingItem: x?.JournalProcessingItem,
            BudgetCostCentreItem: x?.BudgetCostcentreItem,
          },
          Code: x?.Code,
          MasterCode: x?.MasterCode,
          UsedDepartment: x?.UsedDepartment,
          child: [],
        }
      })

    arr.push(...dataToPush)

    if (level < maxLevel) {
      arr.map(k => {
        initializeLevel(level + 1, data, k.child, maxLevel, k.CostCentreID)
      })
    }
  }

  const renderTree = (nodes, index) => (
    <CustomTreeItem
      key={nodes?.CostCentreID}
      nodeId={nodes?.CostCentreID}
      label={
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: nodes?.Level === 0 ? null : '-15px',
          }}
        >
          {nodes?.Level === 0 ? null : (
            <span style={{ marginBottom: 'auto' }}>
              <SubdirectoryArrowRightOutlined
                fontSize="inherit"
                style={{
                  fontSize: '20px',
                }}
              />
            </span>
          )}
          <span style={{ paddingBottom: '10px', marginBottom: 'auto' }}>
            <AddBoxIcon
              style={{
                width: '25px',
                height: '25px',
                float: 'right',
                color:
                  nodes?.IsDefault === true ||
                  nodes?.UsedDepartment ||
                  (!user?.superUser &&
                    handlePermDisabled({
                      permEnum:
                        AcctPermission.GeneralLedgerCompanySettingsCostCentreCreate,
                    }))
                    ? '#e0e0e0'
                    : theme.palette.primary.main,
              }}
              disabled={
                !user?.superUser &&
                handlePermDisabled({
                  permEnum:
                    AcctPermission.GeneralLedgerCompanySettingsCostCentreCreate,
                })
              }
              onClick={() => {
                if (
                  nodes?.IsDefault !== true &&
                  nodes?.UsedDepartment === false
                ) {
                  setSelectedCC(nodes)
                  setValue('Name', '')
                  setValue('Code', '')
                  setOpen(true)
                }
              }}
            />
          </span>
          <Typography
            variant="body2"
            style={{
              fontWeight: 'inherit',
              flexGrow: 1,
              marginTop: '2px',
              paddingLeft: '10px',
              marginBottom: 'auto',
              paddingBottom:
                nodes?.Level === 0 && filteredList[0]?.child?.length === 0
                  ? '10px'
                  : '20px',
            }}
          >
            {nodes?.Level === 0 ? null : (
              <span className="c-orange">{nodes?.Code}</span>
            )}
            <span
              style={{
                paddingLeft: nodes?.Level === 0 ? null : '10px',
                color: nodes?.Level === 0 ? '#9c9c9c' : null,
              }}
            >
              {nodes.Name}
            </span>
          </Typography>
          <Typography
            variant="caption"
            color="inherit"
            style={{
              marginTop: '-11px',
              marginBottom: 'auto',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {nodes?.IsDefault === true ? (
              <CheckCircleIcon
                style={{ color: 'limegreen', fontSize: 'medium' }}
              />
            ) : null}
            {nodes?.Level === 0 ? null : (
              <IconButton
                onClick={e => {
                  handleClick(e, nodes?.ID, index, nodes)
                  checkLastNodeItem(nodes)
                }}
                style={{ color: 'black' }}
              >
                <MoreVert />
              </IconButton>
            )}
          </Typography>
        </Box>
      }
    >
      {Array.isArray(nodes.child)
        ? nodes.child.map((nodes, index) => renderTree(nodes, index))
        : null}
    </CustomTreeItem>
  )

  const onSubmit = data => {
    createCostCentre({
      variables: {
        input: {
          CompanyID,
          Name: data?.Name,
          Level: selectedCC?.Level + 1,
          ParentCostCentreID:
            selectedCC?.Level === 0 ? null : selectedCC?.CostCentreID,
          IsLastNode: true,
          Code: data?.Code.toLocaleUpperCase(),
          MasterCode: !!selectedCC?.MasterCode
            ? `${selectedCC?.MasterCode}${data?.Code.toLocaleUpperCase()}`
            : data?.Code.toLocaleUpperCase(),
        },
      },
      refetchQueries: [
        { query: GetCostCentreDocument, variables: { CompanyID } },
      ],
    })
  }

  const onEdit = (data, defaultData) => {
    updateCostCentre({
      variables: {
        input: {
          CostCentreID: defaultData?.CostCentreID,
          CompanyID: defaultData?.CompanyID,
          CostCentreDefinitionDetailID:
            defaultData?.CostCentreDefinitionDetailID,
          ParentCostCentreID: defaultData?.ParentID,
          Name: data?.Name,
          Code: data?.Code.toLocaleUpperCase(),
          MasterCode:
            menu?.obj?.ParentCostCentreID !== null
              ? `${
                  getCostCentre?.filter(
                    x => x?.CostCentreID === menu?.obj?.ParentCostCentreID
                  )[0]?.MasterCode
                }${data?.Code.toLocaleUpperCase()}`
              : data?.Code.toLocaleUpperCase(),
          Level: defaultData?.Level,
          IsLastNode: defaultData?.IsLastNode,
        },
      },
      refetchQueries: [
        { query: GetCostCentreDocument, variables: { CompanyID } },
      ],
    })
  }

  const onSetDefault = obj => {
    setDefaultCostCentre({
      variables: { CostCentreID: obj?.CostCentreID, CompanyID },
      refetchQueries: [
        { query: GetCostCentreDocument, variables: { CompanyID } },
        { query: GetCompanyNameDocument, variables: { CompanyID } },
      ],
    })
  }

  const onDelete = CostCentreID => {
    deleteCostCentre({
      variables: { CostCentreID: CostCentreID, CompanyID },
      refetchQueries: [
        { query: GetCostCentreDocument, variables: { CompanyID } },
      ],
    })
  }

  const checkLastNodeItem = selectedNode => {
    setHaveItem(false)
    let lastChild = []
    findLastNode(selectedNode, lastChild)
    lastChild?.map(x => {
      if (
        x?.data?.BudgetCostCentreItem?.length > 0 ||
        x?.data?.FavoriteJournalItem?.length > 0 ||
        x?.data?.JournalItem?.length > 0 ||
        x?.data?.JournalProcessingItem?.length > 0
      )
        setHaveItem(true)
    })
  }

  function findLastNode(element, arr) {
    if (element.child.length === 0 || element.child === null) {
      arr.push(element)
    } else {
      var i
      var result = null
      for (i = 0; result == null && i < element.child.length; i++) {
        result = findLastNode(element.child[i], arr)
      }
      return result
    }
  }

  const checkSameCode = (CCID, Level, mode) => {
    const getAllChild =
      mode === 'add'
        ? getCostCentre?.filter(
            x => x?.ParentCostCentreID === CCID && x?.Level === Level + 1
          )
        : getCostCentre?.filter(
            x =>
              x?.ParentCostCentreID === menu?.obj?.ParentCostCentreID &&
              x?.Level === Level &&
              x?.CostCentreID !== menu?.obj?.CostCentreID
          )

    const sameCode = getAllChild?.filter(
      el => el?.Code.toLocaleUpperCase() === watch('Code')?.toLocaleUpperCase()
    )

    if (sameCode?.length > 0) {
      return true
    } else return false
  }

  const checkSameName = (CCID, Level, mode) => {
    const getAllChild =
      mode === 'add'
        ? getCostCentre?.filter(
            x => x?.ParentCostCentreID === CCID && x?.Level === Level + 1
          )
        : getCostCentre?.filter(
            x =>
              x?.ParentCostCentreID === menu?.obj?.ParentCostCentreID &&
              x?.Level === Level &&
              x?.CostCentreID !== menu?.obj?.CostCentreID
          )

    const sameName = getAllChild?.filter(
      el => el?.Name.toLocaleLowerCase() === watch('Name')?.toLocaleLowerCase()
    )

    if (sameName?.length > 0) {
      return true
    } else return false
  }

  const { handlePermDisabled } = usePermissionChecker()

  return (
    <>
      {CreateLoading && <Loading />}
      {DeleteLoading && <Loading />}
      {UpdateLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {SetDefaultLoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {glCompanyPolicyLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(`/general-ledger/${CompanyID}/company-setting`)
        }
        smTitle={'General Ledger'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Company Setting' },
          { name: 'Company List' },
          { name: 'Department', current: true },
        ]}
        rightRouteSegments={[{ name: pageMode, current: true }]}
      />
      <DynamicSubHeader
        title={getCompany[0]?.Name}
        infoLine={
          getCompany[0]?.GLCompanyPolicy?.DefaultDepartmentBalanceSheetCOA &&
          `Default Department: ${getCompany[0]?.DefaultCostCentre?.Name ??
            'NA'}`
        }
      />
      {!CostCentreLoading && !CompanyLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContentWrapper multiDynamicInfo float>
            <CardContents section={{ header: { title: 'Hierarchy' } }}>
              <TreeView
                className="tree-view-custom"
                expanded={expandArray?.map(x => x?.CostCentreID)}
                multiSelect
              >
                {filteredList.map((el, index) => {
                  return renderTree(el, index)
                })}
              </TreeView>
            </CardContents>
            <Menu
              id="menu-list"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onClick={handleClose}
            >
              <MenuItem
                disabled={
                  !user?.superUser &&
                  handlePermDisabled({
                    permEnum:
                      AcctPermission.GeneralLedgerCompanySettingsCostCentreUpdate,
                  })
                    ? true
                    : false
                }
                onClick={() => {
                  setValue('Name', menu?.obj?.Name)
                  setValue('Code', menu?.obj?.Code)
                  setEditDialog(true)
                }}
              >
                <span className="text no-flow">Edit</span>
              </MenuItem>

              {menu?.obj?.IsDefault === true ||
              menu?.obj?.IsLastNode === false ||
              !!!useDefaultDept ? null : (
                <MenuItem
                  disabled={
                    menu?.obj?.UsedDepartment ||
                    handlePermDisabled({
                      permEnum:
                        AcctPermission.GeneralLedgerCompanySettingsCostCentreSetDefault,
                    })
                      ? true
                      : false
                  }
                  onClick={() => {
                    onSetDefault(menu?.obj)
                  }}
                >
                  <span className="text no-flow">Set As Default</span>
                </MenuItem>
              )}

              {haveItem === true ? null : (
                <MenuItem
                  disabled={
                    handlePermDisabled({
                      permEnum:
                        AcctPermission.GeneralLedgerCompanySettingsCostCentreDelete,
                    })
                      ? true
                      : false
                  }
                  onClick={() => {
                    setdelDialog(true)
                  }}
                >
                  Delete
                </MenuItem>
              )}
            </Menu>
            <CommonDialog
              fullWidth={true}
              open={delDialog}
              onClose={() => setdelDialog(false)}
              sections={{
                header: {
                  dynamic: (
                    <div>
                      <div className="dialog-dynamic-content">
                        <span className="mdDesc c-orange flex-space">
                          Department
                        </span>
                        <span
                          className="mdDesc c-orange"
                          style={{ textAlign: 'right' }}
                        >
                          Delete
                        </span>
                      </div>
                      <div className="infoline-content"></div>
                    </div>
                  ),
                },
                body: () => (
                  <CardContents>
                    <div className="content-wrap full">
                      <div style={{ color: '#878787' }} className="desc">
                        Code
                      </div>
                      <div className="mdDesc">{menu?.obj?.Code}</div>
                    </div>
                    <div className="content-wrap full">
                      <div style={{ color: '#878787' }} className="desc">
                        Name
                      </div>
                      <div className="mdDesc">{menu?.obj?.Name}</div>
                    </div>
                  </CardContents>
                ),
                footer: {
                  actions: [
                    {
                      displayText: 'Cancel',
                      props: {
                        onClick: () => setdelDialog(false),
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () => onDelete(menu?.obj?.CostCentreID),
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ],
                },
              }}
            />
            <CommonDialog
              fullWidth={true}
              open={editDialog}
              onClose={() => {
                setEditDialog(false)
                reset()
              }}
              sections={{
                header: {
                  dynamic: (
                    <div>
                      <div className="dialog-dynamic-content">
                        <div className="session">
                          <div className="flex session">
                            <div
                              className="dialog-title c-orange text-noflow"
                              style={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}
                            >
                              {menu?.obj?.Level === 1
                                ? getCompany[0].Name
                                : getCostCentre?.find(
                                    x =>
                                      x?.CostCentreID ===
                                      menu?.obj?.ParentCostCentreID
                                  )?.Name}
                            </div>
                            <div className="flex-space"></div>
                            <div
                              className="dialog-title c-orange text-noflow"
                              style={{ fontSize: '12px', fontWeight: 'bold' }}
                            >
                              Edit{' '}
                              {menu?.obj?.IsDefault === true ? (
                                <CheckCircleIcon
                                  style={{
                                    color: 'limegreen',
                                    fontSize: 'medium',
                                  }}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="infoline-content">
                        <div className="xsTitle">
                          {menu?.obj?.CostCentreDefName}
                        </div>
                      </div>
                    </div>
                  ),
                },
                body: () => (
                  <>
                    <div className="content-container">
                      <Controller
                        as={TextField}
                        id="standard-basic"
                        name="Code"
                        label={'Code'}
                        required
                        control={control}
                        //   defaultValue={currData?.data?.Code}
                        fullWidth
                        helperText={
                          checkSameCode(
                            menu?.obj?.CostCentreID,
                            menu?.obj?.Level,
                            'edit'
                          ) === true
                            ? 'Code have been used.'
                            : errors?.Code
                            ? errors?.Code.message
                            : null
                        }
                        error={
                          checkSameCode(
                            menu?.obj?.CostCentreID,
                            menu?.obj?.Level,
                            'edit'
                          ) === true || errors?.Code
                            ? true
                            : false
                        }
                        ref={register}
                        //defaultValue={menu?.obj?.Code}
                        disabled={
                          getCostCentre?.filter(
                            x =>
                              x?.ParentCostCentreID === menu?.obj?.CostCentreID
                          )?.length > 0
                            ? true
                            : false
                        }
                        inputProps={{
                          style: { textTransform: 'uppercase' },
                        }}
                      />
                      <Controller
                        as={TextField}
                        id="standard-basic"
                        name="Name"
                        label={'Name'}
                        required
                        control={control}
                        fullWidth
                        helperText={
                          checkSameName(
                            menu?.obj?.CostCentreID,
                            menu?.obj?.Level,
                            'edit'
                          ) === true
                            ? 'Name already exsist.'
                            : errors?.Name
                            ? errors?.Name.message
                            : null
                        }
                        error={
                          checkSameName(
                            menu?.obj?.CostCentreID,
                            menu?.obj?.Level,
                            'edit'
                          ) === true || errors?.Name
                            ? true
                            : false
                        }
                        ref={register}
                      />

                      <div
                        className="content-wrap full"
                        style={{
                          justifyContent: 'space-between',
                          display: 'flex',
                        }}
                      ></div>
                    </div>
                  </>
                ),
                footer: {
                  actions: [
                    {
                      displayText: 'Cancel',
                      props: {
                        onClick: () => {
                          isDirty ? setOpenExitConf(true) : setEditDialog(false)
                          reset()
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () =>
                          checkSameCode(
                            menu?.obj?.CostCentreID,
                            menu?.obj?.Level,
                            'edit'
                          ) === false &&
                          checkSameName(
                            menu?.obj?.CostCentreID,
                            menu?.obj?.Level,
                            'edit'
                          ) === false
                            ? handleSubmit(data => onEdit(data, menu?.obj))()
                            : null,
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ],
                },
              }}
            />
            {
              <CommonDialog
                fullWidth={true}
                open={open}
                onClose={() => {
                  setOpen(false)
                  reset()
                }}
                sections={{
                  header: {
                    dynamic: (
                      <div>
                        <div className="dialog-dynamic-content">
                          <span
                            className="title c-orange flex-space"
                            style={{ fontSize: '13px', fontWeight: 'bold' }}
                          >
                            {selectedCC?.Name}
                          </span>
                          <span
                            className="title c-orange"
                            style={{
                              fontSize: '13px',
                              fontWeight: 'bold',
                              textAlign: 'right',
                            }}
                          >
                            New
                          </span>
                        </div>
                      </div>
                    ),
                  },
                  body: () => (
                    <>
                      <div className="content-container">
                        <Controller
                          as={TextField}
                          id="standard-basic"
                          name="Code"
                          label={'Code'}
                          required
                          control={control}
                          fullWidth
                          helperText={
                            checkSameCode(
                              selectedCC?.CostCentreID,
                              selectedCC?.Level,
                              'add'
                            ) === true
                              ? 'Code have been used.'
                              : errors?.Code
                              ? errors?.Code.message
                              : null
                          }
                          error={
                            checkSameCode(
                              selectedCC?.CostCentreID,
                              selectedCC?.Level,
                              'add'
                            ) === true || errors?.Code
                              ? true
                              : false
                          }
                          ref={register}
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                        />
                        <Controller
                          as={TextField}
                          id="standard-basic"
                          name="Name"
                          label={'Name'}
                          required
                          control={control}
                          fullWidth
                          helperText={
                            checkSameName(
                              selectedCC?.CostCentreID,
                              selectedCC?.Level,
                              'add'
                            ) === true
                              ? 'Name already exsist.'
                              : errors?.Name
                              ? errors?.Name.message
                              : null
                          }
                          error={
                            checkSameName(
                              selectedCC?.CostCentreID,
                              selectedCC?.Level,
                              'add'
                            ) === true || errors?.Name
                              ? true
                              : false
                          }
                          ref={register}
                        />
                        <div
                          className="content-wrap full"
                          style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                          }}
                        ></div>
                      </div>
                    </>
                  ),
                  footer: {
                    actions: [
                      {
                        displayText: 'Cancel',
                        props: {
                          onClick: () => {
                            isDirty ? setOpenExitConf(true) : setOpen(false)
                            reset()
                          },
                          variant: 'contained',
                          color: 'primary',
                          type: 'submit',
                        },
                      },
                      {
                        displayText: 'Confirm',
                        props: {
                          onClick: () => {
                            if (
                              checkSameCode(
                                selectedCC?.CostCentreID,
                                selectedCC?.Level,
                                'add'
                              ) === false &&
                              checkSameName(
                                selectedCC?.CostCentreID,
                                selectedCC?.Level,
                                'add'
                              ) === false
                            ) {
                              setConfirmClicked(true)
                              handleSubmit(onSubmit)()
                            }
                          },
                          variant: 'contained',
                          color: 'primary',
                          type: 'submit',
                          disabled: !!confirmClicked,
                        },
                      },
                    ],
                  },
                }}
              />
            }
          </ContentWrapper>
        </form>
      )}
      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          setOpen(false)
          setEditDialog(false)
          setOpenExitConf(false)
        }}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
