import React from 'react'

interface DetailProps {
  listDepreciation: any
}

export const AssetsRegistrationDepreciationDetailContent = (
  props: DetailProps
) => {
  const { listDepreciation } = props

  return (
    <>
      <div className="content-wrap full ">
        <div className="desc " style={{ color: 'grey' }}>
          Method
        </div>
        <div className="mdDesc text-noflow">{listDepreciation?.Method}</div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Start Year
        </div>
        <div className="mdDesc"> {listDepreciation?.StartYear} </div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Start Period
        </div>
        <div className="mdDesc"> {listDepreciation?.StartPeriod} </div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Acc. Depreciation Amount
        </div>
        <div className="mdDesc">{listDepreciation?.AccDepreciationAmount}</div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Useful Life (Period)
        </div>
        <div className="mdDesc"> {listDepreciation?.UsefulLife} </div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Utilized Useful Life (Period)
        </div>
        <div className="mdDesc"> {listDepreciation?.UtilizedUsefulLife} </div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Acc. Revaluation Amount
        </div>
        <div className="mdDesc"> {listDepreciation?.AccRevaluationAmount} </div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Acc. Provision for Impairment
        </div>
        <div className="mdDesc">{listDepreciation?.AccProvisionImpairment}</div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Acc. Reversalof Impairment
        </div>
        <div className="mdDesc">{listDepreciation?.AccReversalImpairment}</div>
      </div>
    </>
  )
}
