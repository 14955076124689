import React from 'react'
import { BillItemListing } from './BillItemListing'

export const billItemRoutes = [
  {
    props: {
      exact: true,
      path: `/common-setting/bill-items`,
    },
    component: <BillItemListing />,
  },
]

export default billItemRoutes
