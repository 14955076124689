import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { AttachFile } from '@material-ui/icons'
import PrintIcon from '@material-ui/icons/Visibility'
import { AttachmentDialog } from 'components/Dialog/AttachmentDialog'
import { useDocumentListingQuery } from 'generated/graphql'
import { AttachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
}

const dummyData = [
  {
    Name: 'Motor Vehicle',
    Category: 'Vehicles',
    Date: '2023-06-12',
    Remark: '1 Item Incorrect Counts',
    ApprovalStatus: 'DRAFT',
    AssetNo: null,
    Description: 'Remark here...',
    Counts: '3',
    IncorrectCount: '1',
    SubmittedBy: 'Rusdhi',
    SubmittedDate: '12/04/2023',
    ApprovedBy: 'Emir',
    ApprovedDate: '23/04/2023',
  },
  {
    Name: 'Motor Vehicle',
    Category: 'Vehicles',
    Date: '2023-06-12',
    Remark: '1 Item Incorrect Counts',
    ApprovalStatus: 'DRAFT',
    AssetNo: null,
    Description: 'Remark here...',
    Counts: '3',
    IncorrectCount: '1',
    SubmittedBy: 'Rusdhi',
    SubmittedDate: '12/04/2023',
    ApprovedBy: 'Emir',
    ApprovedDate: '23/04/2023',
  },
]

export const AssetsCountDetailContent = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { listEl, documentListing, listStatus, userList } = props
  const [openDoc, setOpenDoc] = useState<boolean>(false)
  const { menu } = useMenuOption()

  const {
    loading: DocumentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: { refID: listEl?.AuditAdjustmentID },
  })

  return (
    <>
      {DocumentLoading && <Loading />}

      <div className="content-wrap left ">
        <div className="desc " style={{ color: 'grey' }}>
          Submitted By
        </div>
        <div className="mdDesc text-noflow"> {dummyData[0]?.SubmittedBy} </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.SubmittedDate}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approve By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {dummyData[0]?.ApprovedBy}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {dummyData[0]?.ApprovedDate}
            </div>
          </div>
        </>
      )}

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Remark'}
        </div>
        <div className="mdDesc text-noflow">{dummyData[0]?.Description}</div>
      </div>

      <AttachmentDialog
        title="Assets Count"
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        AttachmentDetailView={AttachmentDetailView}
        DocumentListing={DocumentListing}
      />
    </>
  )
}
