import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { SubmitterDialog } from 'components/Dialog/SubmitterDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetJournalProcessingbyStatusDocument,
  useGetBudgetbyStatusQuery,
  usePostJournalProcessingMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'

export const OriginalBudgetApprovalListing = (props: any) => {
  useEffect(() => {
    console.log('OriginalBudgetApprovalListing')
  }, [])
  let history = useHistory()
  const searchFilter = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()
  const { filteredList, handleSearch, originalList } = useFuseSearch()
  const { anchorEl, menu, handleClose } = useMenuOption()
  const [journalStatus, setJournalStatus] = useState<string>(
    searchFilter?.option
  )
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)
  const [journalIDs, setJournalIDs] = useState<Set<any>>(new Set([]))
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openDialog, setOpenDialog] = useState(false)
  const { menu: menu2, handleClick: handleClick2 }: any = useMenuOption()

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const dropdownOptions = [
    { name: 'All', ID: 'ALL' },
    { name: 'Pending', ID: 'SUBMIT' },
    { name: 'Posted', ID: 'COMPLETED' },
  ]

  const { handleSubmit } = useForm()

  const {
    loading: BudgetbyStatusLoading,
    error: BudgetbyStatusError,
    data: { getBudgetbyStatus: getBudgetbyStatus } = { getBudgetbyStatus: [] },
  } = useGetBudgetbyStatusQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  useEffect(() => {
    handleSearch(searchFilter?.name ?? '', ['DocNo', 'Description'])
  }, [originalList])

  const [
    postJournalProcessing,
    {
      loading: postJournalProcessingLoading,
      called: postJournalProcessingCalled,
      error: postJournalProcessingError,
    },
  ] = usePostJournalProcessingMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
    },
  })

  //////////////////////////////////////////////////////////////
  ////////////////////** FUNCTION *////////////////////////////
  ////////////////////////////////////////////////////////////

  let newFilteredList = filteredList?.filter(
    v => v?.ApprovalStatus === journalStatus || journalStatus === 'ALL'
  )

  // handle checkbox for all employee
  const handleSelectAll = e => {
    let jTemp = journalIDs
    if (e.target.checked) {
      newFilteredList.map(v => {
        jTemp.add(v?.JournalProcessingID)
      })
    } else {
      jTemp.clear()
    }
    setJournalIDs(new Set(jTemp))
  }

  // handle checkbox for specific entityCoa
  const handleCheckBoxChange = (e: any, journalID, index: number) => {
    let jTemp = journalIDs
    if (!jTemp.has(journalID)) {
      jTemp.add(journalID)
    } else {
      jTemp.delete(journalID)
    }

    setJournalIDs(new Set(jTemp))
  }

  let listJournalIDs = Array.from(journalIDs || [])

  const onSubmit = (data, status) => {
    postJournalProcessing({
      variables: {
        JournalProcessingIDs: listJournalIDs,
        CompanyID,
      },
      refetchQueries: [
        {
          query: GetJournalProcessingbyStatusDocument,
          variables: { CompanyID },
        },
      ],
    })
  }

  return (
    <>
      {BudgetbyStatusLoading && <Loading />}
      {postJournalProcessingLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          localStorage.removeItem(`searchFilter`)
          history.push(`/general-ledger/${CompanyID}/`)
        }} //<- back button action
        smTitle="General Ledger"
        title={user?.companyName}
        currency={`MYR`}
        routeSegments={[
          { name: 'Home Journal' },
          { name: 'Original Budget Approval', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['DocNo', 'Description'])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: journalStatus ?? 'SUBMIT',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={searchFilter?.name ?? null}
        />

        <DropdownBar
          options={dropdownOptions}
          setOptionID={setJournalStatus}
          borderRadius="4px"
          defaultOption={dropdownOptions
            ?.map(opt => opt?.ID)
            .indexOf(journalStatus ?? 'SUBMIT')}
          searchFilter={searchFilter}
        />
      </div>
      <ContentWrapper>
        {journalStatus === 'SUBMIT' && newFilteredList?.length !== 0 ? (
          <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
            <Checkbox
              onChange={handleSelectAll}
              color="default"
              value={''}
              checked={journalIDs?.size === newFilteredList?.length}
            />
            Select All
          </span>
        ) : null}

        <List className="core-list">
          {newFilteredList === undefined || newFilteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            newFilteredList.map((el, index) => {
              return (
                <ListItem key={index}>
                  {journalStatus === 'SUBMIT' ? (
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={el.JournalProcessingID}
                            onChange={e => {
                              handleCheckBoxChange(
                                e,
                                el?.JournalProcessingID,
                                index
                              )
                            }}
                            checked={journalIDs?.has(el?.JournalProcessingID)}
                            color="default"
                            style={{
                              marginLeft: '10px',
                              marginRight: '-5px',
                            }}
                          />
                        }
                        label=""
                      />
                    </FormControl>
                  ) : null}

                  <ListItemText
                    primary={
                      <>
                        <span className="xsTitle flex-space">{el?.DocNo}</span>
                        {el?.ApprovalStatus === 'SUBMIT' ? (
                          <span className="xxTitle">
                            <span className="c-red">
                              {formatDate(el?.DocDate)}
                            </span>
                          </span>
                        ) : (
                          <span className="xxTitle">
                            <span className="">{formatDate(el?.DocDate)}</span>
                          </span>
                        )}
                      </>
                    }
                    secondary={
                      <>
                        <span className="desc flex-space">
                          <IconText
                            icon={
                              <img
                                src={SubmitterLog}
                                style={{ width: '12px' }}
                                onClick={e => {
                                  setOpenDialog(true)
                                  handleClick2(
                                    e,
                                    el?.WorkFlow?.WorkFlowID,
                                    index,
                                    el
                                  )
                                }}
                              />
                            }
                            font="desc"
                            children={el.Description}
                          ></IconText>
                        </span>
                        <span className="xxTitle">
                          <span className="c-orange">
                            {amtStr(
                              el?.JournalProcessingItem?.filter(
                                item => item?.DocAmt > 0
                              )?.reduce((x, y) => x + y?.DocAmt, 0)
                            )}
                          </span>
                        </span>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="arrow">
                      <KeyboardArrowRight
                        onClick={() =>
                          history.push({
                            pathname: `/general-ledger/${CompanyID}/journal-posting/${el?.JournalProcessingID}`,
                            state: el,
                          })
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          )}
        </List>
      </ContentWrapper>
      {journalStatus === 'SUBMIT' || journalStatus === 'SUBMIT' ? (
        <Footer
          options={[
            {
              name: 'Post',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              props: { disabled: newFilteredList?.length === 0 },
            },
          ]}
        />
      ) : null}

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            history.push({
              pathname: `/general-ledger/${CompanyID}/journal-posting/${menu?.obj.JournalID}/approve-reject`,
              state: menu.obj,
            })
          }}
        >
          <span className="">Approve/Reject</span>
        </MenuItem>
      </Menu>

      <SubmitterDialog
        data={menu2?.obj}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        moduleName={'Journal Processing'}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
