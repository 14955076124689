import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import { Grid } from '@material-ui/core'
import { formatDateAndTime } from 'helpers/StringNumberFunction/FormatDate'
import React from 'react'

export const InboundDocDetail = ({ data }) => {
  return (
    <Grid container>
      {/* <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Module Name
          </div>
          <div className="mdDesc">{data?.ModuleName ?? '—'}</div>
        </div>
      </Grid> */}
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Document Type
          </div>
          <div className="mdDesc">{data?.DocumentType ?? '—' ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            e-Invoice Number
          </div>
          <div className="mdDesc">{data?.DocNo ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            e-Invoice Ref No
          </div>
          <div className="mdDesc">{data?.eInvRefNo ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            e-Invoice Date
          </div>
          <div className="mdDesc">
            {data?.eInvDate ? formatDateAndTime(new Date(data?.eInvDate)) : '—'}
          </div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            e-Invoice Submission Id
          </div>
          <div className="mdDesc">{data?.eInvSubmissionID ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            e-Invoice Transaction No
          </div>
          <div className="mdDesc">{data?.eInvTrxNo ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            e-Invoice Long Id
          </div>
          <div className="mdDesc">{data?.eInvLongID ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            e-Invoice Type
          </div>
          <div className="mdDesc">{data?.eInvType ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            e-Invoice QR URL
          </div>
          <div className="mdDesc">{data?.eInvQRUrl ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Currency Code
          </div>
          <div className="mdDesc">{data?.CurrencyCode ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Base Currency Code
          </div>
          <div className="mdDesc">{data?.BaseCurrencyCode ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Currency Rate
          </div>
          <div className="mdDesc">{data?.CurrencyRate ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Bill Frequency
          </div>
          <div className="mdDesc">{data?.BillFrequency ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Bill Start Date
          </div>
          <div className="mdDesc">
            {data?.BillStartDate
              ? formatDateAndTime(new Date(data?.BillStartDate))
              : '—'}
          </div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Bill End Date
          </div>
          <div className="mdDesc">
            {data?.BillEndDate
              ? formatDateAndTime(new Date(data?.BillEndDate))
              : '—'}
          </div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Payment Mode
          </div>
          <div className="mdDesc">{data?.PaymentMode ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Payment Term
          </div>
          <div className="mdDesc">{data?.PaymentTerm ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Total Excluding Tax
          </div>
          <div className="mdDesc">
            {data?.TotalExcludingTax ? amtStr(data?.TotalExcludingTax) : '—'}
          </div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Total Including Tax
          </div>
          <div className="mdDesc">
            {data?.TotalIncludingTax ? amtStr(data?.TotalIncludingTax) : '—'}
          </div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Total Payable Amount
          </div>
          <div className="mdDesc">
            {data?.TotalPayableAmount ? amtStr(data?.TotalPayableAmount) : '—'}
          </div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Total Net
          </div>
          <div className="mdDesc">
            {data?.TotalNet ? amtStr(data?.TotalNet) : '—'}
          </div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Total Discount
          </div>
          <div className="mdDesc">
            {data?.TotalDiscount ? amtStr(data?.TotalDiscount) : '—'}
          </div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Total Fee
          </div>
          <div className="mdDesc">
            {data?.TotalFee ? amtStr(data?.TotalFee) : '—'}
          </div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Total Tax
          </div>
          <div className="mdDesc">
            {data?.TotalTax ? amtStr(data?.TotalTax) : '—'}
          </div>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Total Rounding Amount
          </div>
          <div className="mdDesc">
            {data?.TotalRoundingAmount
              ? amtStr(data?.TotalRoundingAmount)
              : '—'}
          </div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Pre Pay Date
          </div>
          <div className="mdDesc">
            {data?.PrePayDate
              ? formatDateAndTime(new Date(data?.PrePayDate))
              : '—'}
          </div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Pre Pay Ref No
          </div>
          <div className="mdDesc">{data?.PrePayRefNo ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Pre Pay Amount
          </div>
          <div className="mdDesc">
            {data?.PrePayAmount ? amtStr(data?.PrePayAmount) : '—'}
          </div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Custom Ref No
          </div>
          <div className="mdDesc">{data?.CustomRefNo ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Custom Ref No Document Type
          </div>
          <div className="mdDesc">{data?.CustomRefNoDocType ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Inco Terms
          </div>
          <div className="mdDesc">{data?.IncoTerms ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            FTA
          </div>
          <div className="mdDesc">{data?.FTA ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Exporter No
          </div>
          <div className="mdDesc">{data?.ExporterNo ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Exporter Agency
          </div>
          <div className="mdDesc">{data?.ExporterAgency ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Custom Ref No 2
          </div>
          <div className="mdDesc">{data?.CustomRefNo2 ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Custom Ref No 2 Document Type
          </div>
          <div className="mdDesc">{data?.CustomRefNo2DocType ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Other Charges Document No
          </div>
          <div className="mdDesc">{data?.OtherChargesDocNo ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Other Charges Amount
          </div>
          <div className="mdDesc">
            {data?.OtherChargesAmt ? amtStr(data?.OtherChargesAmt) : '—'}
          </div>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Other Charges Reason
          </div>
          <div className="mdDesc">{data?.OtherChargesReason ?? '—'}</div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Tax Exemp
          </div>
          <div className="mdDesc">{data?.TaxExemp ?? '—'}</div>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Tax Exemp Amount
          </div>
          <div className="mdDesc">
            {data?.TaxExempAmt ? amtStr(data?.TaxExempAmt) : '—'}
          </div>
        </div>
      </Grid>

      <Grid item sm={12} md={4}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Send Time
          </div>
          <div className="mdDesc">
            {data?.SendTime ? formatDateAndTime(new Date(data?.SendTime)) : '—'}
          </div>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            e-Validate Time
          </div>
          <div className="mdDesc">
            {data?.ValidateTime
              ? formatDateAndTime(new Date(data?.ValidateTime))
              : '—'}
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
