import React from 'react'
import { ARConsolidateEInvoiceForm } from './ARConsolidateEInvoiceForm'
import { ARConsolidateEInvoiceListing } from './ARConsolidateEInvoiceListing'
import { ARConsolidateEInvoiceDetail } from './Detail/ARConsolidateEInvoiceDetail'

export const arConsolidatedEInvoiceRoutes = [
  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */
  //=========== Consolidate e-Invoice ============//
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/consolidate-einvoice/add',
    },
    component: <ARConsolidateEInvoiceForm accountType="account-receivable" />,
  },

  //========== Consolidate e-Invoice Listing ============//
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/consolidate-einvoice/:ConsolidateEInvoiceID',
    },
    component: <ARConsolidateEInvoiceDetail accountType="account-receivable" />,
  },

  //========== Consolidate e-Invoice Form ============//
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/consolidate-einvoice',
    },
    component: (
      <ARConsolidateEInvoiceListing accountType="account-receivable" />
    ),
  },
]

export default arConsolidatedEInvoiceRoutes
