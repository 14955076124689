import { useLazyQuery } from '@apollo/react-hooks'
import CompanyActive from '@ifca-root/react-component/src/assets/icons/company-w.svg'
import GeneralSettingActive from '@ifca-root/react-component/src/assets/icons/general-setting-w.svg'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Avatar,
  IconButton,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import DigitalReports from 'assets/icons/CB/digital-report.svg'
import DigitalReport from 'assets/icons/digital-reporting.svg'
import ApprovalIcon from 'assets/icons/GL/GeneralSettings/approval-policy.svg'
import HomeActive from 'assets/icons/home-w.svg'
import Profile from 'assets/icons/profile.svg'
import AccessSecurity from 'assets/icons/Sidemenu/access-security.svg'
import CompanySetting from 'assets/icons/Sidemenu/company-setting.svg'
import GeneralSettingIcon from 'assets/icons/Sidemenu/general-setting.svg'
import Home from 'assets/icons/Sidemenu/home.svg'
import Logout from 'assets/icons/Sidemenu/log-out.svg'
import { GET_PACKAGE_BY_ACCOUNT } from 'containers/AccountModule/GraphQL/AccountQuery'
import AppContext from 'containers/App/Store/AppContext'
import {
  useCreateUpdateUserActivityLogMutation,
  useGetDrawerPermissionLazyQuery,
  useLoggedInUserProfileQuery,
} from 'generated/graphql'
import { useBroadcastChannel } from 'helpers/Hooks/useBroadcastChannel'
import localForage from 'localforage'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { SubscriptionClient } from 'SubscriptionClient'

export const drawerWidth = 240
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        top: 64,
        height: 'calc(100% - 64px)',
      },
      zIndex: 100,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    activeListItem: {
      borderLeft: '4px solid red',
      boxShadow: '0 3px 2px -3px gray',
      backgroundColor: 'blue',
      '& $listItemText': {
        color: 'white',
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 500,
      },
      '& $listItemIcon': {
        color: 'white',
        marginLeft: '-4px',
        minWidth: '36px',
      },
    },
    listItemText: {
      fontSize: '1rem',
      fontWeight: 400,
      color: 'black',
      marginTop: 0,
      marginBottom: 0,
    },
  })
)

interface Props {
  window?: () => Window
  open: boolean
  variant: any
  onCloseDrawer: any
}

export default function LayoutDrawer(props: Props) {
  const { window, open, onCloseDrawer, variant, ...rest } = props
  const classes = useStyles()
  const container =
    window !== undefined ? () => window().document.body : undefined
  let history = useHistory()
  const CompanyID = localStorage.getItem('latestCompany')
  const { globalState }: any = useContext(AppContext as any)
  const { signOut } = useBroadcastChannel()
  const mode: any = history?.location?.pathname

  let isHome = false,
    isSetting = false

  const { loading, data } = useLoggedInUserProfileQuery()
  const user =
    data?.loggedInUserProfile ??
    JSON?.parse(localStorage?.getItem('loggedInUser'))

  const [
    loadPackage,
    {
      loading: packageLoading,
      error: packageError,
      data: { getPackageByAccount } = { getPackageByAccount: [] },
    },
  ] = useLazyQuery(GET_PACKAGE_BY_ACCOUNT, {
    client: SubscriptionClient,
  })

  const [previewFile, setPreviewFile] = useState(null)

  const lastAccessedModule = localStorage.getItem('lastAccessedModule')

  const [
    updateLog,
    { loading: LogLoading },
  ] = useCreateUpdateUserActivityLogMutation({
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        console.log('ERROR', message)
      })
    },
  })

  useEffect(() => {
    if (user?.accountID) {
      loadPackage({
        variables: { softwareCode: 'ACCOUNT', accountID: user?.accountID },
      })
    }
  }, [user?.accountID])

  useEffect(() => {
    setPreviewFile(data?.loggedInUserProfile?.avatar)
  }, [data?.loggedInUserProfile])

  if (mode === '/' || mode === '/home' || mode === '/alternate') {
    isHome = true
  }
  if (
    globalState?.isPathSysAdmin ||
    mode === '/access-security' ||
    mode === '/general-setting' ||
    mode === '/company'
  ) {
    isSetting = true
  }

  const [
    fetchDrawer,
    {
      loading: drawerLoading,
      data: {
        getDrawerPermission: {
          accessSecurity: accessSecurityPerm,
          companySetup: companySetupPerm,
          generalSetting: generalSettingPerm,
          companySetting: companySettingPerm,
          digitalReport: digitalReportPerm,
          financialReport: financialReportPerm,
          genLedgerGS: genLedgerGSPerm,
          cashBookGS: cashBookGSPerm,
          glExport: glExportPerm,
          accReceivableGS: accReceivableGSPerm,
          accPayableGS: accPayableGSPerm,
          inventoryControlGS: inventoryControlGSPerm,
          genPurchasesGS: genPurchasesGSPerm,
          genLedgerCS: genLedgerCSPerm,
          cashBookCS: cashBookCSPerm,
          accReceivableCS: accReceivableCSPerm,
          accPayableCS: accPayableCSPerm,
          inventoryControlCS: inventoryControlCSPerm,
          genPurchasesCS: genPurchasesCSPerm,
          commonSetting: commonSettingPerm,
          genLedgerDR: genLedgerDRPerm,
          cashBookDR: cashBookDRPerm,
          accReceivableDR: accReceivableDRPerm,
          accPayableDR: accPayableDRPerm,
          inventoryControlDR: inventoryControlDRPerm,
          genPurchasesDR: genPurchasesDRPerm,
        },
      } = {
        getDrawerPermission: {
          accessSecurity: false,
          companySetup: false,
          generalSetting: false,
          companySetting: false,
          digitalReport: false,
          financialReport: false,
          genLedgerGS: false,
          cashBookGS: false,
          glExport: false,
          accReceivableGS: false,
          accPayableGS: false,
          inventoryControlGS: false,
          genPurchasesGS: false,
          genLedgerCS: false,
          cashBookCS: false,
          accReceivableCS: false,
          accPayableCS: false,
          inventoryControlCS: false,
          genPurchasesCS: false,
          commonSetting: false,
          genLedgerDR: false,
          cashBookDR: false,
          accReceivableDR: false,
          accPayableDR: false,
          inventoryControlDR: false,
          genPurchasesDR: false,
        },
      },
    },
  ] = useGetDrawerPermissionLazyQuery({
    fetchPolicy: 'network-only',
  })

  const pageMode = reconsRoute(mode)

  //this is for general settings/company settings permission checking
  const genSettingsList = [
    {
      path: 'general-ledger',
      permissionCheckerGS: genLedgerGSPerm,
      permissionCheckerCS: genLedgerCSPerm,
      permissionCheckerDR: genLedgerDRPerm,
      package_name: 'GENERAL_LEDGER',
    },
    {
      path: 'cash-book',
      permissionCheckerGS: cashBookGSPerm,
      permissionCheckerCS: cashBookCSPerm,
      permissionCheckerDR: cashBookDRPerm,
      package_name: 'CASH_BOOK',
    },
    {
      path: 'account-receivable',
      permissionCheckerGS: accReceivableGSPerm,
      permissionCheckerCS: accReceivableDRPerm,
      permissionCheckerDR: digitalReportPerm,
      package_name: 'ACCOUNT_RECEIVABLE',
    },
    {
      path: 'account-payable',
      permissionCheckerGS: accPayableGSPerm,
      permissionCheckerCS: accPayableCSPerm,
      permissionCheckerDR: accPayableDRPerm,
      package_name: 'ACCOUNT_PAYABLE',
    },
    {
      path: 'inventory-control',
      permissionCheckerGS: inventoryControlGSPerm,
      permissionCheckerCS: inventoryControlCSPerm,
      permissionCheckerDR: inventoryControlDRPerm,
      package_name: 'INVENTORY_CONTROL',
    },
    {
      path: 'general-purchase',
      permissionCheckerGS: genPurchasesGSPerm,
      permissionCheckerCS: genPurchasesCSPerm,
      permissionCheckerDR: genPurchasesDRPerm,
      package_name: 'GENERAL_PURCHASES',
    },
    {
      path: 'fixed-assets',
      permissionCheckerGS: true,
      permissionCheckerCS: true,
      permissionCheckerDR: true,
      package_name: 'FIXED_ASSETS',
    },
  ]

  const pageGS = genSettingsList?.find(x => x?.permissionCheckerGS === true)
    ?.path
  const pageCS = genSettingsList?.find(x => x?.permissionCheckerCS === true)
    ?.path

  const pageDR =
    pageMode ??
    genSettingsList?.find(
      x =>
        x?.permissionCheckerDR === true &&
        getPackageByAccount?.includes(x.package_name)
    )?.path

  const financialReportAccessible =
    financialReportPerm !== false &&
    getPackageByAccount?.includes('GENERAL_LEDGER')

  //this is where the condition for permission takes place

  const home = {
    name: 'Home',
    path: `/home`,
    icon: Home,
    iconSelected: HomeActive,
    permission: true,
    deployed: true,
    isDefault: false,
  }

  const commonSettings = {
    name: `Common Settings`,
    path: `/common-setting`,
    icon: DigitalReports,
    iconSelected: DigitalReports,
    isDefault: true,
    deployed: true,
    permission: commonSettingPerm,
  }

  const defaultGeneralSetting = {
    name: 'General Settings',
    path: `/${pageGS}/general-setting`,
    icon: GeneralSettingIcon,
    iconSelected: GeneralSettingActive,
    permission: generalSettingPerm,
    deployed: true,
    isDefault: true,
  }

  const defaultFinancialReport = {
    name: 'Financial Reports',
    path: `/general-ledger/financial-reporting`,
    icon: ApprovalIcon,
    iconSelected: GeneralSettingActive,
    permission: financialReportPerm,
    isDefault: true,
    deployed: true,
  }

  const defaultCompanySetting = {
    name: 'Company Settings',
    path: `/${pageCS}/company`,
    icon: CompanySetting,
    iconSelected: CompanyActive,
    permission: companySettingPerm,
    deployed: true,
    isDefault: true,
  }

  const defaultDigitalReport = {
    name: 'Digital Reports',
    path: `/digital-reports/${pageDR}`,
    icon: DigitalReport,
    iconSelected: DigitalReport,
    permission: digitalReportPerm,
    deployed: true,
    isDefault: true,
  }

  const homeCompanySetup = {
    name: 'Company Setup',
    path: '/company-setup',
    icon: CompanySetting,
    iconSelected: CompanyActive,
    permission: companySetupPerm,
    deployed: true,
    isDefault: true,
  }

  const homeAccessSecurity = {
    name: 'Access Security',
    path: '/access-security',
    icon: AccessSecurity,
    iconSelected: AccessSecurity,
    permission: accessSecurityPerm,
    deployed: true,
    isDefault: false,
  }

  const logout = {
    name: 'Sign Out',
    path: '/logout',
    icon: Logout,
    iconSelected: Logout,
    permission: true,
    isDefault: false,
    deployed: true,
  }

  const generalSetting = {
    name: 'General Settings',
    path: `/${pageMode}/general-setting`,
    icon: GeneralSettingIcon,
    iconSelected: GeneralSettingActive,
    permission: generalSettingPerm,
    isDefault: false,
    deployed: true,
  }

  const financialReport = {
    name: 'Financial Reports',
    path: `/general-ledger/financial-reporting`,
    icon: ApprovalIcon,
    iconSelected: GeneralSettingActive,
    permission: financialReportAccessible,
    isDefault: false,
    deployed: true,
  }

  const digitalReport = {
    name: 'Digital Reports',
    path: `/digital-reports/${pageDR}`,
    icon: DigitalReport,
    iconSelected: DigitalReport,
    permission: digitalReportPerm,
    deployed: true,
    isDefault: false,
  }

  const companySetting = {
    name: 'Company Settings',
    path: `/${pageMode}/company`,
    icon: CompanySetting,
    iconSelected: CompanyActive,
    permission: companySettingPerm,
    isDefault: false,
    deployed: true,
  }

  const isHomeDefault = localStorage.getItem('isDefault')

  const navigations =
    isHomeDefault === 'true'
      ? [
          home,
          homeAccessSecurity,
          homeCompanySetup,
          commonSettings,
          defaultGeneralSetting,
          defaultCompanySetting,
          financialReport,
          defaultDigitalReport,
          logout,
        ]
      : pageMode === 'general-ledger'
      ? [
          home,
          generalSetting,
          companySetting,
          financialReport,
          digitalReport,
          logout,
        ]
      : pageMode === 'cash-book'
      ? [home, generalSetting, companySetting, digitalReport, logout]
      : pageMode === 'inventory-control'
      ? [home, generalSetting, digitalReport, logout]
      : pageMode === 'account-receivable' || pageMode === 'account-payable'
      ? [home, generalSetting, companySetting, digitalReport, logout]
      : pageMode === 'general-purchase'
      ? [home, generalSetting, companySetting, digitalReport, logout]
      : pageMode === 'mfrs16'
      ? [home, logout]
      : pageMode === 'fixed-assets'
      ? [home, generalSetting, companySetting, logout]
      : [
          home,
          homeAccessSecurity,
          homeCompanySetup,
          commonSettings,
          defaultGeneralSetting,
          defaultCompanySetting,
          financialReport,
          defaultDigitalReport,
          logout,
        ]

  const authenticationMenu = [
    {
      label: 'Authentication',
      child: [
        { label: 'Login ', path: '/login' },
        { label: 'Forgot Password ', path: '/forgot' },
        { label: 'Error ', path: '/authentication/404' },
      ],
    },
  ]

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRedirectProfile = () => {
    if (!!onCloseDrawer) {
      onCloseDrawer()
    }
    setAnchorEl(null)
    history.push('/profile')
  }

  useEffect(() => {
    if (mode === '/home' || mode === '/' || pageMode)
      fetchDrawer({ variables: { pageMode: pageMode } })
  }, [mode])

  const drawer = (
    <div className="route-container">
      <List disablePadding>
        {navigations.map((v, index) => {
          if (!!v?.deployed && v?.permission)
            return (
              <ListItem
                button
                key={index}
                dense
                activeClassName={'active'}
                divider
                component={NavLink}
                to={v?.path}
                disabled={v?.path !== 'disabled' ? false : true}
                onClick={() => {
                  localStorage.setItem('isDefault', v?.isDefault.toString())
                  localStorage.removeItem('companyFilter')
                  if (!!onCloseDrawer) {
                    onCloseDrawer()
                  }
                  if (v.name === 'Sign Out') {
                    return new Promise<void>((resolve, reject) => {
                      updateLog({
                        variables: {
                          UserAction: 'LOGOUT',
                          LastAccessedModule: lastAccessedModule,
                        },
                      })
                      resolve()
                    }).then(() => {
                      signOut(user?.ID, CompanyID)
                      sessionStorage.clear()
                      sessionStorage.removeItem('tokenKey')
                      localForage.removeItem('permission')
                      localForage.removeItem('accountPermission')
                    })
                  }
                }}
              >
                <ListItemIcon>
                  <img className="icon-svg" src={v.icon} alt="drawer-icon" />
                </ListItemIcon>
                <ListItemText primary={v.name} />
              </ListItem>
            )
        })}
      </List>
    </div>
  )

  // User Profile Wrapper
  const userProfile = (
    <div className="profile-container">
      <div className="profile-avatar">
        {!!previewFile ? (
          <img src={previewFile} alt="" className="avatar" />
        ) : (
          <Avatar alt="avatar" className="avatar" />
        )}
      </div>
      <List className="profile-list" disablePadding>
        <ListItem>
          <ListItemText
            primary={
              <span className="smTitle">
                {user?.name &&
                  user?.userName &&
                  `${user?.name} (${user?.userName})`}
              </span>
            }
            secondary={<span className="desc">{user?.email}</span>}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="simple-menu"
              onClick={handleProfileClick}
            >
              <ExpandMore />
            </IconButton>
          </ListItemSecondaryAction>
          {/* )} */}
        </ListItem>
      </List>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="drawer-submenu"
      >
        <MenuItem className="drawer-dropdown" onClick={handleRedirectProfile}>
          <img className="icon-dropdown-svg" src={Profile} alt="" /> Profile
        </MenuItem>
        <MenuItem
          className="drawer-dropdown"
          onClick={() => {
            signOut(user?.ID, CompanyID)
            sessionStorage.clear()
            sessionStorage.removeItem('tokenKey')
            localForage.removeItem('accountPermission')
            // localStorage.removeItem('loggedInUser')
            localForage.removeItem('permission')
            history.push('/login')
          }}
        >
          <img className="icon-dropdown-svg" src={Logout} alt="" /> Logout
        </MenuItem>
      </Menu>
    </div>
  )

  // Better open performance on mobile.
  return (
    <>
      {loading && <Loading />}
      {LogLoading && <Loading />}
      {drawerLoading && <Loading />}
      {packageLoading && <Loading />}
      <nav className={classes.drawer} aria-label="main menu">
        <Drawer
          {...rest}
          container={container}
          variant={variant}
          anchor={'left'}
          open={open}
          onClose={onCloseDrawer}
          className="drawer"
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {userProfile}
          {drawer}
        </Drawer>
      </nav>
    </>
  )
}

const mode = [
  'general-ledger',
  'cash-book',
  'account-receivable',
  'account-payable',
  'inventory-control',
  'system-admin',
  'workdesk',
  'general-purchase',
  'fixed-assets',
]

export const isIn = (route: string, module: string) => {
  return route.match(module)
}

export const reconsRoute = (route: string) => {
  let genRoute = mode.filter(x => isIn(route, x))
  return genRoute.length > 0 ? genRoute[genRoute.length - 1] : null
}
