import React, { lazy } from 'react'

const AssetsCategoryListing = lazy(() =>
  import('./AssetsCategoryListing').then(module => ({
    default: module.AssetsCategoryListing,
  }))
)

const assetsCategoryRoutes = [
  {
    props: {
      exact: true,
      path: '/fixed-assets/general-setting/assets-category',
    },
    component: <AssetsCategoryListing />,
  },
]

export default assetsCategoryRoutes
