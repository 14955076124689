import { yupResolver } from '@hookform/resolvers'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { LockRounded, MoreVert } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  GetTransactionTypeDocument,
  RecordStatus,
  TransactionType,
  useCreateTransactionTypeMutation,
  useGetMasterCoaQuery,
  useGetTransactionTypeListingLazyQuery,
  useUpdateTransactionTypeMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

export interface TransactionTypeProps {
  Name: string
  Type: string
  MasterCOAID: string
}

export const TransactionTypeListing = (props: any) => {
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const isGLSubscribe = localStorage.getItem('isGLSubscribe')

  const TransactionTypeSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
    Type: CommonYupValidation.requireField('Type is required'),
  })

  const getSearch = localStorage?.getItem('searchFilter')
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const [openExitConf, setOpenExitConf] = useState(false)
  const [mode, setMode] = useState(null)
  const [open, setOpen] = useState(false)
  const [TotalLength, setTotalLength] = useState(0)

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const [
    loadData,
    {
      loading: TransactionTypeListingLoading,
      data: { getTransactionTypePerm, getTransactionTypeCount } = {
        getTransactionTypePerm: [],
        getTransactionTypeCount: null,
      },
    },
  ] = useGetTransactionTypeListingLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ getTransactionTypePerm, getTransactionTypeCount }) => {
      if (getTransactionTypePerm && getTransactionTypePerm?.length > 0) {
        const systemData = getTransactionTypePerm
          ?.filter(x => x?.IsSystem === true)
          ?.sort((a, b) => {
            return a.Name.localeCompare(b.Name)
          })
        const activeData = getTransactionTypePerm
          ?.filter(x => x?.RecordStatus === 'ACTIVE' && x?.IsSystem === false)
          ?.sort((a, b) => {
            return a.Name.localeCompare(b.Name)
          })
        const inActiveData = getTransactionTypePerm
          ?.filter(x => x?.RecordStatus === 'INACTIVE' && x?.IsSystem === false)
          ?.sort((a, b) => {
            return a.Name.localeCompare(b.Name)
          })
        setOriginalListing([
          ...filteredList,
          ...systemData,
          ...activeData,
          ...inActiveData,
        ])
      }

      if (!!getTransactionTypeCount) setTotalLength(getTransactionTypeCount)
    },
  })

  useEffect(() => {
    loadData({
      variables: {
        take: 30,
        skip: 0,
      },
    })
  }, [])

  const {
    loading: MasterCOALoading,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Code' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  ///////////////////

  //// Muatation ////
  const [
    createTransactionType,
    { loading: CreateTransactionTypeLoading, data: createdTransactionTypeData },
  ] = useCreateTransactionTypeMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setOpen(false)
      resetMenu()
      setOriginalListing([])
      loadData({
        variables: {
          skip: 0,
          take: 30,
        },
      })
    },
  })

  const [
    updateTransactionType,
    { loading: UpdateTransactionTypeLoading, data: updatedTransactionTypeData },
  ] = useUpdateTransactionTypeMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setOpen(false)
      resetMenu()
      setOriginalListing([])
      loadData({
        variables: {
          skip: 0,
          take: 30,
        },
      })
    },
  })

  ///////////////////

  let array: any[] = getTransactionTypePerm

  const TypeArr = menu => {
    if (!!menu && menu?.obj?.IsSystem === true) {
      return [
        { Name: 'CC_Issue', Type: TransactionType.CcIssue },
        { Name: 'CC_Receipt', Type: TransactionType.CcReceipt },
        { Name: 'Receipt', Type: TransactionType.Receipt },
        { Name: 'Issue', Type: TransactionType.Issue },
        { Name: 'GRN', Type: TransactionType.Grn },
        { Name: 'GRTN', Type: TransactionType.Grtn },
      ]
    } else {
      return [
        { Name: 'Receipt', Type: TransactionType.Receipt },
        { Name: 'Issue', Type: TransactionType.Issue },
      ]
    }
  }

  //FILTER//
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()

  const {
    handleSubmit,
    register,
    errors,
    control,
    reset,
    watch,
    setValue,
  } = useForm<TransactionTypeProps>({
    defaultValues: {
      Name: mode === 'add' ? '' : menu?.obj?.Name,
      Type: mode === 'add' ? '' : menu?.obj?.Type,
      MasterCOAID: mode === 'add' ? '' : menu?.obj?.MasterCOAID,
    },
    mode: 'all',
    resolver: yupResolver(TransactionTypeSchema),
  })

  const onSubmit = data => {
    if (mode === 'add') {
      createTransactionType({
        variables: {
          input: {
            Name: data?.Name,
            Type: data?.Type,
            MasterCOAID: !!data?.MasterCOAID ? data?.MasterCOAID : null,
          },
        },
        refetchQueries: [
          {
            query: GetTransactionTypeDocument,
            variables: { orderByAsc: 'Name' },
          },
        ],
      })
    } else {
      updateTransactionType({
        variables: {
          input: {
            TransactionTypeID: menu?.obj?.TransactionTypeID,
            Name: data?.Name,
            Type: data?.Type,
            MasterCOAID: !!data?.MasterCOAID ? data?.MasterCOAID : null,
          },
        },
        refetchQueries: [
          {
            query: GetTransactionTypeDocument,
            variables: { orderByAsc: 'Name' },
          },
        ],
      })
    }
  }

  const onChangeStatus = status => {
    updateTransactionType({
      variables: {
        input: {
          TransactionTypeID: menu?.obj?.TransactionTypeID,
          RecordStatus: status,
        },
      },
      refetchQueries: [
        {
          query: GetTransactionTypeDocument,
          variables: { orderByAsc: 'Name' },
        },
      ],
    })
  }

  const duplicateName = () => {
    const currentName = !!watch('Name') ? watch('Name').toLowerCase() : ''
    if (
      (mode === 'add' &&
        getTransactionTypePerm?.filter(
          uom => uom?.Name.toLowerCase() === currentName
        )?.length > 0) ||
      (mode === 'edit' &&
        getTransactionTypePerm
          ?.filter(x => x?.TransactionTypeID !== menu?.obj?.TransactionTypeID)
          ?.filter(el => el?.Name.toLowerCase() === currentName)?.length > 0)
    ) {
      return true
    } else {
      return false
    }
  }

  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: menu?.obj,
      formMode: mode,
    })

  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadData({
          variables: {
            skip: 0,
            take: 30,
            searchValue: change,
          },
        })
      }, 1000)
    )
  }

  const { handlePermDisabled } = usePermissionChecker()

  return (
    <>
      {MasterCOALoading && <Loading />}
      {CreateTransactionTypeLoading && <Loading />}
      {UpdateTransactionTypeLoading && <Loading />}
      {TransactionTypeListingLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/inventory-control/general-setting`)
        }}
        smTitle={'Inventory Control'}
        title={user?.accountName}
        routeSegments={[
          { name: 'System Admin' },
          { name: 'Transaction Type', current: true },
        ]}
      />
      <div className="search-filter with-dropdown-filter ">
        <SearchHeader
          title="Transaction Type Listing"
          value={`${TotalLength}`}
          search
          onChangeAction={e => {
            localStorage.setItem('searchFilter', e.target.value)
            changeDelay(e.target.value)
          }}
          defaultValue={getSearch ? getSearch : ''}
          isDefaultValue={!!getSearch}
          onCloseAction={() => {
            handleSearch('', [])
            localStorage.removeItem('searchFilter')
            setOriginalListing([])
            loadData({
              variables: {
                skip: 0,
                take: 30,
              },
            })
          }}
        />
      </div>
      <ContentWrapper footer float>
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                loadData({
                  variables: {
                    skip: currentLength,
                    take: 30,
                    searchValue: getSearch === '' ? undefined : getSearch,
                  },
                })
              }}
              hasMore={true}
              style={{ padding: '8px 6px' }}
              loader={
                TransactionTypeListingLoading && (
                  <div style={{ textAlign: 'center' }}>
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {filteredList?.map((el, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      <>
                        <span
                          className="xsTitle flex-space"
                          style={{
                            color:
                              el?.RecordStatus === 'INACTIVE'
                                ? '#BDBDBD'
                                : null,
                          }}
                        >
                          {el?.Name}
                        </span>
                        {el?.IsSystem && (
                          <span>
                            <LockRounded
                              style={{
                                fontSize: '16px',
                                color:
                                  el?.RecordStatus === 'INACTIVE'
                                    ? '#BDBDBD'
                                    : null,
                              }}
                            />
                          </span>
                        )}
                      </>
                    }
                    secondary={
                      <>
                        {!!el?.MasterCOA ? (
                          <span
                            className="desc flex-space"
                            style={{
                              color:
                                el?.RecordStatus === 'INACTIVE'
                                  ? '#BDBDBD'
                                  : null,
                            }}
                          >
                            {el?.MasterCOA?.Code} | {el?.MasterCOA?.Name}
                          </span>
                        ) : (
                          <span className="desc flex-space"></span>
                        )}

                        <span
                          className="desc"
                          style={{
                            color:
                              el?.RecordStatus === 'INACTIVE'
                                ? '#BDBDBD'
                                : null,
                          }}
                        >
                          {el?.Type}
                        </span>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="menu"
                      aria-controls="menu-list"
                      aria-haspopup="true"
                      onClick={e => handleClick(e, menu.ID, index, el)}
                    >
                      <MoreVert
                        style={{
                          color:
                            el?.RecordStatus === 'INACTIVE' ? '#BDBDBD' : null,
                        }}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </InfiniteScroll>
          )}
        </List>
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <>
            {menu?.obj?.RecordStatus === RecordStatus.Active ? (
              <>
                {' '}
                <MenuItem
                  disabled={
                    !user?.superUser &&
                    handlePermDisabled({
                      permEnum:
                        AcctPermission.InventoryControlGeneralSettingsTrxTypeUpdate,
                    })
                  }
                  onClick={() => {
                    setMode('edit')
                    setOpen(true)
                    register('Name')
                    setValue('Name', menu?.obj?.Name)
                    register('Type')
                    setValue('Type', menu?.obj?.Type)
                    register('MasterCOAID')
                    setValue('MasterCOAID', menu?.obj?.MasterCOAID ?? undefined)
                  }}
                >
                  <span>Edit</span>
                </MenuItem>
                <MenuItem
                  disabled={
                    !user?.superUser &&
                    handlePermDisabled({
                      permEnum:
                        AcctPermission.InventoryControlGeneralSettingsTrxTypeInactive,
                    })
                  }
                  onClick={() => onChangeStatus(RecordStatus.Inactive)}
                >
                  <span>Inactive</span>
                </MenuItem>
              </>
            ) : (
              <MenuItem
                disabled={
                  !user?.superUser &&
                  handlePermDisabled({
                    permEnum:
                      AcctPermission.InventoryControlGeneralSettingsTrxTypeInactive,
                  })
                }
                onClick={() => onChangeStatus(RecordStatus.Active)}
              >
                <span>Deactive</span>
              </MenuItem>
            )}
          </>
        </Menu>
      </ContentWrapper>
      <FloatButton
        disabled={
          !user?.superUser &&
          handlePermDisabled({
            permEnum:
              AcctPermission.InventoryControlGeneralSettingsTrxTypeCreate,
          })
        }
        onClick={() => {
          resetMenu()
          setMode('add')
          setOpen(true)
        }}
      />
      {/* create dialog */}
      <CommonDialog
        fullWidth={true}
        open={open}
        onClose={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            setOpen(false)
            resetMenu()
          }
        }}
        sections={{
          header: {
            title: ' Transaction Type',
            rightText: mode === 'add' ? 'New' : 'Edit',
          },
          body: () => (
            <div className="content-container">
              <Controller
                as={TextField}
                label=""
                name="Name"
                ref={register}
                control={control}
                helperText={
                  duplicateName() === true
                    ? 'Name already existed'
                    : errors?.Name?.message
                }
                error={duplicateName() || errors?.Name ? true : false}
                autoComplete="off"
                defaultValue={menu?.obj?.Name}
                disabled={menu?.obj?.IsSystem === true}
                required
              />
              <Controller
                as={
                  <TextField margin="dense">
                    {TypeArr(menu)?.map((v, index) => (
                      <MenuItem value={v?.Type} key={index}>
                        {v?.Name}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                label="Type"
                select
                name="Type"
                autoComplete="off"
                control={control}
                fullWidth
                ref={register()}
                defaultValue={menu?.obj?.Type}
                required
                helperText={errors?.Type?.message}
                error={errors?.Type ? true : false}
                disabled={menu?.obj?.IsSystem === true}
              />
              <Controller
                render={({ value, onChange }) => {
                  const defVal = getMasterCOA?.filter(
                    x => x?.MasterCOAID === menu?.obj?.MasterCOAID
                  )[0]
                  return (
                    <Autocomplete
                      options={
                        getMasterCOA?.filter(
                          coa => coa?.RecordStatus === RecordStatus.Active
                        ) || []
                      }
                      getOptionLabel={option =>
                        ` ${option?.Code} | ${option?.Name}`
                      }
                      fullWidth
                      onChange={(value, newValue: any) => {
                        onChange(newValue?.MasterCOAID)
                        setValue('MasterCOAID', newValue?.MasterCOAID)
                      }}
                      renderOption={(props, option) => {
                        return (
                          <div>
                            <div>
                              <span className="xsTitle">{props?.Code}</span>
                            </div>
                            <div className="desc">{props?.Name}</div>
                          </div>
                        )
                      }}
                      defaultValue={defVal}
                      disabled={mode === 'approve-reject'}
                      renderInput={(params: any) => {
                        return (
                          <div>
                            <TextField
                              {...params}
                              helperText={errors?.MasterCOAID?.message}
                              error={errors?.MasterCOAID ? true : false}
                              label="GL Account Code"
                              style={{ width: '100%' }}
                              margin="dense"
                              required={isGLSubscribe}
                            />
                          </div>
                        )
                      }}
                    />
                  )
                }}
                label="GL Account Code"
                name="MasterCOAID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="dense"
                ref={register}
                required
                defaultValue={menu?.obj?.MasterCOAID}
              />
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    if (hasChanges() === true) {
                      setOpenExitConf(true)
                    } else {
                      setOpen(false)
                      resetMenu()
                    }
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },

              {
                displayText: 'Save',
                props: {
                  onClick: () => {
                    if (duplicateName() === false) {
                      handleSubmit(onSubmit)()
                    }
                  },
                  variant: 'contained',
                  color: 'primary',
                  form: 'createUOM-form',
                  type: 'submit',
                },
              },
            ],
          },
        }}
      />
      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          setOpenExitConf(false)
          setOpen(false)
          resetMenu()
        }}
        // hasInfo={hasInfo}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
