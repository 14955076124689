import React from 'react'
import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import { AssetsRegistrationMain } from './AssetsRegistrationMain'
import { AssetsRegistrationForm } from './AssetsRegistrationForm/AssetsRegistrationForm'
import { AssetsRegistrationDetail } from './AssetsRegistrationDetail/AssetsRegistrationDetail'

const assetsRegistrationRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-registration/filter',
    },
    component: (
      <AdvancedFilter
        mode="assets-registration"
        app="fixed-assets"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-registration',
    },
    component: <AssetsRegistrationMain />,
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/assets-registration/add',
    },
    component: (
      <AssetsRegistrationForm formMode="add" type="ASSETS_REGISTRATION" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/fixed-assets/:CompanyID/assets-registration/:AssetsRegistrationID/edit',
    },
    component: <AssetsRegistrationForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/fixed-assets/:CompanyID/assets-registration/:AssetsRegistrationID/approve-reject',
    },
    component: <AssetsRegistrationForm mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path:
        '/fixed-assets/:CompanyID/assets-registration/:AssetsRegistrationID/resubmit',
    },
    component: <AssetsRegistrationForm formMode="resubmit" />,
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path:
        '/fixed-assets/:CompanyID/assets-registration/:AssetsRegistrationID/detail',
    },
    component: <AssetsRegistrationDetail />,
  },
]

export default assetsRegistrationRoutes
