import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { Checkbox, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetAssignedCompanyQuery,
  useGetBankAccountLazyQuery,
  useGetCompanyQuery,
  useGetJournalTypeQuery,
  useGetPaymentLazyQuery,
  useGetReceiptLazyQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

interface CashLedgerListingParamsFormProps {
  StartDate: Date
  EndDate: Date
  DocNo: string
  CompanyID: string
  BankAccountID: string
  JournalType: string
  Refno: string
  Paytorecv: string
  Description: string
}

export interface JournalType {
  JournalType: string
  JournalTypeID: string
}

interface CompanyOption {
  Name: string
  CompanyID: string
}

export const CashLedgerListingParamsForm = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const [comp, setComp] = useState(undefined)
  const [bankAcc, setBankAcc] = useState(undefined)
  const [selectedDocNo, setSelectedDocNo] = useState()
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('Company is required'),
    StartDate: CommonYupValidation.requireField('Start Date is required'),
    EndDate: CommonYupValidation.requireField('End Date is required'),
  })

  const latestCompany = localStorage.getItem('latestCompany')
  const [docNoList, setDocNoList] = useState([])
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<CashLedgerListingParamsFormProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      if (latestCompany) {
        loadBankAccount({
          variables: { CompanyID: latestCompany },
        })
      }
    },
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadBankAccount({
          variables: { CompanyID: latestCompany },
        })
      }
    },
  })

  const {
    loading: JournalTypeLoading,
    error: JournalTypeError,
    data: { getJournalType } = { getJournalType: [] },
  } = useGetJournalTypeQuery({
    fetchPolicy: 'network-only',
    variables: {
      AccountID: user?.accountID,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    loadBankAccount,
    {
      loading: bankAccountLoading,
      data: { getBankAccount } = { getBankAccount: [] },
    },
  ] = useGetBankAccountLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    loadReceipt,
    { loading: receiptLoading, data: { getReceipt } = { getReceipt: [] } },
  ] = useGetReceiptLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },

    onCompleted: ({ getReceipt }) => {
      docNoList.push(...getReceipt)
    },
  })

  const [
    loadPayment,
    { loading: paymentLoading, data: { getPayment } = { getPayment: [] } },
  ] = useGetPaymentLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },

    onCompleted: ({ getPayment }) => {
      docNoList.push(...getPayment)
    },
  })

  const [selectedBankAccount, setSelectedBankAccount]: any = useState(new Set())

  const handleCheckboxBankAccountChange = (event: any) => {
    let bankAccount = selectedBankAccount

    if (event?.target?.checked) {
      bankAccount.add(event?.target?.value)
    } else {
      bankAccount.delete(event?.target?.value)
    }
    setSelectedBankAccount(new Set(bankAccount))
  }

  const [selectedJournalType, setSelectedJournalType]: any = useState(new Set())

  const handleCheckboxJournalTypeChange = (event: any) => {
    let journalType = selectedJournalType

    if (event?.target?.checked) {
      journalType.add(event?.target?.value)
    } else {
      journalType.delete(event?.target?.value)
    }
    setSelectedBankAccount(new Set(journalType))
  }

  const onSubmit = (data, status) => {
    console.log('data', data)
    history.push({
      pathname: `/digital-reports/cash-book/cash-ledger-report/generated`,
      state: {
        CompanyID: data?.CompanyID,
        StartDate: new Date(data.StartDate),
        EndDate: new Date(data.EndDate),
        BankAccountID: selectedBankAccount
          ? Array.from(selectedBankAccount)
          : undefined,
        DocNo: data.DocNo,
        Refno: data?.Refno,
        Paytorecv: data?.Paytorecv,
        Description: data?.Description,
        JournalType: selectedJournalType
          ? Array.from(selectedJournalType)
          : undefined,
      },
    })
  }

  return (
    <>
      {companyLoading && <Loading />}
      {receiptLoading && <Loading />}
      {paymentLoading && <Loading />}
      {JournalTypeLoading && <Loading />}
      {bankAccountLoading && <Loading />}
      {CompanyAssignedLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/digital-reports/cash-book`)}
        smTitle={smTitle?.CASH_BOOK}
        title={'IFCA AccountX Dev Team'}
        routeSegments={[
          { name: 'Submenu' },
          { name: 'Cash Ledger Listing', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          {!CompanyAssignedLoading && !companyLoading && (
            <Controller
              render={({ value, onChange }) => {
                const options: CompanyOption[] =
                  !!user?.superUser === false
                    ? getAssignedCompanyByUser
                    : getCompany
                const defVal = getCompany?.filter(
                  x => x?.CompanyID === latestCompany
                )[0]
                return (
                  <Autocomplete
                    options={options || []}
                    getOptionLabel={(option: CompanyOption) => option.Name}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('CompanyID', newValue?.CompanyID)
                      setComp(newValue?.CompanyID)
                      setValue('BankAccountID', undefined)
                      setBankAcc(undefined)
                      loadBankAccount({
                        variables: { CompanyID: newValue?.CompanyID },
                      })
                      setValue('DocNo', undefined)
                      setSelectedDocNo(undefined)
                      setDocNoList([])
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.Name}</span>
                    }}
                    defaultValue={defVal}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CompanyID?.message}
                            error={errors?.CompanyID ? true : false}
                            label="Company"
                            style={{ width: '100%' }}
                            margin="normal"
                            required
                            defaultValue={defVal}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Company"
              name="CompanyID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CompanyID?.message}
              error={errors?.CompanyID ? true : false}
              required
              defaultValue={latestCompany}
            />
          )}

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Date"
              control={control}
              onChange={(date: Date | null) => {
                console.log(date)
              }}
              format="dd/MM/yyyy"
              value={watch('StartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.StartDate ?? new Date()}
              helperText={errors?.StartDate?.message}
              error={errors?.StartDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="EndDate"
              label="End Date"
              control={control}
              onChange={(date: Date | null) => {
                console.log(date)
              }}
              format="dd/MM/yyyy"
              value={watch('EndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.EndDate ?? new Date()}
              helperText={errors?.EndDate?.message}
              error={errors?.EndDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getBankAccount || []}
                  getOptionLabel={option => option?.Code}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.BankAccountID)
                    })

                    setSelectedBankAccount(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.BankAccountID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxBankAccountChange(event)
                        }}
                      />
                      {option?.Code}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Bank"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="BankAccountID"
            label="Bank"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getJournalType || []}
                  getOptionLabel={option => option?.JournalType}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.JournalType)
                    })

                    setSelectedJournalType(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.JournalType}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxJournalTypeChange(event)
                        }}
                      />
                      {option?.JournalType}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Journal Type"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="JournalType"
            label="Journal Type"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="DocNo"
            label="Document No"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.DocNo?.message}
            error={errors?.DocNo ? true : false}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="Refno"
            label="Reference No"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.Refno?.message}
            error={errors?.Refno ? true : false}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="Paytorecv"
            label="Pay To/Receive From"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.Paytorecv?.message}
            error={errors?.Paytorecv ? true : false}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
