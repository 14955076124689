import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import React, { lazy } from 'react'
import { RecurringDetail } from './RecurringDetail/RecurringDetail'
import { RecurringJournalForm } from './RecurringForm/RecurringJournalForm'
import { RecurringJournalItemForm } from './RecurringForm/RecurringJournalItemForm'
import { RecurringJournalDetail } from './RecurringJournalDetail'
import { RecurringJournalJournalDetail } from './RecurringJournalJournalDetail'
import { RecurringJournalJournalListing } from './RecurringJournalJournalListing'
import { RecurringJournalMain } from './RecurringJournalMain'
import { PDFPreviewGLV2 } from '../Hooks/PDFPreviewGLV2'
import { AuditLogListing } from 'components/Listing/AuditLog'

const recurringjournalRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED DILTER               */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/recurring-journal/filter',
    },
    component: (
      <AdvancedFilter
        mode="recurring-journal"
        app="general-ledger"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/recurring-journal',
    },
    component: <RecurringJournalMain />,
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:RecurringJournalID/detail',
    },
    component: <RecurringDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:RecurringJournalID/detail/workdesk',
    },
    component: <RecurringDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:RecurringJournalID/detail/workdesk-history',
    },
    component: <RecurringDetail mode="workdesk-history" />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:RecurringJournalID/journal-listing',
    },
    component: <RecurringJournalJournalListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:RecurringJournalID/:JournalID/journal-Detail',
    },
    component: <RecurringJournalJournalDetail />,
  },

  /* -------------------------------------------- */
  /*                  PREVIEW PDF                 */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:RecurringJournalID/:JournalID/preview',
    },
    component: (
      <PDFPreviewGLV2
        accountType="general-ledger"
        docType="recurring-journal"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/recurring-journal/add',
    },
    component: <RecurringJournalForm formMode="add" type="RECURRING_JOURNAL" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:RecurringJournalID/edit',
    },
    component: <RecurringJournalForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:RecurringJournalID/approve-reject/',
    },
    component: <RecurringJournalForm formMode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:RecurringJournalID/resubmit',
    },
    component: <RecurringJournalForm formMode="resubmit" />,
  },

  /* -------------------------------------------- */
  /*                     ITEM                     */
  /* -------------------------------------------- */
  //payment = add , paymentitem = add
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:formMode/recurring-journal-item/:detailMode',
    },
    component: <RecurringJournalItemForm />,
  },
  //payment = add , paymentitem = edit & add
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:RecurringJournalID/:formMode/recurring-journal-item/:detailMode',
    },
    component: <RecurringJournalItemForm />,
  },
  //payment = add, paymentitem = edit
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:formMode/recurring-journal-item/:RecurringJournalItemID/:detailMode',
    },
    component: <RecurringJournalItemForm />,
  },
  //payment = edit, paymentitem = edit
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:RecurringJournalID/:formMode/recurring-journal-item/:RecurringJournalItemID/:detailMode',
    },
    component: <RecurringJournalItemForm />,
  },
  /* -------------------------------------------- */
  /*                AUDIT LOG                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/recurring-journal/:RecurringJournalID/detail/audit-log',
    },
    component: (
      <AuditLogListing
        module="ledger"
        routeSegments="Recurring Journal"
        smTitle="General Ledger"
        docType="recurring-journal"
      />
    ),
  },
]

export default recurringjournalRoutes
