import React, { lazy } from 'react'
import { CostCentreDefinitionFormV3 } from './CostCentreDefinitionFormV3'
import { CostCentreFormV4 } from './CostCentreFormV4'

// const CostCentreForm = lazy(() =>
//   import('./CostCentreFormV2').then(module => ({
//     default: module.CostCentreForm,
//   }))
// )

const CostCentreAssignmentForm = lazy(() =>
  import('./CostCentreAssignmentForm').then(module => ({
    default: module.CostCentreAssignmentForm,
  }))
)

const costCentreRoutes = [
  // {
  //   props: { exact: true, path: '/company/submenu/:CompanyID/cost-centre' },
  //   component: <CostCentreListing />,
  // },
  {
    props: {
      exact: true,
      path: '/general-ledger/company/submenu/:CompanyID/cost-centre',
    },
    component: <CostCentreFormV4 />,
  },

  {
    props: {
      exact: true,
      path: '/general-ledger/company/submenu/:CompanyID/cost-centre-assignment',
    },
    component: <CostCentreAssignmentForm />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/company/submenu/:CompanyID/cost-centre-definition',
    },
    component: <CostCentreDefinitionFormV3 />,
  },
]
export default costCentreRoutes
