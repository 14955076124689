import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Avatar,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  useDeleteJournalProcessingMutation,
  useGetDocNumTitleQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'

/* -------------------------------------------- */
/*            For ACTIVE and APPROVED           */
/* -------------------------------------------- */
export const JournalEntryList = (props: any) => {
  const {
    filteredList,
    listLoading,
    listStatus,
    userList,
    fetchMore,
    handleFilterDate,
    setOriginalListing,
    selectedField,
    sortOrder,
  } = props
  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()

  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()

  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)
  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any

  /** DELETE FUNCTION AND POPUP TRIGGER **/

  const [deleteJP, setDeleteJP] = useState(false)

  const [
    deleteJournalProcessing,
    {
      loading: deleteJournalProcessingLoading,
      error: deleteJournalProcessingError,
    },
  ] = useDeleteJournalProcessingMutation({
    onError: error => {
      if (error.message.includes('FK_')) {
        setErrorDia(true)
      }
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      fetchMore({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
          sortField: selectedField === null ? undefined : selectedField,
          sortOrder: sortOrder === null ? undefined : sortOrder,
        },
      })
    },
  })

  const handleDelete = data => {
    deleteJournalProcessing({
      variables: { JournalProcessingID: data },
    })
    setDeleteJP(false)
  }

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: 'GL_JournalProcessing',
    },
  })

  const docNumChecker = getDocumentNumberHeader?.length === 0

  return (
    <>
      {listLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {deleteJournalProcessingLoading && <Loading />}
      <ContentWrapper advSearch float overflow>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                fetchMore({
                  variables: {
                    CompanyID: CompanyID,
                    StatusArr: [listStatus],
                    take: 30,
                    skip: currentLength,
                    searchValue:
                      getSearch?.name !== '' || getSearch?.name === undefined
                        ? undefined
                        : getSearch?.name,
                    sortField: selectedField === null ? null : selectedField,
                    sortOrder: sortOrder === null ? null : sortOrder,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                listLoading && (
                  <div style={{ textAlign: 'center' }}>
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {advancedFilterList(filteredList)
                ?.filter(handleFilterDate)
                ?.map((el, index) => {
                  return (
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            <span
                              className={`xsTitle flex-space 
                              ${
                                el?.ApprovalStatus === ApprovalStatus.Completed
                                  ? 'click-text'
                                  : null
                              }  `}
                              onClick={() => {
                                el?.ApprovalStatus ===
                                  ApprovalStatus.Completed &&
                                  history.push({
                                    pathname: `/general-ledger/${CompanyID}/journal-processing/${el?.JournalProcessingID}/preview`,
                                    state: {
                                      ...el,
                                      mode: 'detail',
                                    },
                                  })
                              }}
                              style={{ fontSize: '12px' }}
                            >
                              {el?.DocNo ?? el?.RefNo}
                            </span>
                            <span
                              className="desc"
                              style={{
                                fontSize: '12px',
                                color:
                                  listStatus === 'ACTIVE' ? '#FF0000' : null,
                              }}
                            >
                              <span className="">
                                {formatDate(el?.DocDate)}
                              </span>
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            <span
                              className="desc"
                              style={{
                                whiteSpace: 'break-spaces',
                                lineBreak: 'auto',
                              }}
                            >
                              <GreyTooltip
                                disableFocusListener
                                title={
                                  <React.Fragment>
                                    <Grid container wrap="nowrap" spacing={2}>
                                      <Grid item>
                                        <Avatar
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                        >
                                          <PersonIcon
                                            style={{ fontSize: 'small' }}
                                          />
                                        </Avatar>
                                      </Grid>
                                      <Grid item xs zeroMinWidth>
                                        <div style={{ fontSize: '12px' }}>
                                          {userList?.find(
                                            x =>
                                              x?.ID ===
                                              (listStatus === 'ACTIVE'
                                                ? el?.createdBy
                                                : el?.approvedBy)
                                          )?.name ??
                                            userList?.find(
                                              x =>
                                                x?.ID ===
                                                (listStatus === 'ACTIVE'
                                                  ? el?.createdBy
                                                  : el?.approvedBy)
                                            )?.name}
                                        </div>
                                        <div style={{ fontSize: '10px' }}>
                                          {formatDate(
                                            listStatus === 'ACTIVE'
                                              ? new Date(
                                                  el?.createdTs
                                                ).toISOString()
                                              : el?.approvedTs
                                          )}
                                          &nbsp;
                                          {formatTime(
                                            listStatus === 'ACTIVE'
                                              ? new Date(
                                                  el?.createdTs
                                                ).toISOString()
                                              : el?.approvedTs
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                }
                                arrow
                                enterTouchDelay={0}
                              >
                                <div>
                                  <IconText
                                    icon={
                                      <img
                                        src={SubmitterLog}
                                        style={{
                                          width: '12px',
                                          marginRight: '3px',
                                        }}
                                      />
                                    }
                                    font="desc"
                                  ></IconText>
                                </div>
                              </GreyTooltip>
                            </span>
                            <span
                              className="desc flex-space text-overflow"
                              style={{ marginBottom: '-5px' }}
                            >
                              {el?.Description}
                            </span>

                            <span className="xxTitle">
                              <span className="c-orange">
                                <span style={{ fontSize: '12px' }}>
                                  {amtStr(
                                    el?.JournalProcessingItem?.filter(
                                      item => item?.DocAmt > 0
                                    )?.reduce((x, y) => x + y?.DocAmt, 0)
                                  )}
                                </span>
                              </span>
                            </span>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        {listStatus === 'ACTIVE' ||
                        listStatus === 'REJECTED' ? (
                          <>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              aria-controls="menu-list"
                              aria-haspopup="true"
                              onClick={e =>
                                handleClick(
                                  e,
                                  el?.JournalProcessingID,
                                  index,
                                  el
                                )
                              }
                            >
                              <MoreVert />
                            </IconButton>
                          </>
                        ) : (
                          <IconButton edge="end" aria-label="arrow">
                            <KeyboardArrowRight
                              onClick={() =>
                                history.push({
                                  pathname: `/general-ledger/${CompanyID}/journal-processing/${el?.JournalProcessingID}/detail`,
                                  state: el,
                                })
                              }
                            />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </InfiniteScroll>
          )}
        </List>
        {listStatus === 'ACTIVE' && (
          <FloatButton
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.GeneralLedgerJournalProcessingCreate,
            })}
            onClick={() => {
              if (docNumChecker) {
                setOpenSnackBar(true)
                setSnackBarMsg(SystemMsgs.errorNumberingStructure())
              } else {
                history.push({
                  pathname: `/general-ledger/${CompanyID}/journal-processing/add`,
                })
              }
            }}
          />
        )}
      </ContentWrapper>
      {listStatus === 'ACTIVE' ? (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.GeneralLedgerJournalProcessingUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/general-ledger/${CompanyID}/journal-processing/${menu?.obj?.JournalProcessingID}/edit`,
                state: menu?.obj,
              })
            }}
          >
            <span className="">Edit</span>
          </MenuItem>
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.GeneralLedgerJournalProcessingDelete,
            })}
            onClick={() => setDeleteJP(true)}
          >
            <span className="">Delete</span>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.GeneralLedgerJournalProcessingUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/general-ledger/${CompanyID}/journal-processing/${menu?.obj?.JournalProcessingID}/edit`,
                state: { ...menu?.obj, mode: 'resubmit' },
              })
            }}
          >
            <span className="">Resubmit</span>
          </MenuItem>
        </Menu>
      )}

      <DeleteDialog
        ID={menu?.ID}
        openDeleteDialog={deleteJP}
        setOpenDeleteDialog={setDeleteJP}
        title={'Journal'}
        menu={menu?.obj}
        handleDelete={handleDelete}
        passID={menu.ID}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={
          'You are not allowed to delete this as there are transactions executed already'
        }
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
