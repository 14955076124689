import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import AuditLog from 'assets/icons/MFRS16/auditlog.svg'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import {
  useDocumentListingQuery,
  useGetJournalProcessingQuery,
  useGetUsersByAccountAndSoftwareQuery,
  UserSelection,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { useHistory, useLocation, useParams } from 'react-router'
import '../Journal.scss'
import { JournalDetailContent } from './JournalDetailContent'
import { JournalDetailItemContent } from './JournalDetailItemContent'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import React, { useState } from 'react'

export const JournalDetail = (props: any) => {
  let history = useHistory()
  const { CompanyID, JournalProcessingID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)
  let itemData = []
  itemData = editData?.JournalProcessingItem

  const docAmtReducer = (total, curVal) => total + curVal?.DocAmt

  const positiveAmt = itemData
    ?.filter(v => v?.DocAmt > 0)
    ?.reduce(docAmtReducer, 0)

  const negativeAmt = itemData
    ?.filter(v => v?.DocAmt < 0)
    ?.reduce(docAmtReducer, 0)

  const { previewFiles, handleEditUpload } = useUploadDocument()
  const {
    loading: JournalLoading,
    error: JournalError,
    data: { getJournalProcessing } = { getJournalProcessing: [] },
  } = useGetJournalProcessingQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    variables: { JournalProcessingID: JournalProcessingID },
  })

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
  })

  const {
    loading: docLoading,
    error: docError,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      refID: JournalProcessingID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  return (
    <>
      {docLoading && <Loading />}
      {JournalLoading && <Loading />}
      {getUsersByAccountAndSoftwareLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-ledger/${CompanyID}/journal-processing`)
        }}
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'Journal Processing' },
          { name: 'Journal Entries', current: true },
        ]}
        rightRouteSegments={[{ name: `Approved`, current: true }]}
      />

      <DynamicSubHeader
        title={
          <span className="c-orange">
            <span style={{ fontWeight: 'bold' }}>
              {getJournalProcessing[0]?.DocNo ?? getJournalProcessing[0]?.RefNo}
            </span>
          </span>
        }
        rightText={
          <span className="c-orange">
            {formatDate(getJournalProcessing[0]?.DocDate)}
          </span>
        }
        infoLine={
          <>
            <div
              className="text-overflow"
              style={{ fontSize: '11px', fontWeight: 'bold', width: '270px' }}
            >
              {getJournalProcessing[0]?.Description}
            </div>
          </>
        }
        rightInfoLine={
          <IconText
            label={amtStr(
              getJournalProcessing[0]?.JournalProcessingItem?.filter(
                item => item?.DocAmt > 0
              )?.reduce((x, y) => x + y?.DocAmt, 0)
            )}
            parentClassName={'c-orange'}
            icon={
              <img
                src={AuditLog}
                style={{
                  width: '17px',
                  marginRight: '5px',
                  marginLeft: '12px',
                }}
                onClick={() => {
                  history.push({
                    pathname: `/general-ledger/${CompanyID}/journal-processing/${JournalProcessingID}/detail/audit-log`,
                    state: {
                      ID: getJournalProcessing[0]?.JournalProcessingID,
                      tableName: 'GL_JournalProcessing',
                      primaryKey: 'JournalProcessing',
                      ...getJournalProcessing[0],
                    },
                  })
                }}
              />
            }
            font="desc"
          ></IconText>
        }
      />
      <ContentWrapper multiDynamicInfo float footer>
        <CardContents>
          <JournalDetailContent
            listEl={getJournalProcessing[0]}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Journal Detail',
            },
          }}
        >
          <JournalDetailItemContent
            listItem={getJournalProcessing[0]?.JournalProcessingItem}
          />
        </CardContents>
      </ContentWrapper>

      <TotalAmountFooter
        detail={true}
        debitCreditInfo={true}
        debitAmt={amtStr(positiveAmt) ?? '0.00'}
        creditAmt={amtStr(Math.abs(negativeAmt)) ?? '0.00'}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
