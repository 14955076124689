import { Grid, List, ListItem, ListItemText } from '@material-ui/core'
import { default as React, useEffect } from 'react'

export const AssetsRegistrationUsedDepartmentDetailContent = (props: any) => {
  const { listUsedDepartment } = props

  return (
    <>
      <div className="rm-padding table-wrap">
        <List className="core-list content-wrap full " disablePadding>
          {listUsedDepartment?.map((el, index) => (
            <ListItem
              className="table-listItem "
              key={index}
              divider
              style={{ backgroundColor: 'white' }}
            >
              <ListItemText
                primary={
                  <Grid container justify="flex-start" spacing={1}>
                    <Grid item xs={9} className="fw-medium desc text-noflow">
                      {el?.Department}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className="fw-medium desc text-right text-overflow"
                      style={{
                        textAlign: 'end',
                        overflow: 'visible',
                      }}
                    >
                      {`Qty :  ${el?.Quantity}  ${el?.UOM}`}
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Grid container justify="flex-start">
                    <Grid
                      item
                      xs={12}
                      className="fw-medium desc "
                      style={{ color: '#858585' }}
                    >
                      {el?.Location}
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
    </>
  )
}
