import PrintIcon from '@material-ui/icons/Visibility'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import {
  amtStr,
  replaceUnderscoresWithSpaces,
} from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'

interface DetailProps {
  listEl: any
}

export const ARConsolidateEInvoiceDetailContent = (props: DetailProps) => {
  const { listEl } = props

  return (
    <>
      <div className="content-wrap full">
        <div className="desc" style={{ fontWeight: 300 }}>
          Doc Type
        </div>

        <div className="xsTitle" style={{ fontWeight: 500 }}>
          {replaceUnderscoresWithSpaces(listEl?.einvoice_trx_type)}
        </div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ fontWeight: 300 }}>
          Date
        </div>

        <div className="xsTitle" style={{ fontWeight: 500 }}>
          {formatDate(listEl?.DocDate)}
        </div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Total Amount
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {amtStr(listEl?.DocAmt)}
          {/* {formatDate(listEl?.submittedTs)} */}
        </div>
      </div>

      <div className="content-wrap full">
        <div className="desc" style={{ color: 'grey' }}>
          Submitted Date and Time
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {listEl?.submittedTs}
          {/* {formatDate(listEl?.submittedTs)} */}
        </div>
      </div>

      <div className="content-wrap full">
        <div className="desc" style={{ fontWeight: 300 }}>
          Description
        </div>
        <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
          {listEl?.Description}
        </div>
      </div>
    </>
  )
}
