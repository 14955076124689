import GLAccountCodeJournalTypeMappingRoutes from './GLAccountCodeJournalTypeMapping/GLAccountCodeJournalTypeRoutes'
import leaseContractRoutes from './LeaseContract/LeaseContractRoutes'
import { MFRS16Submenu } from './MFRS16Submenu'
import React from 'react'
import rouAssetsLiabilityScheduleRoutes from './ROUAssetsLeaseLiabilitySchedule/ROUAssetsLeaseLiabilityScheduleRoutes'
import { generateMFRS16JournalRoutes } from './MFRS16Journal/MFRS16JournalRoutes'
const mfrs16Route = [
  {
    props: { exact: true, path: '/mfrs16/:CompanyID' },
    component: <MFRS16Submenu accountType="mfrs16" />,
  },
  ...leaseContractRoutes,
  ...GLAccountCodeJournalTypeMappingRoutes,
  ...rouAssetsLiabilityScheduleRoutes,
  ...generateMFRS16JournalRoutes,
]
export default mfrs16Route
