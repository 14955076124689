import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { AttachmentDialog } from 'components/Dialog/AttachmentDialog'
import { useDocumentListingQuery } from 'generated/graphql'
import { AttachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'

const dummyData = [
  {
    Date: 'Date',
    RevisedNetAmount: '18,000.00',
    AssetNo: 'A-000013',
    AssetName: 'Computer Pheripheral',
    OriginalAmount: '20,000.00',
    AccumulatedDepreciation: '0.00',
    NetAmount: '20,000.00',
    Remark: 'Remark here...',
    SubmittedBy: 'Syima',
    SubmiitedDate: '12 Oct 2023',
    Approvedby: 'Leong Koh',
    ApprovedDate: '12 Oct 2023',
  },
  {
    Date: 'Date',
    RevisedNetAmount: '18,000.00',
    AssetNo: 'A-000013',
    AssetName: 'Computer Pheripheral',
    OriginalAmount: '20,000.00',
    AccumulatedDepreciation: '0.00',
    NetAmount: '20,000.00',
    Remark: 'Remark here...',
    SubmittedBy: 'Syima',
    SubmittedDate: '12 Oct 2023',
    Approvedby: 'Leong Koh',
    ApprovedDate: '12 Oct 2023',
  },
]

export const RevaluationDetailContent = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { listEl, documentListing, listStatus, userList } = props
  const [openDoc, setOpenDoc] = useState<boolean>(false)
  const { menu } = useMenuOption()

  const {
    loading: DocumentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: { refID: listEl?.AuditAdjustmentID },
  })

  return (
    <>
      {DocumentLoading && <Loading />}

      <div className="content-wrap full ">
        <div className="desc " style={{ color: 'grey' }}>
          {'Asset Name'}
        </div>
        <div className="mdDesc text-noflow"> {dummyData[0]?.AssetName} </div>
      </div>
      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Original Amount'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.OriginalAmount}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Accumulated Depreciation'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.AccumulatedDepreciation}
        </div>
      </div>
      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Net Amount'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.NetAmount}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Revised Net Amount'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.RevisedNetAmount}
        </div>
      </div>
      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.SubmittedBy}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {dummyData[0]?.SubmiitedDate}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {dummyData[0]?.Approvedby}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {dummyData[0]?.ApprovedDate}
            </div>
          </div>
        </>
      )}

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Remark'}
        </div>
        <div className="mdDesc text-noflow">{dummyData[0]?.Remark}</div>
      </div>

      <AttachmentDialog
        title="Revaluation"
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        AttachmentDetailView={AttachmentDetailView}
        DocumentListing={DocumentListing}
      />
    </>
  )
}
