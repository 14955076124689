import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  Checkbox,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import AdvanceFilterCardExpansion from 'components/CardExpansion/AdvanceFilterCardExpansion'
import {
  dateFormat,
  formatDashDate,
} from 'helpers/StringNumberFunction/FormatDate'
import React, { Fragment, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

export const AdvanceFilterSearchExpansion = (props: any) => {
  const today = new Date()

  const { CompanyID, setOriginalListing, fetchData, documentType } = props

  const {
    register: registerFilter,
    watch: watchFilter,
    control: controlFilter,
    setValue: setValueFilter,
    getValues: getValueFilter,
  } = useForm()

  const [isReset, setIsReset] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [selectedDocType, setSelectedDocType] = useState([])

  const renderFilterHeader = () => {
    if ((startDate !== '' && endDate !== '') || selectedDocType?.length > 0) {
      return (
        <span className={`xsTitle flex-space`}>
          {selectedDocType?.length > 0 && 'Doc Type:'}
          {`${
            selectedDocType?.length > 0
              ? selectedDocType?.map(x => enumFormatter(x))?.toString()
              : ''
          }`}
          {startDate !== '' && endDate !== '' && selectedDocType?.length > 0
            ? `, `
            : null}
          {startDate !== '' && `From: ${startDate}`}
          {endDate !== '' && ` To: ${endDate}`}
        </span>
      )
    } else {
      return <span className={`xsTitle flex-space`}>Advance Search</span>
    }
  }

  const enumFormatter = (str: string) => {
    return ` ${str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(' ')}`
  }

  const onSubmitFilter = () => {
    setOriginalListing([])
    setSelectedDocType(a => [...getValueFilter('DocType')])
    setStartDate(
      getValueFilter('StartDate') !== null
        ? dateFormat(new Date(getValueFilter('StartDate')).toISOString())
        : ''
    )
    setEndDate(
      getValueFilter('StartDate') !== null
        ? dateFormat(new Date(getValueFilter('EndDate')).toISOString())
        : ''
    )
    fetchData({
      variables: {
        CompanyID: CompanyID,
        skip: 0,
        take: 30,
        searchValue: getValueFilter('DocNo'),
        DocType: getValueFilter('DocType'),
        StartDate: getDateCondition('StartDate'),
        EndDate: getDateCondition('EndDate'),
      },
    })
  }

  const getDateCondition = date => {
    return getValueFilter(date) === null
      ? null
      : formatDashDate(new Date(getValueFilter(date)).toISOString())
  }

  const resetFilter = () => {
    setIsReset(true)
    setOriginalListing([])
    setSelectedDocType(x => [])
    setValueFilter('DocType', [])
    setValueFilter('DocNo', '')

    setValueFilter(
      'StartDate',
      new Date(today.getFullYear(), today.getMonth() - 1, 1)
    )
    setValueFilter(
      'EndDate',
      new Date(today.getFullYear(), today.getMonth(), 0)
    )
    setStartDate('')
    setEndDate('')

    fetchData({
      variables: {
        CompanyID: CompanyID,
        skip: 0,
        take: 30,
        searchValue: '',
      },
    })
  }

  return (
    <AdvanceFilterCardExpansion
      customColor={true}
      customColorCode="#faf2e8"
      // expansion={expanded}
      summary={
        <ListItem
          style={{
            backgroundColor: '#faf2e8',
            color: '#ff9800',
          }}
        >
          <ListItemText primary={<>{renderFilterHeader()}</>} />
        </ListItem>
      }
    >
      <div
        style={{
          fontSize: '14px',
          marginBottom: '15px',
          display: 'block',
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Controller
            as={KeyboardDatePicker}
            name="StartDate"
            label="Doc Start Date"
            control={controlFilter}
            onChange={(date: Date | null) => {}}
            format="dd/MM/yyyy"
            value={watchFilter('StartDate')}
            margin="normal"
            allowKeyboardControl
            ref={registerFilter}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
            className="left"
            defaultValue={
              new Date(today.getFullYear(), today.getMonth() - 1, 1)
            }
            showTodayButton
            fullWidth
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Controller
            as={KeyboardDatePicker}
            name="EndDate"
            label="Doc End Date"
            control={controlFilter}
            onChange={(date: Date | null) => {
              setValueFilter('EndDate', date)
            }}
            format="dd/MM/yyyy"
            value={watchFilter('EndDate')}
            margin="normal"
            allowKeyboardControl
            ref={registerFilter}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
            className="right"
            defaultValue={new Date(today.getFullYear(), today.getMonth(), 0)}
            showTodayButton
            fullWidth
          />
        </MuiPickersUtilsProvider>

        {!isReset && (
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={documentType || []}
                  getOptionLabel={option => `${enumFormatter(option)}`}
                  value={watchFilter('DocType')}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    onChange(newValue)
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        value={option}
                        checked={selected}
                        color="primary"
                      />
                      {`${enumFormatter(option)}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Doc Type"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            value={watchFilter('DocType')}
            name="DocType"
            label="Doc Type"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={controlFilter}
            select
            ref={registerFilter}
          />
        )}

        <Controller
          as={TextField}
          style={{ marginTop: '8px', marginBottom: '12px' }}
          id="standard-basic"
          name="DocNo"
          label="Document No."
          autoComplete="off"
          control={controlFilter}
          fullWidth
          margin="dense"
          ref={registerFilter}
          defaultValue={''}
        />

        <Button
          type="reset"
          color="secondary"
          style={{
            minWidth: '50px',
            float: 'left',
            marginBottom: '15px',
          }}
          variant="contained"
          onClick={() => {
            resetFilter()
          }}
        >
          Reset
        </Button>

        <Button
          type="submit"
          color="primary"
          style={{
            minWidth: '50px',
            float: 'right',
            marginBottom: '15px',
          }}
          variant="contained"
          onClick={() => {
            onSubmitFilter()
          }}
        >
          Filter
        </Button>
      </div>
    </AdvanceFilterCardExpansion>
  )
}
