import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { Menu, MenuItem, Switch, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import BigNumber from 'bignumber.js'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import {
  AddFavoriteDialog,
  DeleteFavoriteDialog,
} from 'components/Favorite/FavoriteDialog'
import FavoriteMenu from 'components/Favorite/FavoriteMenu'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import { dueDateChecker } from 'containers/ARAPModule/Helper/DateCalculation'
import { ARItemDetail } from 'containers/ARModule/components/ARItemDetail'
import { ARItemDialog } from 'containers/ARModule/components/ARItemDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  DocDateValidationAfter,
  DocDateValidationBefore,
} from 'containers/CashBookModule/DocDateValidation'
import {
  AcctPermission,
  ApprovalStatus,
  DocumentListingDocument,
  GetArFavoriteInvoiceDocument,
  GetArInvoiceDocument,
  GetArInvoicebyStatusDocument,
  RecordStatus,
  useCreateArFavoriteInvoiceMutation,
  useCreateArInvoiceMutation,
  useDeleteArFavoriteInvoiceMutation,
  useDocumentListingLazyQuery,
  useGetArFavoriteInvoiceQuery,
  useGetBillItemListingLazyQuery,
  useGetCompanyNameQuery,
  useGetCostCentreCodeLazyQuery,
  useGetDebtorAccountQuery,
  useGetDocNumTitleQuery,
  useGetDocumentDateValidationQuery,
  useGetEInvoiceCompanyStartDateLazyQuery,
  useGetMasterCoaLazyQuery,
  useGetTaxEffectiveDateQuery,
  useGetTaxSchemeQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateArInvoiceMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDashDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { uuid } from 'uuidv4'
import * as yup from 'yup'

interface ARInvoiceProps {
  DocDate: string
  TrxDate: string
  DebtorAccountID: string
  Description: string
  Remark: string
  InvoiceID: string
  DocAmt: number
  RefNo: string
  is_einvoice: boolean
}

interface FavInvoiceProps {
  Name: string
}

export const InvoiceStateForm = (props: any) => {
  const { CompanyID, InvoiceID }: any = useParams()
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any
  const [taxEffectiveList, setTaxEffectiveList] = useState<any>()
  const [invoiceItemData, setInvoiceItemData] = useState([])
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(SnackBarContext)
  const [onSub, setSub] = useState(false)
  const [initDocs, setInitDocs] = useState([])
  const [openExitConf, setOpenExitConf] = useState(null)
  const [openFavoriteDialog, setOpenFavoriteDialog] = useState(false)
  const [openFavoriteDeleteDialog, setOpenFavoriteDeleteDialog] = useState(
    false
  )
  const [record, setRecord] = useState(false)
  const [voiceRemark, setVoiceRemark] = useState('')
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [edit, setEdit] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [detailMode, setDetailMode] = useState('')
  const [enableEInvoice, setEnableEInvoice] = useState(false)
  const { formMode } = props

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()

  const {
    anchorEl: anchorElFav,
    setAnchorEl: setAnchorElFav,
    menu: menuFav,
    handleClick: handleClickFav,
    handleClose: handleCloseFav,
  } = useMenuOption()

  const InvoiceFormSchema = yup.object().shape({
    DebtorAccountID: CommonYupValidation.requireField(
      'Debtor Name is required'
    ),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    DocDate: CommonYupValidation.requireField('Doc Date is Required'),
    TrxDate: CommonYupValidation.requireField('Transaction Date is Required'),
    RefNo: CommonYupValidation.requireField('Reference No is Required'),
  })

  const InvoiceItemFormSchema = yup.object().shape({
    BillItemID: CommonYupValidation.requireField('Bill Item is required'),
    CostCentreID: CommonYupValidation.requireField('Department is required'),
    Amount: yup
      .string()
      .required('Amount is required')
      .test('sumZero', 'Amount should not be zero', val => {
        return parseFloat(amtNumStr(val)) !== 0
      }),
    Remark: CommonYupValidation.requireField('Remark is required'),
  })

  const FavInvoiceFormSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
  })

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
    clearErrors,
    reset,
    formState: { isSubmitted },
  } = useForm<ARInvoiceProps>({
    defaultValues: {
      DocDate: editData ? editData?.DocDate : new Date(),
      TrxDate: editData ? editData?.TrxDate : new Date(),
      Remark: editData ? editData?.Remark : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(InvoiceFormSchema),
  })

  const {
    handleSubmit: handleFavSubmit,
    register: favRegister,
    control: favControl,
    errors: favErrors,
    watch: favWatch,
  } = useForm<FavInvoiceProps>({
    mode: 'onSubmit',
    resolver: yupResolver(FavInvoiceFormSchema),
  })

  /* -------------------------------------------- */
  /*                    QUERY                     */
  /* -------------------------------------------- */
  const {
    loading: CompanyLoading,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onCompleted: ({ getCompany }) => {
      if (getCompany[0]?.eInvIntegrated!!) {
        const startDate = new Date(getCompany[0]?.eInvStartDate)
        const endDate = new Date(getCompany[0]?.eInvEndDate)
        const today = new Date()

        if (today >= startDate && today <= endDate) {
          setEnableEInvoice(true)
        }
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: TaxLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: DocDateValidationLoading,
    data: { getDocumentDateValidation } = { getDocumentDateValidation: [] },
  } = useGetDocumentDateValidationQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: DocNumHeaderLoading,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, RefTable: 'AR_Invoice' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: DebtorAccountLoading,
    data: { getDebtorAccount } = { getDebtorAccount: [] },
  } = useGetDebtorAccountQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    loadMasterCOA,
    {
      loading: MasterCOALoading,
      data: { getMasterCOA } = { getMasterCOA: [] },
    },
  ] = useGetMasterCoaLazyQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    fetchEInvoiceCompanyStartDate,
    {
      data: { getEInvoiceCompanyStartDate } = {
        getEInvoiceCompanyStartDate: [],
      },
      loading: GetEInvoiceCompanyStartDateLoading,
    },
  ] = useGetEInvoiceCompanyStartDateLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(`${errorMessage}`)
      setValue('is_einvoice', false)
    },
    onCompleted(data) {
      setValue('is_einvoice', data?.getEInvoiceCompanyStartDate)
    },
  })

  const [term, setTerm] = useState(
    mode === 'edit' ? editData?.DebtorAccount?.CreditTerm : 0
  )
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  const [
    fetchBillItem,
    { loading: BillItemLoading, data: { getBillItem } = { getBillItem: [] } },
  ] = useGetBillItemListingLazyQuery({
    fetchPolicy: 'network-only',
    variables: { orderByAsc: 'Name', RecordStatus: RecordStatus.Active },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: OpenPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const trxDateTimestamp = new Date(watch('TrxDate'))
  const yearTrxDate = trxDateTimestamp.getFullYear()
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`

  const openPeriod1 =
    transferDate <= latestOpenPeriodCheckingDate?.StartDate ||
    transferDate >= latestOpenPeriodCheckingDate?.EndDate

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? true : false
  const checkingYearClose2 = openPeriod2 ? false : true

  const {
    loading: TaxEffectiveDateLoading,
    data: { getTaxEffectiveDate } = { getTaxEffectiveDate: [] },
  } = useGetTaxEffectiveDateQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (getTaxEffectiveDate?.length > 0) {
        let temp = getTaxEffectiveDate?.filter(
          tax =>
            Number(new Date(tax?.Date)) <
              DocDateValidationAfter(
                getDocumentDateValidation?.map(x => x?.MonthsAfter)
              ) &&
            Number(new Date(tax?.Date)) >
              DocDateValidationBefore(
                getDocumentDateValidation?.map(x => x?.MonthsBefore)
              )
        )
        setTaxEffectiveList(temp)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: FavoriteInvoiceLoading,
    data: { getARFavoriteInvoice } = {
      getARFavoriteInvoice: [],
    },
  } = useGetArFavoriteInvoiceQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, UserID: user?.ID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const favNames = getARFavoriteInvoice?.map(fav => fav?.Name)

  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreCodeLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  /* -------------------------------------------- */
  /*                   USEEFECT                   */
  /* -------------------------------------------- */
  useEffect(() => {
    if (formMode === 'edit' && editData) {
      const InvoiceItems = editData?.ARInvoiceItem?.map((el, index) => {
        return {
          InvoiceItemID: el?.InvoiceItemID,
          BillItemName: el?.BillItem?.Name,
          BillItemID: el?.BillItemID,
          CostCentreName: el?.CostCentre?.Name,
          CostCentreCode: el?.CostCentre?.Code,
          CostCentreID: el?.CostCentreID,
          Amount: el?.Amount,
          Sequence: el?.Sequence,
          TaxSchemeID: el?.TaxScheme?.TaxSchemeID,
          TaxCode: el?.TaxScheme?.Code,
          TaxAmt: el?.TaxAmt,
          TaxRate: el?.TaxRate,
          Remark: el?.Remark,
          DocAmt: el?.DocAmt,
          einvoice_classification_code_id: el?.einvoice_classification_code_id,
          ClassificationCode: el?.ClassificationCode?.classificationCode,
          ClassificationDescription: el?.ClassificationCode?.description,
        }
      })
      invoiceItemData.push(...InvoiceItems)
    }
  }, [formMode, editData])

  useEffect(() => {
    if (!DocumentLoading && mode !== 'add') {
      fetchDocuments({ variables: { refID: editData?.InvoiceID } })
    }

    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [mode, editData?.InvoiceID, editData])

  useEffect(() => {
    if (editData?.Attachment) {
      setInitDocs(editData?.Attachment)
    }
  }, [editData])

  const taxScheme: any = getTaxScheme

  useEffect(() => {
    if (!!editData && !!taxScheme) {
      // set tax on edit
      setSelectedTax(
        taxScheme?.find(x => x?.TaxSchemeID === editData?.TaxSchemeID)
      )

      setTaxRate(
        taxScheme
          .filter(x => x?.TaxSchemeID === editData?.TaxSchemeID)[0]
          ?.TaxEffective?.filter(
            x => new Date(x?.Date) <= new Date(taxEffectiveDate)
          )
          .reduce((a, b) => {
            return new Date(a.Date) > new Date(b.Date) ? a : b
          })?.Rate
      )
    }
  }, [editData?.InvoiceItemID, taxScheme])

  /* -------------------------------------------- */
  /*                  FUNCTION                    */
  /* -------------------------------------------- */
  let statusInput = formMode => {
    let temp
    switch (formMode) {
      case 'submit':
        temp = ApprovalStatus.Submit
        break
      case 'draft':
        temp = ApprovalStatus.Active
        break
      case 'approve':
        temp = ApprovalStatus.Approved
        break
      case 'reject':
        temp = ApprovalStatus.Rejected
        break
    }
    return temp
  }

  let totalAmount = invoiceItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.DocAmt),
    0
  )

  let totalTaxAmt = invoiceItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.TaxAmt),
    0
  )

  let amtBeforeTax = totalAmount - totalTaxAmt

  let calcTotal = items => {
    return items?.reduce(
      (total, currentValue) => (total = total + currentValue.DocAmt),
      0
    )
  }

  const handleAddFavorite = () => {
    setOpenFavoriteDialog(true)
  }

  const handleSelectFavorite = data => {
    const tempFavItemArr = data?.FavoriteInvoiceItem?.map(x => {
      return {
        InvoiceItemID: uuid(),
        BillItemID: x?.BillItemID,
        RevenueCode: x?.MasterCOA?.Code,
        RevenueName: x?.MasterCOA?.Name,
        CostCentreID: x?.CostCentreID,
        CostCentreCode: x?.CostCentre?.Code,
        TaxSchemeID: x?.TaxSchemeID,
        TaxCode: x?.TaxScheme?.Code,
        TaxRate: parseFloat(x?.TaxRate),
        TaxAmt: parseFloat(x?.TaxAmt),
        Amount: parseFloat(x?.Amount),
        DocAmt: parseFloat(x?.DocAmt),
        Sequence: x?.Sequence,
        Remark: x?.Remark,
      }
    })
    setValue('DebtorAccountID', data?.DebtorAccountID)
    setValue('Remark', data?.Remark)
    setValue('DocAmt', data?.DocAmt)
    setValue('Description', data?.Description)
  }

  const updateNewInvoiceItem = (newDate, TEList) => {
    const arr = []
    invoiceItemData?.map(x => {
      let NewTax =
        getTaxEffectiveDate?.filter(
          j =>
            j?.TaxSchemeID === x?.TaxSchemeID &&
            new Date(j?.Date) <= new Date(newDate)
        )?.length > 0
          ? getTaxEffectiveDate
              ?.filter(
                j =>
                  j?.TaxSchemeID === x?.TaxSchemeID &&
                  new Date(j?.Date) <= new Date(newDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })
          : null
      if (NewTax !== null) {
        arr.push({
          InvoiceItemID: x?.InvoiceItemID,
          BillItemID: x?.BillItemID,
          RevenueCode: x?.RevenueCode,
          RevenueName: x?.RevenueName,
          Amount: x?.Amount,
          TaxSchemeID: x?.BillItem?.TaxSchemeID,
          TaxCode: x?.TaxCode,
          TaxRate: NewTax?.Rate,
          TaxAmt: x?.Amount * (NewTax?.Rate / 100),
          DocAmt: x?.Amount + x?.Amount * (NewTax?.Rate / 100),
          CostCentreID: x?.CostCentreID,
          CostCentreCode: x?.CostCentreCode,
          Remark: x?.Remark,
        })
      }
    })
  }

  const [selectedTax, setSelectedTax] = useState(null)
  const [taxRate, setTaxRate] = useState(0.0)
  const [taxAmt, setTaxAmt] = useState(
    !!menu?.obj && menu?.obj?.InvoiceItemID ? editData?.TaxAmt : 0
  )
  const [totalAmt, setTotalAmt] = useState(0.0)
  const [amount, setAmount] = useState(0.0)
  const [cost, setCost] = useState(
    mode === 'edit' ? editData?.DocAmt - editData?.TaxAmt : 0
  )
  const [directSend, setDirectSend] = useState(
    mode === 'edit' ? editData?.einvoice_directsend : false
  )

  const taxEffectiveDate: any =
    !!watch('DocDate') && watch('DocDate').toString() !== 'Invalid Date'
      ? new Date(new Date(watch('DocDate')).getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      : null

  const isEInvoiceDateValidated = () => {
    return !!(
      new Date(watch('DocDate')) < new Date(curCompany[0]?.eInvStartDate) ||
      new Date(watch('DocDate')) > new Date(curCompany[0]?.eInvEndDate)
    )
      ? true
      : false
  }

  /* -------------------------------------------- */
  /*         CREATE & UPDATE MUTATION             */
  /* -------------------------------------------- */
  const [
    createARInvoice,
    { loading: createARInvoiceLoading, called: createARInvoiceCalled },
  ] = useCreateArInvoiceMutation({
    onCompleted: ({ createARInvoice }) => {
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-receivable/${CompanyID}/invoice/${createARInvoice?.InvoiceID}/preview`,
          })
        } else {
          history.push({ pathname: `/account-receivable/${CompanyID}/invoice` })
        }
      }, 500)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    updateARInvoice,
    { loading: updateARInvoiceLoading, called: updateARInvoiceCalled },
  ] = useUpdateArInvoiceMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-receivable/${CompanyID}/invoice/${InvoiceID}/preview`,
          })
        } else {
          history.push({ pathname: `/account-receivable/${CompanyID}/invoice` })
        }
      }, 500)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
    },
  })

  const [
    createArFavoriteInvoice,
    { loading: CreateFavoriteInvoiceLoading },
  ] = useCreateArFavoriteInvoiceMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      reset()
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setOpenFavoriteDialog(false)
    },
  })

  /* -------------------------------------------- */
  /*                  ATTACHMENT                  */
  /* -------------------------------------------- */
  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    fetchDocuments,
    { loading: DocumentLoading, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  /* -------------------------------------------- */
  /*                   DELETE                     */
  /* -------------------------------------------- */
  const onDeleteInvoiceItem = index => {
    invoiceItemData.splice(index, 1)
  }
  const [
    deleteARFavoriteInvoice,
    { loading: DeleteFavoriteInvoiceLoading },
  ] = useDeleteArFavoriteInvoiceMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOpenFavoriteDeleteDialog(false)
    },
    variables: { FavoriteInvoiceID: menuFav?.ID },
  })

  /* -------------------------------------------- */
  /*                  ONSUBMIT                    */
  /* -------------------------------------------- */
  const onSubmit = (data, status) => {
    if (onSub === false) {
      setSub(true)
      if (formMode === 'add') {
        if (
          getDocumentNumberHeader?.filter(x => x?.RefTable === 'AR_Invoice')
            ?.length === 0
        ) {
          setOpenSnackBar(true)
          setSnackBarMsg(SystemMsgs.errorNumberingStructure())
        } else {
          //add the data with GraphQL mutation
          createARInvoice({
            variables: {
              input: {
                InvoiceID: InvoiceID,
                CompanyID: CompanyID,
                DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
                TransactionDate: formatDashDate(
                  new Date(data?.TrxDate)?.toISOString()
                ),
                Remark: data?.Remark,
                ApprovalStatus: statusInput(status),
                Attachment: files,
                DebtorAccountID: data?.DebtorAccountID,
                DocAmt: totalAmount ?? 0,
                Description: data?.Description,
                CreditTerm: term,
                DueDate: new Date(
                  dueDateChecker(data?.DocDate, term)
                ).toISOString(),
                RefNo: data?.RefNo,
                DocAmtBeforeTax: amtBeforeTax ?? 0,
                is_einvoice: watch('is_einvoice'),
                einvoice_status: !!watch('is_einvoice')
                  ? 'NEW'
                  : 'NOT_EINVOICE',
                einvoice_directsend: directSend,
              },
              itemInput: invoiceItemData?.map(x => {
                return {
                  BillItemID: x?.BillItemID,
                  Amount: parseFloat(x?.Amount),
                  TaxSchemeID: x?.TaxSchemeID,
                  TaxRate: parseFloat(x?.TaxRate),
                  TaxAmt: parseFloat(x?.TaxAmt),
                  DocAmt: parseFloat(x?.DocAmt),
                  CostCentreID: x?.CostCentreID,
                  Remark: x?.Remark,
                  Sequence: x?.Sequence,
                  einvoice_classification_code_id:
                    x?.einvoice_classification_code_id ?? null,
                }
              }),
            },
            refetchQueries: [
              {
                query: GetArInvoiceDocument,
                variables: {
                  CompanyID: CompanyID,
                  StatusArr: ['ACTIVE'],
                },
              },
              {
                query: DocumentListingDocument,
                variables: {
                  refTable: 'S_Attachment',
                  refID: editData?.InvoiceID,
                },
              },
            ],
          })
        }
      } else if (formMode !== 'add') {
        //update the data
        updateARInvoice({
          variables: {
            input: {
              InvoiceID: InvoiceID,
              CompanyID: CompanyID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              Remark: data?.Remark,
              ApprovalStatus: statusInput(status),
              Attachment: files,
              DebtorAccountID: data?.DebtorAccountID,
              DocAmt: totalAmount ?? 0,
              Description: data?.Description,
              CreditTerm: term,
              DueDate: new Date(
                dueDateChecker(data?.DocDate, term)
              ).toISOString(),
              RefNo: data?.RefNo,
              DocAmtBeforeTax: amtBeforeTax ?? 0,
              is_einvoice: !!enableEInvoice ? watch('is_einvoice') : false,
              einvoice_status: !!watch('is_einvoice') ? 'NEW' : 'NOT_EINVOICE',
              einvoice_directsend: directSend,
            },
            itemInput: invoiceItemData?.map(x => {
              return {
                BillItemID: x?.BillItemID,
                Amount: parseFloat(x?.Amount),
                TaxSchemeID: x?.TaxSchemeID,
                TaxRate: parseFloat(x?.TaxRate),
                TaxAmt: parseFloat(x?.TaxAmt),
                DocAmt: parseFloat(x?.DocAmt),
                CostCentreID: x?.CostCentreID,
                Remark: x?.Remark,
                Sequence: x?.Sequence,
                einvoice_classification_code_id:
                  x?.einvoice_classification_code_id ?? null,
              }
            }),
          },
          refetchQueries: [
            {
              query: GetArInvoicebyStatusDocument,
              variables: { CompanyID, StatusArr: ['ACTIVE'] },
            },
            {
              query: DocumentListingDocument,
              variables: {
                refTable: 'S_Attachment',
                refID: editData?.InvoiceID,
              },
            },
          ],
        })
      }
    }
  }

  const favRefetchQuery = [
    {
      query: GetArFavoriteInvoiceDocument,
      variables: { CompanyID, UserID: user?.ID },
    },
  ]

  const onSubmitFavorite = data => {
    const tempFav = {
      Name: data?.Name,
      UserID: user?.ID,
      InvoiceID: !!getValues('InvoiceID') ? getValues('InvoiceID') : null,
      Remark: !!getValues('Remark') ? getValues('Remark') : null,
      DebtorAccountID: !!getValues('DebtorAccountID')
        ? getValues('DebtorAccountID')
        : null,
      Description: !!getValues('Description') ? getValues('Description') : null,
      DocAmt: !!getValues('DocAmt') ? getValues('DocAmt') : 0,
    }
    const invItem: any[] = invoiceItemData
    const tempFavItem = invItem?.map(x => {
      return {
        BillItemID: x?.BillItemID,
        CostCentreID: x?.CostCentreID,
        TaxSchemeID: x?.TaxSchemeID,
        TaxRate: parseFloat(x?.TaxRate),
        TaxAmt: parseFloat(x?.TaxAmt),
        Amount: parseFloat(amtNumStr(x?.Amount)),
        DocAmt: parseFloat(amtNumStr(x?.DocAmt)),
        Sequence: x?.Sequence,
        Remark: x?.Remark,
      }
    })
    createArFavoriteInvoice({
      variables: {
        ARFavoriteInvoiceInput: tempFav,
        ARFavoriteInvoiceItemInput: tempFavItem,
      },
      refetchQueries: favRefetchQuery,
    })
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      itemArr: invoiceItemData,
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
      itemSuffixID: 'InvoiceItemID',
      itemTableName: 'ARInvoiceItem',
      formMode: formMode,
    })

  const { handlePermDisabled } = usePermissionChecker()

  const createUpdateLoading = editData
    ? updateARInvoiceLoading
    : createARInvoiceLoading
  const createUpdateCalled = editData
    ? updateARInvoiceCalled
    : createARInvoiceCalled

  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateLoading && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      checkingYearClose1 || checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableInvoicesDraft,
          }),
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateLoading && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      !!errors?.DocDate ||
      !!errors?.TrxDate ||
      !!errors?.RefNo ||
      !!errors?.DebtorAccountID ||
      !!errors?.Description ||
      checkingYearClose1 ||
      checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableInvoicesUpdate,
          }),
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
      setIsSubmit(true)
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      calcTotal(invoiceItemData) === 0 ||
      calcTotal(invoiceItemData) === undefined ||
      checkingYearClose1 ||
      checkingYearClose2
        ? true
        : false,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  return (
    <>
      {TaxLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {BillItemLoading && <Loading />}
      {DocumentLoading && <Loading />}
      {MasterCOALoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {DocNumHeaderLoading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {FavoriteInvoiceLoading && <Loading />}
      {TaxEffectiveDateLoading && <Loading />}
      {DocDateValidationLoading && <Loading />}
      {OpenPeriodCheckDateLoading && <Loading />}
      {CreateFavoriteInvoiceLoading && <Loading />}
      {DeleteFavoriteInvoiceLoading && <Loading />}
      {GetEInvoiceCompanyStartDateLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            history.push({
              pathname: `/account-receivable/${CompanyID}/invoice`,
            })
          }
        }}
        smTitle={'Accounts Receivable'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Accounts Receivable Main Menu' },
          { name: 'Accounts Receivable Submenu' },
          { name: 'Invoice' },
          { name: 'Invoices', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : formMode === 'edit'
                ? 'Edit'
                : 'Draft',
            current: true,
          },
        ]}
      />

      <ContentWrapper float>
        <CardContents
          section={{
            header: {
              title: 'Invoice',
              icon: formMode === 'add' && (
                <FavoriteMenu
                  options={getARFavoriteInvoice}
                  funcLabel={'Add Favorite'}
                  addFavFunc={handleAddFavorite}
                  selectFavFunc={handleSelectFavorite}
                  optionIDName={'FavoriteInvoiceID'}
                  setOpenFavoriteDeleteDialog={setOpenFavoriteDeleteDialog}
                  anchorEl={anchorElFav}
                  setAnchorEl={setAnchorElFav}
                  handleClose={handleCloseFav}
                  handleClickDelete={handleClickFav}
                />
              ),
            },
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocDate"
              label="Invoice Date"
              control={control}
              onChange={(date: Date | null) => {}}
              onAccept={(date: Date | null) => {
                updateNewInvoiceItem(date, taxEffectiveList)

                if (
                  date < new Date(curCompany[0]?.eInvStartDate) ||
                  date > new Date(curCompany[0]?.eInvEndDate)
                ) {
                  setEnableEInvoice(false)
                  setValue('is_einvoice', false)
                } else {
                  setEnableEInvoice(true)
                  setValue('is_einvoice', true)
                }
              }}
              format="dd/MM/yyyy"
              value={watch(formMode === 'add' ? new Date() : editData?.DocDate)}
              margin="dense"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData ? editData?.DocDate : new Date()}
              showTodayButton
              className="left"
              disabled={formMode === 'approve-reject'}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose1
                  ? 'Financial Period already closed'
                  : errors?.DocDate?.message
              }
              error={errors?.DocDate || checkingYearClose1 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxDate"
              required
              label="Transaction Date"
              control={control}
              format="dd/MM/yyyy"
              margin="dense"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose2
                  ? 'Financial Period already closed'
                  : errors?.TrxDate?.message
              }
              error={errors?.TrxDate || checkingYearClose2 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            defaultValue={editData?.RefNo}
            disabled={mode === 'approve-reject'}
          />

          {!DebtorAccountLoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = getDebtorAccount?.filter(
                  x => x?.DebtorAccountID === editData?.DebtorAccountID
                )[0]
                return (
                  <Autocomplete
                    options={
                      getDebtorAccount?.sort((a, b) => {
                        return a?.DebtorName?.localeCompare(b?.DebtorName)
                      }) || []
                    }
                    getOptionLabel={option => `${option?.DebtorName}`}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('DebtorAccountID', newValue?.DebtorAccountID)
                      setTerm(newValue?.CreditTerm)
                      newValue?.einvoice_send_individually === true
                        ? setDirectSend(true)
                        : setDirectSend(false)
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.DebtorName}</span>
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.DebtorAccountID?.message}
                            error={errors?.DebtorAccountID ? true : false}
                            label="Debtor Name"
                            style={{ width: '100%' }}
                            margin="dense"
                            required
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Debtor Name"
              name="DebtorAccountID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="dense"
              ref={register}
              helperText={errors?.DebtorAccountID?.message}
              error={errors?.DebtorAccountID ? true : false}
              defaultValue={editData?.DebtorAccountID}
              required
              disabled={mode === 'approve-reject'}
            />
          )}

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            ref={register}
            defaultValue={editData?.Description}
            disabled={mode === 'approve-reject'}
          />

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />
          {/* e-Invoice Implementation */}
          {!!enableEInvoice && (
            <div
              className="content-wrap full"
              style={{
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <span className="flex-space" style={{ alignContent: 'center' }}>
                e-Invoice
              </span>
              <Switch
                name="is_einvoice"
                color="primary"
                inputRef={register()}
                defaultChecked={
                  formMode === 'edit' ? editData?.is_einvoice : enableEInvoice
                }
                onChange={event => {
                  if (event.target.checked === true) {
                    fetchEInvoiceCompanyStartDate({
                      variables: {
                        CompanyID: CompanyID,
                      },
                    })
                  }
                }}
                disabled={isEInvoiceDateValidated()}
              />
            </div>
          )}

          <div style={{ width: '100%', marginTop: '24px' }}>
            <FileUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              name="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </div>
        </CardContents>

        <ARItemDetail
          titleLabel={'Invoice'}
          itemData={invoiceItemData}
          setOpenDialog={setOpenDialog}
          fetchBillItem={fetchBillItem}
          fetchCostCentre={fetchCostCentre}
          resetMenu={resetMenu}
          setSelectedTax={setSelectedTax}
          setDetailMode={setDetailMode}
          formMode={formMode}
          detailMode={detailMode}
          getBillItem={getBillItem}
          getCostCentre={getCostCentre}
          handleClick={handleClick}
          keyItemID={'InvoiceItemID'}
          isEinvoice={!!watch('is_einvoice')}
          loadMasterCOA={loadMasterCOA}
        />

        <div style={{ marginBottom: '50px' }} />
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            fetchBillItem()
            setEdit(true)
            setOpenDialog(true)
          }}
        >
          <span>Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteInvoiceItem(menu?.index)
          }}
        >
          <span>Delete</span>
        </MenuItem>
      </Menu>

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-receivable/${CompanyID}/invoice`)
          localStorage.removeItem('invoice')
          localStorage.removeItem('invoiceItem')
        }}
      />
      <AddFavoriteDialog
        openFavoriteDialog={openFavoriteDialog}
        setOpenFavoriteDialog={setOpenFavoriteDialog}
        favRegister={favRegister}
        favControl={favControl}
        favNames={favNames}
        favErrors={favErrors}
        favWatch={favWatch}
        dialogTitle={'Invoice Detail'}
        handleFavSubmit={handleFavSubmit}
        onSubmitFavorite={onSubmitFavorite}
      />

      <DeleteFavoriteDialog
        openFavoriteDeleteDialog={openFavoriteDeleteDialog}
        setOpenFavoriteDeleteDialog={setOpenFavoriteDeleteDialog}
        dialogTitle={'Favorite Invoice Entry'}
        menuFav={menuFav}
        deleteMutation={deleteARFavoriteInvoice}
        favRefetchQuery={favRefetchQuery}
        favID={menuFav?.ID}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-receivable/${CompanyID}/invoice`)
          localStorage.removeItem('invoice')
          localStorage.removeItem('invoiceItem')
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />

      {footerOptions?.length > 0 && <Footer options={[...footerOptions]} />}

      {!BillItemLoading && (
        <ARItemDialog
          CompanyID={CompanyID}
          title={'Invoice Detail'}
          fromAP={true}
          openItemDialog={openDialog}
          setOpenItemDialog={setOpenDialog}
          watch={watch}
          menu={menu}
          BillItemLoading={BillItemLoading}
          fetchBillItem={fetchBillItem}
          getBillItem={getBillItem}
          isEdit={edit}
          itemData={invoiceItemData}
          selectedTax={selectedTax}
          setSelectedTax={setSelectedTax}
          detailMode={detailMode}
          setDetailMode={setDetailMode}
          resetMenu={resetMenu}
          primaryItemKey="InvoiceItemID"
          is_einvoice={!!enableEInvoice ? enableEInvoice : false}
        />
      )}
      <TooltipAmountFooter data={invoiceItemData} module={'invoice-draft'} />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
