import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { AttachFile } from '@material-ui/icons'
import PrintIcon from '@material-ui/icons/Visibility'
import { AttachmentDialog } from 'components/Dialog/AttachmentDialog'
import { useDocumentListingQuery } from 'generated/graphql'
import { AttachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
}

export const AssetsRegistrationDetailContent = (props: DetailProps) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { listEl, documentListing, listStatus, userList, mode } = props
  const [openDoc, setOpenDoc] = useState<boolean>(false)
  const { menu } = useMenuOption()

  const {
    loading: DocumentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: listEl?.JournalProcessingID,
    },
  })

  return (
    <>
      {DocumentLoading && <Loading />}
      <div className="content-wrap left ">
        <div className="desc " style={{ color: 'grey' }}>
          Asset Name
        </div>
        <div className="mdDesc text-noflow">{listEl?.AssetName}</div>
      </div>

      <div className="content-wrap right">
        <span
          style={{ float: 'right' }}
          onClick={() => {
            history.push({
              pathname: `/fixed-assets/${CompanyID}/assets-registration/${listEl?.AssetsRegistrationID}/preview`,
              state: { ...listEl, mode: 'detail' },
            })
          }}
        >
          <PrintIcon
            style={{
              fontSize: 'medium',
              padding: '4px 10px 2px 0px',
            }}
          />
        </span>
        {documentListing && documentListing?.length > 0 && (
          <span
            style={{ float: 'right', color: '#000' }}
            onClick={e => {
              setOpenDoc(true)
            }}
          >
            <AttachFile
              className="mdDesc"
              style={{ fontSize: 12, color: 'orange' }}
            />

            <span
              className="mdDesc"
              style={{
                float: 'right',
                color: 'orange',
                textDecoration: 'underline',
                paddingRight: '5px',
                paddingTop: '4px',
              }}
            >
              {documentListing?.length}
            </span>
          </span>
        )}
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Registration Date
        </div>
        <div className="mdDesc"> {listEl?.RegistrationDate} </div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Start Using Date
        </div>
        <div className="mdDesc"> {listEl?.StartUsingDate} </div>
      </div>

      <div className="content-wrap full ">
        <div className="desc " style={{ color: 'grey' }}>
          Asset Category
        </div>
        <div className="mdDesc text-noflow">{listEl?.AssetCategory}</div>
      </div>

      <div className="content-wrap full ">
        <div className="desc " style={{ color: 'grey' }}>
          Source
        </div>
        <div className="mdDesc text-noflow">{listEl?.Source}</div>
      </div>

      <div className="content-wrap full ">
        <div className="desc " style={{ color: 'grey' }}>
          Base Original Amount
        </div>
        <div className="mdDesc text-noflow">{listEl?.BaseOriginalAmount}</div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Unit Price
        </div>
        <div className="mdDesc"> {listEl?.UnitPrice} </div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Quantity
        </div>
        <div className="mdDesc"> {listEl?.Quantity} </div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Residual (%)
        </div>
        <div className="mdDesc"> {listEl?.Residual} </div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Residual Value
        </div>
        <div className="mdDesc"> {listEl?.ResidualValue} </div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Net Amount
        </div>
        <div className="mdDesc"> {listEl?.Residual} </div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          UOM
        </div>
        <div className="mdDesc"> {listEl?.UOM} </div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Model No.
        </div>
        <div className="mdDesc"> {listEl?.ModelNo} </div>
      </div>

      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          Reference No.
        </div>
        <div className="mdDesc"> {listEl?.ReferenceNo} </div>
      </div>

      <div className="content-wrap full ">
        <div className="desc " style={{ color: 'grey' }}>
          Remarks
        </div>
        <div className="mdDesc text-noflow">{listEl?.Remarks}</div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}
      <AttachmentDialog
        title="Assets Registration"
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        AttachmentDetailView={AttachmentDetailView}
        DocumentListing={DocumentListing}
      />
    </>
  )
}
