import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { SortDialog } from '@ifca-root/react-component/src/components/Sort/SortDialog'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'
import { SwapVert, Tune } from '@material-ui/icons'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import {
  useGetJournalProcessingbyStatusListingLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { JournalEntryList } from './JournalList/JournalEntryList'
import { JournalPostingList } from './JournalList/JournalPostingList'

export const JournalMain = (props: any) => {
  useEffect(() => {
    document.title = 'GL-Journal'
  }, [])

  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))
  const [openExitConf, setOpenExitConf] = useState(null)
  const [TotalLength, setTotalLength] = useState(0)
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)
  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    loadJournalProcessing,
    {
      loading: JournalProcessingbyStatusLoading,
      data: { getJournalProcessingbyStatusListing } = {
        getJournalProcessingbyStatusListing: {},
      },
      fetchMore,
    },
  ] = useGetJournalProcessingbyStatusListingLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getJournalProcessingbyStatusListing }) => {
      if (getJournalProcessingbyStatusListing?.Data?.length > 0) {
        setOriginalListing(
          [...filteredList, ...getJournalProcessingbyStatusListing?.Data],
          v => {
            v['DocDateFormatted'] = formatDate(v.JournalDate)
          }
        )
        setTotalLength(getJournalProcessingbyStatusListing?.TotalLength)
      }
    },
  })

  useEffect(() => {
    setOriginalListing([])
    loadJournalProcessing({
      variables: {
        CompanyID: CompanyID,
        StatusArr: [listStatus],
        take: 30,
        skip: 0,
        searchValue:
          getSearch?.name !== '' || getSearch?.name === undefined
            ? undefined
            : getSearch?.name,
        sortField: selectedField === null ? undefined : selectedField,
        sortOrder: sortOrder === null ? undefined : sortOrder,
      },
    })
  }, [listStatus])

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */

  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()

  /* -------------------------------------------- */
  /*                   EXIT CONF                  */
  /* -------------------------------------------- */

  const hasChanges = () => {
    if (postIDs?.size === 0) return false
    else return true
  }

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Doc Date',
      key: 'DocDate',
    },
  })

  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadJournalProcessing({
          variables: {
            CompanyID: CompanyID,
            StatusArr: [listStatus],
            take: 30,
            skip: 0,
            searchValue: change,
            sortField: selectedField === null ? undefined : selectedField,
            sortOrder: sortOrder === null ? undefined : sortOrder,
          },
        })
      }, 1000)
    )
  }

  //*******************************************************//
  //-------------------SORTING DATA------------------------//
  //*******************************************************//

  const sortJPDataDialog = [
    { ID: '1', value: 'DocNo', Name: 'Document No' },
    { ID: '2', value: 'RefNo', Name: 'Reference No' },
    { ID: '3', value: 'DocDate', Name: 'Journal Date' },
    { ID: '4', value: 'Description', Name: 'Description' },
    { ID: '5', value: 'DocAmt', Name: 'Amount' },
  ]

  const [openSortDialog, setOpenSortDialog] = useState(false)
  const [selectedField, setSelectedField] = useState('')
  const [sortOrder, setSortOrder] = useState(null)

  const handleClickSort = () => {
    setOpenSortDialog(true)
  }

  const handleListStatusChange = newStatus => {
    setListStatus(newStatus)
    setSelectedField(null)
    setSortOrder(null)
  }

  const handleSortConfirm = sortOption => {
    const { selectedField, sortOrder } = sortOption
    setOriginalListing([])
    loadJournalProcessing({
      variables: {
        CompanyID: CompanyID,
        StatusArr: [listStatus],
        take: 30,
        skip: 0,
        searchValue:
          getSearch?.name === '' || getSearch?.name === undefined
            ? undefined
            : getSearch?.name,
        sortField: selectedField,
        sortOrder: sortOrder,
      },
    })
  }

  return (
    <>
      {UserLoading && <Loading />}
      {JournalProcessingbyStatusLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true && listStatus === 'SUBMIT'
            ? setOpenExitConf(true)
            : history.push(`/general-ledger/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }} //<- back button action
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'Home Journal' },
          { name: 'Journal Entries', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            changeDelay(e.target.value)
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
            loadJournalProcessing({
              variables: {
                CompanyID: CompanyID,
                StatusArr: [listStatus],
                take: 30,
                skip: 0,
                searchValue:
                  getSearch?.name !== '' || getSearch?.name === undefined
                    ? undefined
                    : getSearch?.name,
                sortField: selectedField,
                sortOrder: sortOrder,
              },
            })
          }}
          isDefaultValue={!!getSearch}
          option={{
            icon: <Tune />,
            onClick: openFilterDialog,
          }}
          sort={{
            icon: <SwapVert />,
            onClick: handleClickSort,
          }}
        />

        <SortDialog
          openSortDialog={openSortDialog}
          setOpenSortDialog={setOpenSortDialog}
          title="Sort Options"
          fieldList={sortJPDataDialog}
          onSortConfirm={handleSortConfirm}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />

        {filterDialog}

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={handleListStatusChange}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') && (
        <JournalEntryList
          filteredList={filteredList}
          handleFilterDate={handleFilterDate}
          listLoading={JournalProcessingbyStatusLoading}
          listStatus={listStatus}
          userList={getUsersByAccountAndSoftware}
          fetchMore={loadJournalProcessing}
          setOriginalListing={setOriginalListing}
          selectedField={selectedField}
          sortOrder={sortOrder}
        />
      )}
      {listStatus === 'SUBMIT' && (
        <JournalPostingList
          list={getJournalProcessingbyStatusListing}
          filteredList={filteredList}
          handleFilterDate={handleFilterDate}
          listLoading={JournalProcessingbyStatusLoading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing} // pass to set new original listing
          loadData={loadJournalProcessing}
          selectedField={selectedField}
          sortOrder={sortOrder}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/general-ledger/${CompanyID}`)
        }}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
