import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import {
  useGetCompanyNameQuery,
  useGetCostCentreQuery,
} from 'generated/graphql'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { uuid } from 'uuidv4'

interface UsedDepartmentProps {
  CostCentreID: string
  Quantity: number
  UOMID: string
  LocationID: string
}

const defaultVal: any = {
  CostCentreID: '',
  Quantity: 0,
  UOMID: '',
  LocationID: '',
}

export const AssetsRegisterUsedDepartment = (props: any) => {
  const {
    openUsedDepartment,
    setOpenUsedDepartment,
    usedDepartmentData,
    CompanyID,
    UsedDepartmentID,
    formMode,
    detailMode,
    menu,
    setFetchUOMID,
    fetchUOMID,
    getUOM,
  } = props

  const UOMCode = getUOM?.find(x => x?.UOMID === fetchUOMID)?.Code

  let form, mode
  switch (detailMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Edit'
      mode = 'edit'
  }

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    reset,
    setError,
    getValues,
    formState,
    watch,
    clearErrors,
  } = useForm<UsedDepartmentProps>({
    defaultValues: {
      CostCentreID:
        detailMode === 'new' || detailMode === 'add'
          ? undefined
          : menu?.obj?.CostCentreID ?? '',
      Quantity:
        detailMode === 'new' || detailMode === 'add'
          ? 0
          : menu?.obj?.Quantity ?? 0,
      UOMID:
        detailMode === 'new' || detailMode === 'add'
          ? undefined
          : menu?.obj?.UOMID ?? '',
      LocationID:
        detailMode === 'new' || detailMode === 'add'
          ? undefined
          : menu?.obj?.LocationID ?? '',
    },
    mode: 'onSubmit',
  })

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [defaultCostCentre, loadDefaultCostCentre] = useState(null)
  const [usedQty, setUsedQty] = useState(
    amtNumStr(UsedDepartmentID && usedDepartmentData?.Quantity) ?? 0
  )
  const [countItem, setCountItem] = useState(0)

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */
  //Company
  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: data => {
      loadDefaultCostCentre(data?.getCompany[0]?.DefaultCostCentre)
    },
  })

  const {
    loading: CostCentreLoading,
    error: CostCentreErrors,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      IsLastNode: true,
    },
  })

  const dummyDataLocation = [
    { LocationID: '1', Name: 'Office', Code: 'LC1', Activated: 'Activated' },
    {
      LocationID: '2',
      Name: 'SiteOffice',
      Code: 'LC2',
      Activated: 'Activated',
    },
  ]

  /* -------------------------------------------- */
  /*                  USE EFFECT                  */
  /* -------------------------------------------- */

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  const handleQtyChange = event => {
    setUsedQty(event.value)
  }

  const onSubmit = (data, mode, index) => {
    if (detailMode === 'add') {
      usedDepartmentData.push({
        Sequence: usedDepartmentData?.length + 1,
        AssetsRegistrationID: data?.AssetsRegistrationID,
        UsedDepartmentID: uuid(),
        Quantity: parseFloat(amtNumStr(data?.Quantity)),
        CostCentreID: data.CostCentreID,
        CostCentreCode:
          getCostCentre.filter(
            ccc => ccc?.CostCentreID === data.CostCentreID
          )[0]?.Code ?? defaultCostCentre.Code,
        CostCentreName:
          getCostCentre.filter(
            ccc => ccc?.CostCentreID === data.CostCentreID
          )[0]?.Name ?? defaultCostCentre.Name,
        LocationID: data.LocationID,
        LocationCode: dummyDataLocation.filter(
          ccc => ccc?.LocationID === data.LocationID
        )[0]?.Code,
        LocationName: dummyDataLocation.filter(
          ccc => ccc?.LocationID === data.LocationID
        )[0]?.Name,
        LocationActivated: dummyDataLocation.filter(
          ccc => ccc?.LocationID === data.LocationID
        )[0]?.Activated,
        UOMID: data?.UOMID,
      })
    } else if (detailMode === 'edit' && formMode === 'edit') {
      let temp = {
        Sequence: usedDepartmentData?.Sequence ?? index + 1,
        AssetsRegistrationID: data?.AssetsRegistrationID,
        UsedDepartmentID: data?.UsedDepartmentID,
        Quantity: !!parseFloat(amtNumStr(data?.Quantity))
          ? parseFloat(amtNumStr(data?.Quantity))
          : null,
        CostCentreID: data?.CostCentreID,
        CostCentreCode:
          getCostCentre.filter(
            ccc => ccc?.CostCentreID === data.CostCentreID
          )[0]?.Code ?? defaultCostCentre.Code,
        CostCentreName:
          getCostCentre.filter(
            ccc => ccc?.CostCentreID === data.CostCentreID
          )[0]?.Name ?? defaultCostCentre.Name,
        LocationID: data.LocationID,
        LocationCode: dummyDataLocation.filter(
          ccc => ccc?.LocationID === data.LocationID
        )[0]?.Code,
        LocationName: dummyDataLocation.filter(
          ccc => ccc?.LocationID === data.LocationID
        )[0]?.Name,
        LocationActivated: dummyDataLocation.filter(
          ccc => ccc?.LocationID === data.LocationID
        )[0]?.Activated,
        UOMID: data?.UOMID,
      }

      usedDepartmentData?.splice(index, 1, temp)
    } else {
      usedDepartmentData[index] = {
        Sequence: usedDepartmentData?.Sequence ?? index + 1,
        AssetsRegistrationID: data?.AssetsRegistrationID,
        UsedDepartmentID: data?.UsedDepartmentID,
        Quantity: !!parseFloat(amtNumStr(data?.Quantity))
          ? parseFloat(amtNumStr(data?.Quantity))
          : null,
        CostCentreID: data?.CostCentreID,
        CostCentreCode:
          getCostCentre.filter(
            ccc => ccc?.CostCentreID === data.CostCentreID
          )[0]?.Code ?? defaultCostCentre.Code,
        CostCentreName:
          getCostCentre.filter(
            ccc => ccc?.CostCentreID === data.CostCentreID
          )[0]?.Name ?? defaultCostCentre.Name,
        LocationID: data.LocationID,
        LocationCode: dummyDataLocation.filter(
          ccc => ccc?.LocationID === data.LocationID
        )[0]?.Code,
        LocationName: dummyDataLocation.filter(
          ccc => ccc?.LocationID === data.LocationID
        )[0]?.Name,
        LocationActivated: dummyDataLocation.filter(
          ccc => ccc?.LocationID === data.LocationID
        )[0]?.Activated,
        UOMID: data?.UOMID,
      }
    }

    if (mode === 'new') {
      reset(defaultVal)
      setCountItem(countItem + 1)
      setOpenUsedDepartment(false)
      setOpenUsedDepartment(true)
    } else {
      setOpenUsedDepartment(false)
    }
  }

  return (
    <>
      {CompanyLoading && <Loading />}

      <CommonDialog
        fullWidth={true}
        open={openUsedDepartment}
        onClose={() => {
          setOpenUsedDepartment(false)
        }}
        sections={{
          header: {
            title: 'Used Department',
            rightText: detailMode === 'add' ? 'New' : 'Edit',
          },
          body: () => (
            <>
              <CardContents>
                {!CostCentreLoading && (
                  <Controller
                    render={({ value, onChange }) => {
                      const editCC = getCostCentre
                        ?.sort((a, b) => {
                          return a.Code.localeCompare(b.Code)
                        })
                        ?.filter(
                          cc => cc?.CostCentreID === watch('CostCentreID')
                        )[0]
                      const selectedCC = getCostCentre
                        ?.sort((a, b) => {
                          return a.Code.localeCompare(b.Code)
                        })
                        ?.filter(cc => cc?.CostCentreID === value)[0]
                      return (
                        <Autocomplete
                          options={getCostCentre || []}
                          getOptionLabel={option =>
                            option ? `${option?.Code} | ${option?.Name}` : ''
                          }
                          fullWidth
                          onChange={(value, newValue: any) => {
                            onChange(newValue?.CostCentreID)
                          }}
                          disabled={mode === 'approve-reject'}
                          PopperComponent={AccCodeDropdownFullWidth}
                          renderOption={(props, option) => {
                            return (
                              <div>
                                <div>
                                  <span className="xsTitle">{props?.Code}</span>
                                </div>
                                <div className="desc">{props?.Name}</div>
                              </div>
                            )
                          }}
                          renderInput={(params: any) => {
                            if (detailMode === 'edit') {
                              params.inputProps.value = `${editCC?.Code} | ${editCC?.Name}`
                            }

                            return (
                              <div>
                                <TextField
                                  {...params}
                                  value={
                                    selectedCC
                                      ? `${selectedCC?.Code} | ${selectedCC?.Name}`
                                      : null
                                  }
                                  defaultValue={
                                    editCC
                                      ? `${editCC?.Code} | ${editCC?.Name}`
                                      : null
                                  }
                                  helperText={errors?.CostCentreID?.message}
                                  error={errors?.CostCentreID ? true : false}
                                  label="Department"
                                  style={{ width: '100%' }}
                                  disabled={mode === 'approve-reject'}
                                  InputLabelProps={
                                    selectedCC ? { shrink: true } : null
                                  }
                                  margin="normal"
                                />
                              </div>
                            )
                          }}
                        />
                      )
                    }}
                    label="Department"
                    name="CostCentreID"
                    autoComplete="off"
                    control={control}
                    multiline={true}
                    fullWidth
                    margin="normal"
                    ref={register}
                    helperText={errors?.CostCentreID?.message}
                    error={errors?.CostCentreID ? true : false}
                    disabled={formMode === 'approve-reject' ? true : false}
                  />
                )}

                <Controller
                  as={<NumberFormat />}
                  thousandSeparator
                  customInput={TextField}
                  id="standard-basic"
                  name="Quantity"
                  label="Quantity"
                  value={usedQty}
                  autoComplete="off"
                  control={control}
                  onValueChange={e => {
                    handleQtyChange(e)
                  }}
                  decimalScale={4}
                  fixedDecimalScale
                  margin="normal"
                  helperText={errors?.Quantity?.message}
                  error={errors?.Quantity ? true : false}
                  ref={register}
                  className="left"
                  disabled={formMode === 'approve-reject' ? true : false}
                />

                <Controller
                  as={TextField}
                  id="standard-basic"
                  name="UOMID"
                  label="UOM"
                  autoComplete="off"
                  control={control}
                  fullWidth
                  margin="normal"
                  className="right"
                  ref={register}
                  defaultValue={UOMCode}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
                <Controller
                  render={({ value, onChange }) => {
                    const editCC = dummyDataLocation
                      ?.sort((a, b) => {
                        return a.Name.localeCompare(b.Name)
                      })
                      ?.filter(cc => cc?.LocationID === watch('LocationID'))[0]
                    const selectedCC = dummyDataLocation
                      ?.sort((a, b) => {
                        return a.Name.localeCompare(b.Name)
                      })
                      ?.filter(cc => cc?.LocationID === value)[0]
                    return (
                      <Autocomplete
                        options={dummyDataLocation || []}
                        getOptionLabel={option =>
                          option
                            ? `${option?.Name} | ${option?.Code} | ${option?.Activated}`
                            : ''
                        }
                        fullWidth
                        onChange={(value, newValue: any) => {
                          onChange(newValue?.LocationID)
                        }}
                        disabled={mode === 'approve-reject'}
                        PopperComponent={AccCodeDropdownFullWidth}
                        renderOption={(props, option) => {
                          return (
                            <div>
                              <div>
                                <span className="xsTitle">{props?.Name}</span>
                              </div>
                              <div className="desc">{props?.Code}</div>
                            </div>
                          )
                        }}
                        renderInput={(params: any) => {
                          if (detailMode === 'edit') {
                            params.inputProps.value = `${editCC?.Name} | ${editCC?.Code}`
                          }

                          return (
                            <div>
                              <TextField
                                {...params}
                                value={
                                  selectedCC
                                    ? `${selectedCC?.Name} | ${selectedCC?.Code}`
                                    : null
                                }
                                defaultValue={
                                  editCC
                                    ? `${editCC?.Name} | ${editCC?.Code}`
                                    : null
                                }
                                helperText={errors?.LocationID?.message}
                                error={errors?.LocationID ? true : false}
                                label="Location"
                                style={{ width: '100%' }}
                                disabled={mode === 'approve-reject'}
                                InputLabelProps={
                                  selectedCC ? { shrink: true } : null
                                }
                                margin="normal"
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  label="Location"
                  name="LocationID"
                  autoComplete="off"
                  control={control}
                  multiline={true}
                  fullWidth
                  margin="normal"
                  ref={register}
                  helperText={errors?.LocationID?.message}
                  error={errors?.LocationID ? true : false}
                  required
                  disabled={formMode === 'approve-reject' ? true : false}
                />
              </CardContents>
              <ErrorDialog
                errorDia={errorDia}
                setErrorDia={setErrorDia}
                errorMsg={errMsg}
                errorHeaderMsg={'Error!'}
              />
            </>
          ),
          footer: {
            actions:
              detailMode === 'add'
                ? [
                    {
                      displayText: 'Close',
                      props: {
                        onClick: () => setOpenUsedDepartment(false),
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm & New',
                      props: {
                        onClick: () => {
                          handleSubmit(data =>
                            onSubmit(data, 'new', menu?.index)
                          )()
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () => {
                          handleSubmit(data =>
                            onSubmit(data, 'save', menu?.index)
                          )()
                        },

                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ]
                : [
                    {
                      displayText: 'Close',
                      props: {
                        onClick: () => setOpenUsedDepartment(false),
                        variant: 'contained',
                        color: 'primary',
                      },
                    },

                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () => {
                          handleSubmit(data =>
                            onSubmit(data, 'save', menu?.index)
                          )()
                        },

                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ],
          },
        }}
      />
    </>
  )
}
