import React, { lazy } from 'react'

const DeliveryLocationListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/DeliveryLocation/DeliveryLocationListing'
  ).then(module => ({
    default: module.DeliveryLocationListing,
  }))
)

const DeliveryLocationForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/DeliveryLocation/DeliveryLocationForm'
  ).then(module => ({
    default: module.DeliveryLocationForm,
  }))
)

const GPDeliveryLocationRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/company-settings/delivery-location',
    },
    component: <DeliveryLocationListing />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/:CompanyID/company-settings/delivery-location/add`,
    },
    component: <DeliveryLocationForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/:CompanyID/company-settings/delivery-location/edit`,
    },
    component: <DeliveryLocationForm mode="edit" />,
  },
]

export default GPDeliveryLocationRoutes
