import taxRoutes from './TaxModule/TaxRoutes'
import React, { lazy } from 'react'
import uomRoutes from './UOMModule/UOMRoutes'
import uomRateRoutes from './UOMExchange/ConversionRatesRoutes'
import billItemRoutes from './BillItem/BillItemRoutes'

const CommonSettingSubmenu = lazy(() =>
  import('./CommonSettingSubmenu').then(module => ({
    default: module.CommonSettingSubmenu,
  }))
)

const commonSettingsRoute = [
  {
    props: { exact: true, path: '/common-setting' },
    component: <CommonSettingSubmenu />,
  },

  ...taxRoutes,
  ...uomRoutes,
  ...uomRateRoutes,
  ...billItemRoutes,
]

export default commonSettingsRoute
