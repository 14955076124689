import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { changeDateFormat } from '@ifca-root/react-component/src/helpers/Functions/dateFunction'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import {
  AddFavoriteDialog,
  DeleteFavoriteDialog,
} from 'components/Favorite/FavoriteDialog'
import FavoriteMenu from 'components/Favorite/FavoriteMenu'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  DocumentListingDocument,
  GetFavoritePaymentDocument,
  GetPaymentDocument,
  RecordStatus,
  useCreateFavoritePaymentMutation,
  useCreatePaymentMutation,
  useDeleteFavoritePaymentMutation,
  useDocumentListingLazyQuery,
  useGetBankAccountQuery,
  useGetCreditCardTypeQuery,
  useGetDocNumTitleQuery,
  useGetDocumentDateValidationQuery,
  useGetFavoritePaymentQuery,
  useGetPaymentMethodQuery,
  useGetPaymentQuery,
  useGetTaxEffectiveDateQuery,
  useGetTaxSchemeQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdatePaymentMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import {
  dateFormat,
  formatDashDate,
  getDatePlusDay,
} from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { uuid } from 'uuidv4'
import * as yup from 'yup'
import {
  DocDateValidationAfter,
  DocDateValidationBefore,
} from '../../DocDateValidation'
import '../Payment.scss'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

interface PaymentProps {
  DocDate: string
  TrxDate: string
  DocAmt: number
  RefNo: string
  Description: string
  approval: string
  user: string
  PaymentMethodID: string
  CreditCardID: string
  ChequeNo: string
  ChequeDate: Date
  Attachment: null
  CompanyID: string
  PaymentID: string
  PItem?: any
  uploadAttach: string
  PayeeName: string
}

interface FavPaymentProps {
  Name: string
}

export const PaymentForm = (props: any) => {
  const { CompanyID, BankAccountID, PaymentID, formMode }: any = useParams()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)
  const [show, setShow] = useState(false)

  const [openFavoriteDialog, setOpenFavoriteDialog] = useState(false)
  const [openFavoriteDeleteDialog, setOpenFavoriteDeleteDialog] = useState(
    false
  )
  const [openExitConf, setOpenExitConf] = useState(null)
  const [initDocs, setInitDocs] = useState([])
  useEffect(() => {
    if (editData?.Attachment) {
      setInitDocs(editData?.Attachment)
    }
  }, [editData])

  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const {
    anchorEl,
    setAnchorEl,
    menu,
    handleClick,
    handleClose,
  } = useMenuOption()

  const {
    anchorEl: anchorElFav,
    setAnchorEl: setAnchorElFav,
    menu: menuFav,
    handleClick: handleClickFav,
    handleClose: handleCloseFav,
  } = useMenuOption()

  const [PaymentValidation, setPaymentValidation] = useState(false)
  const [valuepicked, setValuePicked] = useState<String>(null)
  const [isSubmit, setIsSubmit] = useState(false)

  const [taxEffectiveList, setTaxEffectiveList] = useState<any>()
  const [newPaymentItems, setNewPaymentItems] = useState<any>()

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Edit'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'resubmit'
      break
  }

  // Status Input Enum
  let statusInput = formMode => {
    let temp

    switch (formMode) {
      case 'submit':
        temp = ApprovalStatus.Submit
        break
      case 'draft':
        temp = ApprovalStatus.Active
        break
      case 'approve':
        temp = ApprovalStatus.Approved
        break
      case 'reject':
        temp = ApprovalStatus.Rejected
        break
    }
    return temp
  }

  // Queries //
  const {
    loading: BankAccountLoading,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      BankAccountID: BankAccountID,
      RecordStatus: RecordStatus?.Active,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  // Tax
  const {
    loading: TaxLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: DocDateValidationLoading,
    error: DocDateValidationError,
    data: { getDocumentDateValidation } = { getDocumentDateValidation: [] },
  } = useGetDocumentDateValidationQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  //Payment
  const {
    loading: PaymentLoading,
    error: paymentError,
    data: { getPayment } = { getPayment: [] },
  } = useGetPaymentQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, PaymentID: editData?.PaymentID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  //TaxEffectiveDate

  const {
    loading: TaxEffectiveDateLoading,
    error: TaxEffectiveDateError,
    data: { getTaxEffectiveDate } = { getTaxEffectiveDate: [] },
  } = useGetTaxEffectiveDateQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      let temp = []
      if (getTaxEffectiveDate?.length > 0) {
        temp = getTaxEffectiveDate?.filter(
          tax =>
            Number(new Date(tax?.Date)) <
              DocDateValidationAfter(
                getDocumentDateValidation?.map(x => x?.MonthsAfter)
              ) &&
            Number(new Date(tax?.Date)) >
              DocDateValidationBefore(
                getDocumentDateValidation?.map(x => x?.MonthsBefore)
              )
        )
        setTaxEffectiveList(temp)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  //PaymentMethod
  const {
    loading: PaymentMethodLoading,
    error: PaymentMethodError,
    data: { getPaymentMethod } = { getPaymentMethod: [] },
  } = useGetPaymentMethodQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: DocNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: 'CB_Payment',
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  useEffect(() => {
    if (editData) {
      if (
        !!editData?.PaymentItem &&
        editData?.detailMode !== 'add' &&
        !!!JSON.parse(localStorage.getItem('payment'))
      ) {
        localStorage.setItem(
          'paymentItem',
          JSON.stringify(
            editData?.PaymentItem?.map(item => {
              return {
                PaymentItemID: item?.PaymentItemID,
                MasterCOAID: item?.MasterCOAID,
                DocNo: item?.DocNo,
                DocDate: item?.DocDate,
                ExpenseCode: item?.MasterCOA?.Code,
                ExpenseName: item?.MasterCOA?.Name,
                Amount: item?.Amount,
                TaxSchemeID: item?.TaxSchemeID,
                TaxCode: item?.TaxScheme?.Code,
                TaxRate: item?.TaxRate,
                TaxAmt: item?.TaxAmt,
                DocAmt: item?.DocAmt,
                Sequence: item?.Sequence,
                CostCentreID: item?.CostCentreID,
                CostCentreCode: item?.CostCentre?.Code,
                Remark: item?.Remark,
              }
            })
          )
        )
      }
      if (!!editData?.files) {
        setFiles(editData?.files)
        setPreviewFiles(
          editData?.files?.map(file => {
            return URL.createObjectURL(file)
          })
        )
      } else {
        localStorage.setItem('payment', JSON.stringify(editData))
      }
    }
  }, [editData])

  useEffect(() => {
    if (
      formMode === 'edit' ||
      formMode === 'approve-reject' ||
      formMode === 'resubmit'
    )
      if (!!!editData?.files) {
        fetchdocuments({
          variables: {
            refID: PaymentID,
          },
        })
      }
  }, [formMode])

  const paymentDetail = !!JSON.parse(localStorage.getItem('payment'))
    ? JSON.parse(localStorage.getItem('payment'))
    : editData

  // validation
  const PaymentFormSchema = yup.object().shape({
    RefNo: CommonYupValidation.requireField(SystemMsgs.referenceNo()),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    PaymentMethodID: CommonYupValidation.requireField(
      'Payment Method is required'
    ),
    CreditCardID:
      PaymentValidation && valuepicked?.includes('Card')
        ? CommonYupValidation.requireField('Credit Card Type is required')
        : null,
    ChequeNo: yup.string().when('PaymentMethodID', {
      is: getPaymentMethod?.find(method => method?.Name === 'Cheque')
        ?.PaymentMethodID,
      then: yup.string().required('Cheque no. is required'),
      otherwise: null,
    }),
    ChequeDate: yup.date().when('PaymentMethodID', {
      is: getPaymentMethod?.find(method => method?.Name === 'Cheque')
        ?.PaymentMethodID,
      then: yup.date().required('Cheque date is required'),
      otherwise: null,
    }),
    TrxDate: yup.string().required('Transaction Date is Required'),

    PayeeName: CommonYupValidation.requireField('Payee Name is required'),
  })

  const FavPaymentFormSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
  })

  // File Upload
  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
    setPreviewFiles,
  } = useUploadDocument()

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    reset,
    setError,
    getValues,
    formState,
    watch,
    clearErrors,
  } = useForm<PaymentProps>({
    defaultValues: {
      DocDate: paymentDetail ? paymentDetail?.DocDate : new Date(),
      TrxDate: paymentDetail ? paymentDetail?.TransactionDate : new Date(),
      RefNo: paymentDetail ? paymentDetail?.RefNo : '',
      Description: paymentDetail ? paymentDetail?.Description : '',
      PaymentMethodID: paymentDetail ? paymentDetail?.PaymentMethodID : '',
      CreditCardID: paymentDetail ? paymentDetail?.CreditCardID : '',
      CompanyID: paymentDetail ? paymentDetail?.CompanyID : '',
      Attachment: paymentDetail ? paymentDetail?.Attachment : null,
      ChequeNo: paymentDetail ? paymentDetail?.ChequeNo : '',
      ChequeDate: paymentDetail ? paymentDetail?.ChequeDate : new Date(),
      DocAmt: paymentDetail ? paymentDetail?.DocAmt : '',
      PayeeName: paymentDetail ? paymentDetail?.PayeeName : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(PaymentFormSchema),
  })

  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const trxDateTimestamp = new Date(watch('TrxDate'))
  const yearTrxDate = trxDateTimestamp.getFullYear()
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`

  const openPeriod1 =
    transferDate >= latestOpenPeriodCheckingDate?.StartDate &&
    transferDate <= latestOpenPeriodCheckingDate?.EndDate

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? false : true
  const checkingYearClose2 = openPeriod2 ? false : true

  // To get Tax Code based on selected Doc Date //
  const taxEffectiveDate: any =
    !!watch('DocDate') && watch('DocDate').toString() !== 'Invalid Date'
      ? new Date(new Date(watch('DocDate')).getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      : null

  const taxSchemeInput: any = getTaxScheme
    ?.filter(el => el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate)
    ?.map(j => j?.TaxSchemeID)

  const paymentItems =
    newPaymentItems !== undefined
      ? newPaymentItems
      : !!JSON.parse(localStorage.getItem('paymentItem'))
      ? JSON.parse(localStorage.getItem('paymentItem'))?.filter(x =>
          taxSchemeInput.includes(x?.TaxSchemeID)
        )
      : editData?.PaymentItem?.map(item => {
          return {
            PaymentItemID: item?.PaymentItemID,
            MasterCOAID: item?.MasterCOAID,
            DocNo: item?.DocNo,
            DocDate: item?.DocDate,
            ExpenseCode: item?.MasterCOA?.Code,
            ExpenseName: item?.MasterCOA?.Name,
            Amount: item?.Amount,
            TaxSchemeID: item?.TaxSchemeID,
            TaxCode: item?.TaxScheme?.Code,
            TaxRate: item?.TaxRate,
            TaxAmt: item?.TaxAmt,
            DocAmt: item?.DocAmt,
            CostCentreID: item?.CostCentreID,
            CostCentreCode: item?.CostCentre?.Code,
            Remark: item?.Remark,
          }
        })
  const updateNewPaymentItem = (newDate, TEList) => {
    const arr = []

    paymentItems?.map(x => {
      let NewTax =
        getTaxEffectiveDate?.filter(
          j =>
            j?.TaxSchemeID === x?.TaxSchemeID &&
            new Date(j?.Date) <= new Date(newDate)
        )?.length > 0
          ? getTaxEffectiveDate
              ?.filter(
                j =>
                  j?.TaxSchemeID === x?.TaxSchemeID &&
                  new Date(j?.Date) <= new Date(newDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })
          : null

      if (NewTax !== null) {
        arr.push({
          PaymentItemID: x?.PaymentItemID,
          MasterCOAID: x?.MasterCOAID,
          DocNo: x?.DocNo,
          DocDate: x?.DocDate,
          ExpenseCode: x?.ExpenseCode,
          ExpenseName: x?.ExpenseName,
          Amount: x?.Amount,
          TaxSchemeID: x?.TaxSchemeID,
          TaxCode: x?.TaxCode,
          TaxRate: NewTax?.Rate,
          TaxAmt: x?.Amount * (NewTax?.Rate / 100),
          DocAmt: x?.Amount + x?.Amount * (NewTax?.Rate / 100),
          CostCentreID: x?.CostCentreID,
          CostCentreCode: x?.CostCentreCode,
          Remark: x?.Remark,
        })
      }
    })
    setNewPaymentItems(arr)
    localStorage.setItem('paymentItem', JSON.stringify([...arr]))
  }

  const styles = makeStyles(theme => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },

    item: {
      padding: '0',
      paddingRight: '10px',
      borderBottom: '2px solid rgb(224,224,224)',
    },
    lastItem: {
      padding: '0',
      paddingRight: '10px',
    },
    deleteIcon: {
      display: 'flex',
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
      },
    },
    list: {
      width: '100%',
      // paddingBottom: '25px',
      [theme.breakpoints.up('md')]: {
        width: '80%',
        margin: 'Auto',
      },
    },
  }))

  //Changing PaymentMethodID
  const [selectedPM, setSelectedPM] = useState(null)
  const paymentmethod: any = getPaymentMethod

  const handlePMChange = event => {
    if (
      getPaymentMethod?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name === 'Cheque' ||
      getPaymentMethod
        ?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name?.includes('Card')
    ) {
      setPaymentValidation(true)
    } else setPaymentValidation(false)
    if (
      getPaymentMethod?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name === 'Cheque'
    ) {
      setValuePicked('Cheque')
    } else if (
      getPaymentMethod
        ?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name?.includes('Card')
    ) {
      setValuePicked('Card')
    } else setValuePicked('')
  }

  //CreditCardType
  const {
    loading: CreditCardTypeLoading,
    data: { getCreditCardType } = { getCreditCardType: [] },
  } = useGetCreditCardTypeQuery({
    fetchPolicy: 'network-only',
    variables: {},
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  useEffect(() => {
    if (paymentDetail) {
      setSelectedPM(
        paymentmethod.find(
          x => x?.PaymentMethodID === paymentDetail?.PaymentMethodID
        )
      )
    }
  }, [paymentmethod])

  // Change Tax descrition into code number

  const [taxAmt, setTaxAmt] = useState(
    !!paymentDetail ? paymentDetail?.TaxAmt : 0
  )

  const {
    handleSubmit: handleFavSubmit,
    register: favRegister,
    control: favControl,
    errors: favErrors,
    watch: favWatch,
  } = useForm<FavPaymentProps>({
    mode: 'onSubmit',
    resolver: yupResolver(FavPaymentFormSchema),
  })

  //---Mutation Create & Update Payment---//

  const [
    createPayment,
    { loading: CreateLoading, called: createCalled, error: createError },
  ] = useCreatePaymentMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: ({ createPayment }) => {
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${createPayment?.PaymentID}/detail/preview`,
            state: { success: true, msgMode: 'create' },
          })
        } else {
          history.push({
            pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment`,
            state: { success: true, msgMode: 'update' },
          })
        }
      }, 500)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      localStorage.removeItem('payment')
      localStorage.removeItem('paymentItem')
      localStorage.removeItem('attachment')
    },
  })

  const [
    updatePayment,
    { loading: UpdateLoading, called: updateCalled, error: updateError },
  ] = useUpdatePaymentMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${PaymentID}/detail/preview`,
          })
        } else {
          history.push({
            pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment`,
            state: { success: true, msgMode: 'update' },
          })
        }
      }, 500)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      localStorage.removeItem('payment')
      localStorage.removeItem('paymentItem')
      localStorage.removeItem('attachment')
    },
  })

  //---Favorite Payment API---//

  const favRefetchQuery = [
    {
      query: GetFavoritePaymentDocument,
      variables: {
        CompanyID,
        UserID: user?.ID,
      },
      onError: ({ message }) => {
        let error = message?.substring(15)
        setErrMessage(error)
        setErrDialog(true)
      },
    },
  ]

  const {
    loading: FavoritePaymentLoading,
    data: { getFavoritePayment } = {
      getFavoritePayment: [],
    },
  } = useGetFavoritePaymentQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, UserID: user?.ID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    createFavoritePayment,
    { loading: CreateFavoritePaymentLoading },
  ] = useCreateFavoritePaymentMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenFavoriteDialog(false)
    },
  })

  const [
    deleteFavoritePayment,
    { loading: DeleteFavoritePaymentLoading },
  ] = useDeleteFavoritePaymentMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOpenFavoriteDeleteDialog(false)
    },
    variables: {
      FavoritePaymentID: menuFav?.ID,
    },
  })

  const favNames = getFavoritePayment?.map(fav => fav?.Name)

  //---DocumentListing queries---//
  const [
    fetchdocuments,
    { loading: DocumentLoading, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    variables: { refID: PaymentID },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })
  const [val, setVal] = useState(false)

  //when click Save as Draft / Submit (Fiey)
  const onSubmit = (data, status) => {
    if (val == false) {
      setVal(true)

      if (formMode === 'add') {
        //create the data
        createPayment({
          variables: {
            input: {
              createdBy: user?.name,
              CompanyID: CompanyID,
              BankAccountID: BankAccountID,
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              RefNo: data?.RefNo,
              Description: data.Description,
              PayeeName: data.PayeeName,
              ApprovalStatus: statusInput(status),
              Attachment: files,
              PaymentMethodID: data.PaymentMethodID,
              CreditCardID: data.CreditCardID,
              ChequeNo: data.ChequeNo,
              ChequeDate: data.ChequeDate,
              ChequeExpiryDate: !!data?.ChequeDate
                ? changeDateFormat(
                    getDatePlusDay(data?.ChequeDate, 180),
                    'DD MMM YYYY'
                  )
                : null,
              DocAmt: totalPayment ?? 0,
            },
            itemInput: paymentItems?.map(x => {
              return {
                MasterCOAID: x?.MasterCOAID,
                DocNo: x?.DocNo,
                DocDate: x?.DocDate,
                Amount: parseFloat(amtNumStr(x?.Amount)),
                TaxSchemeID: x?.TaxSchemeID,
                TaxRate: x?.TaxRate,
                TaxAmt: parseFloat(x?.TaxAmt),
                DocAmt: parseFloat(x?.DocAmt),
                CostCentreID: x?.CostCentreID,
                Remark: x?.Remark,
                Sequence: x?.Sequence,
              }
            }),
          },
          refetchQueries: [
            {
              query: GetPaymentDocument,
            },
            {
              query: DocumentListingDocument,
            },
          ],
        })
        // }
      } else if (formMode !== 'add') {
        updatePayment({
          variables: {
            input: {
              CompanyID: CompanyID,
              PaymentID: PaymentID,
              BankAccountID: BankAccountID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              RefNo: data?.RefNo,
              Description: data?.Description,
              PayeeName: data?.PayeeName,
              ApprovalStatus: statusInput(status),
              Attachment: files,
              PaymentMethodID: data?.PaymentMethodID,
              CreditCardID: data?.CreditCardID,
              ChequeNo: data?.ChequeNo,
              ChequeDate: data?.ChequeDate,
              ChequeExpiryDate: !!data?.ChequeDate
                ? changeDateFormat(
                    getDatePlusDay(data?.ChequeDate, 180),
                    'DD MMM YYYY'
                  )
                : null,
              DocAmt: totalPayment ?? 0,
            },
            itemInput: paymentItems?.map(x => {
              return {
                PaymentItemID: x?.PaymentItemID,
                MasterCOAID: x?.MasterCOAID,
                DocNo: x?.DocNo,
                DocDate: x?.DocDate,
                Amount: parseFloat(amtNumStr(x?.Amount)),
                TaxSchemeID: x?.TaxSchemeID,
                TaxRate: parseFloat(x?.TaxRate),
                TaxAmt: parseFloat(x?.TaxAmt),
                DocAmt: parseFloat(x?.DocAmt),
                CostCentreID: x?.CostCentreID,
                Remark: x?.Remark,
                Sequence: x?.Sequence,
              }
            }),
          },
          refetchQueries: [
            {
              query: GetPaymentDocument,
            },
            {
              query: DocumentListingDocument,
              variables: {
                refTable: 'S_Attachment',
                refID: PaymentID,
              },
            },
          ],
        })
      }
    }
  }

  // Delete Payment Detail data
  const handleDeletePaymentDetail = index => {
    const position = paymentItems.indexOf(index)
    if (menu?.index > -1) {
      paymentItems.splice(menu?.index, 1)
    }
    localStorage.setItem('paymentItem', JSON.stringify(paymentItems))
  }

  //To calculate Total Amount of Payment
  let totalPayment = paymentItems?.reduce(
    (total, currentValue) => (total = total + currentValue.DocAmt),
    0
  )

  let calcTotal = items => {
    return items?.reduce(
      (total, currentValue) => (total = total + currentValue.DocAmt),
      0
    )
  }

  const getFiles = file => {
    let myArray = []
    let temp = {}
    for (let i = 0; i < file.length; i++) {
      temp = {
        lastMod: file[i].lastModified,
        lastModDate: file[i].lastModifiedDate,
        name: file[i].name,
        size: file[i].size,
        type: file[i].type,
      }
      myArray.push(temp)
    }
    return myArray
  }

  //--- Add Favorite Payment---//
  const handleAddFavorite = () => {
    setOpenFavoriteDialog(true)
  }

  //---Select Favorite Payment---//
  const handleSelectFavorite = data => {
    const tempFavItemArr = data?.FavoritePaymentItem?.map(x => {
      return {
        PaymentItemID: uuid(),
        MasterCOAID: x?.MasterCOAID,
        DocNo: x?.DocNo,
        DocDate: x?.DocDate,
        ExpenseCode: x?.MasterCOA?.Code,
        ExpenseName: x?.MasterCOA?.Name,
        CostCentreID: x?.CostCentreID,
        CostCentreCode: x?.CostCentre?.Code,
        TaxSchemeID: x?.TaxSchemeID,
        TaxCode: x?.TaxScheme?.Code,
        TaxRate: parseFloat(x?.TaxRate),
        TaxAmt: parseFloat(x?.TaxAmt),
        Amount: parseFloat(x?.Amount),
        DocAmt: parseFloat(x?.DocAmt),
        Sequence: x?.Sequence,
        Remark: x?.Remark,
      }
    })

    setValue('PaymentMethodID', data?.PaymentMethodID)
    setValue('CreditCardID', data?.CreditCardID)
    setValue('ChequeNo', data?.ChequeNo)
    setValue('PayeeName', data?.PayeeName)
    setValue('Description', data?.Description)
    setValue('RefNo', data?.RefNo)
    setValue('DocAmt', data?.DocAmt)

    //---Setting Payment data into LocalStorage---//
    localStorage.removeItem('payment')
    localStorage.setItem(
      'payment',
      JSON.stringify({
        DocDate: getValues('DocDate'),
        TransactionDate: getValues('TrxDate'),
        RefNo: getValues('RefNo'),
        Description: getValues('Description'),
        PayeeName: getValues('PayeeName'),
        PaymentMethodID: getValues('PaymentMethodID'),
        CreditCardID: data?.CreditCardID,
        ChequeNo: data?.ChequeNo,
        DocAmt: getValues('DocAmt'),
      })
    )

    localStorage.removeItem('paymentItem')

    //---Setting Payment Item data into LocalStorage---//
    localStorage.setItem('paymentItem', JSON.stringify(tempFavItemArr))
  }

  //---Submitting Favorite Payment & Payment Item---//
  const onSubmitFavorite = data => {
    const tempFav = {
      Name: data?.Name,
      UserID: user?.ID,
      Description: !!getValues('Description') ? getValues('Description') : null,
      PayeeName: !!getValues('PayeeName') ? getValues('PayeeName') : null,
      RefNo: !!getValues('RefNo') ? getValues('RefNo') : null,
      PaymentMethodID: !!getValues('PaymentMethodID')
        ? getValues('PaymentMethodID')
        : null,
      CreditCardID: !!getValues('CreditCardID')
        ? getValues('CreditCardID')
        : null,
      ChequeNo: !!getValues('ChequeNo') ? getValues('ChequeNo') : null,
      DocAmt: totalPayment ?? 0,
    }

    const paymentitems: any[] = paymentItems
    const tempFavItem = paymentitems?.map(x => {
      return {
        MasterCOAID: x?.MasterCOAID,
        DocNo: x?.DocNo,
        DocDate: x?.DocDate,
        CostCentreID: x?.CostCentreID,
        TaxSchemeID: x?.TaxSchemeID,
        TaxRate: parseFloat(x?.TaxRate),
        TaxAmt: parseFloat(x?.TaxAmt),
        Amount: parseFloat(amtNumStr(x?.Amount)),
        DocAmt: parseFloat(amtNumStr(x?.DocAmt)),
        Sequence: x?.Sequence,
        Remark: x?.Remark,
      }
    })

    //---Input Fav Payment & Payment Item---//
    createFavoritePayment({
      variables: {
        FavoritePaymentInput: tempFav,
        FavoritePaymentItemInput: tempFavItem,
      },
      refetchQueries: favRefetchQuery,
    })
  }

  const createUpdateLoading = editData ? UpdateLoading : CreateLoading
  const createUpdateCalled = editData ? updateCalled : createCalled
  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */
  //---Form Submission Footers---//
  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      checkingYearClose1 || checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.CashBookPaymentDraft,
          }),
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      !!errors?.DocDate ||
      !!errors?.TrxDate ||
      !!errors?.RefNo ||
      !!errors?.PaymentMethodID ||
      !!errors?.PayeeName ||
      !!errors?.Description ||
      checkingYearClose1 ||
      checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.CashBookPaymentUpdate,
          }),
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: () => {
      handleSubmit(data =>
        getDocumentNumberHeader?.filter(x => x?.RefTable === 'CB_Payment')
          ?.length === 0
          ? (setOpenSnackBar(true),
            setSnackBarMsg(SystemMsgs.errorNumberingStructure()))
          : !createUpdateCalled && onSubmit(data, 'submit')
      )()
      setIsSubmit(true)
    },
    color: 'primary',
    props: {
      type: 'submit',
    },

    disabled:
      checkingYearClose1 ||
      checkingYearClose2 ||
      calcTotal(paymentItems) === 0 ||
      !JSON.parse(localStorage.getItem('paymentItem')) ||
      !JSON.parse(localStorage.getItem('payment'))
        ? true
        : false,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      formMode: formMode,
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
      itemArr: paymentItems,
      itemSuffixID: 'PaymentItemID',
      itemTableName: 'PaymentItem',
    })

  return (
    <>
      {TaxLoading && <Loading />}
      {TaxLoading && <Loading />}
      {UpdateLoading && <Loading />}
      {CreateLoading && <Loading />}
      {PaymentLoading && <Loading />}
      {PaymentLoading && <Loading />}
      {DocumentLoading && <Loading />}
      {BankAccountLoading && <Loading />}
      {DocNumHeaderLoading && <Loading />}
      {PaymentMethodLoading && <Loading />}
      {PaymentMethodLoading && <Loading />}
      {CreditCardTypeLoading && <Loading />}
      {CreditCardTypeLoading && <Loading />}
      {FavoritePaymentLoading && <Loading />}
      {FavoritePaymentLoading && <Loading />}
      {TaxEffectiveDateLoading && <Loading />}
      {DocDateValidationLoading && <Loading />}
      {CreateFavoritePaymentLoading && <Loading />}
      {DeleteFavoritePaymentLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          {
            if (hasChanges() === true) {
              setOpenExitConf(true)
            } else {
              history.push({
                pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment`,
              })
              localStorage.removeItem('paymentItem')
              localStorage.removeItem('payment')
              localStorage.removeItem('attachment')
            }
          }
        }}
        smTitle={'Cash Book'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Cash Book Main Menu' },
          { name: 'Cash Book Submenu' },
          { name: 'Payment' },
          { name: 'Payment', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              mode === 'add'
                ? 'New'
                : mode === 'approve-reject'
                ? 'Approve/Reject'
                : 'Edit',
            current: true,
          },
        ]}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {getBankAccount[0]?.BankProfile?.Name}
            </span>
          </>
        }
        infoLine={
          <>
            <span className="xxTitle">{'Account No.'}&nbsp;:&nbsp;</span>
            <span className="xxTitle">{getBankAccount[0]?.AccountNo}</span>
          </>
        }
      />
      <ContentWrapper float multiDynamicInfo>
        <CardContents
          section={{
            header: {
              title: 'Payment',
              icon:
                formMode === 'add' || formMode === 'edit' ? (
                  <FavoriteMenu
                    options={getFavoritePayment}
                    funcLabel={'Add Favorite'}
                    addFavFunc={handleAddFavorite}
                    selectFavFunc={handleSelectFavorite}
                    optionIDName={'FavoritePaymentID'}
                    setOpenFavoriteDeleteDialog={setOpenFavoriteDeleteDialog}
                    anchorEl={anchorElFav}
                    setAnchorEl={setAnchorElFav}
                    handleClose={handleCloseFav}
                    handleClickDelete={handleClickFav}
                  />
                ) : null,
            },
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocDate"
              label="Date"
              control={control}
              onChange={(date: Date | null) => {
                //date
              }}
              onAccept={(date: Date | null) => {
                updateNewPaymentItem(date, taxEffectiveList)
              }}
              format="dd/MM/yyyy"
              value={watch(
                formMode === 'add' ? new Date() : paymentDetail?.DocDate
              )}
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={paymentDetail ? paymentDetail?.DocDate : new Date()}
              showTodayButton
              className="left"
              margin="dense"
              disabled={mode == 'approve-reject'}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose1
                  ? 'Financial Period already closed'
                  : errors?.DocDate?.message
              }
              error={errors?.DocDate || checkingYearClose1 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxDate"
              required
              label="Transaction Date"
              control={control}
              format="dd/MM/yyyy"
              margin="dense"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="right"
              // value={watch(
              //   formMode === 'add' ? new Date() : paymentDetail?.TransactionDate
              // )}
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose2
                  ? 'Financial Period already closed'
                  : errors?.TrxDate?.message
              }
              error={errors?.TrxDate || checkingYearClose2 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            required
            autoComplete="off"
            control={control}
            // className="right"
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            defaultValue={paymentDetail ? paymentDetail?.RefNo : ''}
            disabled={mode == 'approve-reject'}
          />

          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Payment Method"
                required
                select
                value={value}
                onChange={e => {
                  onChange()
                  handlePMChange(e)
                  setValue('PaymentMethodID', e?.target?.value)
                }}
                defaultValue={
                  paymentDetail ? paymentDetail?.PaymentMethodID : ''
                }
                disabled={formMode === 'approve-reject'}
                helperText={errors?.PaymentMethodID?.message}
                error={errors?.PaymentMethodID ? true : false}
              >
                {getPaymentMethod
                  ?.sort((a, b) => {
                    return a.Name.localeCompare(b.Name)
                  })
                  .map((el, index) => (
                    <MenuItem key={index} value={el.PaymentMethodID}>
                      {el.Name}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            control={control}
            helperText={errors?.PaymentMethodID?.message}
            error={errors?.PaymentMethodID ? true : false}
            onChange={e => {
              //handlePMChange(e)
              setValue('PaymentMethodID', e?.target?.value)
            }}
            ref={register()}
            name="PaymentMethodID"
            autoComplete="off"
            value={paymentDetail?.PaymentMethodID}
            margin="normal"
            multiline={true}
            fullWidth
            required
          />

          {getPaymentMethod
            .find(el => el.PaymentMethodID === watch('PaymentMethodID'))
            ?.Name?.includes('Card') && (
            <Controller
              render={({ onChange, value }) => (
                <TextField
                  select
                  required
                  name="CreditCardID"
                  label="Card Type"
                  onChange={e => {
                    onChange(e)
                  }}
                  defaultValue={
                    paymentDetail ? paymentDetail?.CreditCardID : ''
                  }
                  disabled={mode == 'approve-reject'}
                  helperText={errors?.CreditCardID?.message}
                  error={errors?.CreditCardID ? true : false}
                >
                  {getCreditCardType
                    ?.sort((a, b) => {
                      return a.Name.localeCompare(b.Name)
                    })
                    .map((el, index) => (
                      <MenuItem key={index} value={el.CreditCardID}>
                        {`${el.Name}`}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              label="Card Type"
              name="CreditCardID"
              autoComplete="off"
              control={control}
              helperText={errors?.CreditCardID?.message}
              error={errors?.CreditCardID ? true : false}
              multiline={true}
              defaultValue={getPayment[0]?.CreditCardID}
              fullWidth
              margin="normal"
              ref={register}
            />
          )}

          {getPaymentMethod.find(
            el => el.PaymentMethodID === watch('PaymentMethodID')
          )?.Name === 'Cheque' && (
            <>
              <Controller
                as={TextField}
                id="standard-basic"
                name="ChequeNo"
                label="Cheque No"
                className="left"
                autoComplete="off"
                control={control}
                margin="dense"
                required
                ref={register}
                helperText={errors?.ChequeNo?.message}
                error={errors?.ChequeNo ? true : false}
                defaultValue={paymentDetail ? paymentDetail?.ChequeNo : ''}
                disabled={mode == 'approve-reject'}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  required
                  as={KeyboardDatePicker}
                  name="ChequeDate"
                  label="Cheque Date"
                  control={control}
                  onChange={(date: Date | null) => {}}
                  format="dd/MM/yyyy"
                  value={
                    // formMode === 'add' ? new Date() : paymentDetail?.ChequeDate
                    watch('ChequeDate')
                  }
                  margin="dense"
                  allowKeyboardControl
                  //inputRef={register({})}
                  ref={register}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  defaultValue={
                    formMode === 'add' ? new Date() : paymentDetail?.ChequeDate
                  }
                  showTodayButton
                  className="right"
                  disabled={mode == 'approve-reject'}
                  helperText={errors?.ChequeDate?.message}
                  error={errors?.ChequeDate ? true : false}
                />
              </MuiPickersUtilsProvider>
            </>
          )}

          <Controller
            as={TextField}
            id="standard-basic"
            name="PayeeName"
            label="Payee Name"
            autoComplete="off"
            control={control}
            fullWidth
            required
            margin="dense"
            ref={register}
            helperText={errors?.PayeeName?.message}
            error={errors?.PayeeName ? true : false}
            defaultValue={paymentDetail ? paymentDetail?.PayeeName : ''}
          />

          <Controller
            as={TextField}
            style={{ marginTop: '-2px', marginBottom: '30px' }}
            id="standard-basic"
            name="Description"
            label="Description"
            autoComplete="off"
            control={control}
            fullWidth
            required
            margin="dense"
            ref={register}
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            defaultValue={paymentDetail ? paymentDetail?.Description : ''}
          />

          <FileUploadInput
            placeholder={previewFiles.length === 0 ? 'Upload Attachment' : null}
            label={previewFiles.length > 0 ? 'Upload Attachment' : null}
            name="Upload Attachment"
            files={files}
            onHandleUploadChange={handleUploadChange}
            multiple
            // style={{ marginTop: '16px' }}
            imagePreview={
              <>
                {previewFiles?.map((v, i) => (
                  <UploadPreview
                    remove
                    key={v}
                    src={v}
                    onClick={() => removeFile(i)}
                    mediaType={
                      files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                    }
                  />
                ))}
              </>
            }
          />
        </CardContents>

        <CardContents
          //IsDifferentColour
          section={{
            header: {
              title: 'Payment Detail',
              onClickAction: () => {
                localStorage.setItem(
                  'payment',
                  JSON.stringify({
                    DocDate: getValues('DocDate'),
                    TransactionDate: getValues('TrxDate'),
                    RefNo: getValues('RefNo'),
                    Description: getValues('Description'),
                    PayeeName: getValues('PayeeName'),
                    PaymentMethodID: getValues('PaymentMethodID'),
                    CreditCardID: getValues('CreditCardID'),
                    ChequeNo: getValues('ChequeNo'),
                    ChequeDate: getValues('ChequeDate'),
                    DocAmt: getValues('DocAmt'),
                  })
                )

                localStorage.setItem(
                  'attachment',
                  JSON.stringify(getFiles(files))
                )

                if (formMode === 'add') {
                  history.push({
                    pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${formMode}/payment-item/add`,
                    state: { ...editData, files: files },
                  })
                } else {
                  history.push({
                    pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${PaymentID}/${formMode}/payment-item/add`,
                    state: { ...editData, files: files },
                  })
                }
              },

              icon:
                formMode === 'add' || formMode === 'edit' ? (
                  <AddIcon
                    htmlColor="white"
                    fontSize="default"
                    style={{
                      width: '1.3rem',
                      height: '1.3rem',
                      margin: '0',
                      background: theme.palette.primary.main,
                      boxShadow: `1px 1px 4px 0px ${theme.palette.primary.main}`,
                      borderRadius: '3px',
                      color: 'rgba(224,234,254,100)',
                      marginTop: '10px',
                    }}
                  />
                ) : null,
            },
          }}
        >
          <div className="rm-padding table-wrap ">
            {paymentItems === undefined || paymentItems?.length === 0 ? (
              <EmptyList
                title="No Record Found"
                subtitle="Add New Record now."
              />
            ) : (
              paymentItems
                ?.sort(function(a, b) {
                  return a?.Sequence < b?.Sequence ? -1 : 1
                })
                ?.map((v, index) => {
                  return (
                    <>
                      <List
                        className="item-list content-wrap full text-noflow"
                        style={{ paddingTop: '0', paddingBottom: '0' }}
                      >
                        <ListItem disableGutters>
                          <Grid spacing={1} container className="table-content">
                            <Grid
                              item
                              xs={1}
                              style={{
                                placeSelf: 'start',
                              }}
                            >
                              <span className="xxTitle">{`${index + 1}.`}</span>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              style={{ placeSelf: 'start', marginTop: '8px' }}
                            >
                              <div
                                className="xxTitle text-noflow"
                                style={{
                                  lineBreak: 'anywhere',
                                }}
                              >
                                {v?.ExpenseCode} | {v?.ExpenseName}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{
                                placeSelf: 'start',
                                justifyContent: isDesktop ? 'center' : 'end',
                                marginTop: '8px',
                              }}
                            >
                              <div
                                className="xxTitle text-noflow"
                                style={{
                                  lineBreak: 'anywhere',
                                  textAlign: 'end',
                                }}
                              >
                                {amtStr(v?.DocAmt)}
                              </div>
                            </Grid>
                            <Grid item xs={1} style={{ placeSelf: 'start' }} />
                            <Grid
                              item
                              xs={8}
                              style={{
                                placeSelf: 'start',
                                justifyContent: isDesktop ? 'center' : 'end',
                              }}
                            >
                              <div
                                className="desc"
                                style={{ marginTop: '-10px' }}
                              >
                                {dateFormat(v?.DocDate) || 'N/A'}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{
                                placeSelf: 'start',
                                justifyContent: isDesktop ? 'center' : 'end',
                                marginTop: '-10px',
                              }}
                            >
                              <div
                                className="desc text-noflow"
                                style={{
                                  lineBreak: 'anywhere',
                                  textAlign: 'end',
                                }}
                              >
                                {amtStr(v?.Amount)}
                              </div>
                            </Grid>
                            <Grid item xs={1} style={{ placeSelf: 'start' }} />
                            <Grid
                              item
                              xs={4}
                              style={{ placeSelf: 'start', marginTop: '-8px' }}
                            >
                              <div className="desc text-noflow">
                                {v?.DocNo || 'N/A'}
                                {' | '}
                                {v?.CostCentreCode}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              style={{ placeSelf: 'start', marginTop: '-8px' }}
                            >
                              <div className="desc">
                                {`${v?.TaxCode} ${v?.TaxRate}%`}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{ placeSelf: 'start', marginTop: '-8px' }}
                            >
                              <div
                                className="desc text-noflow"
                                style={{ textAlign: 'end' }}
                              >
                                {amtStr(v?.TaxAmt)}
                              </div>
                            </Grid>
                            <Grid item xs={1} style={{ placeSelf: 'start' }} />
                            <Grid
                              item
                              xs={11}
                              style={{ placeSelf: 'start', marginTop: '-6px' }}
                            >
                              <div className="desc text-noflow">
                                {v?.Remark}
                              </div>
                            </Grid>
                          </Grid>

                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              aria-controls="menu-list"
                              aria-haspopup="true"
                              onClick={e =>
                                handleClick(e, v?.PaymentItemID, index, v)
                              }
                            >
                              <MoreVert />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>

                      <Divider
                        variant="fullWidth"
                        style={{ background: '#E4E4E4' }}
                      ></Divider>

                      <Menu
                        id="menu-list"
                        key={index}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        onClick={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            if (formMode === 'add')
                              history.push({
                                pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${formMode}/payment-item/${menu?.obj?.PaymentItemID}/edit`,
                                state: { ...editData, files: files },
                              })
                            else {
                              history.push({
                                pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${PaymentID}/${formMode}/payment-item/${menu?.obj?.PaymentItemID}/edit`,
                                state: { ...editData, files: files },
                              })
                            }
                            localStorage.setItem(
                              'payment',
                              JSON.stringify({
                                Description: getValues('Description'),
                                PayeeName: getValues('PayeeName'),
                                DocDate: getValues('DocDate'),
                                TransactionDate: getValues('TrxDate'),
                                RefNo: getValues('RefNo'),
                                PaymentMethodID: getValues('PaymentMethodID'),
                                CreditCardID: getValues('CreditCardID'),
                                ChequeNo: getValues('ChequeNo'),
                                ChequeDate: getValues('ChequeDate'),
                                DocAmt: getValues('DocAmt'),
                              })
                            )
                          }}
                        >
                          <span className="mdDesc">Edit</span>
                        </MenuItem>

                        <MenuItem
                          onClick={() => handleDeletePaymentDetail(index)}
                        >
                          <span className="mdDesc">Delete</span>
                        </MenuItem>
                      </Menu>
                      <Divider
                        variant="fullWidth"
                        style={{ background: '#E4E4E4' }}
                      ></Divider>
                    </>
                  )
                })
            )}
          </div>
        </CardContents>

        <div style={{ marginBottom: '50px' }} />
      </ContentWrapper>

      <AddFavoriteDialog
        openFavoriteDialog={openFavoriteDialog}
        setOpenFavoriteDialog={setOpenFavoriteDialog}
        favRegister={favRegister}
        favControl={favControl}
        favNames={favNames}
        favErrors={favErrors}
        favWatch={favWatch}
        dialogTitle={'Payment Detail'}
        handleFavSubmit={handleFavSubmit}
        onSubmitFavorite={onSubmitFavorite}
      />

      <DeleteFavoriteDialog
        openFavoriteDeleteDialog={openFavoriteDeleteDialog}
        setOpenFavoriteDeleteDialog={setOpenFavoriteDeleteDialog}
        dialogTitle={'Favorite Payment Entry'}
        menuFav={menuFav}
        deleteMutation={deleteFavoritePayment}
        favRefetchQuery={favRefetchQuery}
        favID={menuFav?.ID}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(
            `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment`
          )
          localStorage.removeItem('paymentItem')
          localStorage.removeItem('payment')
          localStorage.removeItem('attachment')
        }}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />

      {footerOptions?.length > 0 ? (
        <AccountFooter options={[...footerOptions]} />
      ) : null}

      <TooltipAmountFooter data={paymentItems} module={'CB-payment-draft'} />
    </>
  )
}
