import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import React, { useState } from 'react'

interface LeaseContractSubheaderProps {
  getLeaseContract: any
  contractNo: string
  leasePayment: string
  startDate: any
  endDate: any
  leaseMonth: number
  totalLeaseAmount: string
}

export const LeaseContractSubheader = ({
  getLeaseContract,
  contractNo,
  leasePayment,
  startDate,
  endDate,
  leaseMonth,
  totalLeaseAmount,
}: LeaseContractSubheaderProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  //=====================================
  //===============FOOTER================
  //=====================================
  const dialogFooter: any[] = [
    {
      displayText: 'Close',
      props: {
        onClick: () => {
          setOpenDialog(false)
        },
        variant: 'contained',
        color: 'primary',
        type: 'submit',
      },
    },
  ]

  //=====================================
  //==============FUNCTION===============
  //=====================================
  const dialogBody = () => {
    return (
      <CardContents>
        <div className="content-wrap left">
          <div className="desc" style={{ fontWeight: 300 }}>
            Monthly Lease Payment
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {leasePayment}
          </div>
        </div>
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Total Lease Payment
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {totalLeaseAmount}
          </div>
        </div>
        <div className="content-wrap left">
          <div className="desc" style={{ fontWeight: 300 }}>
            Discounted Total Cash Flows
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {amtStr(getLeaseContract[0]?.DiscountedTotalCashFlows)}
          </div>
        </div>
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Account ROU
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {!!getLeaseContract[0]?.IsAccountROU ? 'Yes' : 'No'}
          </div>
        </div>
        <div className="content-wrap left">
          <div className="desc" style={{ fontWeight: 300 }}>
            Start Journal Date
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {startDate}
          </div>
        </div>
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Annual Discount Rate
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {amtStr(getLeaseContract[0]?.AnnualDiscountRate)}
          </div>
        </div>
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Description
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {getLeaseContract[0]?.Description}
          </div>
        </div>
      </CardContents>
    )
  }

  return (
    <>
      <DynamicSubHeader
        title={
          <span
            className="click-text"
            onClick={() => {
              setOpenDialog(true)
            }}
          >
            {contractNo}
          </span>
        }
        rightText={leasePayment}
        infoLine={`${startDate} - ${endDate} ${leaseMonth} Month`}
        rightInfoLine={totalLeaseAmount}
      />
      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
        sections={{
          header: {
            title: contractNo,
            infoLine: `${startDate} - ${endDate}`,
            rightInfoLine: `${leaseMonth} Month`,
          },
          body: dialogBody,
          footer: { actions: [...dialogFooter] },
        }}
      />
    </>
  )
}
