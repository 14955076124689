import {
  DocumentType,
  ModuleType,
  useGetBankTransferLazyQuery,
  useGetLetterTemplateByModuleLazyQuery,
  useGetPaymentLazyQuery,
  useGetReceiptLazyQuery,
} from 'generated/graphql'
import { useEffect } from 'react'

export const useCBQueries: any = ({
  docType,
  CompanyID,
  BankAccountID,
  TransactionID,
  setErrMessage,
  setErrDialog,
}) => {
  //----------CB Queries----------//
  const [
    fetchPayment,
    {
      loading: PaymentLoading,
      called: PaymentCalled,
      data: { getPayment } = { getPayment: [] },
    },
  ] = useGetPaymentLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    fetchReceipt,
    {
      loading: ReceiptLoading,
      called: ReceiptCalled,
      data: { getReceipt } = { getReceipt: [] },
    },
  ] = useGetReceiptLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    fetchBankTransfer,
    {
      loading: BankTransferLoading,
      called: BankTransferCalled,
      data: { getBankTransfer } = { getBankTransfer: [] },
    },
  ] = useGetBankTransferLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    fetchLetterTemplate,
    {
      loading: LetterTemplateLoading,
      data: { getLetterTemplateByModule: templateBody } = {
        getLetterTemplateByModule: '',
      },
    },
  ] = useGetLetterTemplateByModuleLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  let loading, called, listData, subMenuName, primaryKey

  switch (docType) {
    case 'payment':
      loading = PaymentLoading
      called = PaymentCalled
      listData = getPayment
      subMenuName = 'Payment'
      primaryKey = 'PaymentID'
      break

    case 'receipt':
      loading = ReceiptLoading
      called = ReceiptCalled
      listData = getReceipt
      subMenuName = 'Receipt'
      primaryKey = 'ReceiptID'
      break

    case 'bank-transfer':
      loading = BankTransferLoading
      called = BankTransferCalled
      listData = getBankTransfer
      subMenuName = 'Bank Transfer'
      primaryKey = 'BankTransferID'
      break
  }

  useEffect(() => {
    switch (docType) {
      case 'payment':
        fetchPayment({
          variables: {
            CompanyID,
            BankAccountID,
            PaymentID: TransactionID,
          },
        })
        fetchLetterTemplate({
          variables: {
            LetterCategory: ModuleType.Cb,
            LetterType: DocumentType.Payment,
            CompanyID,
          },
        })
        break

      case 'receipt':
        fetchReceipt({
          variables: {
            CompanyID,
            BankAccountID,
            ReceiptID: TransactionID,
          },
        })
        fetchLetterTemplate({
          variables: {
            LetterCategory: ModuleType.Cb,
            LetterType: DocumentType.Receipt,
            CompanyID,
          },
        })
        break

      case 'bank-transfer':
        fetchBankTransfer({
          variables: {
            CompanyID,
            BankAccountID,
            BankTransferID: TransactionID,
          },
        })
        fetchLetterTemplate({
          variables: {
            LetterCategory: ModuleType.Cb,
            LetterType: DocumentType.BankTransfer,
            CompanyID,
          },
        })
        break
    }
  }, [docType])

  return {
    loading,
    called,
    listData,
    subMenuName,
    primaryKey,
    LetterTemplateLoading,
    templateBody,
  }
}
