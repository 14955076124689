import React, { lazy } from 'react'
import { LeaseContractMain } from './LeaseContractMain'
import { LeaseContractForm } from './LeaseContractForm/LeaseContractForm'
import { LeaseContractDetail } from './LeaseContractListing/LeaseContractPostList'

// const UOMExchangeListing = lazy(() =>
//   import(
//     '@ifca-root//UOMExchange/UOMRateListing'
//   ).then(module => ({
//     default: module.UOMRateListing,
//   })),
// );

const leaseContractRoutes = [
  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/mfrs16/:CompanyID/lease-contract',
    },
    component: (
      <LeaseContractMain
        transactionType="Lease Contract"
        transactionTypePrefix="lease-contract"
      />
    ),
  },
  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/mfrs16/:CompanyID/lease-contract/add',
    },
    component: <LeaseContractForm formMode={'add'} />,
  },
  {
    props: {
      exact: true,
      path: '/mfrs16/:CompanyID/lease-contract/:LeaseContractID/edit',
    },
    component: <LeaseContractForm formMode={'edit'} />,
  },
  /* -------------------------------------------- */
  /*                   DETAIL                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/mfrs16/:CompanyID/lease-contract/:LeaseContractID/post',
    },
    component: <LeaseContractDetail mode={'post'} />,
  },
  {
    props: {
      exact: true,
      path: '/mfrs16/:CompanyID/lease-contract/:LeaseContractID/detail',
    },
    component: <LeaseContractDetail mode={'detail'} />,
  },
]

export default leaseContractRoutes
