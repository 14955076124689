import AnimatedFloatButton from '@ifca-root/react-component/src/components/Button/AnimatedFloatButton'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons'
import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { AcctPermission, ApprovalStatus } from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useContext, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useParams } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import PublishIcon from '@material-ui/icons/Publish'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'

/* -------------------------------------------- */
/*            For ACTIVE and APPROVED           */
/* -------------------------------------------- */

export const AssetsCountEntryList = (props: any) => {
  const {
    filteredList,
    listLoading,
    listStatus,
    userList,
    fetchMore,
    setOriginalListing,
  } = props
  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  let history = useHistory()
  const { CompanyID }: any = useParams()
  //search part -----------------------------------------

  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const { handlePermDisabled } = usePermissionChecker()
  const [openUpload, setOpenUpload] = useState(false)
  const [open, setOpen] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const [deleteAA, setDeleteAA] = useState(false)
  const actions = [
    {
      icon: (
        <AddIcon
          onClick={() =>
            history.push(`/fixed-assets/${CompanyID}/assets-count/add`)
          }
        />
      ),
      name: 'Add',
    },
    {
      icon: <PublishIcon onClick={() => setOpenUpload(true)} />,
      name: 'Import',
    },
  ]

  console.log(CompanyID)

  return (
    <>
      {listLoading && <Loading />}

      <ContentWrapper advSearch float>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                fetchMore({
                  variables: {
                    CompanyID: CompanyID,
                    StatusArr: [listStatus],
                    take: 30,
                    skip: currentLength,
                    searchValue:
                      getSearch?.name === '' || getSearch?.name === undefined
                        ? undefined
                        : getSearch?.name,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                listLoading && (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {advancedFilterList(filteredList)
                ?.sort((a, b) => {
                  return (
                    Object.values(ApprovalStatus).indexOf(a?.ApprovalStatus) -
                    Object.values(ApprovalStatus).indexOf(b?.ApprovalStatus)
                  )
                })
                ?.map((el, index) => {
                  return (
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            <span className={`xsTitle flex-space`}>
                              {el?.Name}
                            </span>
                            <span className={`Flex-space label`}>
                              {formatDate(el?.Date)}
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            {listStatus === 'ACTIVE' && (
                              <div className="desc flex-space">
                                <span className="desc">
                                  <span style={{ color: 'orange' }}>
                                    {el?.Counts}
                                  </span>{' '}
                                  item counts |{' '}
                                  <span style={{ color: 'red' }}>
                                    {el?.IncorrectCount}
                                  </span>{' '}
                                  Item Incorrect Counts
                                </span>
                                <div className="desc flex-space">
                                  {el?.Description}
                                </div>
                              </div>
                            )}

                            {(listStatus === 'COMPLETED' ||
                              listStatus === 'REJECTED') && (
                              <>
                                <span className={`desc flex-space`}>
                                  {el?.Description}
                                </span>
                                <span className="desc">
                                  <span style={{ color: 'orange' }}>
                                    {el?.Counts}
                                  </span>{' '}
                                  item counts
                                </span>
                              </>
                            )}
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        {listStatus === 'ACTIVE' ||
                        listStatus === 'REJECTED' ? (
                          <>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              aria-controls="menu-list"
                              aria-haspopup="true"
                              onClick={e =>
                                handleClick(e, el?.AssetsCountsID, index, el)
                              }
                            >
                              <MoreVert />
                            </IconButton>
                          </>
                        ) : (
                          <IconButton edge="end" aria-label="arrow">
                            <KeyboardArrowRight
                              onClick={() =>
                                history.push({
                                  pathname: `/fixed-assets/:CompanyID/assets-count/detail`,
                                  state: el,
                                })
                              }
                            />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </InfiniteScroll>
          )}
        </List>
        {listStatus === 'ACTIVE' && <AnimatedFloatButton actions={actions} />}
      </ContentWrapper>

      {listStatus === 'ACTIVE' ? (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.FixedAssetsRevaluationUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/fixed-assets/${CompanyID}/assets-count/edit`,
                state: menu?.obj,
              })
            }}
          >
            <span className="">Edit</span>
          </MenuItem>
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.FixedAssetsAssetsCountDelete,
            })}
            onClick={() => setDeleteAA(true)}
          >
            <span>Delete</span>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.FixedAssetsAssetsCountUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/fixed-assets/${CompanyID}/assets-count/resubmit`,
                state: { ...menu?.obj, mode: 'resubmit' },
              })
            }}
          >
            <span className="">Resubmit</span>
          </MenuItem>
        </Menu>
      )}

      <CommonDialog
        fullWidth={true}
        open={openUpload}
        onClose={() => {
          setOpenUpload(false)
          setFiles([])
        }}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <span
                    className="title c-orange desc"
                    style={{
                      fontSize: '13px',
                    }}
                  >
                    Import Excel
                  </span>

                  <span
                    className="title c-orange"
                    style={{
                      fontSize: '13px',
                      textAlign: 'right',
                    }}
                  ></span>
                </div>
              </div>
            ),
            title: menu?.obj?.Name,
          },
          body: () => (
            <>
              <div style={{ width: '100%', marginTop: '24px' }}>
                <FileUploadInput
                  // placeholder={previewFiles.length === 0 ? 'Attachment' : null}
                  label={'File'}
                  name="File"
                  required
                  files={files}
                  onHandleUploadChange={handleUploadChange}
                  accept={'.xlsx, .xls, .cxv'}
                  singleFile
                  singleFileClear={setFiles}
                />
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => {
                    setOpenUpload(false)
                    setFiles([])
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => history.goBack(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <DeleteDialog
        ID={menu?.ID}
        openDeleteDialog={deleteAA}
        setOpenDeleteDialog={setDeleteAA}
        title={'asset Count'}
        menu={menu?.obj}
        // handleDelete={handleDelete}
        passID={menu?.ID}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error'}
      />
    </>
  )
}
