import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  useGetCompanyNameQuery,
  useGetCostCentreLazyQuery,
  useGetEntityCoaQuery,
  useGetMasterCoaQuery,
} from 'generated/graphql'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { uuid } from 'uuidv4'
import * as yup from 'yup'
import '../RecurringJournal.scss'

interface RecurringJournalItemProps {
  MasterCOAID: string
  DocAmt: number
  CostCentreID: string
  Remark: string
}

export const RecurringJournalItemForm = (props: any) => {
  const {
    CompanyID,
    RecurringJournalID,
    RecurringJournalItemID,
    formMode,
    detailMode,
  }: any = useParams()

  let form, mode
  switch (detailMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Edit'
      mode = 'edit'
  }

  let history = useHistory()

  let location = useLocation()
  const editData = location?.state as any

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */
  const RecurringJournalItemSchema = yup.object().shape({
    MasterCOAID: CommonYupValidation.requireField('Account Code is required'),
    CostCentreID: CommonYupValidation.requireField('Department is required'),
    Remark: CommonYupValidation.requireField('Remark is required'),
    DocAmt: yup
      .string()
      .notOneOf([0, '0', '0.00', '-0', '-0.00'], 'Amount cannot be 0')
      .required('Amount is required'),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    reset,
    watch,
    clearErrors,
  } = useForm<RecurringJournalItemProps>({
    defaultValues: {
      MasterCOAID:
        RecurringJournalItemID && editData ? editData?.MasterCOAID : '',
      DocAmt: RecurringJournalItemID && editData ? editData?.DocAmt : 0,
      CostCentreID:
        RecurringJournalItemID && editData ? editData?.CostCentreID : '',
      Remark: RecurringJournalItemID && editData ? editData?.Remark : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(RecurringJournalItemSchema),
  })

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [docAmt, setDocAmt] = useState(
    amtNumStr(RecurringJournalItemID && editData?.DocAmt) ?? 0
  )
  const [openExitConf, setOpenExitConf] = useState(null)
  const [text, setText] = useState(mode === 'add' ? '' : editData?.remark)
  const [record, setRecord] = useState(false)
  const [audioInput, setAudioInput] = useState('')
  const [singleRemark, setSingleRemark] = useState(null)
  const handleSingleRemark = remark => {
    setSingleRemark(remark)
  }
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [defaultCostCentre, loadDefaultCostCentre] = useState(null)
  const [accountType, setAccountType] = useState('')

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */
  //Company
  const {
    loading: CompanyLoading,
    error: CompanyErrors,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
    onCompleted: data => {
      loadDefaultCostCentre(data?.getCompany[0]?.DefaultCostCentre)
    },
  })

  useEffect(() => {
    if (RecurringJournalItemID && editData?.Remark) {
      setText(editData?.Remark)
      handleSingleRemark(RecurringJournalItemID && editData?.Remark)
      setAudioInput(RecurringJournalItemID && editData?.Remark)
    }
  }, [editData])

  //EntityCOA
  const {
    loading: EntityCOALoading,
    error: EntityCOAErros,
    data: { getEntityCOA } = { getEntityCOA: [] },
  } = useGetEntityCoaQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
    onCompleted: ({ getMasterCOA }) => {
      if (getMasterCOA?.length > 0) {
        if (!!editData) {
          const findcoa = getMasterCOA?.find(
            x => x?.MasterCOAID === editData?.MasterCOAID
          )
          setAccountType(findcoa?.AccountType)
        }
      }
    },
  })

  const masterCOAList =
    getEntityCOA?.length > 0
      ? getEntityCOA
          ?.map(coa => coa?.MasterCOA)
          ?.filter(coa => coa?.IsLastNode === true && coa?.IsControl === false)
      : getMasterCOA?.filter(
          coa => coa?.IsLastNode === true && coa?.IsControl === false
        )

  //CostCentre
  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      error: CostCentreErrors,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
  })

  useEffect(() => {
    if (!!watch('MasterCOAID')) {
      fetchCostCentre({
        variables: {
          CompanyID: CompanyID,
          MasterCOAID: watch('MasterCOAID'),
          IsLastNode: true,
        },
      })
    }
  }, [watch('MasterCOAID')])

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  const handleAmtChange = event => {
    setDocAmt(event.value)
  }

  const handleDefaultCostCentre = (accType, coaId) => {
    setAccountType(accType)
    if (
      accType === 'ASSET' ||
      accType === 'LIABILITY' ||
      accType === 'EQUITY'
    ) {
      setValue('CostCentreID', defaultCostCentre?.CostCentreID)
    } else {
      reset({ CostCentreID: null })
      fetchCostCentre({
        variables: {
          CompanyID: CompanyID,
          MasterCOAID: coaId,
          IsLastNode: true,
        },
      })
    }
  }

  /* -------------------------------------------- */
  /*                   USE EFFECT                 */
  /* -------------------------------------------- */
  useEffect(() => {
    if (RecurringJournalItemID && editData?.Remark) {
      setValue('Remark', editData?.Remark)
    }
  }, [editData])

  const hasChanges = () => {
    const hasInfo =
      (watch('MasterCOAID') !== '' && watch('MasterCOAID') !== undefined) ||
      (watch('DocAmt').toString() !== '' && watch('DocAmt') !== undefined) ||
      (watch('CostCentreID')?.length !== 0 &&
        watch('CostCentreID') !== undefined) ||
      (watch('Remark')?.length !== 0 && watch('Remark') !== undefined)

    let prevData = {
      MasterCOAID: editData?.MasterCOAID,
      CostCentreID: editData?.CostCentreID,
      Remark: editData?.Remark,
      DocAmt: editData?.DocAmt,
    }
    let currentData = {
      MasterCOAID: watch('MasterCOAID'),
      CostCentreID: watch('CostCentreID'),
      Remark: watch('Remark'),
      DocAmt: watch('DocAmt'),
    }

    if (detailMode === 'edit') {
      if (JSON.stringify(prevData) === JSON.stringify(currentData)) return false
      else return true
    } else {
      if (hasInfo === true) return true
      else return false
    }
  }

  //--- Submit Payment Item to LocalStorage
  const onSubmit = data => {
    const prvdetail =
      detailMode === 'add'
        ? JSON.parse(localStorage.getItem('recurringJournalItem')) || []
        : JSON.parse(localStorage.getItem('recurringJournalItem')).filter(
            x => x?.RecurringJournalItemID !== RecurringJournalItemID
          )

    localStorage.setItem(
      'recurringJournalItem',
      JSON.stringify([
        ...(prvdetail?.length === null || 0 ? null : prvdetail),

        {
          Sequence: editData?.Sequence ?? prvdetail?.length + 1,
          RecurringJournalItemID:
            detailMode === 'add' ? uuid() : RecurringJournalItemID,
          MasterCOAID:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? data.MasterCOAID
              : editData?.MasterCOAID,
          MasterCOACode:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? getEntityCOA.filter(
                  coa => coa?.MasterCOAID === data.MasterCOAID
                )[0]?.MasterCOA?.Code
              : getEntityCOA.filter(
                  coa => coa?.MasterCOAID === editData?.MasterCOAID
                )[0]?.MasterCOA?.Code,
          MasterCOAName:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? getEntityCOA.filter(
                  coa => coa?.MasterCOAID === data.MasterCOAID
                )[0]?.MasterCOA?.Name
              : getEntityCOA.filter(
                  coa => coa?.MasterCOAID === editData?.MasterCOAID
                )[0]?.MasterCOA?.Name,
          DocAmt:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? parseFloat(amtNumStr(data.DocAmt))
              : editData?.DocAmt,
          CostCentreID: data.CostCentreID,
          CostCentreCode: getCostCentre.filter(
            ccc => ccc?.CostCentreID === data.CostCentreID
          )[0]?.Code,
          CostCentreName: getCostCentre.filter(
            ccc => ccc?.CostCentreID === data.CostCentreID
          )[0]?.Name,
          Remark: data.Remark,
        },
      ])
    )

    if (formMode === 'add') {
      history.push({
        pathname: `/general-ledger/${CompanyID}/recurring-journal/${formMode}`,
        state: { ...editData },
      })
    } else if (formMode === 'edit' || formMode === 'approve-reject')
      history.push({
        pathname: `/general-ledger/${CompanyID}/recurring-journal/${RecurringJournalID}/${formMode}`,
        state: { ...editData, detailMode: 'add' },
      })
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {masterCOALoading && <Loading />}
      {EntityCOALoading && <Loading />}
      {CostCentreLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            if (formMode === 'add')
              history.push({
                pathname: `/general-ledger/${CompanyID}/recurring-journal/${formMode}`,
                state: { ...editData },
              })
            else if (formMode === 'edit' || formMode === 'approve-reject')
              history.push({
                pathname: `/general-ledger/${CompanyID}/recurring-journal/${RecurringJournalID}/${formMode}`,
                state: { ...editData, detailMode: 'add' },
              })
          }
        }}
        smTitle={'General Ledger'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'General Ledger' },
          { name: 'Recurring Journal', current: true },
        ]}
        rightRouteSegments={[
          { name: detailMode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />

      <ContentWrapper float>
        <CardContents
          section={{
            header: {
              title: 'Journal Detail',
            },
          }}
        >
          <Controller
            render={({ value, onChange }) => {
              const editCOA = masterCOAList?.filter(
                coa => coa?.MasterCOAID === editData?.MasterCOAID
              )[0]
              const selectedCOA = masterCOAList?.filter(
                coa => coa?.MasterCOAID === value
              )[0]
              return (
                <Autocomplete
                  options={
                    masterCOAList?.sort((a, b) => {
                      return a?.Code.localeCompare(b?.Code)
                    }) || []
                  }
                  getOptionLabel={option => `${option?.Code} | ${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    onChange(newValue?.MasterCOAID)
                    setValue('CostCentreID', null)
                    handleDefaultCostCentre(
                      newValue?.AccountType,
                      newValue?.MasterCOAID
                    )
                  }}
                  disabled={mode === 'approve-reject'}
                  PopperComponent={AccCodeDropdownFullWidth}
                  renderOption={(props, option) => {
                    return (
                      <div>
                        <div>
                          <span className="xsTitle">{props?.Code}</span>
                        </div>
                        <div className="desc">{props?.Name}</div>
                      </div>
                    )
                  }}
                  renderInput={(params: any) => {
                    if (detailMode === 'edit') {
                      params.inputProps.value = `${editCOA?.Code} | ${editCOA?.Name}`
                    }

                    return (
                      <div>
                        <TextField
                          {...params}
                          value={
                            selectedCOA
                              ? `${selectedCOA?.Code} | ${selectedCOA?.Name}`
                              : null
                          }
                          defaultValue={
                            editCOA
                              ? `${editCOA?.Code} | ${editCOA?.Name}`
                              : null
                          }
                          helperText={errors?.MasterCOAID?.message}
                          error={errors?.MasterCOAID ? true : false}
                          label="Account Code *"
                          style={{ width: '100%' }}
                          disabled={mode === 'approve-reject'}
                          InputLabelProps={
                            selectedCOA ? { shrink: true } : null
                          }
                          margin="normal"
                          variant="standard"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Account Code "
            name="MasterCOAID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.MasterCOAID?.message}
            error={errors?.MasterCOAID ? true : false}
            required
            disabled={formMode === 'approve-reject' ? true : false}
          />
          {accountType === 'ASSET' ||
          accountType === 'LIABILITY' ||
          accountType === 'EQUITY' ? (
            <Controller
              render={({ value, onChange }) => {
                return (
                  <TextField
                    value={`${defaultCostCentre?.Code} | ${defaultCostCentre?.Name}`}
                    label="Department *"
                    fullWidth
                    disabled
                  />
                )
              }}
              label="Department *"
              name="CostCentreID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CostCentreID?.message}
              error={errors?.CostCentreID ? true : false}
              required
              disabled={formMode === 'approve-reject' ? true : false}
            />
          ) : (
            !CostCentreLoading && (
              <Controller
                render={({ value, onChange }) => {
                  const editCC = getCostCentre?.filter(
                    cc => cc?.CostCentreID === editData?.CostCentreID
                  )[0]
                  const selectedCC = getCostCentre?.filter(
                    cc => cc?.CostCentreID === value
                  )[0]
                  return (
                    <Autocomplete
                      options={
                        getCostCentre?.sort((a, b) => {
                          return a?.Code.localeCompare(b?.Code)
                        }) || []
                      }
                      getOptionLabel={option =>
                        option?.Code !== undefined
                          ? `${option?.Code} | ${option?.Name}`
                          : ''
                      }
                      fullWidth
                      onChange={(value, newValue: any) => {
                        onChange(newValue?.CostCentreID)
                      }}
                      disabled={mode === 'approve-reject'}
                      PopperComponent={AccCodeDropdownFullWidth}
                      renderOption={(props, option) => {
                        return (
                          <div>
                            <div>
                              <span className="xsTitle">{props?.Code}</span>
                            </div>
                            <div className="desc">{props?.Name}</div>
                          </div>
                        )
                      }}
                      renderInput={(params: any) => {
                        if (detailMode === 'edit') {
                          params.inputProps.value = `${editCC?.Code} | ${editCC?.Name}`
                        }

                        return (
                          <div>
                            <TextField
                              {...params}
                              value={
                                selectedCC
                                  ? `${selectedCC?.Code} | ${selectedCC?.Name}`
                                  : null
                              }
                              defaultValue={
                                editCC
                                  ? `${editCC?.Code} | ${editCC?.Name}`
                                  : null
                              }
                              helperText={errors?.CostCentreID?.message}
                              error={errors?.CostCentreID ? true : false}
                              label="Department *"
                              style={{ width: '100%' }}
                              disabled={mode === 'approve-reject'}
                              InputLabelProps={
                                selectedCC ? { shrink: true } : null
                              }
                              margin="normal"
                            />
                          </div>
                        )
                      }}
                    />
                  )
                }}
                label="Department *"
                name="CostCentreID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="normal"
                ref={register}
                helperText={errors?.CostCentreID?.message}
                error={errors?.CostCentreID ? true : false}
                required
                disabled={formMode === 'approve-reject' ? true : false}
              />
            )
          )}

          <Controller
            as={<NumberFormat />}
            thousandSeparator
            customInput={TextField}
            id="standard-basic"
            name="DocAmt"
            label="Amount"
            value={docAmt}
            autoComplete="off"
            control={control}
            onValueChange={e => {
              handleAmtChange(e)
            }}
            decimalScale={2}
            fixedDecimalScale
            margin="normal"
            required
            helperText={errors?.DocAmt?.message}
            error={errors?.DocAmt ? true : false}
            ref={register}
            disabled={formMode === 'approve-reject' ? true : false}
          />

          <VoiceTextField
            name="Remark"
            mounted={true}
            label="Remark"
            required
            value={audioInput}
            record={record}
            setRecord={setRecord}
            setValue={setValue}
            helperTextProps={{
              helperText: errors?.Remark?.message,
              error: errors?.Remark ? true : false,
            }}
            customOnchange
            register={register}
            control={control}
            clearErrors={clearErrors}
            handleCustomOnChange={e => {
              setText(e.target.value)
              handleSingleRemark(e.target.value)
            }}
          />
        </CardContents>
      </ContentWrapper>

      <AccountFooter
        options={[
          {
            name: 'Save',
            color: 'primary',
            onClick: () => {
              handleSubmit(data => onSubmit(data))()
            },
            props: {
              type: 'submit',
            },
          },
        ]}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          if (formMode === 'add')
            history.push({
              pathname: `/general-ledger/${CompanyID}/recurring-journal/${formMode}`,
              state: { ...editData },
            })
          else {
            history.push({
              pathname: `/general-ledger/${CompanyID}/recurring-journal/${RecurringJournalID}/${formMode}`,
              state: { ...editData, detailMode: 'add' },
            })
          }
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
