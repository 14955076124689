import React from 'react'
import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import { WriteOffDisposalForm } from './WriteOffDisposalForm/WriteOffDisposalForm'
import { WriteOffDisposalDetail } from './WriteOffDisposalDetail/WriteOffDisposalDetail'
import { WriteOffDisposalMain } from './WriteOffDisposalMain'

const WriteOffDisposalRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/revaluation/filter',
    },
    component: (
      <AdvancedFilter
        mode="revaluation"
        app="fixed-assets"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/write-off-disposal',
    },
    component: <WriteOffDisposalMain />,
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/write-off-disposal/add',
    },
    component: <WriteOffDisposalForm />,
  },

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/write-off-disposal/edit',
    },
    component: <WriteOffDisposalForm formMode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/write-off-disposal/approve-reject',
    },
    component: <WriteOffDisposalForm Mode="approve-reject" />,
  },

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/write-off-disposal/resubmit',
    },
    component: <WriteOffDisposalForm formMode="resubmit" />,
  },

  /* -------------------------------------------- */
  /*                     DETAIL                   */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/write-off-disposal/detail',
    },
    component: <WriteOffDisposalDetail />,
  },

  {
    props: {
      exact: true,
      path: '/fixed-assets/:CompanyID/write-off-disposal/detail/workdesk',
    },
    component: <WriteOffDisposalDetail mode="workdesk" />,
  },

  {
    props: {
      exact: true,
      path:
        '/fixed-assets/:CompanyID/write-off-disposal/detail/workdesk-history',
    },
    component: <WriteOffDisposalDetail mode="workdesk-history" />,
  },
]

export default WriteOffDisposalRoutes
